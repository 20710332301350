import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// FIREBASE
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';

// MODELS
import { Tenant, TenantSubscription } from '@app/core/models/tenant.model';
import { Result } from '@app/core/models/api.model';

// ENV
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class NewTenantService {

  private showFormSubject = new BehaviorSubject<boolean>(true);
  public showForm = this.showFormSubject.asObservable().pipe(distinctUntilChanged());

  private showPlanSubject = new BehaviorSubject<boolean>(false);
  public showPlan = this.showPlanSubject.asObservable().pipe(distinctUntilChanged());



  // Handles details on new tenant
  private newTenantsDetailsSubject = new BehaviorSubject<Tenant>({} as Tenant);
  public newTenantsDetails = this.newTenantsDetailsSubject.asObservable().pipe(distinctUntilChanged());

  // Holds name of the selected plan
  private newTenantPlanSubject = new BehaviorSubject<string>('');
  public newTenantsPlan = this.newTenantPlanSubject.asObservable().pipe(distinctUntilChanged());

  // Holds terms of selected payment plan. (Annual or Monthly)
  private newTenantPaymentTermSubject = new BehaviorSubject<string>('');
  public newTenantPaymentTerm = this.newTenantPaymentTermSubject.asObservable().pipe(distinctUntilChanged());

  tenantRef: AngularFireList<Tenant>;
  tenants: Observable<any[]>;

  baseUrl: string = environment.api.url;

  constructor(
    private db: AngularFireDatabase,
    private http: HttpClient,
  ) {
    this.tenantRef = this.db.list('tenants');
    this.tenants = this.tenantRef.snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }
  

  // Stores a temp object of submitted form data
  storeNewTenantFormData(data: object) {

    const plan = this.getSelectedPlan();
    var templates: any = 0;
    if (plan === 'startup') {
      templates = 1;
    } else {
      templates = 'unlimited';
    }

    console.log(data);
    var tempTenant: Tenant = {
      id: '',
      name: data['organizationName'],
      description: '',
      logo: '',
      url_name: '',
      website: '',
      industry: data['organizationIndustry'],
      size: data['organizationSize'],
      available_templates: templates,
      contact_name: `${data['firstName']} ${data['lastName']}`,
      contact_phone: '',
      contact_email: data['email']
    }
    this.newTenantsDetailsSubject.next(tempTenant);

    console.log(tempTenant);


    // TODO: Add Potential Client to the CRM/EMAIL marketing list (incase of a cancellation of form finish)
  }


  // Sets the selected plan to set tenant up on
  setSelectedPlan(plan: string, term: string) {
    this.newTenantPlanSubject.next(plan);
    this.newTenantPaymentTermSubject.next(term);
  }
  getSelectedPlan(): string {
    return this.newTenantPlanSubject.value;
  }

  showPlanSelection() {
    this.showFormSubject.next(false);
    this.showPlanSubject.next(true);
  }


  getNewTenantDetails(): Tenant {
    return this.newTenantsDetailsSubject.value;
  }


  async createTenant(data: {tenant: Tenant, stripe: TenantSubscription, owner: {}}): Promise<Result> {

    // Url of new-tenant function 
    const functionUrl = environment.api.tenants.newTenant;

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, data).toPromise().then(res => {
        const result = res as Result;
        console.log(result);
        sessionStorage.setItem('t', result.data['tenant_id']);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });

    });

  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";
// ANIMATION
import { fadeInAnimation } from '@app/shared/animations/animations';



@Component({
  selector: 'app-admin-new-template',
  templateUrl: './admin-new-template.component.html',
  styleUrls: ['./admin-new-template.component.css'],
  animations: [
    fadeInAnimation
  ]
})
export class AdminNewTemplateComponent implements OnInit {

   ngOnInit(): void {

   }
}

import { Component, OnInit } from '@angular/core';
import { SnackBarService } from "@core/notifications/services/snack-bar.service";
import { SnackBarNotification, SnackBarNotificationType } from "@core/models/snack-bar.model";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent implements OnInit {


  notifications: SnackBarNotification[] = [];
  private subscription: Subscription;

  constructor(private notificationService: SnackBarService) { }

  private addNotification(notification: SnackBarNotification) {
    this.notifications.push(notification);
    console.log('Adding Notif Success');
    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  ngOnInit() {
    this.subscription = this.notificationService.getObservable().subscribe(notification => this.addNotification(notification));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(notification: SnackBarNotification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }


  className(notification: SnackBarNotification): string {

    let style: string;

    switch (notification.type) {

      case SnackBarNotificationType.success:
        style = 'success';
        break;

      case SnackBarNotificationType.warning:
        style = 'warning';
        break;

      case SnackBarNotificationType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }

  iconName(notification: SnackBarNotification): string {

    let icon: string;
    switch (notification.type) {

      case SnackBarNotificationType.success:
        icon = 'fa-check-circle';
        break;

      case SnackBarNotificationType.warning:
        icon = 'fa-exclamation-triangle';
        break;

      case SnackBarNotificationType.error:
        icon = ' fa-exclamation-circle';
        break;

      default:
        icon = 'fa-bell';
        break;
    }

    return icon

  }
}

<div class="item">
    <div class="prompt-wrapper">
        <div class="row justify-content-center align-items-center d-flex mx-auto width">
            <div class="col-lg-12 col-12 p-0 align-self-center">

                
            
                <div class="row justify-content-center align-items-center d-flex mx-auto">
                    <div class="col-lg-6 col-12 p-0 align-self-center">
                        <app-account-link *ngIf="isAccountLink"></app-account-link>
                        <app-account-welcome *ngIf="!isAccountLink"></app-account-welcome>
                    </div>
                </div>
            
           

            </div>
        </div>
    </div>
</div>


<ngx-spinner 
    name="setupSpinner"
    [fullScreen]="true"
    bdColor="rgba(255, 255, 255, 0.9)"
    size="default"
    color="#5D656C"
    type = "ball-clip-rotate-multiple"
    >
</ngx-spinner>

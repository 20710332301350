import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInAnimation } from '@app/shared/animations/animations';

// SERVICES
import { AuthService } from '@app/core/services/auth/auth.service';
import { NewTenantService } from '@app/core/services/tenant/new-tenant/new-tenant.service';
import { UserService } from '@app/core/services/user/user.service';
import { Result } from '@app/core/models/api.model';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-account-link',
  templateUrl: './account-link.component.html',
  styleUrls: ['./account-link.component.css'],
  animations: [fadeInAnimation]
})
export class AccountLinkComponent implements OnInit, OnDestroy {

  accountLinkForm = new FormGroup({
    email: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.required,]),
    password: new FormControl('', [Validators.minLength(6), Validators.required]),
  });

  get email() { return this.accountLinkForm.get('email'); }
  get password() { return this.accountLinkForm.get('password'); }

  error: boolean = false;
  errorMessage: string;

  tenantDetailsSub: Subscription;

  constructor(
    private authService: AuthService,
    private newTenantService: NewTenantService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // prefills users email based on that entered in previous steps
    this.tenantDetailsSub = this.newTenantService.newTenantsDetails.subscribe(tenant => {
      this.accountLinkForm.patchValue({
        email: tenant.contact_email,
      });
    });
  }
  ngOnDestroy(): void {
    this.tenantDetailsSub.unsubscribe();
  }

  // 1.) Attempts to link a new users anonymous account to an email/password account
  // 2.) Converts from anonymous with same UID using provided email/password
  // 3.) If successful, asks userService to attach this new user as owner to the created tenant
  //     a.) If successful, org setup is complete, all info is created or saved.
  //     b.) Route user to dashboard for onboarding
  // 4.) If an error occurs, checks error code to identify if it's a fixable error
  //     a.) If email user tries to link is that of pre-exisiting account, user is prompted to login and link with the existing account
  //     b.) Else, show user error and allow for editing
  // 5.) Opens modal to allow user to login to exisiting account 
  linkAccount() {
    this.spinner.show('accountLinkSpinner');

    this.authService.anonymousToUser(this.email.value, this.password.value).then(res => {
      if (res) {
        const plan = this.newTenantService.getSelectedPlan();

        this.userService.addUserToTenant(this.email.value, plan, 'new').then(res => {
          const result = res as Result;
          if (result.success) {
            this.spinner.hide('accountLinkSpinner');
            this.router.navigate(['/'], { queryParams: { 'option':  'org-onboard' }})
          }
        })
      }
    }).catch(err => {
      if (err.data.code = 'auth/email-already-in-use') {
        this.spinner.hide('accountLinkSpinner');
        this.openModal(this.modalView);
      } else {
        this.spinner.hide('accountLinkSpinner');
        this.error = true;
        this.errorMessage = err.message;
        this.snackBarService.error(err.message);
      }
    });
  }


  // 1.) Once user input login credentials and submits, validate login and request the link
  // 2.) Logs user in using provided credentials
  //      a.) on error, show user auth error
  // 3.) Asks user service to attach the exisiting users id as a new user in the generated tenant
  //      a.) if successful, account linking worked. route user to dashboard.
  //      b.) on error, show user error for resolution.
  loginAndLinkAccount() {
    this.spinner.show('existingAccountLinkSpinner');
    // 2
    this.authService.newOrgExistingAccountlogin(this.email.value, this.password.value).then(res => {
      const plan = this.newTenantService.getSelectedPlan();
      // 3
      this.userService.addUserToTenant(this.email.value, plan, 'existing').then(res => {
        const result = res as Result;
        // 3a
        if (result.success) {
          this.modalRef.hide();
          this.spinner.hide('existingAccountLinkSpinner');
          this.router.navigate(['/'], { queryParams: { 'option':  'org-onboard' }})
        }
      }).catch(err => {
        // 3b
        this.spinner.hide('existingAccountLinkSpinner');
        this.error = true;
        this.errorMessage = 'An error occured while linking your account.';
        this.snackBarService.error('An error occured while linking your account.');
      })
    }).catch(err => {
      // 2b
      this.spinner.hide('existingAccountLinkSpinner');
      this.error = true;
      this.errorMessage = this.authService.handleAuthError(err.code);
      this.snackBarService.error(this.authService.handleAuthError(err.code));
    })
  }




  // Handles modals
  modalRef: BsModalRef;
  
  // Allows opening modal from inside component
  @ViewChild('existingAccountLinkModal') modalView: TemplateRef<any>;
  openModal(template: TemplateRef<any>) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, config);
  }
}

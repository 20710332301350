import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { UserService } from '@core/services/user/user.service';


@Injectable({
    providedIn: 'root'
})

export class PermissionGuard implements CanActivateChild {
  
    role: string;

    constructor(
        private router: Router,
        private userService: UserService,
    ) {
    
    }

    async canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        
        // Child guard used to ensure the current user is an Admin or Owner for route that are admin protected
        // 1.) waits for the user service to get the user object from the db
        // 2.) switches through the users role and react based on role.
        // 3.) returns true or false to router, based on whether or not the user has permissions to access the requested route. 

        // 1
        const status = await this.userService.checkCurrentUserRole().then(user => {
            this.role = user.role;
        
            // 2.
            switch (this.role) {
                case 'owner': {
                    console.log('👤 Owner Allowed');
                    return true;
                }
                case 'admin': {
                    console.log('👤 Admin Allowed');
                    return true;
                }
                case 'user': {
                    console.log('👤 User Denied');
                    this.router.navigate(['/']);
                    return false;
                }
                default: {
                    console.log('👤 Unknown Denied');
                    this.router.navigate(['/']);
                    return false;
                }
            }
        }).catch(err => {
            console.log("😡 Permissions Error: ", err);
            this.router.navigate(['/']);
            return false;
        });

        // 3.)
        console.log('😡 returning PERMISSIONS result now');
        return status
    }
}

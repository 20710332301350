import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { DepartmentsMediaService } from '@core/services/departments/departments-media/departments-media.service';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-departments-media-upload',
    templateUrl: './departments-media-upload.component.html',
    styleUrls: ['./departments-media-upload.component.css']
})
export class DepartmentsMediaUploadComponent implements OnInit {

    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    files: any[] = [];

    errorMessage: string;
    successMessage: string;

    progressSub: Subscription;
    progress: any = 0;

    constructor(
        private departmentsMediaService: DepartmentsMediaService,
        private snackBarService: SnackBarService,
    ) {
        this.progressSub = this.departmentsMediaService.uploadProgress.subscribe(value => {
            this.progress = value;
        })
    }

    ngOnInit(): void {
        this.progress = 0;
    }
    ngOnDestroy(): void {
        this.progressSub.unsubscribe();
    }


    /**
    * on file drop handler
    */
    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(files) {
        this.prepareFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(index: number) {
        
        this.files.splice(index, 1);
        this.departmentsMediaService.resetTempImageData();
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            item.progress = 0;
            if (item?.type.includes('svg')) {
                console.log('Error: SVG un supported');
                this.errorMessage = 'SVG files are not supported';
                this.snackBarService.error('SVG files are not supported');
                return;
            }
            if (item?.type.includes('image/')) {
                this.files.push(item);
                console.log('Files added 📂', item, files);
                this.errorMessage = null;
            }
        }
        this.fileDropEl.nativeElement.value = "";
        // this.uploadFilesSimulator(0);


        // Sets current file in service
        console.log("FILE TYPE: ", this.files[0].type);

        // temporarily store file iun case of a cancellation
        this.departmentsMediaService.setTempImageData(this.files[0]);
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

// Animation
import { fadeInAnimation } from '@app/shared/animations/animations'

import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Template } from '@app/core/models/template.model';
import { TemplatingService } from '@app/core/services/templates/templating.service';

import { AllQuickLinkSectionNames, AllQuickLinkContentTypes, ContentType, ContentSources, LinkTypes } from '@app/core/models/content-type-models/content-types.model';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { UrlFormatterService } from '@app/core/services/content-types/url/url-formatter.service';

// VCARD
import { VCard } from 'ngx-vcard';
import { VCardEncoding } from 'ngx-vcard';
import { VcardService } from '@app/core/services/content-types/vcard/vcard.service';
import { ContentTestingService } from '@app/core/services/content-types/content-testing/content-testing.service';

@Component({
    selector: 'app-web-quick-links',
    templateUrl: './web-quick-links.component.html',
    styleUrls: ['./web-quick-links.component.css'],
    animations: [fadeInAnimation],
})
export class WebQuickLinksComponent implements OnInit {

    templateSub: Subscription;
    currentTemplateSettings: Template;

    contentSources = ContentSources;

    isQuickLinkOneCollapsed: boolean = false;
    isQuickLinkTwoCollapsed: boolean = true;
    isQuickLinkThreeCollapsed: boolean = true;

    quickLinkOneContentTypeSelection: boolean = true;
    quickLinkOneContentEntry: boolean = false;

    quickLinkTwoContentTypeSelection: boolean = true;
    quickLinkTwoContentEntry: boolean = false;

    quickLinkThreeContentTypeSelection: boolean = true;
    quickLinkThreeContentEntry: boolean = false;

    // Hover State
    quickLinksHovered: boolean[] = [false, false, false];

    selected: boolean = false;

    quickLinkOneForm = new FormGroup({
        labelOne: new FormControl('', []),
        dataOne: new FormControl('', []),
    });

    get labelOne() { return this.quickLinkOneForm.get('labelOne'); }
    get dataOne() { return this.quickLinkOneForm.get('dataOne'); }

    quickLinkOneFormSub: Subscription;

    // Stores options for quick link content
    sectionNames = AllQuickLinkSectionNames;
    availableContentTypes = AllQuickLinkContentTypes;


    public vCardEncoding: typeof VCardEncoding = VCardEncoding;
 

    constructor(
        private templatingService: TemplatingService,
        private router: Router,
        private snackBarService: SnackBarService,
        private urlFormatterService: UrlFormatterService,
        private contentTestingService: ContentTestingService,
        private vcardService: VcardService,
    ) {
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });
    }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();

    }

    // Pushes changed data into service.
    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }





    // LINK TESTING
    // If link is testable then route to the items url, else show snackbar message
    async routeToLink(link: number) {
        const templateQuickLinkOneType = this.currentTemplateSettings.web.quick_link_one_content_type;
        const templateQuickLinkOneData = this.currentTemplateSettings.web.quick_link_one_content_data;

        const templateQuickLinkTwoType = this.currentTemplateSettings.web.quick_link_two_content_type;
        const templateQuickLinkTwoData = this.currentTemplateSettings.web.quick_link_two_content_data;

        const templateQuickLinkThreeType = this.currentTemplateSettings.web.quick_link_three_content_type;
        const templateQuickLinkThreeData = this.currentTemplateSettings.web.quick_link_three_content_data;
        
        const nonTestableLinkTypes = [LinkTypes.vcard, LinkTypes.message];

        console.log(nonTestableLinkTypes, nonTestableLinkTypes.some(type => type === templateQuickLinkOneType.ref));
        if (link === 0) {
            if (nonTestableLinkTypes.some(type => type === templateQuickLinkOneType.ref)) {
                // A non testable content type was selected
                this.showNonTestableAlert(templateQuickLinkOneType.ref);
            } else if (templateQuickLinkOneType.ref === 'sms') {
                // Test SMS
                this.contentTestingService.sendSms(templateQuickLinkOneData.phone);
            } else if (templateQuickLinkOneType.ref === 'email') {
                // Test Email
                this.contentTestingService.sendEmail(templateQuickLinkOneData.email);
            } else if (templateQuickLinkOneType.ref === 'phone') {
                // Test Phone Call
                this.contentTestingService.callNumber(templateQuickLinkOneData.phone);
            } else {
                // Test a formatted url
                if (templateQuickLinkOneData != null) {
                    var url: string;
                    await this.urlFormatterService.formatUrl(templateQuickLinkOneData.url, 
                        templateQuickLinkOneType.ref, templateQuickLinkOneType.type.source_type).then(res => {
                        url = res;
                    })
                    window.open(url, "_blank");
                } else {
                    this.snackBarService.warning('No URL is available. update the quick link, and try again');
                }
            }
        } else if (link === 1) {
            if (nonTestableLinkTypes.some(type => type === templateQuickLinkTwoType.ref)) {
                this.showNonTestableAlert(templateQuickLinkTwoType.ref);
            } else if (templateQuickLinkTwoType.ref === 'sms') {
                // Test SMS
                this.contentTestingService.sendSms(templateQuickLinkTwoData.phone);
            } else if (templateQuickLinkTwoType.ref === 'email') {
                // Test Email
                this.contentTestingService.sendEmail(templateQuickLinkTwoData.email);
            } else if (templateQuickLinkTwoType.ref === 'phone') {
                // Test Phone Call
                this.contentTestingService.callNumber(templateQuickLinkTwoData.phone);
            } else {
                // Test a formatted url
                if (templateQuickLinkTwoData != null) {
                    var url: string;
                    await this.urlFormatterService.formatUrl(templateQuickLinkTwoData.url, 
                        templateQuickLinkTwoType.ref, templateQuickLinkTwoType.type.source_type).then(res => {
                        url = res;
                    })
                    window.open(url, "_blank");
                } else {
                    this.snackBarService.warning('No URL is available. update the quick link, and try again');
                }
            }
        } else if (link === 2) {
            if (nonTestableLinkTypes.some(type => type === templateQuickLinkThreeType.ref)) {
                this.showNonTestableAlert(templateQuickLinkThreeType.ref);
            } else if (templateQuickLinkThreeType.ref === 'sms') {
                // Test SMS
                this.contentTestingService.sendSms(templateQuickLinkThreeData.phone);
            } else if (templateQuickLinkThreeType.ref === 'email') {
                // Test Email
                this.contentTestingService.sendEmail(templateQuickLinkThreeData.email);
            } else if (templateQuickLinkThreeType.ref === 'phone') {
                // Test Phone Call
                this.contentTestingService.callNumber(templateQuickLinkThreeData.phone);
            } else {
                // Test a formatted url
                if (templateQuickLinkThreeData != null) {
                    var url: string;
                    await this.urlFormatterService.formatUrl(templateQuickLinkThreeData.url, 
                        templateQuickLinkThreeType.ref, templateQuickLinkThreeType.type.source_type).then(res => {
                        url = res;
                    })
                    window.open(url, "_blank");
                } else {
                    this.snackBarService.warning('No URL is available. update the quick link, and try again');
                }
            }
        }
    }




    togglingQuickLinkOne() {
        this.isQuickLinkOneCollapsed = !this.isQuickLinkOneCollapsed;
        this.isQuickLinkTwoCollapsed = true;
        this.isQuickLinkThreeCollapsed = true;
    }
    togglingQuickLinkTwo() {
        this.isQuickLinkTwoCollapsed = !this.isQuickLinkTwoCollapsed;
        this.isQuickLinkOneCollapsed = true;
        this.isQuickLinkThreeCollapsed = true;
    }
    togglingQuickLinkThree() {
        this.isQuickLinkThreeCollapsed = !this.isQuickLinkThreeCollapsed;
        this.isQuickLinkOneCollapsed = true;
        this.isQuickLinkTwoCollapsed = true;
    }




    quickLinkContentTypeChange(type: ContentType) {
        console.log(type);
        this.currentTemplateSettings.web.quick_link_one_content_type = type;
        this.quickLinkOneContentTypeSelection = !this.quickLinkOneContentTypeSelection;
        this.quickLinkOneContentEntry = !this.quickLinkOneContentEntry;

        this.updateTemplate();
    }
    changeQuickLinkOneContentType() {
        this.quickLinkOneContentTypeSelection = !this.quickLinkOneContentTypeSelection;
        this.quickLinkOneContentEntry = !this.quickLinkOneContentEntry;
    }


    quickLinkTwoContentTypeChange(type: ContentType) {
        console.log(type);
        this.currentTemplateSettings.web.quick_link_two_content_type = type;
        this.quickLinkTwoContentTypeSelection = !this.quickLinkTwoContentTypeSelection;
        this.quickLinkTwoContentEntry = !this.quickLinkTwoContentEntry;

        this.updateTemplate();
    }
    changeQuickLinkTwoContentType() {
        this.quickLinkTwoContentTypeSelection = !this.quickLinkTwoContentTypeSelection;
        this.quickLinkTwoContentEntry = !this.quickLinkTwoContentEntry;
    }

    quickLinkThreeContentTypeChange(type: ContentType) {
        console.log(type);
        this.currentTemplateSettings.web.quick_link_three_content_type = type;
        this.quickLinkThreeContentTypeSelection = !this.quickLinkThreeContentTypeSelection;
        this.quickLinkThreeContentEntry = !this.quickLinkThreeContentEntry;

        this.updateTemplate();
    }
    changeQuickLinkThreeContentType() {
        this.quickLinkThreeContentTypeSelection = !this.quickLinkThreeContentTypeSelection;
        this.quickLinkThreeContentEntry = !this.quickLinkThreeContentEntry;
    }




    // CONTENT CONTROL FUNCS
    toggleQuickLinkContentControlOne(event) {
        this.currentTemplateSettings.web.quick_link_one_content_control = event;
        this.updateTemplate();
    }
    toggleQuickLinkContentControlTwo(event) {
        this.currentTemplateSettings.web.quick_link_one_content_control = event;
        this.updateTemplate();
    }
    toggleQuickLinkContentControlThree(event) {
        this.currentTemplateSettings.web.quick_link_one_content_control = event;
        this.updateTemplate();
    }



    // Analytics TOGGLE
    togglingQuickLinkOneAnalytics(event) {
        this.currentTemplateSettings.web.quick_link_one_analytics_enabled = event;
        this.updateTemplate();
    }
    togglingQuickLinkTwoAnalytics(event) {
        this.currentTemplateSettings.web.quick_link_one_analytics_enabled = event;
        this.updateTemplate();
    }
    togglingQuickLinkThreeAnalytics(event) {
        this.currentTemplateSettings.web.quick_link_one_analytics_enabled = event;
        this.updateTemplate();
    }




    // VCARD FORMATTER
    getVcard(link: number): VCard {
        switch(link) {
            case 0: {
                return this.vcardService.generateVcard(this.currentTemplateSettings.web.quick_link_one_content_data);
            }
            case 1: {
                return this.vcardService.generateVcard(this.currentTemplateSettings.web.quick_link_two_content_data);
            }
            case 2: {
                return this.vcardService.generateVcard(this.currentTemplateSettings.web.quick_link_three_content_data);
            }
            default: {
                // Should not run
            }
        }
    }
        




    // Shadows

    shadowHovered(shadowColor: string) {
        // Gets selected shadow color
        let rgb = this.getRGB(shadowColor);
        let style = {
            'box-shadow': `0 22px 32px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.56), 0 9px 28px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.64)`
        }
        return style; // Returns the Social Card shadow string back
    }
    shadowNotHovered(shadowColor: string) {
        let rgb = this.getRGB(shadowColor);

        let style = {
            'box-shadow': `0 22px 25px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.22), 0 9px 23px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.5)`
        };
        return style;
    }





    getRGB(str) {
        var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        return match ? {
            red: match[1],
            green: match[2],
            blue: match[3]
        } : {};
    }



    showNonTestableAlert(type: string) {
        console.log(type);
        switch (type) {
            case LinkTypes.vcard: {
                this.snackBarService.warning('Testing the Contact type is currently unavailable');
                break;
            }
            case LinkTypes.sms: {
                this.snackBarService.warning('Testing the Text type is currently unavailable');
                break;
            }
            case LinkTypes.email: {
                this.snackBarService.warning('Testing the Email type is currently unavailable');
                break;
            }
            case LinkTypes.phone: {
                this.snackBarService.warning('Testing the Phone type is currently unavailable');
                break;
            }
            default: {
                this.snackBarService.warning('Testing the this content type is currently unavailable');
                break;
            }
        }
    }

}

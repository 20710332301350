<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text">
            QR
        </div>
    </div>

    <div id="">
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Allow QR Editing
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.qr.can_edit_qr"  [(ngModel)]="currentTemplateSettings.apple.qr.can_edit_qr" (ngModelChange)="allowQrEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.qr.can_edit_qr" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.qr.can_edit_qr" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to change name field text.</small>
            </div>
            <div class="current-item-border"></div>
        </div>

        <div class="control-item control-item-switch">
            <div class="current-item-label">
                QR Analytics Enabled
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.qr.analytics_enabled"  [(ngModel)]="currentTemplateSettings.apple.qr.analytics_enabled" (ngModelChange)="analtyicsEnabled($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.qr.analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.qr.analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, analytics will be enabled for this QR code.</small>
            </div>
        </div>
    </div>

</div>




<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">

            <div class="card shadow" [@fade-in]>
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Apple Wallet Social Card</span>
                    </div>

                    <div class="text-center" *ngIf="this.currentTemplateSettings.apple.qr.type.ref != 'vcard'">
                        <qrcode [qrdata]="qrUrl" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                    <div class="text-center" *ngIf="this.currentTemplateSettings.apple.qr.type.ref === 'vcard'">
                        <qrcode [qrdata]="getVcard()" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>

                    <div class="text-center">
                        <a [routerLink]="" (click)="getFormattedLink()">Refresh Sample QR Code</a>
                    </div>
                </div>
            </div>

            <div class="card shadow mt-4" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">

                    
                        <div class="">
                            <span class="card-label mb-4">QR Setup</span>
                            <p>Select the type of content you want displayed within this QR code.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>

                        <div class="" *ngIf="itemContentTypeSelection">
                            <div class="mb-4" *ngFor="let section of availableContentTypes; let i = index;">
                                <h4 class="mb-3">{{ sectionNames[i] }}</h4>
                                <div class="row mx-auto">
                                    <div class="content-type-container" [tooltip]="option.name" *ngFor="let option of section;" (click)="qrContentTypeChange(option)">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='option.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ option.name }}</div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
                        <div class="" *ngIf="!itemContentTypeSelection">
                            <div class="">

                                <div class="d-flex justify-content-center">
                                    <div class="content-type-container" (click)="editItemContentType()">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='currentTemplateSettings.apple.qr.type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ currentTemplateSettings.apple.qr.type.name }}</div>
                                    </div>
                                </div>
                                <div class="text-center mb-4">
                                    <a [routerLink]="" (click)="editItemContentType()">Change</a>
                                </div>

                                <app-apple-wallet-qr-content-type-input
                                [type]="currentTemplateSettings.apple.qr.type.ref">
                                </app-apple-wallet-qr-content-type-input>

                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
</div>
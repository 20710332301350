// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  firebaseConfig: {
    apiKey: "AIzaSyBQ1qFrEAwKArrzogvTMwqaqTAE7VTliis",
    authDomain: "social-card-business.firebaseapp.com",
    projectId: "social-card-business",
    storageBucket: "social-card-business.appspot.com",
    messagingSenderId: "753962676414",
    appId: "1:753962676414:web:33733b9e4f45ec56422eef",
    measurementId: "G-EFY9NBDWRQ"
  },

  stripe: {
    key: 'pk_test_51I2RTuLVMpCzmpsqDUMkk4VQYIHF5X9zEE8zuEyfegYVNNfeIyrnRzWVOYEMungSaQFtA3NeMaW67xKefOwz4ylv00ADA5b6Hl',
    
    startupAnnualPriceId: 'price_1IIyp7LVMpCzmpsqawdOlVQQ',
    growthAnnualPriceId: 'price_1IIymkLVMpCzmpsq6HMKfGnZ',
    startupMonthlyPriceId: 'price_1IJ0WALVMpCzmpsqczNV1YIu',
    growthMonthlyPriceId: 'price_1IJ0TBLVMpCzmpsqqKvEpTBU',
  },

  api: {
    url: 'https://us-central1-social-card-business.cloudfunctions.net',
    
    stripe: {
      createCustomer: '/stripe/create-customer',
      getCustomer: '/stripe/get-customer',
      deleteCustomer: '/stripe/delete-customer',
      
      newSubscription: '/stripe/new-subscription',
      getSubscription: '/stripe/get-subscription',
      cancelSubscription: '/stripe/cancel-subscription',
      resumeSubscription: '/stripe/resume-subscription',
      deleteSubscription: '/stripe/delete-subscription',
      modifySubscription: '/stripe/modify-subscription',
      decreaseSubQuantity: '/stripe/decrease-sub-quantity',

      getCurrentInvoice: '/stripe/current-invoice',
      getAllInvoices: '/stripe/get-invoices',

      getPaymentMethods: '/stripe/get-payment-methods',
      updatePaymentMethod: '/stripe/update-payment-method',
    },

    tenants: {
      newTenant: '/tenants/new-tenant',
    },

    users: {
      newUser: '/users/add-user',
      updateUser: '/users/update-user',
      updateUserSettings: '/users/update-user-settings',
      changeUserRole: '/users/change-user-role',
      removeUser: '/users/remove-user',
    },

    teams: {
      inviteUsers: '/teams/invite_users', 
      resendInvite: '/teams/resend_invite',
      updateInvite: '/teams/update_invite',
    },

    departments: {
        createDepartment: '/departments/create-department', 
        updateDepartment: '/departments/update-department', 
        removeUserFromDepartment: '/departments/remove-member', 
        addMember: './departments/add-member',
        deleteDepartment: './departments/delete-department',
    },

    templates: {
        createTemplate: '/templates/create-template', 
        updateTemplate: '/templates/update-template', 
        deleteTemplate: './templates/delete-template',
    },


    pub: {
      getInvite: '/pub/get_invite',
    }
  },

  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

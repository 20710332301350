<div class="bg-container">
    <div class="parent-container text-center justify-content-center">
        <div class="child-container">
            <div class="error-text">Oops! Looks like you’re lost!</div>
            <img class="img" src="assets/404.svg">
            <p class="text-center">Page not found</p>
            <button class="btn btn-auxiliary btn-lg ml-auto mr-auto" (click)="backToSafety()">Back To Safety</button>
        </div>
    </div>

    <!-- <div class="circle-one"></div> -->
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


//Routing Module
import { HomeRoutingModule } from './home-routing.module';

//Reference Modules
import { CoreModule } from '@core/core.module';

//Reactive Forms
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { NgxSpinnerModule } from "ngx-spinner";

//Components
import { HomeComponent } from '@app/modules/home/pages/home/home.component';
import { HomeOverviewComponent } from './components/home-overview/home-overview.component';
import { CardViewComponent } from './pages/card-view/card-view.component';
import { CardViewOverviewComponent } from './components/card-view/card-view-overview/card-view-overview.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

// NGX BOOTSRAP
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TrendModule } from 'ngx-trend';


// ONBOARDING
import { OnboardingAccountDetailsComponent } from './components/onboarding/onboarding-account-details/onboarding-account-details.component';
import { OnboardingAddTeamComponent } from './components/onboarding/onboarding-add-team/onboarding-add-team.component';
import { OnboardingDepartmentComponent } from './components/onboarding/onboarding-department/onboarding-department.component';
import { OnboardingTemplateComponent } from './components/onboarding/onboarding-template/onboarding-template.component';





@NgModule({
  declarations: [
    HomeComponent, 
    HomeOverviewComponent, 
    CardViewComponent, 
    CardViewOverviewComponent, DashboardComponent, OnboardingAccountDetailsComponent, OnboardingAddTeamComponent, OnboardingDepartmentComponent, OnboardingTemplateComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule,
    TabsModule,
    TooltipModule,
    TrendModule,
    TypeaheadModule,
  ]
})
export class HomeModule { }

<div class="cover" [@fade-in]>
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                🤙
            </div>
            <p>One last thing</p>
        </div>
        <h1>Set up your payment method</h1>
    </div>
</div>



<!-- <nav class="topnav navbar navbar-expand fixed-top navbar-light bg mb-4">
    <a class="navbar-brand mr-4"><img class="nav-logo" src="assets/logo_full_gradient.svg"></a>
</nav> -->


<div class="container-fluid form-container-fluid" [@fade-in]>

    <div class="row card-row section-margin">

        <div class="col-12">
            <ul class="nav org-setup-nav justify-content-center d-lg-flex d-none">
                <li class="nav-item">
                  <a class="nav-link">Acount</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">Subscription</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active">Payment Method</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">Finish</a>
                </li>
            </ul>

            <h3 class="h3">Subscription Summary & Checkout</h3>
            <p class="p">Verify your subscription summary and enter your card details below.</p>
            <div class="mb-2">
                <small *ngIf="error" class="validation-error">
                    {{ errorMessage }}
                </small>
            </div>
        </div>


        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col" class="card-label">Item Description</th>
                                <th scope="col" class="card-label text-right">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                        

                                <tr>
                                    <td scope="row">
                                        <p class="">User Subscription</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p>
                                            {{ userPrice | currency }}/user/mo
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <p class="">Base Subscription</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p>
                                            {{ price | currency }}/mo
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="current-item-border mb-4 mt-2"></div>
                        <table class="table table-hover">
                            <tbody>
                                <tr *ngIf="paymentTerm === 'annual'">
                                    <td scope="row">
                                        <p class="font-weight-bolder">Monthly Subtotal</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ monthlyTotal | currency }}/mo</p>
                                    </td>
                                </tr>
                                <tr *ngIf="paymentTerm === 'monthly'">
                                    <td scope="row">
                                        <p class="font-weight-bolder">Subtotal</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ monthlyTotal | currency }}/mo</p>
                                    </td>
                                </tr>
                                <tr *ngIf="paymentTerm === 'annual'">
                                    <td scope="row">
                                        <p class="font-weight-bolder">Current Total (Billed Annually)</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ subtotal | currency }}/yr</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>





                    <!-- <div class="mb-4 summary">
                        <div class="row">
                            <div class="col-6">
                                <p class="text-left">User Cost: </p>
                            </div>
                            <div class="col-6 text-right">
                                <p class="text-right">1 x {{ userPrice | currency }}/user</p>
                            </div>
                        </div>
                    
                        <div class="row">
                            <div class="col-6">
                                <p class="text-left">Base Cost: </p>
                            </div>
                            <div class="col-6 text-right">
                                <p class="text-right">{{ price | currency }}/mo</p>
                            </div>
                        </div>
                        <hr >
                        <div class="row" [tooltip]="subtotalDescription" placement="bottom">
                            <div class="col-6">
                                <p *ngIf="paymentTerm === 'annual'" class="text-left">Monthly Total: </p>
                                <p *ngIf="paymentTerm === 'monthly'" class="font-weight-bolder text-left">Subtotal: </p>
                            </div>
                            <div class="col-6 text-right">
                                <p *ngIf="paymentTerm === 'annual'" class="text-right">{{ monthlyTotal | currency }}/mo</p>
                                <p *ngIf="paymentTerm === 'monthly'" class="font-weight-bolder text-right">{{ monthlyTotal | currency }}/mo</p>                        
                            </div>

                            <div class="col-6">
                                <p *ngIf="paymentTerm === 'annual'" class="font-weight-bolder text-left">Current Subtotal: </p>
                            </div>
                            <div class="col-6 text-right">
                                <p *ngIf="paymentTerm === 'annual'" class="text-right font-weight-bolder">{{ subtotal | currency }}/yr</p>
                            </div>
                        </div>                
                    </div> -->
                </div>
            </div>
        </div>



        <div class="col-12 mb-4">
            <form id="subscription-form">
                <div class="card shadow">
                    <div class="card-body">
                        <label>Payment Method</label>
                        <div id="card-element" class="form-control">
                        <!-- Elements(STRIPE) will create input elements here -->
                        </div>
                
                        <!-- We'll put the error messages in this element -->
                        <small class="card-errors validation-error" id="card-errors" role="alert"></small>
                
                        <!-- Terms Notice -->
                        <div class="text-center mt-2">
                            <small class="terms">By subscribing you agree to the Social Card recurring billing <a href="https://www.joinsocialcard.com/terms" target="_blank" rel="noopener"><small class="text-primary-1">Terms of Service</small></a>.</small>
                        </div>
                    </div>
                </div>

                <button class="btn btn-primary mt-4 mb-2 w-100" type="button" [disabled]="buttonStatus" (click)="createStripeCustomer()">Subscribe</button>
                <div class="text-center"><a routerLink="/auth/org-setup"><small>Previous</small></a></div>
            </form>
        </div>

    </div>
    
</div>



<ngx-spinner 
    name="checkoutSpinner"
    [fullScreen]="true"
    bdColor="rgba(255, 255, 255, 0.9)"
    size="default"
    color="#5D656C"
    type = "ball-clip-rotate-multiple"
    >
</ngx-spinner>
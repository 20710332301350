


<div class="content-nav">

    <app-template-media-upload></app-template-media-upload>

    <!-- <div class="section-text">
        Cover Photo
    </div>
    <div>
        <div class="control-item-group"> 
            <div class="control-item">
                <app-media-upload></app-media-upload>
            </div>
        </div>
    </div>

    <div class="section-text">
        Headshot
    </div>
    <div>
        <div class="control-item-group"> 
            <div class="control-item">
                <app-media-upload></app-media-upload>
            </div>
        </div>
    </div>

    <div class="section-text">
        Logo
    </div>
    <div>
        <div class="control-item-group"> 
            <div class="control-item">
                
            </div>
        </div>
    </div> -->


</div>















































<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">

            
            <!-- <img src="https://source.unsplash.com/random/90x300" class="web-media-cover" alt="..." [@fade-in] *ngIf="!isBasicCollapsed" [style.background-color]="currentTemplateSettings.web.background_color">

            <div class="" [@fade-in] *ngIf="!isBasicCollapsed">
                <img class="web-media-headshot" src="https://source.unsplash.com/random/250X250">
            </div> -->

            <div class="card shadow" [@fade-in] [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Cover Photo</span>
                    </div>
    
                    <img [src]="coverPhotoTemp" *ngIf="coverPhotoTemp" class="web-media-cover" alt="..." [style.border-color]="currentTemplateSettings.web.border_color" 
                    [style.background-color]="currentTemplateSettings.web.background_color" 
                    [style.border-radius.px]="currentTemplateSettings.web.media_cover_corner_radius"
                    [ngStyle]="mediaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                    (mouseover)="mediaHovered[0] = true"
                    (mouseout)="mediaHovered[0]= false"
                    >

                    <img [src]="currentTemplateSettings.web.cover_photo" *ngIf="!coverPhotoTemp && currentTemplateSettings.web.cover_photo" class="web-media-cover" alt="..." [style.border-color]="currentTemplateSettings.web.border_color" 
                    [style.background-color]="currentTemplateSettings.web.background_color" 
                    [style.border-radius.px]="currentTemplateSettings.web.media_cover_corner_radius"
                    [ngStyle]="mediaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                    (mouseover)="mediaHovered[0] = true"
                    (mouseout)="mediaHovered[0]= false"
                    >

                    <div class="web-media-cover" *ngIf="!coverPhotoTemp && !currentTemplateSettings.web.cover_photo" [style.border-color]="currentTemplateSettings.web.border_color" 
                    [style.background-color]="currentTemplateSettings.web.background_color" 
                    [style.border-radius.px]="currentTemplateSettings.web.media_cover_corner_radius"
                    [ngStyle]="mediaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                    (mouseover)="mediaHovered[0] = true"
                    (mouseout)="mediaHovered[0]= false"
                    ></div>

                    <div class="">
                        <div class="web-media-headshot" 
                        [style.border-color]="currentTemplateSettings.web.border_color" 
                        [style.background-color]='currentTemplateSettings.web.background_color'
                        [style.border-radius.px]="currentTemplateSettings.web.media_headshot_corner_radius"
                        [ngStyle]="mediaHovered[1] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                        (mouseover)="mediaHovered[1] = true"
                        (mouseout)="mediaHovered[1]= false"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="80" height="80" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="currentTemplateSettings.web.border_color" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
           
                        
            <div class="card shadow mt-4" [@fade-in] [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Logo</span>
                    </div>
                    <img [src]="logoTemp" *ngIf="(logoTemp && !currentTemplateSettings.web.logo) || (logoTemp && currentTemplateSettings.web.logo)" class="org-logo org-logo-lg">
                    <img [src]="currentTemplateSettings.web.logo" *ngIf="!logoTemp && currentTemplateSettings.web.logo" class="org-logo org-logo-lg">
                </div>
            </div>
            
            
            
            
            
            
            




        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Result } from '@app/core/models/api.model';
import { StripePaymentMethods, StripePaymentMethodObject } from '@app/core/models/stripe.model';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentMethodsService {
  private baseUrl: string = environment.api.url;

  private paymentMethodsSubject = new BehaviorSubject<StripePaymentMethodObject>(null);
  public paymentMethods = this.paymentMethodsSubject.asObservable().pipe(distinctUntilChanged());

  // Holds boolean representing status of 
  private paymentMethodStatusSubject = new BehaviorSubject<boolean>(false);
  public paymentMethodStatus = this.paymentMethodStatusSubject.asObservable().pipe(distinctUntilChanged());

  

  constructor(
    private http: HttpClient,
  ) { }


  getPaymentMethods(): Promise<Result> {
    const functionUrl = environment.api.stripe.getPaymentMethods;
    const tenant_id = sessionStorage.getItem('t');

    return new Promise<Result>(async (resolve, reject) => {

      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log("Payment results: ", result.data as StripePaymentMethodObject);
        let method = result.data as StripePaymentMethodObject
        this.paymentMethodsSubject.next(method);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    });
  }


  

  updatePaymentMethod(pmId: string): Promise<Result> {
    const functionUrl = environment.api.stripe.updatePaymentMethod;
    const tenant_id = sessionStorage.getItem('t');

    const body = {
      tenant_id: tenant_id,
      payment_method_id: pmId,
    }

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, body).toPromise().then(res => {
        let result = res as Result
        console.log("PM update result: ", result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    });
  }




  updateStatus() {
    this.paymentMethodStatusSubject.next(!this.paymentMethodStatusSubject.value);
  }
}
<!-- CONTACT -->
<div *ngIf="type === dataTypes.vcard" class="">
    <div class="">
        <h3 class="h3">Contact Card</h3>
        <p>The contact card content type will give the viewer access to the contact information provided by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="vcardForm" class="row">
                                
        <div class="form-group col-12">
            <label for="vcardLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="vcardLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="vcardLabel" type="text" placeholder="" id="vcardLabel">
        </div>
    </form>
    
    <div class="current-item-border mt-2 mb-4"></div>

    <h6 *ngIf="currentTemplateSettings.web[this.contentControl]" class="mb-4">Basic Contact Information</h6>
    <h6 *ngIf="!currentTemplateSettings.web[this.contentControl]" class="mb-4">Default Basic Contact Information</h6>

    <form [formGroup]="vcardForm" class="row">
        

        <div class="form-group col-12">
            <label for="vcardDataFirstName">First Name</label>
            <input class="form-control" formControlName="vcardDataFirstName" type="text" placeholder="" id="vcardDataFirstName">
        </div>
        <div class="form-group col-12">
            <label for="vcardDataLastName">Last Name</label>
            <input class="form-control" formControlName="vcardDataLastName" type="text" placeholder="" id="vcardDataLastName">
        </div>

        <div class="form-group col-12">
            <label for="vcardDataEmail">Email</label>
            <input class="form-control" formControlName="vcardDataEmail" type="text" placeholder="" id="vcardDataEmail">
            <div *ngIf="vcardDataEmail.invalid && (vcardDataEmail.dirty || vcardDataEmail.touched)" class="form-text text-muted">
                <small *ngIf="emailData.errors.required" class="validation-error">A email address is required.</small>
                <small *ngIf="emailData.errors.email" class="validation-error">A valid email address is required.</small>
            </div>
        </div>

        <div class="form-group col-12">
            <label for="vcardDataWorkPhone">Work Phone</label>
            <input class="form-control" formControlName="vcardDataWorkPhone" type="text" placeholder="" id="vcardDataWorkPhone">
        </div>

        <div class="form-group col-12">
            <label for="vcardDataCellPhone">Cell Phone</label>
            <input class="form-control" formControlName="vcardDataCellPhone" type="text" placeholder="" id="vcardDataCellPhone">
        </div>

    </form>

    <div class="current-item-border mt-2 mb-4"></div>

    <h6 *ngIf="currentTemplateSettings.web[this.contentControl]" class="mb-4">Company Information</h6>
    <h6 *ngIf="!currentTemplateSettings.web[this.contentControl]" class="mb-4">Default Company Information</h6>

    <form [formGroup]="vcardForm" class="row">
        <div class="form-group col-12">
            <label for="vcardDataOrgName">Organization Name</label>
            <input class="form-control" formControlName="vcardDataOrgName" type="text" placeholder="" id="vcardDataOrgName">
        </div>

        <div class="form-group col-12">
            <label for="vcardDataJobTitle">Job Title</label>
            <input class="form-control" formControlName="vcardDataJobTitle" type="text" placeholder="" id="vcardDataJobTitle">
        </div>

        <div class="form-group col-12">
            <label for="vcardDataWebsite">Organization Website</label>
            <input class="form-control" formControlName="vcardDataWebsite" type="text" placeholder="" id="vcardDataWebsite">
        </div>
    </form>


    <div class="current-item-border mt-2 mb-4"></div>

    <h6 *ngIf="currentTemplateSettings.web[this.contentControl]" class="mb-4">Work Address</h6>
    <h6 *ngIf="!currentTemplateSettings.web[this.contentControl]" class="mb-4">Default Work Address</h6>

    <form [formGroup]="vcardForm" class="row">

        <div class="form-group col-12">
            <label for="vcardDataWorkAddressStreet">Street Address</label>
            <input formControlName="vcardDataWorkAddressStreet" type="text" class="form-control" id="vcardDataWorkAddressStreet" placeholder="">
        </div>
       
        <div class="form-group col-md-6">
            <label for="vcardDataWorkAddressCity">City</label>
            <input formControlName="vcardDataWorkAddressCity" type="text" class="form-control" id="vcardDataWorkAddressCity" placeholder="">
        </div>

        <div class="form-group col-md-6">
            <label for="vcardDataWorkAddressState" class="form-label">State</label>
            <select formControlName="vcardDataWorkAddressState" class="custom-select">
                <option value="" disabled>- -</option>
                <option
                *ngFor="let state of states"
                [value]="state"
                >{{ state }}</option>
            </select> 
        </div>

        
        <div class="form-group col-md-6">
            <label for="vcardDataWorkAddressZip">Zip</label>
            <input formControlName="vcardDataWorkAddressZip" type="text" class="form-control" id="vcardDataWorkAddressZip" placeholder="">
        </div>

        <div class="form-group col-md-6">
            <label for="vcardDataWorkAddressBuilding">Building</label>
            <input formControlName="vcardDataWorkAddressBuilding" type="text" class="form-control" id="vcardDataWorkAddressBuilding" placeholder="">
        </div>

        <div class="form-group col-12">
            <label for="vcardDataWorkAddressCountry" class="form-label">Country</label>
            <select formControlName="vcardDataWorkAddressCountry" class="custom-select">
                <option value="" disabled>- -</option>
                <option
                *ngFor="let country of countries | keyvalue;"
                [value]="country.key"
                >{{ country.key }}  -  {{ country.value }}</option>
            </select> 
        </div>

    </form>

    <h6 *ngIf="currentTemplateSettings.web[this.contentControl]" class="mt-4 mb-4">Alternate Address</h6>
    <h6 *ngIf="!currentTemplateSettings.web[this.contentControl]" class="mt-4 mb-4">Default Alternate Address</h6>


    <form [formGroup]="vcardForm" class="row">

        <div class="form-group col-12">
            <label for="vcardDataAltAddressStreet">Street Address</label>
            <input formControlName="vcardDataAltAddressStreet" type="text" class="form-control" id="vcardDataAltAddressStreet" placeholder="">
        </div>
       
        <div class="form-group col-md-6">
            <label for="vcardDataAltAddressCity">City</label>
            <input formControlName="vcardDataAltAddressCity" type="text" class="form-control" id="vcardDataAltAddressCity" placeholder="">
        </div>

        <div class="form-group col-md-6">
            <label for="vcardDataAltAddressState" class="form-label">State</label>
            <select formControlName="vcardDataAltAddressState" class="custom-select">
                <option value="" disabled>- -</option>
                <option
                *ngFor="let state of states"
                [value]="state"
                >{{ state }}</option>
            </select> 
        </div>

        
        <div class="form-group col-md-6">
            <label for="vcardDataAltAddressZip">Zip</label>
            <input formControlName="vcardDataAltAddressZip" type="text" class="form-control" id="vcardDataAltAddressZip" placeholder="">
        </div>

        <div class="form-group col-md-6">
            <label for="vcardDataAltAddressBuilding">Building</label>
            <input formControlName="vcardDataAltAddressBuilding" type="text" class="form-control" id="vcardDataAltAddressBuilding" placeholder="">
        </div>

        <div class="form-group col-12">
            <label for="vcardDataAltAddressCountry" class="form-label">Country</label>
            <select formControlName="vcardDataAltAddressCountry" class="custom-select">
                <option value="" disabled>- -</option>
                <option
                *ngFor="let country of countries | keyvalue;"
                [value]="country.key"
                >{{ country.key }}  -  {{ country.value }}</option>
            </select> 
        </div>

    </form>
       
</div>



<!-- EMAIL -->
<div *ngIf="type === dataTypes.email" class="">
    <div class="">
        <h3 class="h3">Email</h3>
        <p>The email content type will direct the viewer to their email client and begin the process of sending an email to the address specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="emailForm">
                                
        <div class="form-group">
            <label for="emailLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="emailLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="emailLabel" type="text" placeholder="Ex. Email" id="emailLabel">
        </div>

        <div class="form-group">
            <label for="emailData" *ngIf="currentTemplateSettings.web[this.contentControl]">Email Address</label>
            <label for="emailData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Email Address</label>
            <input class="form-control" formControlName="emailData" type="text" placeholder="Ex. hello@email.com" id="emailData">
            <div *ngIf="emailData.invalid && (emailData.dirty || emailData.touched)" class="form-text text-muted">
                <small *ngIf="emailData.errors.required" class="validation-error">A email address is required.</small>
                <small *ngIf="emailData.errors.email" class="validation-error">A valid email address is required.</small>
            </div>
        </div>
    </form>
</div>



<!-- PHONE -->
<div *ngIf="type === dataTypes.phone" class="">
    <div class="">
        <h3 class="h3">Phone</h3>
        <p>The phone content type will initiate a phone call between the viewers device and that of the phone number specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="phoneForm">
                                
        <div class="form-group">
            <label for="phoneLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="phoneLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="phoneLabel" type="text" placeholder="Ex. Call Me" id="phoneLabel">
        </div>

        <div class="form-group">
            <label for="phoneData" *ngIf="currentTemplateSettings.web[this.contentControl]">Phone Number</label>
            <label for="phoneData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Phone Number</label>
            <input class="form-control" formControlName="phoneData" type="text" placeholder="Ex. 123-456-7890" id="phoneData">
        </div>
    </form>
</div>



<!-- TEXT -->
<div *ngIf="type === dataTypes.sms" class="">
    <div class="">
        <h3 class="h3">Text</h3>
        <p>The text content type will initiate an SMS message between the viewers device and that of the phone number specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="textForm">
                                
        <div class="form-group">
            <label for="textLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="textLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="textLabel" type="text" placeholder="Ex. Text Me" id="textLabel">
        </div>

        <div class="form-group">
            <label for="textData" *ngIf="currentTemplateSettings.web[this.contentControl]">Phone Number</label>
            <label for="textData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Phone Number</label>
            <input class="form-control" formControlName="textData" type="text" placeholder="Ex. 123-456-7890" id="textData">
        </div>
    </form>
</div>



<!-- WEBSITE -->
<div *ngIf="type === dataTypes.url" class="">
    <div class="">
        <h3 class="h3">Website</h3>
        <p>The website content type will direct viewers to the web page specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="websiteForm">
                                
        <div class="form-group">
            <label for="websiteLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="websiteLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="websiteLabel" type="text" placeholder="Ex. Website" id="websiteLabel">
        </div>

        <div class="form-group">
            <label for="websiteData" *ngIf="currentTemplateSettings.web[this.contentControl]">Website Url</label>
            <label for="websiteData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Website Url</label>
            <input class="form-control" formControlName="websiteData" type="text" placeholder="Ex. www.example.com" id="websiteData">
            <div *ngIf="websiteData.invalid && (websiteData.dirty || websiteData.touched)" class="form-text text-muted">
                <small *ngIf="websiteData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="websiteData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<div *ngIf="type === dataTypes.message" class="">
    <div class="">
        <h3 class="h3">Message</h3>
        <p>The message content type will contain a provided text-based message provided by default below or provided by your team members later.</p>
    </div>

    <form [formGroup]="messageForm">
                                
        <div class="form-group">
            <label for="messageLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="messageLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="messageLabel" type="text" placeholder="Ex. Bio" id="messageLabel">
        </div>

        <div class="form-group">
            <label for="messageData" *ngIf="currentTemplateSettings.web[this.contentControl]">Message Content</label>
            <label for="messageData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Message Content</label>
            <!-- <textarea formcontrolname="messageData" id="messageData" rows="3" class="form-control"></textarea> -->
            <textarea class="form-control" formControlName="messageData" type="text" rows="3" placeholder="" id="messageData"></textarea>
            <!-- <input class="form-control" formControlName="messageData" type="text" placeholder="A string of text..." id="messageData"> -->
            <div *ngIf="messageData.invalid && (messageData.dirty || messageData.touched)" class="form-text text-muted">
                <small *ngIf="messageData.errors.required" class="validation-error">A message is required.</small>
            </div>
        </div>
    </form>
</div>





<!-- iOS APP -->
<div *ngIf="type === dataTypes.ios_app" class="">
    <div class="">
        <h3 class="h3">iOS App</h3>
        <p>The iOS app content type will direct viewers to the dynamic link within your application. Dynamic links can be specified by default below or by your team members later.</p>
    </div>

    <form [formGroup]="iosAppForm">
                                
        <div class="form-group">
            <label for="iosAppLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="iosAppLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="iosAppLabel" type="text" placeholder="Ex. iOS App" id="iosAppLabel">
        </div>

        <div class="form-group">
            <label for="iosAppData" *ngIf="currentTemplateSettings.web[this.contentControl]">iOS App Url</label>
            <label for="iosAppData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default iOS App Url</label>
            <input class="form-control" formControlName="iosAppData" type="text" placeholder="Your dynamic link" id="iosAppData">
            <div *ngIf="iosAppData.invalid && (iosAppData.dirty || iosAppData.touched)" class="form-text text-muted">
                <small *ngIf="iosAppData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="iosAppData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- iOS APPSTORE -->
<div *ngIf="type === dataTypes.app_store" class="">
    <div class="">
        <h3 class="h3">App Store</h3>
        <p>The App Store content type will direct viewers to the Apple App Store page to download your application.</p>
    </div>

    <form [formGroup]="appStoreForm">
                                
        <div class="form-group">
            <label for="appStoreLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="appStoreLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="appStoreLabel" type="text" placeholder="Ex. Download" id="appStoreLabel">
        </div>

        <div class="form-group">
            <label for="appStoreData" *ngIf="currentTemplateSettings.web[this.contentControl]">App Store Url</label>
            <label for="appStoreData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default App Store Url</label>
            <input class="form-control" formControlName="appStoreData" type="text" placeholder="Your dynamic link" id="appStoreData">
            <div *ngIf="appStoreData.invalid && (appStoreData.dirty || appStoreData.touched)" class="form-text text-muted">
                <small *ngIf="appStoreData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="appStoreData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>



<!-- ANDROID APP -->
<div *ngIf="type === dataTypes.android_app" class="">
    <div class="">
        <h3 class="h3">Android App</h3>
        <p>The Android app content type will direct viewers to the dynamic link within your application. Dynamic links can be specified by default below or by your team members later.</p>
    </div>

    <form [formGroup]="androidAppForm">
                                
        <div class="form-group">
            <label for="androidAppLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="androidAppLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="androidAppLabel" type="text" placeholder="Ex. Android App" id="androidAppLabel">
        </div>

        <div class="form-group">
            <label for="androidAppData" *ngIf="currentTemplateSettings.web[this.contentControl]">Android App Url</label>
            <label for="androidAppData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Android App Url</label>
            <input class="form-control" formControlName="androidAppData" type="text" placeholder="Your dynamic link" id="androidAppData">
            <div *ngIf="androidAppData.invalid && (androidAppData.dirty || androidAppData.touched)" class="form-text text-muted">
                <small *ngIf="androidAppData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="androidAppData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>



<!-- GOOGLE PLAY STORE -->
<div *ngIf="type === dataTypes.google_play" class="">
    <div class="">
        <h3 class="h3">Google Play Store</h3>
        <p>The Google Play Store content type will direct viewers to the Google Play Store page to download your application.</p>
    </div>

    <form [formGroup]="googlePlayForm">
                                
        <div class="form-group">
            <label for="googlePlayLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="googlePlayLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="googlePlayLabel" type="text" placeholder="Ex. Download" id="googlePlayLabel">
        </div>

        <div class="form-group">
            <label for="googlePlayData" *ngIf="currentTemplateSettings.web[this.contentControl]">Google Play Store Url</label>
            <label for="googlePlayData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Google Play Store Url</label>
            <input class="form-control" formControlName="googlePlayData" type="text" placeholder="Your dynamic link" id="googlePlayData">
            <div *ngIf="googlePlayData.invalid && (googlePlayData.dirty || googlePlayData.touched)" class="form-text text-muted">
                <small *ngIf="googlePlayData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="googlePlayData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>










<!-- CALENDLY -->
<div *ngIf="type === dataTypes.calendly" class="">
    <div class="">
        <h3 class="h3">Calendly</h3>
        <p>The Calendly content type will direct viewers to the Calendly calendar specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="calendlyForm">
                                
        <div class="form-group">
            <label for="calendlyLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="calendlyLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="calendlyLabel" type="text" placeholder="Ex. Calendar" id="calendlyLabel">
        </div>

        <div class="form-group">
            <label for="calendlyData" *ngIf="currentTemplateSettings.web[this.contentControl]">Calendly Link</label>
            <label for="calendlyData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Calendly Link</label>
            <input class="form-control" formControlName="calendlyData" type="text" placeholder="Calendly Url" id="calendlyData">
            <div *ngIf="calendlyData.invalid && (calendlyData.dirty || calendlyData.touched)" class="form-text text-muted">
                <small *ngIf="calendlyData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="calendlyData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>
























<!-- LINKEDIN -->
<div *ngIf="type === dataTypes.linkedin" class="">
    <div class="">
        <h3 class="h3">Linkedin</h3>
        <p>The Linkedin content type will direct viewers to the Linkedin page specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="linkedinForm">
                                
        <div class="form-group">
            <label for="linkedinLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="linkedinLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="linkedinLabel" type="text" placeholder="Ex. Connect" id="linkedinLabel">
        </div>

        <div class="form-group">
            <label for="linkedinData" *ngIf="currentTemplateSettings.web[this.contentControl]">Calendly Link</label>
            <label for="linkedinData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Calendly Link</label>
            <input class="form-control" formControlName="linkedinData" type="text" placeholder="Linkedin Url" id="linkedinData">
            <div *ngIf="linkedinData.invalid && (linkedinData.dirty || linkedinData.touched)" class="form-text text-muted">
                <small *ngIf="linkedinData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="linkedinData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>

<!-- INSTAGRAM -->
<div *ngIf="type === dataTypes.instagram" class="">
    <div class="">
        <h3 class="h3">Instagram</h3>
        <p>The Instagram content type will direct viewers to the Instagram page specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="instagramForm">
                                
        <div class="form-group">
            <label for="instagramLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="instagramLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="instagramLabel" type="text" placeholder="Ex. Instagram" id="instagramLabel">
        </div>

        <div class="form-group">
            <label for="instagramData" *ngIf="currentTemplateSettings.web[this.contentControl]">Instagram Handle</label>
            <label for="instagramData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Instagram Handle</label>
            <input class="form-control" formControlName="instagramData" type="text" placeholder="Your Handle" id="instagramData">
            <div *ngIf="instagramData.invalid && (instagramData.dirty || instagramData.touched)" class="form-text text-muted">
                <small *ngIf="instagramData.errors.required" class="validation-error">An instagram handle is required.</small>
            </div>
        </div>
    </form>
</div>


<!-- TWITTER -->
<div *ngIf="type === dataTypes.twitter" class="">
    <div class="">
        <h3 class="h3">Twitter</h3>
        <p>The Twitter content type will direct viewers to the Twitter page specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="twitterForm">
                                
        <div class="form-group">
            <label for="twitterLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="twitterLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="twitterLabel" type="text" placeholder="Ex. Twitter" id="twitterLabel">
        </div>

        <div class="form-group">
            <label for="twitterData" *ngIf="currentTemplateSettings.web[this.contentControl]">Twitter Handle</label>
            <label for="twitterData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Twitter Handle</label>
            <input class="form-control" formControlName="twitterData" type="text" placeholder="Your Handle" id="twitterData">
            <div *ngIf="twitterData.invalid && (twitterData.dirty || twitterData.touched)" class="form-text text-muted">
                <small *ngIf="twitterData.errors.required" class="validation-error">A twitter handle is required.</small>
            </div>
        </div>
    </form>
</div>


<!-- TIKTOK -->
<div *ngIf="type === dataTypes.tik_tok" class="">
    <div class="">
        <h3 class="h3">TikTok</h3>
        <p>The TikTok content type will direct viewers to the TikTok page specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="tikTokForm">
                                
        <div class="form-group">
            <label for="tikTokLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="tikTokLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="tikTokLabel" type="text" placeholder="Ex. TikTok" id="tikTokLabel">
        </div>

        <div class="form-group">
            <label for="tikTokData" *ngIf="currentTemplateSettings.web[this.contentControl]">TikTok URL</label>
            <label for="tikTokData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default TikTok URL</label>
            <input class="form-control" formControlName="tikTokData" type="text" placeholder="Your Profile Link" id="tikTokData">
            <div *ngIf="tikTokData.invalid && (tikTokData.dirty || tikTokData.touched)" class="form-text text-muted">
                <small *ngIf="tikTokData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="tikTokData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>




<!-- FACEBOOK -->
<div *ngIf="type === dataTypes.facebook" class="">
    <div class="">
        <h3 class="h3">Facebook</h3>
        <p>The Facebook content type will direct viewers to the Facebook page specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="facebookForm">
                                
        <div class="form-group">
            <label for="facebookLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="facebookLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="facebookLabel" type="text" placeholder="Ex. Facebook" id="facebookLabel">
        </div>

        <div class="form-group">
            <label for="facebookData" *ngIf="currentTemplateSettings.web[this.contentControl]">Facebook Link</label>
            <label for="facebookData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Facebook Link</label>
            <input class="form-control" formControlName="facebookData" type="text" placeholder="Your Facebook URL" id="facebookData">
            <div *ngIf="facebookData.invalid && (facebookData.dirty || facebookData.touched)" class="form-text text-muted">
                <small *ngIf="facebookData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="facebookData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- TWITCH -->
<div *ngIf="type === dataTypes.twitch" class="">
    <div class="">
        <h3 class="h3">Twitch</h3>
        <p>The Twitch content type will direct viewers to the Twitch channel specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="twitchForm">
                                
        <div class="form-group">
            <label for="twitchLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="twitchLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="twitchLabel" type="text" placeholder="Ex. Twitch" id="twitchLabel">
        </div>

        <div class="form-group">
            <label for="twitchData" *ngIf="currentTemplateSettings.web[this.contentControl]">Twitch Link</label>
            <label for="twitchData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Twitch Link</label>
            <input class="form-control" formControlName="twitchData" type="text" placeholder="Your Twitch URL" id="twitchData">
            <div *ngIf="twitchData.invalid && (twitchData.dirty || twitchData.touched)" class="form-text text-muted">
                <small *ngIf="twitchData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="twitchData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- SNAPCHAT -->
<div *ngIf="type === dataTypes.snapchat" class="">
    <div class="">
        <h3 class="h3">Snapchat</h3>
        <p>The Snapchat content type will direct viewers to add the Snapchat account specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="snapchatForm">
                                
        <div class="form-group">
            <label for="snapchatLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="snapchatLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="snapchatLabel" type="text" placeholder="Ex. Snap" id="snapchatLabel">
        </div>

        <div class="form-group">
            <label for="snapchatData" *ngIf="currentTemplateSettings.web[this.contentControl]">Snapchat Username</label>
            <label for="snapchatData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Snapchat Username</label>
            <input class="form-control" formControlName="snapchatData" type="text" placeholder="Your Username" id="snapchatData">
            <div *ngIf="snapchatData.invalid && (snapchatData.dirty || snapchatData.touched)" class="form-text text-muted">
                <small *ngIf="snapchatData.errors.required" class="validation-error">A snapchat handle is required.</small>
            </div>
        </div>
    </form>
</div>



<!-- YOUTUBE VIDEO -->
<div *ngIf="type === dataTypes.youtube_video" class="">
    <div class="">
        <h3 class="h3">YouTube Video</h3>
        <p>The YouTube video content type will direct viewers to a YouTube video specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="youtubeVideoForm">
                                
        <div class="form-group">
            <label for="youtubeVideoLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="youtubeVideoLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="youtubeVideoLabel" type="text" placeholder="Ex. Video" id="youtubeVideoLabel">
        </div>

        <div class="form-group">
            <label for="youtubeVideoData" *ngIf="currentTemplateSettings.web[this.contentControl]">YouTube Video URL</label>
            <label for="youtubeVideoData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default YouTube Video URL</label>
            <input class="form-control" formControlName="youtubeVideoData" type="text" placeholder="Your URL" id="youtubeVideoData">
            <div *ngIf="youtubeVideoData.invalid && (youtubeVideoData.dirty || youtubeVideoData.touched)" class="form-text text-muted">
                <small *ngIf="youtubeVideoData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="youtubeVideoData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>



<!-- YOUTUBE CHANNEL -->
<div *ngIf="type === dataTypes.youtube_channel" class="">
    <div class="">
        <h3 class="h3">YouTube Channel</h3>
        <p>The YouTube channel content type will direct viewers to a YouTube channel specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="youtubeChannelForm">
                                
        <div class="form-group">
            <label for="youtubeChannelLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="youtubeChannelLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="youtubeChannelLabel" type="text" placeholder="Ex. Subscribe" id="youtubeChannelLabel">
        </div>

        <div class="form-group">
            <label for="youtubeChannelData" *ngIf="currentTemplateSettings.web[this.contentControl]">YouTube Channel URL</label>
            <label for="youtubeChannelData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default YouTube Channel URL</label>
            <input class="form-control" formControlName="youtubeChannelData" type="text" placeholder="Your URL" id="youtubeChannelData">
            <div *ngIf="youtubeChannelData.invalid && (youtubeChannelData.dirty || youtubeChannelData.touched)" class="form-text text-muted">
                <small *ngIf="youtubeChannelData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="youtubeChannelData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>



<!-- VIMEO -->
<div *ngIf="type === dataTypes.vimeo" class="">
    <div class="">
        <h3 class="h3">Vimeo</h3>
        <p>The Vimeo content type will direct viewers to a Vimeo link specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="vimeoForm">
                                
        <div class="form-group">
            <label for="vimeoLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="vimeoLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="vimeoLabel" type="text" placeholder="Ex. Video" id="vimeoLabel">
        </div>

        <div class="form-group">
            <label for="vimeoData" *ngIf="currentTemplateSettings.web[this.contentControl]">YouTube Channel URL</label>
            <label for="vimeoData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default YouTube Channel URL</label>
            <input class="form-control" formControlName="vimeoData" type="text" placeholder="Your URL" id="vimeoData">
            <div *ngIf="vimeoData.invalid && (vimeoData.dirty || vimeoData.touched)" class="form-text text-muted">
                <small *ngIf="vimeoData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="vimeoData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>

<!-- PHOTOS -->
<div *ngIf="type === dataTypes.photo_gallery" class="">
    <div class="">
        <h3 class="h3">Photo Gallery</h3>
        <p>The Photo Gallery content type will direct viewers to a photo gallery specified by default below or the one provided by your team members.</p>
    </div>

    <form [formGroup]="photosForm">
                                
        <div class="form-group">
            <label for="photosLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="photosLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="photosLabel" type="text" placeholder="Ex. Photos" id="photosLabel">
        </div>

        <div class="form-group">
            <label for="photosData" *ngIf="currentTemplateSettings.web[this.contentControl]">Photo Gallery URL</label>
            <label for="photosData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Photo Gallery URL</label>
            <input class="form-control" formControlName="photosData" type="text" placeholder="Your Link" id="photosData">
            <div *ngIf="photosData.invalid && (photosData.dirty || photosData.touched)" class="form-text text-muted">
                <small *ngIf="photosData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="photosData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>















<!-- DRIBBBLE -->
<div *ngIf="type === dataTypes.dribbble" class="">
    <div class="">
        <h3 class="h3">Dribbble</h3>
        <p>The Dribbble content type will direct viewers to Dribbble account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="dribbbleForm">
                                
        <div class="form-group">
            <label for="dribbbleLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="dribbbleLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="dribbbleLabel" type="text" placeholder="Ex. Dribbble" id="dribbbleLabel">
        </div>

        <div class="form-group">
            <label for="dribbbleData" *ngIf="currentTemplateSettings.web[this.contentControl]">Dribbble URL</label>
            <label for="dribbbleData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Dribbble URL</label>
            <input class="form-control" formControlName="dribbbleData" type="text" placeholder="Your Link" id="dribbbleData">
            <div *ngIf="dribbbleData.invalid && (dribbbleData.dirty || dribbbleData.touched)" class="form-text text-muted">
                <small *ngIf="dribbbleData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="dribbbleData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- BEHANCE -->
<div *ngIf="type === dataTypes.behance" class="">
    <div class="">
        <h3 class="h3">Behance</h3>
        <p>The Behance content type will direct viewers to Behance account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="behanceForm">
                                
        <div class="form-group">
            <label for="behanceLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="behanceLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="behanceLabel" type="text" placeholder="Ex. Behance" id="behanceLabel">
        </div>

        <div class="form-group">
            <label for="behanceData" *ngIf="currentTemplateSettings.web[this.contentControl]">Behance URL</label>
            <label for="behanceData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Behance URL</label>
            <input class="form-control" formControlName="behanceData" type="text" placeholder="Your Link" id="behanceData">
            <div *ngIf="behanceData.invalid && (behanceData.dirty || behanceData.touched)" class="form-text text-muted">
                <small *ngIf="behanceData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="behanceData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- PINTEREST -->
<div *ngIf="type === dataTypes.pinterest" class="">
    <div class="">
        <h3 class="h3">Pinterest</h3>
        <p>The Pinterest content type will direct viewers to Pinterest account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="pinterestForm">
                                
        <div class="form-group">
            <label for="pinterestLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="pinterestLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="pinterestLabel" type="text" placeholder="Ex. Pinterest" id="pinterestLabel">
        </div>

        <div class="form-group">
            <label for="pinterestData" *ngIf="currentTemplateSettings.web[this.contentControl]">Pinterest URL</label>
            <label for="pinterestData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Pinterest URL</label>
            <input class="form-control" formControlName="pinterestData" type="text" placeholder="Your Link" id="pinterestData">
            <div *ngIf="pinterestData.invalid && (pinterestData.dirty || pinterestData.touched)" class="form-text text-muted">
                <small *ngIf="pinterestData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="pinterestData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>






<!-- GITHUB -->
<div *ngIf="type === dataTypes.github" class="">
    <div class="">
        <h3 class="h3">GitHub</h3>
        <p>The GitHub content type will direct viewers to GitHub account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="gitHubForm">
                                
        <div class="form-group">
            <label for="gitHubLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="gitHubLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="gitHubLabel" type="text" placeholder="Ex. GitHub" id="gitHubLabel">
        </div>

        <div class="form-group">
            <label for="gitHubData" *ngIf="currentTemplateSettings.web[this.contentControl]">GitHub URL</label>
            <label for="gitHubData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default GitHub URL</label>
            <input class="form-control" formControlName="gitHubData" type="text" placeholder="Your Link" id="gitHubData">
            <div *ngIf="gitHubData.invalid && (gitHubData.dirty || gitHubData.touched)" class="form-text text-muted">
                <small *ngIf="gitHubData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="gitHubData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>

<!-- GIT LAB -->
<div *ngIf="type === dataTypes.gitlab" class="">
    <div class="">
        <h3 class="h3">GitLab</h3>
        <p>The GitLab content type will direct viewers to GitLab account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="gitLabForm">
                                
        <div class="form-group">
            <label for="gitLabLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="gitLabLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="gitLabLabel" type="text" placeholder="Ex. GitLab" id="gitLabLabel">
        </div>

        <div class="form-group">
            <label for="gitLabData" *ngIf="currentTemplateSettings.web[this.contentControl]">GitLab URL</label>
            <label for="gitLabData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default GitLab URL</label>
            <input class="form-control" formControlName="gitLabData" type="text" placeholder="Your Link" id="gitLabData">
            <div *ngIf="gitLabData.invalid && (gitLabData.dirty || gitLabData.touched)" class="form-text text-muted">
                <small *ngIf="gitLabData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="gitLabData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- BIT BUCKET -->
<div *ngIf="type === dataTypes.bit_bucket" class="">
    <div class="">
        <h3 class="h3">Bit Bucket</h3>
        <p>The Bit Bucket content type will direct viewers to Bit Bucket account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="bitBucketForm">
                                
        <div class="form-group">
            <label for="bitBucketLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="bitBucketLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="bitBucketLabel" type="text" placeholder="Ex. Bit Bucket" id="bitBucketLabel">
        </div>

        <div class="form-group">
            <label for="bitBucketData" *ngIf="currentTemplateSettings.web[this.contentControl]">Bit Bucket URL</label>
            <label for="bitBucketData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Bit Bucket URL</label>
            <input class="form-control" formControlName="bitBucketData" type="text" placeholder="Your Link" id="bitBucketData">
            <div *ngIf="bitBucketData.invalid && (bitBucketData.dirty || bitBucketData.touched)" class="form-text text-muted">
                <small *ngIf="bitBucketData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="bitBucketData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>




<!-- COMMUNCATIONS -->

<!-- MEDIUM -->
<div *ngIf="type === dataTypes.medium" class="">
    <div class="">
        <h3 class="h3">Medium</h3>
        <p>The Medium content type will direct viewers to Medium account or page specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="mediumForm">
                                
        <div class="form-group">
            <label for="mediumLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="mediumLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="mediumLabel" type="text" placeholder="Ex. Medium" id="mediumLabel">
        </div>

        <div class="form-group">
            <label for="mediumData" *ngIf="currentTemplateSettings.web[this.contentControl]">Medium URL</label>
            <label for="mediumData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Medium URL</label>
            <input class="form-control" formControlName="mediumData" type="text" placeholder="Your Link" id="mediumData">
            <div *ngIf="mediumData.invalid && (mediumData.dirty || mediumData.touched)" class="form-text text-muted">
                <small *ngIf="mediumData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="mediumData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>




<!-- REDDIT -->
<div *ngIf="type === dataTypes.reddit" class="">
    <div class="">
        <h3 class="h3">Reddit</h3>
        <p>The Reddit content type will direct viewers to Reddit account or sub specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="redditForm">
                                
        <div class="form-group">
            <label for="redditLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="redditLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="redditLabel" type="text" placeholder="Ex. Reddit" id="redditLabel">
        </div>

        <div class="form-group">
            <label for="redditData" *ngIf="currentTemplateSettings.web[this.contentControl]">Reddit URL</label>
            <label for="redditData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Reddit URL</label>
            <input class="form-control" formControlName="redditData" type="text" placeholder="Your Link" id="redditData">
            <div *ngIf="redditData.invalid && (redditData.dirty || redditData.touched)" class="form-text text-muted">
                <small *ngIf="redditData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="redditData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- SLACK -->
<div *ngIf="type === dataTypes.slack" class="">
    <div class="">
        <h3 class="h3">Slack</h3>
        <p>The Slack content type will invite viewers to the Slack channel or team specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="slackForm">
                                
        <div class="form-group">
            <label for="slackLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="slackLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="slackLabel" type="text" placeholder="Ex. Slack" id="slackLabel">
        </div>

        <div class="form-group">
            <label for="slackData" *ngIf="currentTemplateSettings.web[this.contentControl]">Slack Invite URL</label>
            <label for="slackData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Slack Invite URL</label>
            <input class="form-control" formControlName="slackData" type="text" placeholder="Your Link" id="slackData">
            <div *ngIf="slackData.invalid && (slackData.dirty || slackData.touched)" class="form-text text-muted">
                <small *ngIf="slackData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="slackData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- TELEGRAM -->
<div *ngIf="type === dataTypes.telegram" class="">
    <div class="">
        <h3 class="h3">Telegram</h3>
        <p>The Telegram content type will invite viewers to the Telegram channel or team specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="telegramForm">
                                
        <div class="form-group">
            <label for="telegramLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="telegramLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="telegramLabel" type="text" placeholder="Ex. Telegram" id="telegramLabel">
        </div>

        <div class="form-group">
            <label for="telegramData" *ngIf="currentTemplateSettings.web[this.contentControl]">Telegram Invite URL</label>
            <label for="telegramData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Telegram Invite URL</label>
            <input class="form-control" formControlName="telegramData" type="text" placeholder="Your Link" id="telegramData">
            <div *ngIf="telegramData.invalid && (telegramData.dirty || telegramData.touched)" class="form-text text-muted">
                <small *ngIf="telegramData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="telegramData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>


<!-- DISCORD -->
<div *ngIf="type === dataTypes.discord" class="">
    <div class="">
        <h3 class="h3">Discord</h3>
        <p>The Discord content type will invite viewers to the Discord channel or team specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="discordForm">
                                
        <div class="form-group">
            <label for="discordLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="discordLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="discordLabel" type="text" placeholder="Ex. Discord" id="discordLabel">
        </div>

        <div class="form-group">
            <label for="discordData" *ngIf="currentTemplateSettings.web[this.contentControl]">Discord Invite URL</label>
            <label for="discordData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Discord Invite URL</label>
            <input class="form-control" formControlName="discordData" type="text" placeholder="Your Link" id="discordData">
            <div *ngIf="discordData.invalid && (discordData.dirty || discordData.touched)" class="form-text text-muted">
                <small *ngIf="discordData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="discordData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>



<!-- WHATS APP -->
<div *ngIf="type === dataTypes.whatsapp" class="">
    <div class="">
        <h3 class="h3">WhatsApp</h3>
        <p>The WhatsApp content type will invite viewers to the WhatsApp group or team specified by default below or to the one provided by your team members.</p>
    </div>

    <form [formGroup]="whatsAppForm">
                                
        <div class="form-group">
            <label for="whatsAppLabel" *ngIf="currentTemplateSettings.web[this.contentControl]">Content Label</label>
            <label for="whatsAppLabel" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default Content Label</label>
            <input class="form-control" formControlName="whatsAppLabel" type="text" placeholder="Ex. WhatsApp" id="whatsAppLabel">
        </div>

        <div class="form-group">
            <label for="whatsAppData" *ngIf="currentTemplateSettings.web[this.contentControl]">WhatsApp Invite URL</label>
            <label for="whatsAppData" *ngIf="!currentTemplateSettings.web[this.contentControl]">Default WhatsApp Invite URL</label>
            <input class="form-control" formControlName="whatsAppData" type="text" placeholder="Your Link" id="whatsAppData">
            <div *ngIf="whatsAppData.invalid && (whatsAppData.dirty || whatsAppData.touched)" class="form-text text-muted">
                <small *ngIf="whatsAppData.errors.required" class="validation-error">A URL is required.</small>
                <small *ngIf="whatsAppData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
            </div>
        </div>
    </form>
</div>
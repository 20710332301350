import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { fadeInAnimation } from '@app/shared/animations/animations';

import { AllQuickLinkContentTypes, AllQuickLinkSectionNames, ContentType, LinkTypes } from '@app/core/models/content-type-models/content-types.model';
import { Template } from '@app/core/models/template.model';
import { TemplatingService } from '@app/core/services/templates/templating.service';

import { VCard } from 'ngx-vcard';
import { VCardEncoding } from 'ngx-vcard';
import { VCardFormatter } from 'ngx-vcard';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { UrlFormatterService } from '@app/core/services/content-types/url/url-formatter.service';
import { VcardService } from '@app/core/services/content-types/vcard/vcard.service';

@Component({
    selector: 'app-apple-wallet-qr',
    templateUrl: './apple-wallet-qr.component.html',
    styleUrls: ['./apple-wallet-qr.component.css'],
    animations: [fadeInAnimation],
})
export class AppleWalletQrComponent implements OnInit, OnDestroy {

    templateSub: Subscription;
    currentTemplateSettings: Template;

    itemContentTypeSelection: boolean = false;

    sectionNames = AllQuickLinkSectionNames;
    availableContentTypes = AllQuickLinkContentTypes;

    public vCardEncoding: typeof VCardEncoding = VCardEncoding;

    qrUrl: string;

    constructor(
        private templatingService: TemplatingService,
        private urlFormatterService: UrlFormatterService,
        private vcardService: VcardService,
        private snackBarService: SnackBarService,
    ) { 
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });
    }

    ngOnInit(): void {
        if (this.currentTemplateSettings.apple.qr.type.ref != 'vcard') {
            this.getFormattedLink();
        }
    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();
    }


    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }



    getVcard(): string {
        console.log(this.vcardService.generateVcardString(this.currentTemplateSettings.apple.qr.data));
        return this.vcardService.generateVcardString(this.currentTemplateSettings.apple.qr.data);

        // return ''
    }

    // LINK TESTING
    // If link is testable then route to the items url, else show snackbar message
    async getFormattedLink() {
        const templateQrType = this.currentTemplateSettings.apple.qr.type;
        const templateQrData = this.currentTemplateSettings.apple.qr.data;

        const nonTestableLinkTypes = [LinkTypes.vcard, LinkTypes.message];
  
        if (nonTestableLinkTypes.some(type => type === templateQrType.ref)) {
            this.showNonTestableAlert(templateQrType.ref);
        } else if (templateQrType.ref === 'sms') {
            this.qrUrl = `SMSTO:${templateQrData.phone}`;
        } else if (templateQrType.ref === 'email') {
            this.qrUrl = `mailto:${templateQrData.email}`;
        } else if (templateQrType.ref === 'phone') {
            this.qrUrl = `tel:${templateQrData.phone}`;
        } else {
            if (templateQrData != null) {
                var url: string;
                await this.urlFormatterService.formatUrl(templateQrData.url, 
                    templateQrType.ref, templateQrType.type.source_type).then(res => {
                    url = res;
                });
                console.log(url);
                this.qrUrl = url;
            } else {
                this.snackBarService.warning('No URL is available. update the quick link, and try again');
            }
        }
    }








    allowQrEditing(val) {
        this.currentTemplateSettings.apple.qr.can_edit_qr = val;
        this.updateTemplate();
    }

    analtyicsEnabled(val) {
        this.currentTemplateSettings.apple.qr.analytics_enabled = val;
        this.updateTemplate();
    }





    editItemContentType() {
        this.itemContentTypeSelection = !this.itemContentTypeSelection;
    }

    qrContentTypeChange(type: ContentType) {
        console.log(type);
        this.currentTemplateSettings.apple.qr.type = type;
        this.itemContentTypeSelection = !this.itemContentTypeSelection;

        this.updateTemplate();
    }







    showNonTestableAlert(type: string) {
        switch (type) {
            case LinkTypes.vcard: {
                this.snackBarService.warning('Testing the Contact type is currently unavailable');
                break;
            }
            case LinkTypes.sms: {
                this.snackBarService.warning('Testing the Text type is currently unavailable');
                break;
            }
            case LinkTypes.email: {
                this.snackBarService.warning('Testing the Email type is currently unavailable');
                break;
            }
            case LinkTypes.phone: {
                this.snackBarService.warning('Testing the Phone type is currently unavailable');
                break;
            }
        }
    }
}


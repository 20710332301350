<app-top-nav></app-top-nav>
<app-side-nav></app-side-nav>

<div class="cover" [@fade-in]>
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👋
            </div>
            <p>Welcome, let's get started</p>
        </div>
        <h1>Take a few minutes to finalize your organizations account.</h1>
    </div>
</div>



<div class="container-fluid form-container-fluid" [@fade-in] *ngIf="!load_completed">
    <div class="position-relative">
        <ngx-spinner 
        name="newDepartmentSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
    </div>
</div>






<div class="container-fluid form-container-fluid" *ngIf="load_completed" [@fade-in]="load_completed">


    <div class="row card-row section-margin">
        <div class="col-12">
            <h3 class="h3">New Department</h3>
        </div>

        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <form [formGroup]="newDepartmentForm">
        
                        <div class="form-group">
                            <label for="name">Department Name</label>
                            <input class="form-control" formControlName="name" type="text" placeholder="" id="name">
                            
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                                <small *ngIf="name.errors.required" class="validation-error">A department name is required.</small>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <label for="description">Department Description</label>
                            <textarea class="form-control" formControlName="description" type="text" rows="3" placeholder="" id="description"></textarea>
                            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="form-text text-muted">
                                <small *ngIf="description.errors.required" class="validation-error">A department description is required.</small>
                                <small *ngIf="description.errors.maxLength" class="validation-error">A department descrption must be 255 characters or less.</small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="description">Is Department Public?</label>
                                               
                        <div class="d-flex mt-2">
                            <label class="switch">
                                <input type="checkbox" [checked]="newDepartment.public" (change)="newDepartment.public = !newDepartment.public">
                                <span class="slider round"></span>
                            </label>
                            <p *ngIf="newDepartment.public" class="slider-text ml-4 mb-0">Public</p>
                            <p *ngIf="!newDepartment.public" class="slider-text ml-4 mb-0">Private</p>
                          
                        </div>

                        <div class="">
                            <small>A "Public" department is visible to everyone within your organization in the department's tab. A "Private" department is only visible to its members.</small>
                        </div>     
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="description">Department Logo</label>
                                        
                        
                        <div class="mt-4 text-center" (click)="openModal(departmentLogoModal)">
                            <div class="avatar-md avatar-default-md shadow text-center" *ngIf="newDepartment.logo === '' && tempUrl === null && newDepartment.public"> 
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <circle cx="6" cy="16" r="3" />
                                    <circle cx="16" cy="19" r="2" />
                                    <circle cx="14.5" cy="7.5" r="4.5" />
                                </svg>         
                            </div>
                            <div class="avatar-md avatar-default-admin-md shadow text-center" *ngIf="newDepartment.logo === '' && tempUrl === null && !newDepartment.public"> 
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <circle cx="6" cy="16" r="3" />
                                    <circle cx="16" cy="19" r="2" />
                                    <circle cx="14.5" cy="7.5" r="4.5" />
                                </svg>      
                            </div>
                            <img class="avatar-md shadow" *ngIf="newDepartment.logo != '' || tempUrl != null" [src]="newDepartment.logo || tempUrl">
                        </div>

                        <div class="text-center">
                            <small (click)="openModal(departmentLogoModal)">Click to customize.</small>
                        </div>     
                    </div>
                </div>
            </div>
        </div>



        <div class="col-12 mb-4">
            <div class="card shadow">
                <ngx-spinner 
                name="departmentHeadSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body">
                    <div class="form-group">
                        <label for="description">Department Heads</label>
                                               
                        <p class="mt-2">Look up your deparment heads by email.</p>
                        
                        <input [(ngModel)]="customSelected"
                        [isAnimated]="true"
                        [typeahead]="teamArray"
                        placeholder="Email"
                        typeaheadOptionField="email"
                        [typeaheadMinLength]="3"
                        [optionsListTemplate]="customListTemplate"
                        (typeaheadOnSelect)="onSearchSelect($event)"
                        class="form-control mb-4">

                        <div class="current-item-border mb-4 mt-2"></div>
                        <label for="description">Current Department Heads</label>
                        <p *ngIf="!departmentHeads">⚠️ No department heads added</p>

                        <div class="" *ngFor="let user of allSelectedDepHeads; let i = index">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex">
                                    <div class="table-avatar-default mr-3 shadow text-center mt-auto mb-auto" *ngIf="user.photo === '' && user.role === 'user'">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <circle cx="12" cy="7" r="4" />
                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        </svg>
                                    </div>
                                    <div class="table-avatar-default-admin mr-3 shadow text-center mt-auto mb-auto" *ngIf="user.photo === '' && user.role != 'user'">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <circle cx="12" cy="7" r="4" />
                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        </svg>
                                    </div>
                                    <img *ngIf="user.photo != ''" [src]="user.photo" class="table-avatar mr-3 shadow mt-auto mb-auto">
                                    <div class="">
                                    
                                        <p class="mb-0 mt-auto mb-auto"><strong>{{ departmentHeads[user.uid].org_role }}</strong></p>
                                        <p class="mb-0 mt-auto mb-auto">{{ (user.name | titlecase) || user.email }}</p>
                                        <small>{{ user.role | titlecase }} | {{ user.email }}</small>
    
                                    </div>
                                </div>
                               

                                <button class="nav-btn mt-auto mb-auto ml-2" (click)="removeDepartmentHead(i, user.uid)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </button>
                             
                            </div>
                            <div class="current-item-border mb-4 mt-2"></div>
                        </div>

                        

                        <div class="mt-4">
                            <small>Department heads are similar to admins, they have permission to add or remove team members from the department and can update department information.</small>
                        </div>     
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid form-container-fluid" [@fade-in]>
        <div class="text-center">
            <button *ngIf="!departmentCreated" (click)="createDepartment()" [disabled]="newDepartmentForm.invalid || newDepartmentForm.untouched || newDepartmentForm.pristine" class="btn btn-primary w-100 mb-4">Create Department</button>
            <button *ngIf="departmentCreated" class="btn btn-primary w-100 mb-4" routerLink="/onboarding/team">Next</button>
            
            <a class="" routerLink="/">Finish Later</a>
        </div>
    </div>
</div>













<ng-template #departmentLogoModal>
    <div class="modal-card">
        <ngx-spinner 
        name="orgLogoUpdateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="mb-3">New Department Logo</h2>
                </div>
                <label>Preview</label>
                <div class="card shadow mt-2 mb-4">
                    <div class="card-body card-body-sm text-center">
                        <div class="avatar-md avatar-default-md shadow text-center" *ngIf="newDepartment.logo === '' && tempUrl === null && newDepartment.public"> 
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="6" cy="16" r="3" />
                                <circle cx="16" cy="19" r="2" />
                                <circle cx="14.5" cy="7.5" r="4.5" />
                            </svg>         
                        </div>
                        <div class="avatar-md avatar-default-admin-md shadow text-center" *ngIf="newDepartment.logo === '' && tempUrl === null && !newDepartment.public"> 
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="6" cy="16" r="3" />
                                <circle cx="16" cy="19" r="2" />
                                <circle cx="14.5" cy="7.5" r="4.5" />
                            </svg>      
                        </div>
                        <img class="avatar-md shadow" *ngIf="newDepartment.logo != '' || tempUrl != null" [src]="newDepartment.logo || tempUrl">
                    </div>
                </div>
                <label>Logo Upload</label>
                <app-departments-media-upload></app-departments-media-upload>

                <div class="mt-4 text-center">
                    <a (click)="modalRef.hide()" class="btn btn-primary w-100" [routerLink]="">Continue</a>
                    <a *ngIf="tempUrl != null" (click)="resetLogoImg()" class="mt-4"><small>Reset</small></a>
                </div>
            </div>
        </div>
    </div>

</ng-template>







<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
    <ul class="custom-list-group">
        <li class="custom-list-group-item"
          *ngFor="let user of matches"
          [class.active]="typeaheadTemplateMethods.isActive(user)"
          (click)="typeaheadTemplateMethods.selectMatch(user, $event)"
          (mouseenter)="typeaheadTemplateMethods.selectActive(user)">
            <div class="d-flex">
                <div class="table-avatar-default mr-4 shadow text-center" *ngIf="user.item.photo === '' && user.item.role === 'user'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="12" cy="7" r="4" />
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    </svg>
                </div>
                <div class="table-avatar-default-admin mr-4 shadow text-center" *ngIf="user.item.photo === '' && user.item.role != 'user'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="12" cy="7" r="4" />
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    </svg>
                </div>
                <img *ngIf="user.item.photo != ''" [src]="user.item.photo" class="table-avatar mr-4 shadow">
                <div class="">
                    <p class="mb-0 mt-auto mb-auto ">{{ (user.item.name | titlecase) || user.item.email }}</p>
                    <small>{{ user.item.role | titlecase }} | {{ user.item.email }}</small>
                </div>

            </div>
        <!-- {{ user.item | json }} -->
        </li>
    </ul>
</ng-template>




<ng-template #departmentHeadModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">New Department Head</h2>
                </div>
                <div class="current-item-border mb-4 mt-2"></div>
                <p class="mt-4">Add department specific information to this new department head.</p>
                <div class="current-item-border mb-4 mt-2"></div>

                <form [formGroup]="newDepartmentHeadForm" (ngSubmit)="addDepartmentHead()">
        
                    <div class="form-group">
                        <label for="role">Department Role/Position</label>
                        <input class="form-control" formControlName="role" type="text" placeholder="" id="role">
                        
                        <div *ngIf="role.invalid && (role.dirty || role.touched)" class="form-text text-muted">
                            <small *ngIf="role.errors.required" class="validation-error">A role/position is required for each department member.</small>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <button type="submit" [disabled]="newDepartmentHeadForm.invalid || newDepartmentHeadForm.untouched || newDepartmentHeadForm.pristine" class="btn btn-primary mt-4 mb-4 w-100">Confrim Department Head</button>
                        <a (click)="modalRef.hide()" [routerLink]=""><small>Cancel</small></a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Result } from '@app/core/models/api.model';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DepartmentsMediaService {

    private uploadProgressSubject = new BehaviorSubject<number>(0);
    public uploadProgress = this.uploadProgressSubject.asObservable().pipe(distinctUntilChanged());

    // Store the file uploaded until the publish action is called by service (user adds photo in new dep comp, added to this object, and consumed once user confrims dep creation)
    private tempImgDataSubject = new BehaviorSubject<any>(null);
    public tempImgData = this.tempImgDataSubject.asObservable().pipe(distinctUntilChanged());

    private tempImgUrlSubject = new BehaviorSubject<any>(null);
    public tempImgUrl = this.tempImgUrlSubject.asObservable().pipe(distinctUntilChanged());


    private depLogoSubject = new BehaviorSubject<string>('');
    public depLogo = this.depLogoSubject.asObservable().pipe(distinctUntilChanged());


    baseUrl: string = environment.api.url;

    constructor(
        private storage: AngularFireStorage,
        private http: HttpClient,
    ) { }



    getDepLogoFromStorage(depId: string): Promise<Result> {
        const tenant_id = sessionStorage.getItem('t');
    
        const path = `tenants/${tenant_id}/departments/${depId}/logo`;
        // Create a reference to the file we want to download
        const ref = this.storage.ref(path);

        return new Promise<Result>(async (resolve, reject) => {

            // Get the download URL
            ref.getDownloadURL().toPromise().then((url) => {
                // Insert url into an <img> tag to "download"
                console.log('📷', url);
                this.depLogoSubject.next(url);
                resolve({
                    success: true,
                    data: url,
                })
            }).catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.error('📷', error);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
                reject();
            });
        })
    }




    uploadDepartmentLogoToStorage(depId: string): Promise<Result> {
        return new Promise<Result>((resolve, reject) => {

            var imgData = this.tempImgDataSubject.value;
            var rawImgData: any;

            const tenant_id = sessionStorage.getItem('t');
            const path = `tenants/${tenant_id}/departments/${depId}/logo`;

            // Create a reference to the file we want to download
            const ref = this.storage.ref(path);


            if (imgData.length === 0) {
                return;
            }
           
            // Ensures file uploaded is an img
            var mimeType = imgData.type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                return;
            } else if (mimeType.match(/imagesvg\+xml|image\/svg\+xml/)) {
                console.log("Only non svg images are supported.");
                return;
            }

            // Converts file to data of type ArrayBuffer
            var reader = new FileReader();
            reader.readAsDataURL(imgData); 
            reader.onload = (_event) => { 
                rawImgData = reader.result;
            }
            console.log('📷', rawImgData);

            const task = this.storage.upload(path, imgData);

            // Tracks state of upload
            task.snapshotChanges().subscribe(result => {
                console.log('📷', result.state);
                if (result.state === 'success') {

                    // get the img URL
                    this.getDepLogoFromStorage(depId).then(res => {
                        if (res.success) {
                            resolve({
                                success: true,
                                data: res.data,
                            });
                        }
                    }).catch(err => {
                        console.error('Unable to retreive Dep Logo URL');
                        reject();
                    })
                    
                } else if (result.state === 'error') {
                    reject();
                } else {
                    console.log('📷', 'still processing image');
                }
            })

            // observe percentage changes
            task.percentageChanges().subscribe(progress => {
                this.uploadProgressSubject.next(progress);
                console.log('📷', 'Uploading progress: ', progress);
                if (progress === 100) {
                    
                }
            });

            // Handle any errors
            task.catch(err => {
                console.error('📷', err);
                switch (err.name) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        // Failed in storage rules
                        reject();
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        reject();
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        reject();
                        break;
                }
            });
        });
    }


    


    setTempImageData(data: any) {
        this.tempImgDataSubject.next(data);
        // Converts file to data of type ArrayBuffer
        var reader = new FileReader();
        reader.readAsDataURL(data); 
        reader.onload = (_event) => { 
            this.tempImgUrlSubject.next(reader.result);
            console.log('📷', this.tempImgUrlSubject.value);
        }
    }

    resetTempImageData() {
        this.tempImgDataSubject.next(null);
        this.tempImgUrlSubject.next(null);
    }
    
}

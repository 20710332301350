import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { OrgLogoService } from '@app/core/services/org-logo/org-logo.service';
import { TenantService } from '@app/core/services/tenant/tenant.service';
import { Tenant } from '@core/models/tenant.model';
import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AngularFireStorage } from '@angular/fire/storage';

import { NgxSpinnerService } from 'ngx-spinner';
import { fadeInAnimation } from '@app/shared/animations/animations';
import { OnboardingService } from '@app/core/services/onboarding/onboarding.service';



@Component({
    selector: 'app-onboarding-account-details',
    templateUrl: './onboarding-account-details.component.html',
    styleUrls: ['./onboarding-account-details.component.css'],
    animations: [fadeInAnimation],
})
export class OnboardingAccountDetailsComponent implements OnInit {

    load_completed: boolean = false;

    tenantSub: Subscription;
    tenant: Tenant;

    generalOrgInfoForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        website: new FormControl(''),
        industry: new FormControl('', [Validators.required]),
        size: new FormControl('', [Validators.required]),
        contact_name: new FormControl('', [Validators.required]),
        contact_email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]),
        contact_phone: new FormControl('', [Validators.required]),
    });

    get name() { return this.generalOrgInfoForm.get('name'); }
    get website() { return this.generalOrgInfoForm.get('website'); }
    get industry() { return this.generalOrgInfoForm.get('industry'); }
    get size() { return this.generalOrgInfoForm.get('size'); }
    get contact_name() { return this.generalOrgInfoForm.get('contact_name'); }
    get contact_email() { return this.generalOrgInfoForm.get('contact_email'); }
    get contact_phone() { return this.generalOrgInfoForm.get('contact_phone'); }



    sizeList = [
        { name: '1-10', nickname: '1-10' },
        { name: '11-50', nickname: '11-50' },
        { name: '51-200', nickname: '51-200' },
        { name: '201-500', nickname: '201-500' },
        { name: '501-1,000', nickname: '501-1,000' },
        { name: '1,001-5,000', nickname: '1,001-5,000' },
        { name: '5,001-10,000', nickname: '5,001-10,000' },
        { name: '10,001+', nickname: '10,001+' }
    ];

    error: boolean = false;
    errorMessage: string;

    logoDataSub: Subscription;
    logoLocalUrl: any;
    canSaveLogo: boolean = false;

    constructor(
        private storage: AngularFireStorage,
        private onboardingService: OnboardingService,
        private tenantService: TenantService,
        private orgLogoService: OrgLogoService,
        private modalService: BsModalService,
        private snackBarService: SnackBarService,
        private spinner: NgxSpinnerService,
    ) {
        this.tenantSub = this.tenantService.activeTenant.subscribe(tenant => {
            console.log("Tenant in comp", tenant);

            this.tenant = tenant;

            if (tenant != null) {
                this.generalOrgInfoForm.patchValue({
                    name: tenant.name,
                    website: tenant.website,
                    industry: tenant.industry,
                    size: tenant.size,
                    contact_name: tenant.contact_name,
                    contact_phone: tenant.contact_phone,
                    contact_email: tenant.contact_email,
                });
                this.spinner.hide('onboardingOrgDetailsSpinner');
                this.load_completed = true
            } else {
                
            }

            

        })

        this.logoDataSub = this.orgLogoService.logoData.subscribe(img => {
            if (img === null) {
                this.canSaveLogo = false;
            } else {
                this.canSaveLogo = true;
            }

        })
    }

    ngOnInit(): void {
        this.getOrgLogo();
        this.onboardingService.setOrgOnboardingObservable();
        this.spinner.show('onboardingOrgDetailsSpinner');
    }
    ngOnDestroy(): void {
        this.tenantSub.unsubscribe();
        this.logoDataSub.unsubscribe();
    }



    async updateItemCompletion() {
        await this.onboardingService.setOnboardingItemAsCompleted(1).then(_ => {
            // Show success notif & stop animations
            this.spinner.hide('accountDetailsSpinner');
            this.snackBarService.success('Organization info successfully updated');
        }).catch(err => {
            this.spinner.hide('accountDetailsSpinner');
            // handle err
            console.error(err);
            this.snackBarService.error('An internal error occured');
        });
    }


    // Updates the organizations basic info
    async updateOrgInfo() {
        this.spinner.show('accountDetailsSpinner');
        await this.tenantService.updateTenantBasicInfo(this.generalOrgInfoForm.value).then(_ => {
            this.updateItemCompletion();

        }).catch(err => {
            this.spinner.hide('accountDetailsSpinner');
            // handle err
            console.error(err);
            this.snackBarService.error('Failed to complete update');
        });
    }







    // LOGO
    async updateOrgLogo() {
        this.spinner.show('accountDetailsSpinner');
        this.spinner.show('orgLogoUpdateSpinner');
        await this.orgLogoService.uploadOrgLogoToStorage().then(res => {
            if (res) {
                this.modalRef.hide();
                this.spinner.hide('orgLogoUpdateSpinner');
                this.snackBarService.success('Logo successfully uploaded');
                this.getOrgLogo();
                this.updateItemCompletion();
            }
        }).catch(err => {
            this.spinner.hide('orgLogoUpdateSpinner');
            // handle err
            this.snackBarService.error('Failed to upload logo');
        })
    }
    // downloads logo from storage
    getOrgLogo() {
        const t = sessionStorage.getItem('t');
        const ref = this.storage.ref(`tenants/${t}/media/logo`);
        this.logoLocalUrl = ref.getDownloadURL();
        console.log(this.logoLocalUrl);

    }

    modalRef: BsModalRef;


    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }

}

export interface ContentType {
    name: string,
    ref: string,
    type: ContentTypeData,
}


export interface ContentTypeData {
    source_type: LinkTypes,
    data_type: DataTypes,
}

export enum LinkTypes {
    // Contact
    vcard = 'vcard',
    sms = 'sms',
    email = 'email',
    phone = 'phone',

    message = 'message',

    // App
    ios_app = 'ios_app', //In app link (ex. socialcardapp://)
    app_store = 'app_store', //Url to app store
    android_app = 'android_app', //App Deep link
    google_play = 'google_play', // Url to google play store

    // Link 
    url = 'url',
    deep_link = 'deep_link',

    // Wallet Pass
    apple_wallet = 'apple_wallet',

    // Socials
    social = 'social',
}

export enum DataTypes {
    // Contact
    vcard = 'vcard',
    email = 'email',
    phone = 'phone',
    sms = 'sms',

    message = 'message',

    // Link
    url = 'url',
    deep_link = 'deep_link',

    ios_app = 'ios_app',
    app_store = 'app_store',
    android_app = 'android_app',
    google_play = 'google_play',

    // Wallet Pass
    apple_wallet = 'apple_wallet',

    /////// Socials ////////
    // General Socials
    linkedin = 'linkedin', //Link or URL
    instagram = 'instagram', // username
    twitter = 'twitter', //username
    tik_tok = 'tik_tok', //URL to page or vid
    facebook = 'facebook', //URL
    twitch = 'twitch', //Twitch username
    snapchat = 'snapchat', //username

    // Video & Media Socials
    youtube_video = 'youtube_video', //URL
    youtube_channel = 'youtube_channel', // URL
    vimeo = 'vimeo', //URL
    photo_gallery = 'photo_gallery', //URL

    // Developer Socials
    github = 'github', //URL
    gitlab = 'gitlab', //URL
    bit_bucket = 'bit_bucket', //URL
    stack_overflow = 'stack_overflow', //URL

    // Blogging Socials
    medium = 'medium', //URL
    reddit = 'reddit', //URL
    slack = 'slack', //URL invite link
    telegram = 'telegram', //URl invite link
    discord = 'discord', //URL
    whatsapp = 'whatsapp', //URL


    // Designer Socials
    dribbble = 'dribbble', //URL
    behance = 'behance', //URL
    pinterest = 'pinterest', //URL

    // Calendar Socials
    calendly = 'calendly', //URL
    google_calendar = 'google_calendar',


    // Payment Socials
    patreon = 'patreon', //username
    kickstarter = 'kickstarter', //URL
    angellist = 'angellist', //URL

    //Video Conference
    // Skype
    // Zoom


    // General Music
    soundcloud = 'soundcloud', //URL
    spotify = 'spotify', //URL
}





// Content type objects used for select inputs when user is choosing a content type for a field
export const AllContentTypes: ContentType[] = [
    { name: 'Contact', ref: 'vcard', type: { source_type: LinkTypes.vcard, data_type: DataTypes.vcard } },
    { name: 'Email', ref: 'email', type: { source_type: LinkTypes.email, data_type: DataTypes.email } },
    { name: 'Phone', ref: 'phone', type: { source_type: LinkTypes.phone, data_type: DataTypes.phone } },
    { name: 'Text', ref: 'sms', type: { source_type: LinkTypes.sms, data_type: DataTypes.sms } },
    { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },

    { name: 'iOS App', ref: 'ios_app', type: { source_type: LinkTypes.ios_app, data_type: DataTypes.ios_app } },
    { name: 'App Store', ref: 'app_store', type: { source_type: LinkTypes.app_store, data_type: DataTypes.app_store } },
    { name: 'Android App', ref: 'android_app', type: { source_type: LinkTypes.android_app, data_type: DataTypes.android_app } },
    { name: 'Google Play', ref: 'google_play', type: { source_type: LinkTypes.google_play, data_type: DataTypes.google_play } },

    { name: 'Calendly', ref: 'calendly', type: { source_type: LinkTypes.url, data_type: DataTypes.calendly } },

    { name: 'LinkedIn', ref: 'linkedin', type: { source_type: LinkTypes.social, data_type: DataTypes.linkedin } },
    { name: 'Instagram', ref: 'instagram', type: { source_type: LinkTypes.social, data_type: DataTypes.instagram } },
    { name: 'Twitter', ref: 'twitter', type: { source_type: LinkTypes.social, data_type: DataTypes.twitter } },
    { name: 'TikTok', ref: 'tik_tok', type: { source_type: LinkTypes.social, data_type: DataTypes.tik_tok } },
    { name: 'Facebook', ref: 'facebook', type: { source_type: LinkTypes.social, data_type: DataTypes.facebook } },
    { name: 'Twitch', ref: 'twitch', type: { source_type: LinkTypes.social, data_type: DataTypes.twitch } },
    { name: 'Snapchat', ref: 'snapchat', type: { source_type: LinkTypes.social, data_type: DataTypes.snapchat } },
    { name: 'Youtube Video', ref: 'youtube_video', type: { source_type: LinkTypes.social, data_type: DataTypes.youtube_video } },
    { name: 'Youtube Channel', ref: 'youtube_channel', type: { source_type: LinkTypes.social, data_type: DataTypes.youtube_channel } },
    { name: 'Vimeo', ref: 'vimeo', type: { source_type: LinkTypes.social, data_type: DataTypes.vimeo } },
    { name: 'Photos', ref: 'photo_gallery', type: { source_type: LinkTypes.social, data_type: DataTypes.photo_gallery } },

    { name: 'Dribbble', ref: 'dribbble', type: { source_type: LinkTypes.social, data_type: DataTypes.dribbble } },
    { name: 'Behance', ref: 'behnace', type: { source_type: LinkTypes.social, data_type: DataTypes.behance } },
    { name: 'Pinterest', ref: 'pinterest', type: { source_type: LinkTypes.social, data_type: DataTypes.pinterest } },

    { name: 'GitHub', ref: 'github', type: { source_type: LinkTypes.social, data_type: DataTypes.github } },
    { name: 'GitLab', ref: 'gitlab', type: { source_type: LinkTypes.social, data_type: DataTypes.gitlab } },
    { name: 'Bit Bucket', ref: 'bit_bucket', type: { source_type: LinkTypes.social, data_type: DataTypes.bit_bucket } },

    { name: 'Medium', ref: 'medium', type: { source_type: LinkTypes.social, data_type: DataTypes.medium } },
    { name: 'Reddit', ref: 'reddit', type: { source_type: LinkTypes.social, data_type: DataTypes.reddit } },
    { name: 'Slack', ref: 'slack', type: { source_type: LinkTypes.social, data_type: DataTypes.slack } },
    { name: 'Telegram', ref: 'telegram', type: { source_type: LinkTypes.social, data_type: DataTypes.telegram } },
    { name: 'Discord', ref: 'discord', type: { source_type: LinkTypes.social, data_type: DataTypes.discord } },
    { name: 'WhatsApp', ref: 'whatsapp', type: { source_type: LinkTypes.social, data_type: DataTypes.whatsapp } },
]



export const AllQuickLinkSectionNames: string[] = ['General', 'Applications', 'Scheduling', 'Socials', 'Design', 'Engineering', 'Communications', 'Other']
export const AllQuickLinkContentTypes: ContentType[][] = [
    // General
    [
        { name: 'Contact', ref: 'vcard', type: { source_type: LinkTypes.vcard, data_type: DataTypes.vcard } },
        { name: 'Email', ref: 'email', type: { source_type: LinkTypes.email, data_type: DataTypes.email } },
        { name: 'Phone', ref: 'phone', type: { source_type: LinkTypes.phone, data_type: DataTypes.phone } },
        { name: 'Text', ref: 'sms', type: { source_type: LinkTypes.sms, data_type: DataTypes.sms } },
        { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
    ],

    // Applications (Apps & Products)
    [
        { name: 'iOS App', ref: 'ios_app', type: { source_type: LinkTypes.ios_app, data_type: DataTypes.ios_app } },
        { name: 'App Store', ref: 'app_store', type: { source_type: LinkTypes.app_store, data_type: DataTypes.app_store } },
        { name: 'Android App', ref: 'android_app', type: { source_type: LinkTypes.android_app, data_type: DataTypes.android_app } },
        { name: 'Google Play', ref: 'google_play', type: { source_type: LinkTypes.google_play, data_type: DataTypes.google_play } },
    ],

    // Scheduling
    [
        { name: 'Calendly', ref: 'calendly', type: { source_type: LinkTypes.url, data_type: DataTypes.calendly } },
    ],

    // Socials
    [
        { name: 'LinkedIn', ref: 'linkedin', type: { source_type: LinkTypes.social, data_type: DataTypes.linkedin } },
        { name: 'Instagram', ref: 'instagram', type: { source_type: LinkTypes.social, data_type: DataTypes.instagram } },
        { name: 'Twitter', ref: 'twitter', type: { source_type: LinkTypes.social, data_type: DataTypes.twitter } },
        { name: 'TikTok', ref: 'tik_tok', type: { source_type: LinkTypes.social, data_type: DataTypes.tik_tok } },
        { name: 'Facebook', ref: 'facebook', type: { source_type: LinkTypes.social, data_type: DataTypes.facebook } },
        { name: 'Twitch', ref: 'twitch', type: { source_type: LinkTypes.social, data_type: DataTypes.twitch } },
        { name: 'Snapchat', ref: 'snapchat', type: { source_type: LinkTypes.social, data_type: DataTypes.snapchat } },
        { name: 'Youtube Video', ref: 'youtube_video', type: { source_type: LinkTypes.social, data_type: DataTypes.youtube_video } },
        { name: 'Youtube Channel', ref: 'youtube_channel', type: { source_type: LinkTypes.social, data_type: DataTypes.youtube_channel } },
        { name: 'Vimeo', ref: 'vimeo', type: { source_type: LinkTypes.social, data_type: DataTypes.vimeo } },
        { name: 'Photos', ref: 'photo_gallery', type: { source_type: LinkTypes.social, data_type: DataTypes.photo_gallery } },

    ],

    // Design
    [
        { name: 'Dribbble', ref: 'dribbble', type: { source_type: LinkTypes.social, data_type: DataTypes.dribbble } },
        { name: 'Behance', ref: 'behnace', type: { source_type: LinkTypes.social, data_type: DataTypes.behance } },
        { name: 'Pinterest', ref: 'pinterest', type: { source_type: LinkTypes.social, data_type: DataTypes.pinterest } },

    ],

    // Engineering
    [
        { name: 'GitHub', ref: 'github', type: { source_type: LinkTypes.social, data_type: DataTypes.github } },
        { name: 'GitLab', ref: 'gitlab', type: { source_type: LinkTypes.social, data_type: DataTypes.gitlab } },
        { name: 'Bit Bucket', ref: 'bit_bucket', type: { source_type: LinkTypes.social, data_type: DataTypes.bit_bucket } },
    ],

    // Communications
    [
        { name: 'Medium', ref: 'medium', type: { source_type: LinkTypes.social, data_type: DataTypes.medium } },
        { name: 'Reddit', ref: 'reddit', type: { source_type: LinkTypes.social, data_type: DataTypes.reddit } },
        { name: 'Slack', ref: 'slack', type: { source_type: LinkTypes.social, data_type: DataTypes.slack } },
        { name: 'Telegram', ref: 'telegram', type: { source_type: LinkTypes.social, data_type: DataTypes.telegram } },
        { name: 'Discord', ref: 'discord', type: { source_type: LinkTypes.social, data_type: DataTypes.discord } },
        { name: 'WhatsApp', ref: 'whatsapp', type: { source_type: LinkTypes.social, data_type: DataTypes.whatsapp } },
    ],

]






export interface Vcard {

    vcard_url?: string, // Storage url to the vcard

    // Vcard Data fields 
    full_name: string,
    email: string,
    org: string, // Contact company name
    org_title: string,
    photo: string, // Url to storage photo,
    work_phone: string, // work phone number (formatted)
    cell_phone: string, // cell/alt phone (formatted)

    work_street: string, // work street address
    work_city: string,
    work_state: string,
    work_zip: string,
    work_country: string,
    work_building: string,

    alt_street: string, // Alternate address
    alt_city: string,
    alt_state: string,
    alt_zip: string,
    alt_country: string,
    alt_building: string,

    // background
    // source: string // URL to get updated info



    // BEGIN:VCARD
    // VERSION:4.0
    // N:Gump;Forrest;;Mr.;
    // FN:Forrest Gump
    // ORG:Bubba Gump Shrimp Co.
    // TITLE:Shrimp Man
    // PHOTO;MEDIATYPE=image/gif:http://www.example.com/dir_photos/my_photo.gif
    // TEL;TYPE=work,voice;VALUE=uri:tel:+1-111-555-1212
    // TEL;TYPE=home,voice;VALUE=uri:tel:+1-404-555-1212
    // ADR;TYPE=WORK;PREF=1;LABEL="100 Waters Edge\nBaytown\, LA 30314\nUnited States of America":;;100 Waters Edge;Baytown;LA;30314;United States of America
    // ADR;TYPE=HOME;LABEL="42 Plantation St.\nBaytown\, LA 30314\nUnited States of America":;;42 Plantation St.;Baytown;LA;30314;United States of America
    // EMAIL:forrestgump@example.com
    // REV:20080424T195243Z
    // x-qq:21588891
    // END:VCARD
}


export interface Email {
    email: string,
}

export interface Phone {
    phone: string,
}

export interface Text {
    phone: string,
}

export interface Website {
    url: string,
}

export interface Message {
    message: string,
}

export interface IosApp {
    url: string,
}

export interface Appstore {
    url: string,
}

export interface AndroidApp {
    url: string,
}

export interface GooglePlay {
    url: string,
}

export interface Calendly {
    url: string,
}

export interface Linkedin {
    url: string,
}

export interface Instagram {
    handle: string,
    url: string,
}

export interface Twitter {
    handle: string,
    url: string,
}

export interface TikTok {
    url: string,
}

export interface Facebook {
    url: string,
}

export interface Twitch {
    url: string,
}

export interface Snapchat {
    handle: string,
    url: string,
}

export interface YoutubeVideo {
    url: string,
}

export interface YoutubeChannel {
    url: string,
}

export interface Vimeo {
    url: string,
}

export interface PhotoGallery {
    url: string,
}

export interface Dribbble {
    url: string,
}

export interface Behance {
    url: string,
}

export interface Pinterest {
    url: string,
}

export interface Github {
    url: string,
}

export interface GitLab {
    url: string,
}

export interface BitBucket {
    url: string,
}

export interface Medium {
    url: string,
}

export interface Reddit {
    url: string,
}

export interface Slack {
    url: string,
}

export interface Telegram {
    url: string,
}

export interface Telegram {
    url: string,
}

export interface Discord {
    url: string,
}

export interface WhatsApp {
    url: string,
}

export interface Message {
    header: string,
    body: string,
}






export enum ContentSources {
    quick_link_one = 'quick_link_one',
    quick_link_two = 'quick_link_two',
    quick_link_three = 'quick_link_three',

    cta = 'cta',

    accessory_item = 'accessory_item',
}
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileNavService {

  isProfileNavVisible: boolean;

  profileNavVisibilityChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.profileNavVisibilityChange.subscribe((value) => {
      this.isProfileNavVisible = value
    });
  }

  

  toggleNav() {
    this.profileNavVisibilityChange.next(!this.isProfileNavVisible);
  }
}

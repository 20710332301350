// Animations
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const fadeInAnimation = trigger('fade-in', [
    transition(':enter', [
        query('*', style({ opacity: 0 })),
        query('*', stagger('10ms', [
            animate('0.75s ease-in', style({ opacity: 1 })),
        ]))
    ])
])


export const numberAnimation = trigger('roll-digits', [
   
])


<app-top-nav></app-top-nav>
<app-side-nav></app-side-nav>

<div class="cover" [@fade-in] *ngIf="load_completed && (growthPlan || (!growthPlan && allTemplatesCount <= 0))">
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                😎
            </div>
            <p>Let's get started</p>
        </div>
        <h1>New Template</h1>
        <p>Templates help guide your team through the creation of Social Cards, while giving you control of the entire process.</p>
    </div>
</div>

<div class="cover" [@fade-in] *ngIf="load_completed && (!growthPlan && allTemplatesCount >= 0)">
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👀
            </div>
            <p>Upgrade your plan</p>
        </div>
        <h1>No Templates Available</h1>
        <p>This account already has an active template. Please upgrade your plan to create new templates!</p>
    </div>
</div>


<div class="container-fluid form-container-fluid" [@fade-in] *ngIf="!load_completed">
    <div class="position-relative">
        <ngx-spinner 
        name="newTemplateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
    </div>
</div>


<div class="container-fluid form-container-fluid" *ngIf="load_completed && (growthPlan || (!growthPlan && allTemplatesCount <= 0))" [@fade-in]="load_completed">


    <div class="row card-row section-margin">
        <div class="col-12">
            <h3 class="h3">New Template</h3>
        </div>

        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <form [formGroup]="newTemplateForm">
        
                        <div class="form-group">
                            <label for="name">Template Name</label>
                            <input class="form-control" formControlName="name" type="text" placeholder="" id="name">
                            
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                                <small *ngIf="name.errors.required" class="validation-error">A template name is required.</small>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <label for="description">Template Description</label>
                            <textarea class="form-control" formControlName="description" type="text" rows="3" placeholder="" id="description"></textarea>
                            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="form-text text-muted">
                                <small *ngIf="description.errors.required" class="validation-error">A template description is required.</small>
                                <small *ngIf="description.errors.maxLength" class="validation-error">A template descrption must be 255 characters or less.</small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <label>Template Starting Theme</label>
                    <p>Select the starting point for your template.</p>

                    <div class="row mt-4 text-center">
                        <div class="col-sm-6 col-12" [ngClass]="isLight ? 'preview-section-selected' : 'preview-section'" (click)="changeTheme(true)">
                            <img class="mt-2" style="width: 150px; border-radius: 12px;" src="assets/template-previews/template_preview_light.svg" alt="...">
                            <h6 class="text-center mt-4">Light Theme</h6>
                        </div>
                        <div class="col-sm-6 col-12" [ngClass]="isLight ? 'preview-section' : 'preview-section-selected'" (click)="changeTheme(false)">
                            <img class="mt-2" style="width: 150px; border-radius: 12px;" src="assets/template-previews/template_preview_dark.svg" alt="...">
                            <h6 class="text-center mt-4">Dark Theme</h6>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>


        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <label>Template Platforms</label>
                    <p class="p">Which platforms will this template support?</p>
                    <div class="current-item-border mt-2 mb-2"></div>


                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-world icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="12" r="9" />
                                <line x1="3.6" y1="9" x2="20.4" y2="9" />
                                <line x1="3.6" y1="15" x2="20.4" y2="15" />
                                <path d="M11.5 3a17 17 0 0 0 0 18" />
                                <path d="M12.5 3a17 17 0 0 1 0 18" />
                            </svg>
                            <div class="ml-3 mt-auto mb-auto">Web</div>
                        </div>

                        <div class="text-right">
                            <label class="switch">
                                <input type="checkbox" [checked]="currentTemplateSettings.support_web" (change)="currentTemplateSettings.support_web = !currentTemplateSettings.support_web">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="current-item-border mt-2 mb-2"></div>
                    <div class="d-flex justify-content-between">
                        <div class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-apple icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z" />
                                <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
                            </svg>
                            <div class="ml-3 mt-auto mb-auto">Apple Wallet</div>
                        </div>

                        <div class="text-right">
                            <label class="switch">
                                <input type="checkbox" [checked]="currentTemplateSettings.support_apple" (change)="currentTemplateSettings.support_apple = !currentTemplateSettings.support_apple">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="current-item-border mt-2 mb-2"></div>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <rect x="3" y="5" width="18" height="14" rx="2" />
                                <polyline points="3 7 12 13 21 7" />
                            </svg>
                            <div class="ml-3 mt-auto mb-auto">Email Signature</div>
                        </div>

                        <div class="text-right">
                            <label class="switch">
                                <input type="checkbox" [checked]="currentTemplateSettings.support_email" (change)="currentTemplateSettings.support_email = !currentTemplateSettings.support_email">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <div class="current-item-border mt-2 mb-2"></div>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="d-flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-qrcode icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <rect x="4" y="4" width="6" height="6" rx="1" />
                                <line x1="7" y1="17" x2="7" y2="17.01" />
                                <rect x="14" y="4" width="6" height="6" rx="1" />
                                <line x1="7" y1="7" x2="7" y2="7.01" />
                                <rect x="4" y="14" width="6" height="6" rx="1" />
                                <line x1="17" y1="7" x2="17" y2="7.01" />
                                <line x1="14" y1="14" x2="17" y2="14" />
                                <line x1="20" y1="14" x2="20" y2="14.01" />
                                <line x1="14" y1="14" x2="14" y2="17" />
                                <line x1="14" y1="20" x2="17" y2="20" />
                                <line x1="17" y1="17" x2="20" y2="17" />
                                <line x1="20" y1="17" x2="20" y2="20" />
                              </svg>
                            <div class="ml-3 mt-auto mb-auto">QR Code</div>
                        </div>

                        <div class="text-right">
                            <label class="switch">
                                <input type="checkbox" [checked]="currentTemplateSettings.support_qr" (change)="currentTemplateSettings.support_qr = !currentTemplateSettings.support_qr">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <label>Before you start customizing</label>
                    <p class="mt-2">Want to learn more about the process and best practices of template building?</p>
                    <a class="text-right" href="https://www.joinsocialcard.com/learning-center" target="_blank">Explore More
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <polyline points="9 6 15 12 9 18" />
                      </svg>
                    </a>
                </div>
            </div>
        </div>




        <div class="col-12">
            <button [disabled]="newTemplateForm.invalid || newTemplateForm.untouched || newTemplateForm.pristine" class="btn btn-primary w-100" (click)="createTemplate()">Generate Template</button>
            <div class="text-center mt-2">
                <a routerLink="/admin">Cancel</a>
            </div>
        </div>

    </div>

</div>



<div class="container-fluid form-container-fluid" *ngIf="load_completed && (!growthPlan && allTemplatesCount >= 0)" [@fade-in]>
    <div class="card shadow">
        <div class="card-body">
            <label>No Template Available</label>
            <p>To access the full feature set of Social Card, we suggest upgrading your team to the Growth Plan. To access features like unlimited templates, unlimited Social Cards, Social Card Departments, and much more.</p>
            <a class="btn btn-primary w-100 mt-4" href="https://www.joinsocialcard.com" target="_blank">Learn More</a>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

import {Router, ActivatedRoute, Params} from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.css']
})
export class AccountSetupComponent implements OnInit {

  isAccountLink: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const type = params['type'];
      if (type === 'link') {
        this.isAccountLink = true;
      } 
      else if (type === 'welcome') {
        
      } else {
        this.isAccountLink = false;
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

    code: number;
    message: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) { 
        this.route.queryParams.subscribe(params => {
            this.code = params['code'];
            this.message = params['message']
        });
    }

    ngOnInit(): void {
    }

    backToSafety() {
        this.router.navigate(['/']);
    }

}

<nav class="navbar navbar-light bg shadow-sm justify-content-between fixed-top">
    <a class="btn btn-sm" routerLink='/admin'><i class="fas fa-times"></i></a>
    
    <div class="">
        <small>Styling & layout may change depending on device & screen size.</small>
    </div>
</nav>


<div class="container-fluid" [@fade-in]>
    <ngx-spinner 
    name="newTemplateSPinner"
    [fullScreen]="true"
    bdColor="rgba(255, 255, 255, 0.9)"
    size="default"
    color="#5D656C"
    type = "ball-clip-rotate-multiple"
    >
    
    </ngx-spinner>


    <div class="row no-gutters">
        <div class="col-lg-8 col-md-8 col-12 mx-auto form-width-max">
            <h2>Template Name</h2>
            <p>Template Description</p>
        </div>
    </div>


    

    <h4 class="font-weight-bolder">Template Details</h4>
    <p class="form-p">Give your new Social Card template an identity.</p>

    <app-social-card [socialCard]="preview"></app-social-card>
</div>
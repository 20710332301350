import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { TemplateMediaService } from '@app/core/services/template-media/template-media.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
    selector: 'app-template-media-upload',
    templateUrl: './template-media-upload.component.html',
    styleUrls: ['./template-media-upload.component.css']
})
export class TemplateMediaUploadComponent implements OnInit {

    @ViewChild("fileDropRefCover", { static: false }) fileDropElCover: ElementRef;
    @ViewChild("fileDropRefLogo", { static: false }) fileDropElLogo: ElementRef;
    coverFiles: any[] = [];
    logoFiles: any[] = [];

    errorMessage: string;
    successMessage: string;

    progressSub: Subscription;
    progress: any = 0;


    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};


    tempCoverSub: Subscription;
    tempCover: string;

    tempLogoSub: Subscription;
    tempLogo: string;

    constructor(
        private templateMediaService: TemplateMediaService,
        private snackBarService: SnackBarService,
        private modalService: BsModalService,
    ) {
        this.progressSub = this.templateMediaService.uploadProgress.subscribe(value => {
            this.progress = value;
        })


        this.tempCoverSub = this.templateMediaService.tempCoverPhotoUrl.subscribe(img => {
            this.tempCover = img;
        })

        this.tempLogoSub = this.templateMediaService.tempLogoDataUrl.subscribe(img => {
            this.tempLogo = img;
        })
    }

    ngOnInit(): void {
        this.progress = 0;
    }
    ngOnDestroy(): void {
        this.progressSub.unsubscribe();
        this.tempCoverSub.unsubscribe();
        this.tempLogoSub.unsubscribe();
    }


    /**
    * on file drop handler
    */
    onCoverFileDropped($event) {
        this.prepareCoverFilesList($event);
    }

    /**
     * handle file from browsing
     */
    coverfileBrowseHandler(files) {
        this.prepareCoverFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteCoverFile(index: number) {

        this.coverFiles.splice(index, 1);
        this.templateMediaService.resetTempCoverImageData();
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareCoverFilesList(files: Array<any>) {
        for (const item of files) {
            item.progress = 0;
            if (item?.type.includes('svg')) {
                console.log('Error: SVG un supported');
                this.errorMessage = 'SVG files are not supported';
                this.snackBarService.error('SVG files are not supported');
                return;
            }
            if (item?.type.includes('image/')) {
                this.coverFiles.push(item);
                console.log('Files added 📂', item, files);
                this.errorMessage = null;
            }
        }
        this.fileDropElCover.nativeElement.value = "";
        // this.uploadFilesSimulator(0);


        // Sets current file in service
        console.log("FILE TYPE: ", this.coverFiles[0].type);

        // temporarily store file iun case of a cancellation
        this.templateMediaService.setTempCoverImageData(this.coverFiles[0]);

        this.openModal(this.coverModalView);
    }













    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log('IMAGE EVENT', event);
        this.openModal(this.coverModalView);
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log(event, base64ToFile(event.base64));

    }

    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }







    saveCroppedCoverImageToService() {
        this.templateMediaService.setTempCoverImageData(base64ToFile(this.croppedImage));
        this.modalRef.hide();
    }

    saveCroppedLogoImageToService() {
        this.templateMediaService.setTempLogoImageData(base64ToFile(this.croppedImage));
        this.modalRef.hide();
    }











    cancelCoverCrop() {
        this.modalRef.hide();
        this.templateMediaService.resetTempCoverImageData();
    }
    cancelLogoCrop() {
        this.modalRef.hide();
        this.templateMediaService.resetTempLogoImageData();
    }




























    /**
    * on file drop handler
    */
    onLogoFileDropped($event) {
        this.prepareLogoFilesList($event);
    }

    /**
     * handle file from browsing
     */
    logoFileBrowseHandler(files) {
        this.prepareLogoFilesList(files);
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteLogoFile(index: number) {

        this.logoFiles.splice(index, 1);
        this.templateMediaService.resetTempLogoImageData();
    }


    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    prepareLogoFilesList(files: Array<any>) {
        for (const item of files) {
            item.progress = 0;
            if (item?.type.includes('svg')) {
                console.log('Error: SVG un supported');
                this.errorMessage = 'SVG files are not supported';
                this.snackBarService.error('SVG files are not supported');
                return;
            }
            if (item?.type.includes('image/')) {
                this.logoFiles.push(item);
                console.log('Files added 📂', item, files);
                this.errorMessage = null;
            }
        }
        this.fileDropElLogo.nativeElement.value = "";
        // this.uploadFilesSimulator(0);


        // Sets current file in service
        console.log("FILE TYPE: ", this.logoFiles[0].type);

        // temporarily store file iun case of a cancellation
        this.templateMediaService.setTempLogoImageData(this.logoFiles[0]);


        this.openModal(this.logoModalView);
    }





























    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }


    modalRef: BsModalRef;

    @ViewChild('coverCropperModal') coverModalView: TemplateRef<any>;
    @ViewChild('logoCropperModal') logoModalView: TemplateRef<any>;
    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }

}

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Trend Graphing
import { TrendModule } from 'ngx-trend';

// BAse Animation
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Reactive Forms
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// Main Modules
import { CoreModule } from './core/core.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { HomeModule } from './modules/home/home.module';
import { AdminModule } from './modules/admin/admin.module';
import { TemplatesModule } from '@app/modules/templates/templates.module';

// Angular Fire Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// NGX BOOTSTRAP / OTHER NGX MODULES
import { NgxSpinnerModule } from "ngx-spinner";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { ColorPickerModule } from 'ngx-color-picker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { QRCodeModule } from 'angularx-qrcode';
import { NgxVcardModule } from "ngx-vcard";
import { ImageCropperModule } from 'ngx-image-cropper';

// Angular Fire Config
import { environment } from '../environments/environment';
import { TokenInterceptor } from '@app/shared/interceptors/token.interceptor';
import { HttpErrorInterceptor } from '@app/shared/interceptors/http-error.interceptor';
import { ErrorComponent } from './shared/components/error/error.component';

// Custom Driectives
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';



@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,

        AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFirestoreModule, // firestore
        AngularFireAuthModule, // auth
        AngularFireStorageModule, // storage

        NgxSpinnerModule,

        BrowserAnimationsModule,

        CoreModule,
        AuthenticationModule,
        HomeModule,
        AdminModule,
        TemplatesModule,

        ReactiveFormsModule,
        FormsModule,

        DirectivesModule,

        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        ProgressbarModule.forRoot(),
        SortableModule.forRoot(),
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        ButtonsModule.forRoot(),
        TypeaheadModule.forRoot(),
        ColorPickerModule,
        TrendModule,
        QRCodeModule,
        NgxVcardModule,
        ImageCropperModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }

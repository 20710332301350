import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { fadeInAnimation } from '@app/shared/animations/animations';

import { Template } from '@app/core/models/template.model';
import { TemplatingService } from '@app/core/services/templates/templating.service';

import { AllOrgDepartments, Department, DepartmentMember, DepartmentMembers } from '@core/models/departments.model';
import { AllContentTypes, ContentType } from '@app/core/models/content-type-models/content-types.model';
import { DepartmentsService } from '@app/core/services/departments/departments.service';
import { Router } from '@angular/router';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';


@Component({
    selector: 'app-template-general',
    templateUrl: './template-general.component.html',
    styleUrls: ['./template-general.component.css'],
    animations: [fadeInAnimation]
})
export class TemplateGeneralComponent implements OnInit, OnDestroy {

    isGeneralCollapsed: boolean = false;
    isDepartmentsCollapsed: boolean = true;
    isContentCollapsed: boolean = true;


    templateSub: Subscription;
    currentTemplateSettings: Template;

    generalTemplateInfoForm = new FormGroup({
        name: new FormControl('', [Validators.required,]),
        desc: new FormControl('', [Validators.required]),
    });

    get name() { return this.generalTemplateInfoForm.get('name'); }
    get desc() { return this.generalTemplateInfoForm.get('desc'); }

    generalFormSub: Subscription;

    departmentsSub: Subscription;
    tenantDepartments: AllOrgDepartments<Department>;
    selectedDepartments: string[];

    allDepartments: boolean;

    allContentTypes = AllContentTypes;
    allowAllContent: boolean;

    error: boolean;
    errorMessage: string;

    constructor(
        private templatingService: TemplatingService,
        private departmentsService: DepartmentsService,
        private router: Router,
        private modalService: BsModalService,
        private snackBarService: SnackBarService,
    ) {
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            if (template === null) {
                this.router.navigate(['/admin']);
            } else {
                this.currentTemplateSettings = template;
                this.generalTemplateInfoForm.patchValue({
                    name: template.name,
                    desc: template.description,
                });
            }
            
        });

        this.departmentsService.setAllOrgDepartments();
        this.departmentsSub = this.departmentsService.allOrgDepartments.subscribe(res => {
            if (res != null) {
                this.tenantDepartments = res;
            }
        });

    }

    ngOnInit(): void {
        this.onChanges();
    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();
        this.generalFormSub.unsubscribe();
    }

    // Sends component changes to the service managing unsaved/saved template state 
    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }


    onChanges(): void {
        // Monitors changes to the form and pushes them to the template service
        this.generalFormSub = this.generalTemplateInfoForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.name = val.name;
            this.currentTemplateSettings.description = val.desc;

            this.updateTemplate();
        });
    }








    toggleDepartmentForId(id: string) {
        this.allDepartments = false;
        // Unchecks a selected department
        if (this.isDepartmentChecked(id)) {
            this.currentTemplateSettings.departments = this.currentTemplateSettings.departments.filter(d => d != id);
            console.log(this.currentTemplateSettings.departments);

        } else {
            this.currentTemplateSettings.departments.push(id);
            this.currentTemplateSettings.departments = this.currentTemplateSettings.departments.filter((id, index, array) =>
                index === array.findIndex((dep) =>
                    dep === id
                )
            );
        }
        console.log(this.currentTemplateSettings.departments, this.currentTemplateSettings.departments.some(item => item === id));
        
        this.updateTemplate();
    }

    isDepartmentChecked(id: string) {
        return this.currentTemplateSettings.departments.some(item => item === id);
    }

    getObjectLength(object: DepartmentMembers<DepartmentMember>): number {
        return Object.keys(object).length;
    }

    selectAllDepartments() {
        if (this.allDepartments) {
            // Remove all departments
            this.currentTemplateSettings.departments = [];
        } else {
            for (let dep of Object.keys(this.tenantDepartments)) {
                this.currentTemplateSettings.departments.push(dep);
                this.currentTemplateSettings.departments = this.currentTemplateSettings.departments.filter((id, index, array) =>
                    index === array.findIndex((dep) =>
                        dep === id
                    )
                );
            }
        }
    }












    toggleContentSettingForId(index: number) {
        this.allowAllContent = false;
        
        if (this.isContentTypeChecked(index)) {
            // Remove content type from supported types
            this.currentTemplateSettings.supported_content = this.currentTemplateSettings.supported_content.filter(key => key.ref != this.allContentTypes[index].ref)
            console.log('Removing content type: ', this.allContentTypes[index].ref);
        } else {
            // Add content type to supported list
            this.currentTemplateSettings.supported_content.push(this.allContentTypes[index]);
            this.currentTemplateSettings.supported_content = this.currentTemplateSettings.supported_content.filter((id, index, array) =>
                index === array.findIndex((type) =>
                    type === id
                )
            );
        }
        
        this.updateTemplate();
    }

    isContentTypeChecked(index: number) {

        return this.currentTemplateSettings.supported_content.some( type => type.ref === this.allContentTypes[index].ref)
    }


    selectAllContentTypes() {
        if (this.allowAllContent) {
            // Remove all content types
            this.currentTemplateSettings.supported_content = [];
        } else {
            for (let type of this.allContentTypes) {
                this.currentTemplateSettings.supported_content.push(type);
                this.currentTemplateSettings.supported_content = this.currentTemplateSettings.supported_content.filter((id, index, array) =>
                    index === array.findIndex((type) =>
                        type === id
                    )
                );
            }
        }
    }





    togglingGeneral() {
        this.isGeneralCollapsed = !this.isGeneralCollapsed;
        this.isDepartmentsCollapsed = true;
        this.isContentCollapsed = true;
    }
    togglingDepartments() {
        this.isDepartmentsCollapsed = !this.isDepartmentsCollapsed;
        this.isGeneralCollapsed = true;
        this.isContentCollapsed = true;
    }
    togglingContent() {
        this.isContentCollapsed = !this.isContentCollapsed;
        this.isDepartmentsCollapsed = true;
        this.isGeneralCollapsed = true;
    }








    // Delete the active template
    deleteTemplate() {
        // Call template service func to delete template
        
        this.templatingService.deleteTemplate().then(res => {
            this.modalRef.hide();
            this.router.navigate(['/admin']);
        }).catch(err => {
            this.snackBarService.error('Could not delete template, try again');
        });
    }








    modalRef: BsModalRef;


    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }

}

import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { fadeInAnimation } from '@app/shared/animations/animations';
import { NgxSpinnerService } from 'ngx-spinner';

import { StripeService } from '@core/services/stripe/stripe.service';
import { StripeInvoiceService } from '@core/services/stripe/stripe-invoice.service';
import { StripePaymentMethodsService } from '@core/services/stripe/stripe-payment-methods.service';
import { StripeInvoiceItem, StripeInvoiceLineItem, StripeInvoiceLineItemData, StripeInvoicesObject, StripePaymentMethodObject, StripeSubscriptionObject } from '@app/core/models/stripe.model';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { environment } from '@env/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-billing',
  templateUrl: './admin-billing.component.html',
  styleUrls: ['./admin-billing.component.css'],
  animations: [fadeInAnimation],
})
export class AdminBillingComponent implements OnInit, OnDestroy {

  paymentMethodsSub: Subscription;
  currentInvoiceSub: Subscription;
  invoicesSub: Subscription;

  subscription: StripeSubscriptionObject;
  plan: string;
  term: string;

  paymentMethod: StripePaymentMethodObject;
  cardImageSrc: string;
  cardType: string;

  currentInvoice: StripeInvoiceItem;
  currentInvoiceLineItems: StripeInvoiceLineItem;
  reversedLineItems: StripeInvoiceLineItemData;

  allInvoices: StripeInvoicesObject;

  planGrowthAnnual: string = environment.stripe.growthAnnualPriceId;
  planGrowthMonthly: string = environment.stripe.growthMonthlyPriceId;
  planStartupAnnual: string = environment.stripe.startupAnnualPriceId;
  planStartupMonthly: string = environment.stripe.startupMonthlyPriceId;

  statusSub: Subscription;
  pmStatus: boolean;

  constructor(
    private stripeService: StripeService,
    private stripeInvoiceService: StripeInvoiceService,
    private stripePaymentMethodsService: StripePaymentMethodsService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private modalService: BsModalService,
  ) { 
    this.statusSub = this.stripePaymentMethodsService.paymentMethodStatus.subscribe(res => {
      this.pmStatus = res;
    });
    // this.paymentMethodsSub = this.stripePaymentMethodsService.paymentMethods.subscribe(res => {
    //   console.log(res);
    //   if (res != null) {
    //     this.paymentMethod = res;
    //     this.setCardType();
    //   } 
     
      
    // });
    // this.currentInvoiceSub = this.stripeInvoiceService.currentInvoice.subscribe(res => {
    //   console.log(res);
    // });
  }

  ngOnInit(): void {
    this.spinner.show('subscriptionSpinner');
    this.spinner.show('currentInvoiceSpinner');
    this.spinner.show('paymentMethodSpinner');
    this.spinner.show('allInvoicesSpinner');


    this.stripeService.getSubscription().then(res => {
      if (res.success) {
        
        console.log(res.data);
        this.subscription = res.data as StripeSubscriptionObject;
        this.setSubPlan();
        this.spinner.hide('subscriptionSpinner');
      }
    }).catch(err => {
      this.snackBarService.error('An iternal error occured in Billing');
      this.spinner.hide('subscriptionSpinner');
    });


    // PAYMENT METHOD
    this.stripePaymentMethodsService.getPaymentMethods().then(res => {
      if (res.success) {
        this.paymentMethod = res.data as StripePaymentMethodObject;
        this.setCardType();
        this.spinner.hide('paymentMethodSpinner');
      } 
    }).catch(err => {
      this.snackBarService.error('An iternal error occured in Billing');
      this.spinner.hide('paymentMethodSpinner');
    });


    // CURRENT INVOICE
    this.stripeInvoiceService.getCurrentInvoice().then(res => {
      if (res.success) {
        this.currentInvoice = res.data['invoice'] as StripeInvoiceItem;
        this.currentInvoiceLineItems = res.data['lineItems'] as StripeInvoiceLineItem;
        this.reversedLineItems = res.data['lineItems'].data.slice().reverse() as StripeInvoiceLineItemData;
        console.log(this.currentInvoice, this.currentInvoiceLineItems);
        this.spinner.hide('currentInvoiceSpinner');
      } 
    }).catch(err => {
      this.spinner.hide('currentInvoiceSpinner');
      this.snackBarService.error('An iternal error occured in Billing');
    });


    // ALL INVOICES
    this.stripeInvoiceService.getAllInvoices().then(res => {
      if (res.success) {
        this.allInvoices = res.data as StripeInvoicesObject;
  
        console.log(this.allInvoices);
        this.spinner.hide('allInvoicesSpinner');
      } 
    }).catch(err => {
      this.spinner.hide('allInvoicesSpinner');
      this.snackBarService.error('An iternal error occured in Billing');
    });
  }
  ngOnDestroy(): void {
    this.statusSub.unsubscribe();
  }








  updateTenantPaymentMethod() {
    this.spinner.show('paymentMethodSpinner')
    this.stripePaymentMethodsService.updatePaymentMethod('').then(res => {
      if (res.success) {
        console.log(res);
        this.snackBarService.success('Successfully updated payment method');
      }
    }).catch(err => {
      console.error(err);
      this.snackBarService.error('An iternal error occured in Billing');
    });
  }




  setCardType() {
    switch (this.paymentMethod.card.brand) {
      case 'amex': {
        this.cardImageSrc = 'assets/card-types/Amex.svg';
        this.cardType = 'American Express';
        break;
      }
      case 'diners': {
        this.cardImageSrc = 'assets/card-types/Diners.svg';
        this.cardType = 'Diners';
        break;
      }
      case 'discover': {
        this.cardImageSrc = 'assets/card-types/Discover.svg';
        this.cardType = 'Discover';
        break;
      }
      case 'jcb': {
        this.cardImageSrc = 'assets/card-types/JCB.svg';
        this.cardType = 'JCB';
        break;
      }
      case 'mastercard': {
        this.cardImageSrc = 'assets/card-types/Master-Card.svg';
        this.cardType = 'Master Card';
        break;
      }
      case 'unionpay': {
        this.cardImageSrc = 'assets/card-types/Union-Pay.svg';
        this.cardType = 'Union Pay';
        break;
      }
      case 'visa': {
        this.cardImageSrc = 'assets/card-types/Visa.svg';
        this.cardType = 'Visa';
        break;
      }
      default: {
        this.cardImageSrc = 'assets/card-types/Generic.svg';
        this.cardType = 'Generic';
        break;
      }
    }
  }


  setSubPlan() {
    switch (this.subscription.items.data[0].plan.id) {
      case (this.planStartupAnnual): {
        this.plan = 'Startup (Annual)';
        this.term = 'Charged Annually';
        break;
      }
      case (this.planStartupMonthly): {
        this.plan = 'Startup (Monthly)';
        this.term = 'Charged Monthly';
        break;
      }
      case (this.planGrowthAnnual): {
        this.plan = 'Growth (Annual)';
        this.term = 'Charged Annually';
        break;
      }
      case (this.planGrowthMonthly): {
        this.plan = 'Growth (Monthly)';
        this.term = 'Charged Monthly';
        break;
      }
      default: {
        this.plan = 'Not Found or Canceled';
        break;
      }
    }
  }


  downloadingPdfNotif() {
    this.snackBarService.info('Donwloading invoice');
  }




  modalRef: BsModalRef;
  
  openModal(template: TemplateRef<any>) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, config);
  }

  hidePmChangeModal() {
    if (this.pmStatus) {
      this.stripePaymentMethodsService.updateStatus();
      this.modalRef.hide();
    } else {
      this.modalRef.hide();
    }
  }
}

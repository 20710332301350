<!-- VCARD -->
<svg *ngIf="type === assetTypes.vcard" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="12" cy="7" r="4" />
  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
</svg>

<!-- EMAIL -->
<svg *ngIf="type === assetTypes.email" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <rect x="3" y="5" width="18" height="14" rx="2" />
    <polyline points="3 7 12 13 21 7" />
</svg>

<!-- PHONE -->
<svg *ngIf="type === assetTypes.phone" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
</svg>

<!-- SMS -->
<svg *ngIf="type === assetTypes.sms" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-messages" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
  <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
</svg>


<!-- URL, WEBSITE -->
<svg *ngIf="type === assetTypes.url" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-link" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
</svg>

<!-- Long form message/text/bio -->
<svg *ngIf="type === assetTypes.message" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-align-justified" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <line x1="4" y1="6" x2="20" y2="6" />
    <line x1="4" y1="12" x2="20" y2="12" />
    <line x1="4" y1="18" x2="16" y2="18" />
</svg>

<!-- GENERAL -->
<!-- GENERAL --><!-- GENERAL -->
<!-- GENERAL --><!-- GENERAL --><!-- GENERAL -->
<!-- GENERAL --><!-- GENERAL --><!-- GENERAL --><!-- GENERAL -->


<!-- Applications --><!-- Applications --><!-- Applications --><!-- Applications -->
<!-- Applications --><!-- Applications --><!-- Applications -->
<!-- Applications --><!-- Applications -->
<!-- Applications -->

<!-- Ios App -->
<svg *ngIf="type === assetTypes.ios_app" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-apple" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z" />
  <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
</svg>

<!-- App Store -->
<svg *ngIf="type === assetTypes.app_store" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-appstore" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="12" cy="12" r="9" />
  <path d="M8 16l1.106 -1.99m1.4 -2.522l2.494 -4.488" />
  <path d="M7 14h5m2.9 0h2.1" />
  <path d="M16 16l-2.51 -4.518m-1.487 -2.677l-1.003 -1.805" />
</svg>

<!-- Android -->
<svg *ngIf="type === assetTypes.android_app" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-android" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="4" y1="10" x2="4" y2="16" />
  <line x1="20" y1="10" x2="20" y2="16" />
  <path d="M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0" />
  <line x1="8" y1="3" x2="9" y2="5" />
  <line x1="16" y1="3" x2="15" y2="5" />
  <line x1="9" y1="18" x2="9" y2="21" />
  <line x1="15" y1="18" x2="15" y2="21" />
</svg>

<!-- Google Play -->
<svg *ngIf="type === assetTypes.google_play" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-google-play" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 3.71v16.58a0.7 .7 0 0 0 1.05 .606l14.622 -8.42a0.55 .55 0 0 0 0 -.953l-14.622 -8.419a0.7 .7 0 0 0 -1.05 .607z" />
  <line x1="15" y1="9" x2="4.5" y2="20.5" />
  <line x1="4.5" y1="3.5" x2="15" y2="15" />
</svg>

<!-- Applications -->
<!-- Applications --><!-- Applications -->
<!-- Applications --><!-- Applications --><!-- Applications -->
<!-- Applications --><!-- Applications --><!-- Applications --><!-- Applications -->





<!-- Scheduling -->
<!-- Scheduling --><!-- Scheduling -->
<!-- Scheduling --><!-- Scheduling --><!-- Scheduling -->
<!-- Scheduling --><!-- Scheduling --><!-- Scheduling --><!-- Scheduling -->

<svg *ngIf="type === assetTypes.calendly" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-event" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <rect x="4" y="5" width="16" height="16" rx="2" />
    <line x1="16" y1="3" x2="16" y2="7" />
    <line x1="8" y1="3" x2="8" y2="7" />
    <line x1="4" y1="11" x2="20" y2="11" />
    <rect x="8" y="15" width="2" height="2" />
</svg>

<!-- <svg *ngIf="type === assetTypes.calendly" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 60 60" stroke-width="1" [style.stroke]="stroke" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
  <path d="M49.4573 55.8039H10.5281C7.48112 55.8039 4.99998 53.3228 4.99998 50.2758V11.332C4.99998 8.285 7.48112 5.80386 10.5281 5.80386H49.4573C52.5043 5.80386 54.9855 8.285 54.9855 11.332V50.2612C55 53.3228 52.5188 55.8039 49.4573 55.8039ZM10.5281 8C9.16423 8 7 9.96812 7 11.332V50.2612C7 51.6252 9.16423 53.5 10.5281 53.5H49.4573C50.8212 53.5 53 51.6252 53 50.2612V11.332C53 9.96812 50.8212 8 49.4573 8H10.5281Z" fill="#5D656C"/>
  <path d="M41.1578 14.0743C40.5049 14.0743 39.9826 13.552 39.9826 12.899V4.74464C39.9826 4.09171 40.5049 3.56937 41.1578 3.56937C41.8108 3.56937 42.3331 4.09171 42.3331 4.74464V12.8845C42.3331 13.5374 41.8108 14.0743 41.1578 14.0743ZM18.7115 14.0743C18.0586 14.0743 17.5363 13.552 17.5363 12.899V4.74464C17.5363 4.09171 18.0586 3.56937 18.7115 3.56937C19.3645 3.56937 19.8868 4.09171 19.8868 4.74464V12.8845C19.9013 13.5374 19.3645 14.0743 18.7115 14.0743ZM31.4074 40.3947C26.5322 40.3947 22.5711 36.4336 22.5711 31.5583C22.5711 26.6831 26.5322 22.722 31.4074 22.722C33.6129 22.722 35.7168 23.5345 37.3418 25.0145C37.545 25.1886 37.5595 25.5078 37.3708 25.6965C37.1967 25.8996 36.8775 25.9141 36.6889 25.7255C35.2379 24.4051 33.3662 23.6796 31.4074 23.6796C27.0691 23.6796 23.5432 27.2055 23.5432 31.5438C23.5432 35.8822 27.0691 39.408 31.4074 39.408C33.3662 39.408 35.2379 38.6825 36.6889 37.3622C36.892 37.1881 37.1967 37.2026 37.3708 37.3912C37.545 37.5943 37.5305 37.899 37.3418 38.0731C35.7168 39.5821 33.6129 40.3947 31.4074 40.3947Z" fill="#5D656C"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="50" height="53.3732" fill="white" transform="translate(5 3)"/>
  </clipPath>
  </defs>
  </svg> -->

<!-- Scheduling -->
<!-- Scheduling --><!-- Scheduling -->
<!-- Scheduling --><!-- Scheduling --><!-- Scheduling -->
<!-- Scheduling --><!-- Scheduling --><!-- Scheduling --><!-- Scheduling -->




<!-- SOCIALS --><!-- SOCIALS --><!-- SOCIALS -->
<!-- SOCIALS --><!-- SOCIALS -->
<!-- SOCIALS -->

<!-- LinkedIn -->
<svg *ngIf="type === assetTypes.linkedin" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="4" y="4" width="16" height="16" rx="2" />
  <line x1="8" y1="11" x2="8" y2="16" />
  <line x1="8" y1="8" x2="8" y2="8.01" />
  <line x1="12" y1="16" x2="12" y2="11" />
  <path d="M16 16v-3a2 2 0 0 0 -4 0" />
</svg>

<!-- Instagram -->
<svg *ngIf="type === assetTypes.instagram" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="4" y="4" width="16" height="16" rx="4" />
  <circle cx="12" cy="12" r="3" />
  <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
</svg>

<!-- Twitter -->
<svg *ngIf="type === assetTypes.twitter" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-twitter" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
</svg>


<!-- TikTok -->
<svg *ngIf="type === assetTypes.tik_tok" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-tiktok" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5" />
</svg>

<!-- Facebook -->
<svg *ngIf="type === assetTypes.facebook" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
</svg>

<!-- Twitch -->
<svg *ngIf="type === assetTypes.twitch" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-twitch" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 5v11a1 1 0 0 0 1 1h2v4l4 -4h5.584c.266 0 .52 -.105 .707 -.293l2.415 -2.414c.187 -.188 .293 -.442 .293 -.708v-8.585a1 1 0 0 0 -1 -1h-14a1 1 0 0 0 -1 1z" />
  <line x1="16" y1="8" x2="16" y2="12" />
  <line x1="12" y1="8" x2="12" y2="12" />
</svg>

<!-- Snapchat -->
<svg *ngIf="type === assetTypes.snapchat" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-snapchat" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M16.882 7.842a4.882 4.882 0 0 0 -9.764 0c0 4.273 -.213 6.409 -4.118 8.118c2 .882 2 .882 3 3c3 0 4 2 6 2s3 -2 6 -2c1 -2.118 1 -2.118 3 -3c-3.906 -1.709 -4.118 -3.845 -4.118 -8.118zm-13.882 8.119c4 -2.118 4 -4.118 1 -7.118m17 7.118c-4 -2.118 -4 -4.118 -1 -7.118" />
</svg>

<!-- YOUTUBE -->
<svg *ngIf="type === assetTypes.youtube_channel || type === assetTypes.youtube_video" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="3" y="5" width="18" height="14" rx="4" />
  <path d="M10 9l5 3l-5 3z" />
</svg>

<!-- Vimeo -->
<svg *ngIf="type === assetTypes.vimeo" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-vimeo" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 8.5l1 1s1.5 -1.102 2 -.5c.509 .609 1.863 7.65 2.5 9c.556 1.184 1.978 2.89 4 1.5c2 -1.5 7.5 -5.5 8.5 -11.5c.444 -2.661 -1 -4 -2.5 -4c-2 0 -4.047 1.202 -4.5 4c2.05 -1.254 2.551 1.003 1.5 3c-1.052 2.005 -2 3 -2.5 3c-.49 0 -.924 -1.165 -1.5 -3.5c-.59 -2.42 -.5 -6.5 -3 -6.5s-5.5 4.5 -5.5 4.5z" />
</svg>

<!-- Photo Gallery -->
<svg *ngIf="type === assetTypes.photo_gallery" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-camera" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
  <circle cx="12" cy="13" r="3" />
</svg>



<!-- SOCIALS -->
<!-- SOCIALS --><!-- SOCIALS -->
<!-- SOCIALS --><!-- SOCIALS --><!-- SOCIALS -->





<!-- DESIGN --><!-- DESIGN --><!-- DESIGN --><!-- DESIGN -->
<!-- DESIGN --><!-- DESIGN --><!-- DESIGN -->
<!-- DESIGN --><!-- DESIGN -->
<!-- DESIGN -->

<!-- Dribbble -->
<svg *ngIf="type === assetTypes.dribbble" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-dribbble" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="12" cy="12" r="9" />
  <path d="M9 3.6c5 6 7 10.5 7.5 16.2" />
  <path d="M6.4 19c3.5 -3.5 6 -6.5 14.5 -6.4" />
  <path d="M3.1 10.75c5 0 9.814 -.38 15.314 -5" />
</svg>

<!-- Behance -->
<svg *ngIf="type === assetTypes.behance" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-behance" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 18v-12h4.5a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-4.5" />
  <line x1="3" y1="12" x2="7.5" y2="12" />
  <path d="M14 13h7a3.5 3.5 0 0 0 -7 0v2a3.5 3.5 0 0 0 6.64 1" />
  <line x1="16" y1="6" x2="19" y2="6" />
</svg>

<!-- Pinterest -->
<svg *ngIf="type === assetTypes.pinterest" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-pinterest" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="8" y1="20" x2="12" y2="11" />
  <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7" />
  <circle cx="12" cy="12" r="9" />
</svg>

<!-- DESIGN -->
<!-- DESIGN --><!-- DESIGN -->
<!-- DESIGN --><!-- DESIGN --><!-- DESIGN -->
<!-- DESIGN --><!-- DESIGN --><!-- DESIGN --><!-- DESIGN -->




<!-- Engineering --><!-- Engineering --><!-- Engineering --><!-- Engineering -->
<!-- Engineering --><!-- Engineering --><!-- Engineering -->
<!-- Engineering --><!-- Engineering -->
<!-- Engineering -->

<!-- GITHUB -->
<svg *ngIf="type === assetTypes.github" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-github" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
</svg>

<!-- GITLAB -->
<svg *ngIf="type === assetTypes.gitlab" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-gitlab" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M21 14l-9 7l-9 -7l3 -11l3 7h6l3 -7z" />
</svg>

<!-- BIT BUCKET -->
<svg *ngIf="type === assetTypes.bit_bucket" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-bitbucket" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3.648 4a0.64 .64 0 0 0 -.64 .744l3.14 14.528c.07 .417 .43 .724 .852 .728h10a0.644 .644 0 0 0 .642 -.539l3.35 -14.71a0.641 .641 0 0 0 -.64 -.744l-16.704 -.007z" />
  <path d="M14 15h-4l-1 -6h6z" />
</svg>

<!-- Engineering -->
<!-- Engineering --><!-- Engineering -->
<!-- Engineering --><!-- Engineering --><!-- Engineering -->
<!-- Engineering --><!-- Engineering --><!-- Engineering --><!-- Engineering -->


<!-- Communications -->
<!-- Communications --><!-- Communications -->
<!-- Communications --><!-- Communications --><!-- Communications -->
<!-- Communications --><!-- Communications --><!-- Communications --><!-- Communications -->

<!-- Medium -->
<svg *ngIf="type === assetTypes.medium" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-medium" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="4" y="4" width="16" height="16" rx="2" />
  <path d="M8 9h1l3 3l3 -3h1" />
  <line x1="8" y1="15" x2="10" y2="15" />
  <line x1="14" y1="15" x2="16" y2="15" />
  <line x1="9" y1="9" x2="9" y2="15" />
  <line x1="15" y1="9" x2="15" y2="15" />
</svg>

<!-- Reddit -->
<svg *ngIf="type === assetTypes.reddit" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-reddit" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 8c2.648 0 5.028 .826 6.675 2.14a2.5 2.5 0 0 1 2.326 4.36c0 3.59 -4.03 6.5 -9 6.5c-4.875 0 -8.845 -2.8 -9 -6.294l-1 -.206a2.5 2.5 0 0 1 2.326 -4.36c1.646 -1.313 4.026 -2.14 6.674 -2.14z" />
  <path d="M12 8l1 -5l6 1" />
  <circle cx="19" cy="4" r="1" />
  <circle cx="9" cy="13" r=".5" fill="currentColor" />
  <circle cx="15" cy="13" r=".5" fill="currentColor" />
  <path d="M10 17c.667 .333 1.333 .5 2 .5s1.333 -.167 2 -.5" />
</svg>

<!-- Slack -->
<svg *ngIf="type === assetTypes.slack" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-slack" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 12v-6a2 2 0 0 1 4 0v6m0 -2a2 2 0 1 1 2 2h-6" />
  <path d="M12 12h6a2 2 0 0 1 0 4h-6m2 0a2 2 0 1 1 -2 2v-6" />
  <path d="M12 12v6a2 2 0 0 1 -4 0v-6m0 2a2 2 0 1 1 -2 -2h6" />
  <path d="M12 12h-6a2 2 0 0 1 0 -4h6m-2 0a2 2 0 1 1 2 -2v6" />
</svg>

<!-- Telegram -->
<svg *ngIf="type === assetTypes.telegram" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-telegram" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
</svg>

<!-- Discord -->
<svg *ngIf="type === assetTypes.discord" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-discord" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="9" cy="12" r="1" />
  <circle cx="15" cy="12" r="1" />
  <path d="M7.5 7.5c3.5 -1 5.5 -1 9 0" />
  <path d="M7 16.5c3.5 1 6.5 1 10 0" />
  <path d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-1 2.5" />
  <path d="M8.5 17c0 1 -1.356 3 -1.832 3c-1.429 0 -2.698 -1.667 -3.333 -3c-.635 -1.667 -.476 -5.833 1.428 -11.5c1.388 -1.015 2.782 -1.34 4.237 -1.5l1 2.5" />
</svg>

<!-- WhatsApp -->
<svg *ngIf="type === assetTypes.whatsapp" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-whatsapp" [style.width.px]="size" [style.height.px]="size" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
  <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
</svg>

<!-- Communications -->
<!-- Communications --><!-- Communications -->
<!-- Communications --><!-- Communications --><!-- Communications -->
<!-- Communications --><!-- Communications --><!-- Communications --><!-- Communications -->

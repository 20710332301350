<div class="tab-container" [@fade-in]>
    <h3 class="h3">Billing</h3>

    <div class="d-lg-flex d-block justify-content-between">
     
    </div>

    <div class="current-item-border mb-4 mt-2"></div>


    <div class="row" *ngIf="subscription && subscription.status != 'canceled'">

        <div class="col col-lg-6 col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="paymentMethodSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body d-flex flex-column" *ngIf="paymentMethod && currentInvoice" [@fade-in]>
                    <div class="d-flex justify-content-between">
                        <div class="card-label mt-auto">Payment Method</div>
                        <a [routerLink]="" (click)="openModal(paymentMethodChangeModal)" >Change</a>
                    </div>

                    <div class="current-item-border mb-4 mt-2"></div>
                    <div class="d-flex justify-content-between">
                        <img [src]="cardImageSrc">
                        <div class="d-flex mt-auto mb-auto">
                            <div class="mr-2">
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                            </div>
                            <div class="mr-2">
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                            </div>
                            <div class="mr-2">
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                                <span class="dot"></span>
                            </div>
                            <div class="">
                                {{ paymentMethod.card.last4 }}
                            </div>
                            
                        </div>
                    </div>

                    <div class="font-weight-lighter mt-2 mb-4">
                        {{ cardType }} | Expires {{ paymentMethod.card.exp_month }}/{{ paymentMethod.card.exp_year }}
                    </div>

                    
                    <div class="mt-auto mt-4">
                        <div class="" *ngIf="!subscription.cancel_at_period_end">
                            Next billing on <strong>{{ currentInvoice.next_payment_attempt * 1000 | date }}</strong>
                        </div>
                        <div class="" *ngIf="subscription.cancel_at_period_end">
                            Subscription ends on <strong>{{ currentInvoice.period_end * 1000 | date }}</strong>
                        </div>
                        <!-- Next billing on <strong>{{ currentInvoice.next_payment_attempt * 1000 | date }}</strong> -->
                    </div>
                </div>
            </div>
        </div>


        <div class="col col-lg-6 col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="subscriptionSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body" *ngIf="subscription" [@fade-in]>
                    <div class="d-flex justify-content-between">
                        <div class="card-label mt-auto">Subscription</div>
                        <a routerLink="/admin/subscription">Modify</a>
                    </div>

                    <div class="current-item-border mb-4 mt-2"></div>

                    <div class="mb-2">
                        <div class="card-label">Subscription Plan</div>
                        <div class="">{{ plan }}</div>
                    </div>
                    <div class="mb-2">
                        <div class="card-label">Subscription Term</div>
                        <div class="">{{ term }}</div>
                    </div>
                    <div class="">
                        <div class="card-label">Subscription Status</div>
                        <div class="">
                            <span class="badge badge-success badge-pill" *ngIf="subscription.status === 'active' || subscription.status === 'trialing'">{{ subscription.status | titlecase }}</span>
                            <span class="badge badge-warning badge-pill" *ngIf="subscription.cancel_at_period_end">Canceling on {{ subscription.current_period_end * 1000 | date:'mediumDate' }}</span>
                            <span class="badge badge-warning badge-pill" *ngIf="subscription.status === 'incomplete' || subscription.status === 'incomplete_expired'">{{ subscription.status | titlecase }}</span>
                            <span class="badge badge-warning badge-pill" *ngIf="subscription.status === 'past_due' || subscription.status === 'unpaid' || subscription.status === 'canceled'">{{ subscription.status | titlecase }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <div class="row card-row section-margin" *ngIf="subscription && subscription.status != 'canceled'">
        <div class="col col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="currentInvoiceSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body" *ngIf="currentInvoice" [@fade-in]>
                    <div class="d-lg-flex d-block justify-content-between">
                        <div class="card-label mt-auto">Your {{ currentInvoice.billing_reason | titlecase }} Social Card Invoice</div>
                        <div class="text-right" *ngIf="!subscription.cancel_at_period_end">
                            Next billing on <strong>{{ currentInvoice.next_payment_attempt * 1000 | date }}</strong>
                        </div>
                        <div class="text-right" *ngIf="subscription.cancel_at_period_end">
                            Subscription ends on <strong>{{ currentInvoice.period_end * 1000 | date }}</strong>
                        </div>
                    </div>

                    <div class="current-item-border mb-4 mt-2"></div>

                    <!-- <div class="d-lg-flex d-block justify-content-between mb-4 mt-2">
                        <p class="font-weight-bolder mb-0">{{ currentInvoiceLineItems.data[0].plan.nickname }}</p>
                        <div class="text-right mt-auto mb-auto">
                            <small>{{ currentInvoice.period_start * 1000 | date:'mediumDate' }} - {{ currentInvoice.period_end * 1000 | date:'mediumDate' }}</small>
                        </div>
                    </div> -->

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col" class="card-label">Item Description</th>
                                <th scope="col" class="card-label text-center">Quantity</th>
                                <th scope="col" class="card-label text-right">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                        

                                <tr *ngFor="let item of reversedLineItems">
                                    <td scope="row">
                                        <p class="">
                                        {{ item.description }}
                                        </p>
                                    </td>
                                    <td class="text-center">
                                        <p>
                                        {{ item.quantity }}
                                        </p>
                                    </td>
    
                                    <td class="text-right">
                                        <p>
                                            {{ item.amount * 0.01 | currency }}
                                        </p>
                                    </td>
                                </tr>

                             
                              
                            </tbody>
                        </table>

                        <div class="current-item-border mb-4 mt-2"></div>
                        <table class="table table-hover">
                    
                            <tbody>
                        

                                <tr>
                                    <td scope="row">
                                        <p class="font-weight-bolder">Total</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ currentInvoice.total * 0.01 | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <p class="font-weight-bolder">Amount Due</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ currentInvoice.amount_due * 0.01 | currency }}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <p class="font-weight-bolder">Amount Paid</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ currentInvoice.amount_paid * 0.01 | currency }}</p>
                                    </td>
                                </tr>
                                <tr class="" *ngIf="currentInvoice.ending_balance < 0">
                                    <td scope="row">
                                        <p class="font-weight-bolder">Credit Balance</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ currentInvoice.ending_balance * 0.01 | currency }}</p>
                                    </td>
                                </tr>
                                <tr *ngIf="currentInvoice.ending_balance > 0">
                                    <td scope="row">
                                        <p class="font-weight-bolder">Amount Remaining</p>
                                    </td>
    
                                    <td class="text-right">
                                        <p class="text-right">{{ currentInvoice.amount_remaining * 0.01 | currency }}</p>
                                    </td>
                                </tr>

                             
                              
                            </tbody>
                        </table>
                        
                    </div>

                    <!-- <pre>{{ currentInvoice | json }}</pre> -->
                </div>
            </div>
        </div>
    </div>







    <div class="row card-row section-margin" *ngIf="subscription && subscription.status != 'canceled'">
        <div class="col col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="allInvoicesSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body" *ngIf="allInvoices" [@fade-in]>
                    <div class="d-flex justify-content-between">
                        <div class="card-label mt-auto">Payment History</div>
                    </div>

                    <div class="current-item-border mb-4 mt-2"></div>

                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col" class="card-label">Date</th>
                                <th scope="col" class="card-label text-center">Status</th>
                                <th scope="col" class="card-label text-center">Amount Due</th>
                                <th scope="col" class="card-label text-center">Amount Paid</th>
                                <th scope="col" class="card-label"></th>
                            
            
                              </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let invoice of allInvoices.data; let i = index;">
                                    <td class="invoice-item"><p>{{ invoice.period_end * 1000 | date:'mediumDate' }}</p></td>


                                    <td class="invoice-item text-center" *ngIf="invoice.status === 'open'"><span class="badge badge-primary badge-pill">{{ invoice.status | titlecase }}</span></td>
                                    <td class="invoice-item text-center" *ngIf="invoice.status === 'draft'"><span class="badge badge-warning badge-pill">{{ invoice.status | titlecase }}</span></td>
                                    <td class="invoice-item text-center" *ngIf="invoice.status === 'paid'"><span class="badge badge-success badge-pill">{{ invoice.status | titlecase }}</span></td>
                                    <td class="invoice-item text-center" *ngIf="invoice.status === 'void' || invoice.status === 'uncollectible'"><span class="badge badge-error badge-pill">{{ invoice.status | titlecase }}</span></td>

                                    
                                    <td class="invoice-item text-center">
                                      <p>{{ invoice.amount_due * 0.01 | currency }}</p>
                                    </td>

                                    <td class="invoice-item text-center">
                                        <p>{{ invoice.amount_paid * 0.01 | currency }}</p>
                                    </td>

                                    <td class="text-right">
                                        <a class="btn nav-btn" [href]="invoice.invoice_pdf" [download]="'Invoice: ' + (invoice.period_end * 1000 | date:'mediumDate')" (click)="downloadingPdfNotif()">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                                <polyline points="7 11 12 16 17 11" />
                                                <line x1="12" y1="4" x2="12" y2="16" />
                                            </svg>
                                        </a>
                                    </td>
                                </tr>
                              
                            </tbody>
                        </table>

                    </div>
                    <small>Contact Social Card support for further invoicing information.</small>
                    <!-- <pre>{{ allInvoices | json }}</pre> -->
                </div>
                
            </div>
        </div>
    </div>




    <div class="card shadow" *ngIf="subscription && subscription.status === 'canceled'">
        <div class="card-body">
            <h3>No Active Subscriptions</h3>
            <p>This account has no active Social Card subscriptions.</p>
            <button class="btn btn-primary mt-4">Setup Subscription</button>
        </div>
    </div>
</div>




<ngx-spinner 
name="billingSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>
</ngx-spinner>




<!-- 
<div class="card shadow cta-card">
                
    <div class="card-body cta-card-body d-flex flex-column" *ngIf="subscription.items.data[0].plan.id === planStartupAnnual || subscription.items.data[0].plan.id === planStartupMonthly">
        <div class="cta-card-container">
            <h2 class="cta-h2">Upgrade Now!</h2>
            <div class="mb-4">
                Upgrade your organizations account now, and gain access to the best of Social Card.
            </div>
            <div class="mt-auto">
                <button class="btn btn-gradient w-100">Upgrade</button>
            </div>
        </div>
        
    </div>
    <div class="card-body cta-card-body d-flex flex-column" *ngIf="subscription.items.data[0].plan.id === planGrowthMonthly || subscription.items.data[0].plan.id === planGrowthAnnual">
        <div class="cta-card-container">
            <h2 class="cta-h2">We are here to help!</h2>
            <div class="mb-4">
                Reach out to our support team for any and all billing questions, or read more about Social Card billing.
            </div>
            <div class="mt-auto">
                <button class="btn btn-gradient w-100">More On Billing</button>
            </div>
        </div>
        
    </div>
    <img src="assets/shapes_b_left.svg" class="shapes-b-left">
    <img src="assets/shapes_b_left.svg" class="shapes-t-right">
</div> -->




<ng-template #paymentMethodChangeModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="">Change Payment Method</h2>
                    <p class="">Add your new payment method below, and we'll update your billing account.</p>
                </div>
              
                <app-admin-card-change></app-admin-card-change>
               
                <div class="mt-2 text-center">
                    <a (click)="hidePmChangeModal()" class="" [routerLink]=""><small>Cancel</small></a>
                </div>
            </div>
        </div>
    </div>

</ng-template>




<ng-template #SubscriptionModificationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="">Modify Your Subscription</h2>
                    <p class="">Manage the inner working of your organizations subscription.</p>
                </div>
                
                <div class="current-item-border mb-4 mt-2"></div>
                <button class="btn btn-sm btn-error">Cancel Subscription</button>
           
                <div class="current-item-border mb-4 mt-2"></div>
                <div class="mt-2 text-center">
                    <a (click)="this.modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                </div>
            </div>
        </div>
    </div>

</ng-template>
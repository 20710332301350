import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '@env/environment';

import { NewTenantService } from '@core/services/tenant/new-tenant/new-tenant.service';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Result } from '@app/core/models/api.model';
import { TenantSubscription } from '@app/core/models/tenant.model';


@Injectable({
  providedIn: 'root'
})
@Injectable()
export class StripeService {

  tenant_subscriptionRef: AngularFireList<any>;

  baseUrl: string = environment.api.url;
  constructor(
    private newTenantService: NewTenantService,
    private http: HttpClient,
    private db: AngularFireDatabase,
  ) {
    this.tenant_subscriptionRef = this.db.list('tenant_subscriptions');
  }

  


  // Stores a retrivable object containing stripe customer info
  // Current Stripe Customer object
  private currentStripeCustomerSubject = new BehaviorSubject<{}>({});
  public currentStripeCustomerObject = this.currentStripeCustomerSubject.asObservable().pipe(distinctUntilChanged());

  private currentStripeCustomerIdSubject = new BehaviorSubject<string>(null);
  public currentStripeCustomerId = this.currentStripeCustomerIdSubject.asObservable().pipe(distinctUntilChanged());

  // Stores a retrivable object containing stripe subscription info
  // Current Stripe Subscription object
  private currentStripeSubscriptionSubject = new BehaviorSubject<{}>({});
  public currentStripeSubscriptionObject = this.currentStripeSubscriptionSubject.asObservable().pipe(distinctUntilChanged());

  private currentStripeSubIdSubject = new BehaviorSubject<string>(null);
  public currentStripeSubId = this.currentStripeSubIdSubject.asObservable().pipe(distinctUntilChanged());


  // SUB STATUSING INFO

  // Stores the status of a tenants subscription
  private currentStripeSubStatusSubject = new BehaviorSubject<string>(null);
  public currentStripeSubStatus = this.currentStripeSubStatusSubject.asObservable().pipe(distinctUntilChanged());

  // Stores the status of a tenants subscription
  private currentStripePeriodEndSubject = new BehaviorSubject<number>(null);
  public currentStripePeriodEnd = this.currentStripePeriodEndSubject.asObservable().pipe(distinctUntilChanged());
  




  getCurrentStripeCustomerObject(): {} {
    return this.currentStripeCustomerSubject.value;
  }
  getCurrentStripeSubscriptionObject(): {} {
    return this.currentStripeSubscriptionSubject.value;
  }

  getCurrentTenantSubscriptionObject(): TenantSubscription {
    return {
      customer_id: this.currentStripeCustomerIdSubject.value,
      subscription_id: this.currentStripeSubIdSubject.value,
      users: 1,
      plan: '',
      status: this.currentStripeSubStatusSubject.value,
      current_period_ends: this.currentStripePeriodEndSubject.value,
    }
  }








  // Sends request to Server for the creation of a new customer object with provided info from tenant setup
  async createCustomer(): Promise<Result> {

    return new Promise<Result>((resolve, reject) => {

      // Fetches the tenant details from the tenant creation service
      this.newTenantService.newTenantsDetails.subscribe(async tenant => {

        const billingEmail = tenant.contact_email
        const billingname = tenant.contact_name
        const billingDescription = tenant.name

        // Url of create-customer function 
        const functionUrl = environment.api.stripe.createCustomer;

        await this.http.post(`${this.baseUrl}${functionUrl}`, {
          name: billingname,
          email: billingEmail,
          description: billingDescription,
        }).toPromise().then(res => {
          let result = res as Result
          console.log(result);
          this.currentStripeCustomerIdSubject.next(result.data['customer']);
          resolve(result);
        }).catch(err => {
          console.error(err);
          reject();
        });
        
      });

    });
  }




  // Create the subscription on the server and returns the Stripe subscription object for tenant
  async createSubscription({customerId, paymentMethodId, priceId, quantity}): Promise<Result> {

    // Url of create-customer function 
    const functionUrl = environment.api.stripe.newSubscription;
    const tenant_id = sessionStorage.getItem('t');

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, 
      {
        sub: {
          customer: customerId,
          items: [
            { price: priceId },
          ],
          metadata: {
            tenant_id: tenant_id,
          }
        },
        payment_method: paymentMethodId,
      }
      ).toPromise().then(res => {
        let result = res as Result
        console.log(result);

        this.currentStripeSubIdSubject.next(result.data['subscription_id']);
        this.currentStripeSubStatusSubject.next(result.data['status']);
        this.currentStripePeriodEndSubject.next(result.data['current_period_end']);

        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    });
  }

  async getSubscription(): Promise<Result> {
    const tenant_id = sessionStorage.getItem('t'); 
    const functionUrl = environment.api.stripe.getSubscription;

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log(result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      })
    })
  }



  async cancelSubscription(): Promise<Result> {
    const tenant_id = sessionStorage.getItem('t'); 
    const functionUrl = environment.api.stripe.cancelSubscription;

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log(result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      })
    })
  }

  async resumeSubscription(): Promise<Result> {
    const tenant_id = sessionStorage.getItem('t'); 
    const functionUrl = environment.api.stripe.resumeSubscription;

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log(result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      })
    })
  }


  async modifySubscription(price_id: string): Promise<Result> {
    const tenant_id = sessionStorage.getItem('t'); 
    const functionUrl = environment.api.stripe.modifySubscription;

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
        price_id: price_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log(result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      })
    })
  }



















  // Removes a Quantity of **1** from the subscription to reflect a revokal of a sent invitation.
  async updateSubForInvitationRevoke(tenant_id: string): Promise<Result> {
  
    const functionUrl = environment.api.stripe.decreaseSubQuantity;

    return new Promise<Result>(async (resolve, reject) => {
      await this.http.post(`${this.baseUrl}${functionUrl}`, tenant_id).toPromise().then(res => {
        let result = res as Result
        
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    });
  }

  // Removes a Quantity of **1** from the subscription to reflect a removal of a user from the team
  async updateSubForMemberRemoval(tenant_id: string): Promise<Result> {
    
    return new Promise<Result>((resolve, reject) => {


    });
  }

}


import { Component, OnInit, TemplateRef } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { fadeInAnimation } from '@app/shared/animations/animations';

import { OnboardingService } from '@app/core/services/onboarding/onboarding.service';
import { TeamService } from '@core/services/team/team.service';
import { InvitesObject } from '@app/core/models/team.model';

@Component({
  selector: 'app-admin-invite',
  templateUrl: './admin-invite.component.html',
  styleUrls: ['./admin-invite.component.css'],
  animations: [fadeInAnimation],
})
export class AdminInviteComponent implements OnInit {

  inviteArrayItems: {
    email: string;
    role: string;
  }[];

  inviteForm: FormGroup;
  items: FormArray;

  public roles = ['User', 'Admin'];


  invitesSent: boolean = false;

  constructor(
    private onboardingService: OnboardingService,
    private teamService: TeamService,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) { }
  
  
  ngOnInit() {
    this.onboardingService.setOrgOnboardingObservable();

    this.inviteForm = this.formBuilder.group({
      items: this.formBuilder.array([ this.createItem() ])
    });
    this.items = this.inviteForm.get('items') as FormArray;

    // const test = this.items.controls[0].get('email');
  }


  async sendUserInvites() {
    this.modalRef.hide()
    this.snackBarService.success('Sending invites');
    this.spinner.show('inviteTeamSpinner');

    let invite: InvitesObject = {
      admin_uid: sessionStorage.getItem('u'),
      tenant_id: sessionStorage.getItem('t'),
      invitees: this.inviteForm.value.items,
    };

    // Clear duplicates and add status: pending deafault
    invite.invitees = invite.invitees.filter((invitee, index, self) => self.findIndex(i => i.email === invitee.email) === index);
    // invite.invitees = invite.invitees.filter((invitee, index, self) => self.findIndex(i => i.role === invitee.email && i.role === invitee.role) === index)

    invite.invitees.forEach(invitee => {
      invitee.status = 'pending';
      invitee.role = invitee.role.toLowerCase();
    });

    console.log(invite);
    await this.teamService.sendEmailInvites(invite).then(res => {
      console.log('👩‍💻  :', invite);
      this.updateItemCompletion();
    }).catch(err => {
      this.snackBarService.error('An internal error occured');
    });
    // Trigger invites to be send to all added users,
    // Send email to owner contact about user additions/billing changes ,and who did it
    
  }

  async updateItemCompletion() {
    await this.onboardingService.setOnboardingItemAsCompleted(2).then(_ => {
      // Show success notif & stop animations
      this.spinner.hide('inviteTeamSpinner');
      this.snackBarService.success('Invitations successfully sent');
      this.invitesSent = true;
    }).catch(err => {
      this.spinner.hide('inviteTeamSpinner');
      // handle err
      console.error(err);
      this.snackBarService.error('An internal error occured');
    });
  }


  // Resets the forms so user can invite more
  resetInviteForms() {
    this.inviteForm.reset();

    this.inviteForm = this.formBuilder.group({
      items: this.formBuilder.array([ this.createItem() ])
    });
    this.items = this.inviteForm.get('items') as FormArray;

    this.invitesSent = false;
  }











  createItem(): FormGroup {
    const newGroup = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', [Validators.required]),
    });
    console.log(newGroup);
    return newGroup
  }

  addItem(): void {
    this.items = this.inviteForm.get('items') as FormArray;
    this.items.push(this.createItem());


    console.log(this.inviteForm.value);
  }

  removeItem(index: number) {
    console.log(index);
    if (this.items.length != 0) {
      this.items.removeAt(index);
      this.snackBarService.success('Removed user from invite list');
    }
  }




  modalRef: BsModalRef;
  
  openModal(template: TemplateRef<any>) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, config);
  }
}

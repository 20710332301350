<app-top-nav></app-top-nav>
<app-side-nav></app-side-nav>

<div class="cover" [@fade-in] *ngIf="load_completed && (growthPlan || (!growthPlan && allTemplatesCount <= 0))">
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👋
            </div>
            <p>Welcome, let's get started</p>
        </div>
        <h1>Take a few minutes to finalize your organizations account.</h1>
    </div>
</div>

<div class="cover" [@fade-in] *ngIf="load_completed && (!growthPlan && allTemplatesCount >= 0)">
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👀
            </div>
            <p>Upgrade your plan</p>
        </div>
        <h1>No Templates Available</h1>
        <p>This account already has an active template. Please upgrade your plan to create new templates!</p>
    </div>
</div>


<div class="container-fluid form-container-fluid" [@fade-in] *ngIf="!load_completed">
    <div class="position-relative">
        <ngx-spinner 
        name="newTemplateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
    </div>
</div>


<div class="container-fluid form-container-fluid" *ngIf="load_completed && (growthPlan || (!growthPlan && allTemplatesCount <= 0))" [@fade-in]="load_completed">


    <div class="row card-row section-margin">
        <div class="col-12">
            <h3 class="h3">New Template</h3>
        </div>

        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <form [formGroup]="newTemplateForm">
        
                        <div class="form-group">
                            <label for="name">Template Name</label>
                            <input class="form-control" formControlName="name" type="text" placeholder="" id="name">
                            
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                                <small *ngIf="name.errors.required" class="validation-error">A template name is required.</small>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <label for="description">Template Description</label>
                            <textarea class="form-control" formControlName="description" type="text" rows="3" placeholder="" id="description"></textarea>
                            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="form-text text-muted">
                                <small *ngIf="description.errors.required" class="validation-error">A template description is required.</small>
                                <small *ngIf="description.errors.maxLength" class="validation-error">A template descrption must be 255 characters or less.</small>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <label>Template Starting Theme</label>
                    <p>Select the starting point for your template.</p>

                    <div class="row mt-4 text-center">
                        <div class="col-sm-6 col-12" [ngClass]="isLight ? 'preview-section-selected' : 'preview-section'" (click)="changeTheme(true)">
                            <img class="mt-2" style="width: 150px; border-radius: 12px;" src="assets/template-previews/template_preview_light.svg" alt="...">
                            <h6 class="text-center mt-4">Light Theme</h6>
                        </div>
                        <div class="col-sm-6 col-12" [ngClass]="isLight ? 'preview-section' : 'preview-section-selected'" (click)="changeTheme(false)">
                            <img class="mt-2" style="width: 150px; border-radius: 12px;" src="assets/template-previews/template_preview_dark.svg" alt="...">
                            <h6 class="text-center mt-4">Dark Theme</h6>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="col-12 mb-4">
            <div class="card shadow">
                <div class="card-body">
                    <label>Before you start customizing</label>
                    <p class="mt-2">Want to learn more about the steps and process of templating building?</p>
                    <a class="text-right" href="https://www.joinsocialcard.com/learning-center" target="_blank">Explore More
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <polyline points="9 6 15 12 9 18" />
                      </svg>
                    </a>
                    
                </div>
            </div>
        </div>




        <div class="col-12">
            <button [disabled]="newTemplateForm.invalid || newTemplateForm.untouched || newTemplateForm.pristine" class="btn btn-primary mb-4 w-100" (click)="createTemplate()">Generate Template</button>
            <div class="text-center mt-2">
                <a class="" routerLink="/">Finish Later</a>
            </div>
        </div>

    </div>

</div>



<div class="container-fluid form-container-fluid" *ngIf="load_completed && (!growthPlan && allTemplatesCount >= 0)" [@fade-in]>
    <div class="card shadow">
        <div class="card-body">
            <label>No Template Available</label>
            <p>To access the full feature set of Social Card, we suggest upgrading your team to the Growth Plan. To access features like unlimited templates, unlimited Social Cards, Social Card Departments, and much more.</p>
            <a class="btn btn-primary w-100 mt-4" href="https://www.joinsocialcard.com" target="_blank">Learn More</a>
        </div>
    </div>
</div>
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { AccessoryItemContent, AccessorySection, Template } from '@app/core/models/template.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

// Animation
import { fadeInAnimation } from '@app/shared/animations/animations';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { TemplatingService } from '@app/core/services/templates/templating.service';
import { UrlFormatterService } from '@core/services/content-types/url/url-formatter.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AllQuickLinkContentTypes, AllQuickLinkSectionNames, ContentType, DataTypes, LinkTypes } from '@app/core/models/content-type-models/content-types.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { VCard } from 'ngx-vcard';
import { VCardEncoding } from 'ngx-vcard';
import { VcardService } from '@app/core/services/content-types/vcard/vcard.service';
import { ContentTestingService } from '@app/core/services/content-types/content-testing/content-testing.service';

@Component({
    selector: 'app-web-accessory-items',
    templateUrl: './web-accessory-items.component.html',
    styleUrls: ['./web-accessory-items.component.css'],
    animations: [fadeInAnimation],
})
export class WebAccessoryItemsComponent implements OnInit, OnDestroy {

    templateSub: Subscription;
    currentTemplateSettings: Template;


    isSectionView: boolean = false;
    isSectionItemView: boolean = false;

    itemContentTypeSelection: boolean = true;



    accessoryHovered: boolean[][] = [];

    newAccessoryItem: boolean = false;

    currentSectionIndex: number;
    currentItemIndex: number; // index of item currently being edited (new or existing)
    removeSectionIndex: number;
    removeSectionItemIndex: number;

    accessoryItemContentTypeSelection: boolean = true;
    accessoryItemContentEntry: boolean = false;
    
    sectionNames = AllQuickLinkSectionNames;
    availableContentTypes = AllQuickLinkContentTypes;

    newSectionForm = new FormGroup({
        sectionTitle: new FormControl('', [Validators.required]),
    });
    newSectionFormSub: Subscription;
    get sectionTitle() { return this.newSectionForm.get('sectionTitle'); }


    sectionTitleForm = new FormGroup({
        newSectionTitle: new FormControl('', [Validators.required]),
    });
    sectionTitleFormSub: Subscription;
    get newSectionTitle() { return this.sectionTitleForm.get('newSectionTitle'); }

    public vCardEncoding: typeof VCardEncoding = VCardEncoding;

    constructor(
        private templatingService: TemplatingService,
        private router: Router,
        private snackBarService: SnackBarService,
        private modalService: BsModalService,
        private urlFormatterService: UrlFormatterService,
        private contentTestingService: ContentTestingService,
        private vcardService: VcardService,
    ) {
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });

        // Adds long form text type to available content
        this.availableContentTypes[0].push(
            { name: 'Message', ref: 'message', type: { source_type: LinkTypes.message, data_type: DataTypes.message }},
        )

        this.currentSectionIndex = null;

        this.updateHoverStateVariables();
    }

    ngOnInit(): void {
        this.onChanges();
    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();
        this.sectionTitleFormSub.unsubscribe();
    }
    // Pushes changed data into service.
    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }

    onChanges() {
        this.sectionTitleFormSub = this.sectionTitleForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].section_label = val.newSectionTitle;
        })
    }
    

    addNewAccessoryItem() {
        this.newAccessoryItem = true;
    }


    editAccessoryItem(section: number, index: number) {
        //edit a selected accessory items content
        this.currentItemIndex = index;

        this.accessoryItemContentTypeSelection = false;
        this.accessoryItemContentEntry = true;
    }


    cancelNewAccessoryItem() {
        // this.currentTemplateSettings.web.accessory_items.items.splice(this.currentItemIndex, 1);

        this.newAccessoryItem = false;

        this.accessoryItemContentTypeSelection = true;
        this.accessoryItemContentEntry = false;
    }


    accessoryItemContentTypeChange(type: ContentType) {
        console.log(type);
        // this.currentTemplateSettings.web.accessory_items.items[this.currentItemIndex].item_content_type = type;
        this.accessoryItemContentTypeSelection = !this.accessoryItemContentTypeSelection;
        this.accessoryItemContentEntry = !this.accessoryItemContentEntry;

        this.updateTemplate();
    }
    changeAccessoryItemContentType() {
        this.accessoryItemContentTypeSelection = !this.accessoryItemContentTypeSelection;
        this.accessoryItemContentEntry = !this.accessoryItemContentEntry;
    }































    toggleContentControl(index: number, event) {
        this.currentTemplateSettings.web.accessory_items.acessory_sections[index].content_control = event;
        this.updateTemplate();
    }
    toggleSectionPositionControl(index: number, event) {
        this.currentTemplateSettings.web.accessory_items.acessory_sections[index].postion_control = event;
        this.updateTemplate();
    }
    toggleAnalyticsEnabled(index: number, event) {
        this.currentTemplateSettings.web.accessory_items.acessory_sections[index].analytics_enabled = event;
        this.updateTemplate();
    }


    addNewSection() {

        this.currentTemplateSettings.web.accessory_items.acessory_sections.push({
            section_label: this.sectionTitle.value,
            content_control: true,
            postion_control: true,
            analytics_enabled: true,
            items: [],
        })

        this.editSection(this.currentTemplateSettings.web.accessory_items.acessory_sections.length - 1);
        this.updateTemplate();
        this.modalRef.hide();
    }


    editSection(index: number) {
        this.currentSectionIndex = index;
        this.isSectionView = true;
        this.isSectionItemView = false;

        this.sectionTitleForm.patchValue({
            newSectionTitle: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].section_label,
        })
    }
    removeSection() {
        console.log(this.removeSectionIndex, this.currentTemplateSettings.web.accessory_items.acessory_sections[this.removeSectionIndex]);
        this.currentTemplateSettings.web.accessory_items.acessory_sections.splice(this.removeSectionIndex, 1);

        this.updateHoverStateVariables();
        this.updateTemplate();
        this.backToSectionsView();
        this.modalRef.hide();
    }

    backToSectionsView() {
        this.currentSectionIndex = null;
        this.isSectionView = false;
        this.isSectionItemView = false;
        this.itemContentTypeSelection = !this.itemContentTypeSelection;
    }


    addNewItemToSection() {
        this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items.push({
            item_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
            item_content_label: null,
            item_content_data: null,
            item_analytics_id: null,
        })

        this.updateHoverStateVariables();
        this.editSectionItem(this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items.length - 1);
    }

    editSectionItem(index: number) {
        this.currentItemIndex = index;

        this.isSectionView = false;
        this.isSectionItemView = true;
    }

    removeSectionItem(index: number) {
        console.log(this.removeSectionItemIndex, this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items[this.removeSectionItemIndex]);
        this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items.splice(this.removeSectionItemIndex, 1);

        this.updateHoverStateVariables();
        this.updateTemplate();
        this.backToSectionsView();
        this.modalRef.hide();
    }

    backToSectionView() {
        this.currentItemIndex = null;
        this.isSectionView = true;
        this.isSectionItemView = false;
    }



    

    itemContentTypeChange(type: ContentType) {
        console.log(type);
        this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items[this.currentItemIndex].item_content_type = type;
        this.itemContentTypeSelection = !this.itemContentTypeSelection;

        this.updateTemplate();
    }

    editItemContentType() {
        this.itemContentTypeSelection = !this.itemContentTypeSelection;
    }

























    async testAccessoryItemLink() {
        const templateAccessoryType = this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items[this.currentItemIndex].item_content_type;
        const templateAccessoryData = this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items[this.currentItemIndex].item_content_data;

        const nonTestableLinkTypes = [LinkTypes.vcard, LinkTypes.message];
  
        if (nonTestableLinkTypes.some(type => type === templateAccessoryType.ref)) {
            this.showNonTestableAlert(templateAccessoryType.ref);
        } else if (templateAccessoryType.ref === 'sms') {
            this.contentTestingService.sendSms(templateAccessoryData.phone);
        } else if (templateAccessoryType.ref === 'email') {
            this.contentTestingService.sendEmail(templateAccessoryData.email);
        } else if (templateAccessoryType.ref === 'phone') {
            this.contentTestingService.callNumber(templateAccessoryData.phone);
        } else {
            if (templateAccessoryData != null) {
                var url: string;
                await this.urlFormatterService.formatUrl(templateAccessoryData.url, 
                    templateAccessoryType.ref, templateAccessoryType.type.source_type).then(res => {
                    url = res;
                })
                window.open(url, "_blank");
            } else {
                this.snackBarService.warning('No URL is available. update the quick link, and try again');
            }
        }
    }




    // VCARD FORMATTER
    getVcard(): VCard {
        return this.vcardService.generateVcard(this.currentTemplateSettings.web.accessory_items.acessory_sections[this.currentSectionIndex].items[this.currentItemIndex].item_content_data);
    }








    updateHoverStateVariables() {
        // Update hover state variables
        this.accessoryHovered = [];
        var sectionArray = [];
        for (const section of this.currentTemplateSettings.web.accessory_items.acessory_sections) {
            for (const item of section.items) {
                sectionArray.push(false);
            }
            this.accessoryHovered.push(sectionArray);
            sectionArray = [];
        }
    }



    // Shadows

    shadowHovered(shadowColor: string) {
        // Gets selected shadow color
        let rgb = this.getRGB(shadowColor);
        let style = {
            'box-shadow': `0 22px 32px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.56), 0 9px 28px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.64)`
        }
        return style; // Returns the Social Card shadow string back
    }
    shadowNotHovered(shadowColor: string) {
        let rgb = this.getRGB(shadowColor);

        let style = {
            'box-shadow': `0 22px 25px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.22), 0 9px 23px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.5)`
        };
        return style;
    }
    getRGB(str) {
        var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        return match ? {
            red: match[1],
            green: match[2],
            blue: match[3]
        } : {};
    }


    modalRef: BsModalRef;
  
    openModal(template: TemplateRef<any>, index: number) {
      var config = {
        animated: false,
        ignoreBackdropClick: true,
      };

      this.removeSectionIndex = index;

      this.modalRef = this.modalService.show(template, config);
    }


    openRemoveSectionItemModal(template: TemplateRef<any>, index: number) {
        var config = {
          animated: false,
          ignoreBackdropClick: true,
        };
  
        this.removeSectionItemIndex = index;
        console.log(index);
  
        this.modalRef = this.modalService.show(template, config);
    }

    openNewSectionModal(template: TemplateRef<any>) {
        var config = {
          animated: false,
          ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }



    showNonTestableAlert(type: string) {
        switch (type) {
            case LinkTypes.vcard: {
                this.snackBarService.warning('Testing the Contact type is currently unavailable');
                break;
            }
            case LinkTypes.sms: {
                this.snackBarService.warning('Testing the Text type is currently unavailable');
                break;
            }
            case LinkTypes.email: {
                this.snackBarService.warning('Testing the Email type is currently unavailable');
                break;
            }
            case LinkTypes.phone: {
                this.snackBarService.warning('Testing the Phone type is currently unavailable');
                break;
            }
            default: {
                this.snackBarService.warning('Testing the this content type is currently unavailable');
                break;
            }
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalAlert } from '@app/core/models/global-alert.model';

import { fadeInAnimation } from '@app/shared/animations/animations';

// Service
import { GlobalAlertService } from '@core/services/global/global-alert.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-global-alert',
    templateUrl: './global-alert.component.html',
    styleUrls: ['./global-alert.component.css'],
    animations: [fadeInAnimation],
})
export class GlobalAlertComponent implements OnInit, OnDestroy {

    isCollapsed: boolean;

    alert: GlobalAlert;

    isCollapsedSub: Subscription;
    alertSub: Subscription;

    constructor(
        private globalAlertService: GlobalAlertService,
    ) { 
        this.globalAlertService.setAlertObject();

        this.isCollapsedSub = this.globalAlertService.alertCollapse.subscribe(res => {
            this.isCollapsed = res;
        })

        this.alertSub = this.globalAlertService.globalAlert.subscribe(alert => {
            this.alert = alert;
        });
    }
    ngOnDestroy(): void {
        this.alertSub.unsubscribe();
        this.isCollapsedSub.unsubscribe();
    }

    ngOnInit(): void {

    }


    closeAlert() {
        this.globalAlertService.toggleAlert();
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ROUTING
import { TemplatesRoutingModule } from './templates-routing.module';

//Reference Modules
import { CoreModule } from '@core/core.module';


//Reactive Forms
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// COMPONENTS

// NGX BOOTSRAP
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

// Services
import { NgxSpinnerModule } from "ngx-spinner";
import { ColorPickerModule } from 'ngx-color-picker';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxVcardModule } from "ngx-vcard";

import { TemplatingComponent } from './pages/templating/templating.component';
import { NewTemplateComponent } from './pages/new-template/new-template.component';

import { TemplatingLeftNavComponent } from './components/templating-left-nav/templating-left-nav.component';
import { WebAppearanceComponent } from './components/web/web-appearance/web-appearance.component';
import { TemplateGeneralComponent } from './components/template-general/template-general.component';
import { WebMediaComponent } from './components/web/web-media/web-media.component';
import { WebQuickLinksComponent } from './components/web/web-quick-links/web-quick-links.component';
import { WebCtaComponent } from './components/web/web-cta/web-cta.component';
import { WebAccessoryItemsComponent } from './components/web/web-accessory-items/web-accessory-items.component';
import { ContentTypeInputComponent } from './components/web/web-quick-links/components/content-type-input/content-type-input.component';
import { AppleWalletGeneralComponent } from './components/apple-wallet/apple-wallet-general/apple-wallet-general.component';
import { AppleWalletFrontComponent } from './components/apple-wallet/apple-wallet-front/apple-wallet-front.component';
import { AppleWalletQrComponent } from './components/apple-wallet/apple-wallet-qr/apple-wallet-qr.component';
import { AppleWalletBackComponent } from './components/apple-wallet/apple-wallet-back/apple-wallet-back.component';
import { WebAccessoryContentTypeInputComponent } from './components/web/web-accessory-content-type-input/web-accessory-content-type-input.component';
import { AppleWalletQrContentTypeInputComponent } from './components/apple-wallet/apple-wallet-qr-content-type-input/apple-wallet-qr-content-type-input.component';
import { QrComponent } from './components/qr/qr.component';


@NgModule({
    declarations: [
        TemplatingComponent,
        TemplatingLeftNavComponent,
        NewTemplateComponent,

        WebAppearanceComponent,
        TemplateGeneralComponent,

        WebMediaComponent,
        WebQuickLinksComponent,
        WebCtaComponent,

        WebAccessoryItemsComponent,

        ContentTypeInputComponent,

        AppleWalletGeneralComponent,

        AppleWalletFrontComponent,

        AppleWalletQrComponent,

        AppleWalletBackComponent,

        WebAccessoryContentTypeInputComponent,

        AppleWalletQrContentTypeInputComponent,
        QrComponent
    ],
    imports: [
        CommonModule,

        TemplatesRoutingModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        ButtonsModule,
        TooltipModule,
        SortableModule,
        NgxSpinnerModule,
        ColorPickerModule,
        CollapseModule,

        QRCodeModule,
        NgxVcardModule,
    ]
})
export class TemplatesModule { }

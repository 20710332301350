import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, finalize } from 'rxjs/operators';

import { AngularFireStorage } from '@angular/fire/storage';
import { TenantService } from '@app/core/services/tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class OrgLogoService {

  private logoSubject = new BehaviorSubject<any>(null);
  public logo = this.logoSubject.asObservable().pipe(distinctUntilChanged());

  private logoDataSubject = new BehaviorSubject<any>(null);
  public logoData = this.logoDataSubject.asObservable().pipe(distinctUntilChanged());

  private logoProgressSubject = new BehaviorSubject<number>(null);
  public logoProgress = this.logoProgressSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private storage: AngularFireStorage,
    private tenantService: TenantService,
  ) { }


  updateLogoObject(file: any) {
    this.logoSubject.next(file);
    console.log('🇺🇸', file);
    
    if (file.length === 0) {
      return;
    }
 
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      console.log("Only images are supported.");
      return;
    } else if (mimeType.match(/imagesvg\+xml|image\/svg\+xml/)) {
      console.log("Only non svg images are supported.");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file); 
    reader.onload = (_event) => { 
      this.logoDataSubject.next(reader.result);
    }
  }

  deleteUploadedLogo() {
    this.logoSubject.next(null)
    this.logoDataSubject.next(null);
  }

  getCurrentOrgLogo() {
    return this.logoDataSubject.value;
  }

  // Uploads the current document to cloud storage and returns the necessary references for DB
  async uploadOrgLogoToStorage(): Promise<Boolean> {
    console.log('Uploading logo to storage...', this.logoSubject.value);

    return new Promise<Boolean>((resolve, reject) => {

      const tenant_id = sessionStorage.getItem('t');
      const path = `tenants/${tenant_id}/media/logo`;

      const ref = this.storage.ref(path);

      const task = this.storage.upload(path, this.logoSubject.value);
    
      // Tracks state of upload
      task.snapshotChanges().subscribe(result => {
        console.log(result.state);
        if (result.state === 'success') {
          resolve(true);
        } else if (result.state === 'error') {
          reject(false);
        } else {
          console.log('still processing image');
        }
      })
      // observe percentage changes
      task.percentageChanges().subscribe(progress => {
        this.logoProgressSubject.next(progress);
        console.log('Uploading progress: ', progress);
      });

      // get notified when the download URL is available
      ref.getDownloadURL().subscribe(url => {
        console.log(url);
      });
    })
  }
    





}

<div class="bg-container">
    <div class="parent-container text-center justify-content-center">
        <div class="child-container">
            <div class="error-text">Erm... Oops!</div>
            <img class="img" src="assets/404.svg">
            <p class="text-center">This totally is not supposed to happen. We're usually experts at this, but we can't complete the task at this time.</p>
            <button class="btn btn-auxiliary btn-lg ml-auto mr-auto mt-4" (click)="backToSafety()">Back To Safety</button>
        </div>
    </div>

    <!-- <div class="circle-one"></div> -->
</div>
<!-- <h1>Erm... Oops!</h1>
<p>This totally is not supposed to happen. We're usually experts at this, but we can't complete the task at this time.</p> -->
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { CardCountGuard } from '@core/guards/card-count-guard/card-count.guard';

// Components
import { DashboardComponent } from '@app/modules/home/pages/dashboard/dashboard.component';
import { HomeComponent } from '@app/modules/home/pages/home/home.component';
import { CardViewComponent } from '@app/modules/home/pages/card-view/card-view.component';

// Onboarding COMPS
import { OnboardingAccountDetailsComponent } from '@app/modules/home/components/onboarding/onboarding-account-details/onboarding-account-details.component';
import { OnboardingAddTeamComponent } from '@app/modules/home/components/onboarding/onboarding-add-team/onboarding-add-team.component';
import { OnboardingDepartmentComponent } from '@app/modules/home/components/onboarding/onboarding-department/onboarding-department.component';
import { OnboardingTemplateComponent } from './components/onboarding/onboarding-template/onboarding-template.component';

import { PageNotFoundComponent } from '@app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {path: '', component: DashboardComponent},
  {path: 'onboarding', children: [
    {path: '', redirectTo: '/', pathMatch: 'full'},
    {path: 'account', component: OnboardingAccountDetailsComponent},
    {path: 'team', component: OnboardingAddTeamComponent},
    {path: 'departments', component: OnboardingDepartmentComponent},
    {path: 'templates', component: OnboardingTemplateComponent},
  ]},

  {path: 'cards', component: HomeComponent },
  {path: 'card', component: CardViewComponent},
  
  {path: 'new', component: DashboardComponent, canActivate: [CardCountGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
import { AllContentTypes, ContentType, DataTypes, LinkTypes } from "@app/core/models/content-type-models/content-types.model";
import { Department, Departments } from "@app/core/models/departments.model";
import { MediaPublished, MediaUnpublished } from "@app/core/models/media.model";

export interface Template {
    // General
    id: string, //Preset
    tenant_id: string, //Preset
    last_updated: string,
    published: boolean;

    name: string,
    description: string,
    
    support_web: boolean,
    support_apple: boolean,
    support_email: boolean,
    support_qr: boolean,
    
    departments: string[],

    supported_content: ContentType[], // Content types allowed for use in this template. Socials, contact, sites, etc.

    
    web: WebTemplateSettings,
    apple: AppleWalletTemplateSettings,
    qr: QrTemplateSettings,
}

// Interface type for all templates within a tenant
export interface TenantTemplates<Template> {
    [key: string]: Template,
}


export interface WebTemplateSettings {
    // Web - Media
    cover_photo: string,
    logo: string,

    // Web - appearance

    // Basic
    background_color: string,
    can_edit_background_color: boolean,

    section_text_color: string,
    can_edit_section_text_color: boolean,

    border_color: string,
    border_width: number,
    can_edit_border_style: boolean,

    // Media
    can_edit_media_style: boolean,
    media_cover_corner_radius: number,
    media_headshot_corner_radius: number,
    media_shadow_color: string,

    // Quick Links
    can_edit_quick_link_style: boolean,
    quick_links_fill_color: string,
    quick_links_text_color: string,
    quick_links_shadow_color: string,
    quick_links_corner_radius: number,


    // CTA
    can_edit_cta_style: boolean,
    cta_fill_color: string,
    cta_text_color: string,
    cta_shadow_color: string,
    cta_corner_radius: number,

    // Accessory
    can_edit_acessory_style: boolean,
    accessory_item_fill_color: string,
    accessory_item_text_color: string,
    accessory_item_shadow_color: string,
    accessory_item_corner_radius: number,



    // CONTENT SETTINGS


    // WEB - Quick Links
    quick_link_one_content_control: boolean,
    quick_link_one_content_type: ContentType,
    quick_link_one_content_label: string,
    quick_link_one_content_data: any,
    quick_link_one_analytics_enabled: boolean,
    quick_link_one_analytics_id: string,

    quick_link_two_content_control: boolean,
    quick_link_two_content_type: ContentType,
    quick_link_two_content_label: string,
    quick_link_two_content_data: any,
    quick_link_two_analytics_enabled: boolean,
    quick_link_two_analytics_id: string,

    quick_link_three_content_control: boolean,
    quick_link_three_content_type: ContentType,
    quick_link_three_content_label: string,
    quick_link_three_content_data: any,
    quick_link_three_analytics_enabled: boolean,
    quick_link_three_analytics_id: string,

    cta_content_control: boolean,
    cta_content_type: ContentType,
    cta_content_label: string,
    cta_content_data: any,
    cta_analytics_enabled: boolean,
    cta_analytics_id: string,



    accessory_items: {
        acessory_sections: AccessorySection[],
    }
}





export interface AppleWalletTemplateSettings {

    // Config
    colors: TemplateColors
    logos: TemplateLogo,
    thumbnails: TemplateThumbnails,

    // Fields
    frontFields: TemplateFrontFields,
    qr: TemplateWalletQr,
    can_customize_front_fields: boolean,
    backFields: TemplateBackFields[],
    can_add_backfields: boolean,
    backfield_default_location: boolean,
}


export interface QrTemplateSettings {
    can_edit_qr: boolean,
    qr_analytics_enabled: boolean,
    
    background_color: string,
    logo_url: string,

    qr_type: ContentType,
    qr_data: any,
}


























































// SUPPORTING OBJECTS// SUPPORTING OBJECTS// SUPPORTING OBJECTS// SUPPORTING OBJECTS
// SUPPORTING OBJECTS// SUPPORTING OBJECTS// SUPPORTING OBJECTS
// SUPPORTING OBJECTS// SUPPORTING OBJECTS
// SUPPORTING OBJECTS

export interface TemplateColors {
    background_color: string,
    foreground_color: string,
    label_color: string,
    
    can_edit_styling: boolean;
}

export interface TemplateLogo {
    logo: string,
    logo_2x: string,
    logo_text: string,
    can_edit_logo: boolean,
    can_edit_logo_text: boolean,
}

export interface TemplateThumbnails {
    thumbnail: string,
    thumbnail_2x: string,
    can_edit_thumbnail: boolean,
}

export interface TemplateFrontFields {
    primary_field: TemplatePrimaryField,
    can_edit_primary_field: boolean,

    secondary_field: TemplateSecondaryField,
    can_edit_secondary_field: boolean,

    auxiliary_field: TemplateAuxiliaryField,
    can_edit_auxiliary_field: boolean,
}

export interface TemplateWalletQr {
    type: ContentType,
    analytics_enabled: boolean,
    can_edit_qr: boolean,
    data: any,
}

export interface TemplatePrimaryField {
    key: string,
    label: string,
    value: string,
}

export interface TemplateSecondaryField {
    key: string,
    label: string,
    value: string,
}

export interface TemplateAuxiliaryField {
    key: string,
    label: string,
    value: string,
}




export interface TemplateBackFields {
    attributed_value: string,
    key: string,
    label: string,
    value: string,

    type: ContentType,
    can_edit_back_field: boolean,
    analytics_enabled: boolean,
}















export interface AccessorySection {
    section_label: string,
    content_control: boolean,
    postion_control: boolean,
    analytics_enabled: boolean,

    items: AccessoryItemContent[],
}
export interface AccessoryItemContent {
    item_content_type: ContentType,
    item_content_label: string,
    item_content_data: any,
    
    item_analytics_id: string,
}






export enum TemplatingState {
    General,
    
    WebBasic,
    WebMedia,
    WebAppearance,
    WebQuickLinks,
    WebCta,
    WebAccessory,
  
    AppleWalletGeneral,
    AppleWalletFront,
    AppleWalletQr,
    AppleWalletBack,
  
    Qr,
  
    Email,
}





export enum QrType {
    contact,
    url,
}











































export const DefaultTemplate: Template = {
    id: '',
    tenant_id: '',
    last_updated: '1234567890',
    published: false,
    
    // General
    name: 'New Template',
    description: 'No description',

    support_web: true,
    support_apple: true,
    support_email: true,
    support_qr: true,

    departments: [],
    supported_content: [],

    web: {
        // Media
        cover_photo: 'https://firebasestorage.googleapis.com/v0/b/social-card-business.appspot.com/o/global%2Fdemo_bg_light.png?alt=media&token=f9d1adb3-86f0-4f2a-83b2-ff1775076c56',
        logo: null,


        // Basic
        can_edit_background_color: false,
        background_color: 'rgb(255,255,255)',

        can_edit_section_text_color: false,
        section_text_color: 'rgb(220,226,231)',

        can_edit_border_style: false,
        border_color: 'rgb(220,226,231)',
        border_width: 5,

        // Media
        can_edit_media_style: false,
        media_cover_corner_radius: 50,
        media_headshot_corner_radius: 75,
        media_shadow_color: 'rgb(220,226,231)',

        // Quick Links
        can_edit_quick_link_style: false,
        quick_links_fill_color: 'rgb(255,255,255)',
        quick_links_text_color: 'rgb(36,40,44)',
        quick_links_shadow_color: 'rgb(220,226,231)',
        quick_links_corner_radius: 12,

        // CTA
        can_edit_cta_style: false,
        cta_fill_color: 'rgb(79,172,254)',
        cta_text_color: 'rgb(255,255,255)',
        cta_shadow_color: 'rgb(220,226,231)',
        cta_corner_radius: 12,

        // Acessory
        can_edit_acessory_style: false,
        accessory_item_fill_color: 'rgb(255,255,255)',
        accessory_item_text_color: 'rgb(36,40,44)',
        accessory_item_shadow_color: 'rgb(220,226,231)',
        accessory_item_corner_radius: 12,




        quick_link_one_content_control: true,
        quick_link_one_content_type: {name: 'Email', ref: 'email', type: {source_type: LinkTypes.email, data_type: DataTypes.email}},
        quick_link_one_content_label: null,
        quick_link_one_content_data: null,
        quick_link_one_analytics_enabled: true,
        quick_link_one_analytics_id: null,

        quick_link_two_content_control: true,
        quick_link_two_content_type: {name: 'iOS App', ref: 'ios_app', type: {source_type: LinkTypes.ios_app, data_type: DataTypes.ios_app}},
        quick_link_two_content_label: null,
        quick_link_two_content_data: null,
        quick_link_two_analytics_enabled: true,
        quick_link_two_analytics_id: null,

        quick_link_three_content_control: true,
        quick_link_three_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
        quick_link_three_content_label: null,
        quick_link_three_content_data: null,
        quick_link_three_analytics_enabled: true,
        quick_link_three_analytics_id: null,


        cta_content_control: true,
        cta_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
        cta_content_label: null,
        cta_content_data: null,
        cta_analytics_enabled: true,
        cta_analytics_id: null,


        accessory_items: {
            acessory_sections: [
                {
                    section_label: 'Default Section',
                    content_control: true,
                    postion_control: true,
                    analytics_enabled: true,
                    items: [
                        {
                            item_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
                            item_content_label: null,
                            item_content_data: null,
                            item_analytics_id: null,
                        },
                    ]
                },
            ]
        }
    },


    apple: {
        colors: {
            background_color: 'rgb(141, 175, 186)',
            foreground_color: 'rgb(69, 70, 69)',
            label_color: 'rgb(255, 255, 255)',

            can_edit_styling: false,
        },
        logos: {
            logo: '',
            logo_2x: '',
            logo_text: '',
            can_edit_logo: false,
            can_edit_logo_text: true,
        },
        thumbnails: {
            thumbnail: '',
            thumbnail_2x: '',
            can_edit_thumbnail: true,
        },

        // Fields
        frontFields: {
            primary_field: {
                key: '',
                label: '',
                value: '',
            },
            can_edit_primary_field: true,
        
            secondary_field: {
                key: '',
                label: '',
                value: '',
            },
            can_edit_secondary_field: true,
        
            auxiliary_field: {
                key: '',
                label: '',
                value: '',
            },
            can_edit_auxiliary_field: true,
        },
        qr: {
            type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
            analytics_enabled: true,
            can_edit_qr: true,
            data: '',
        },
        can_customize_front_fields: true,
        backFields: [],
        can_add_backfields: true,
        backfield_default_location: true,
    },

    qr: {
        can_edit_qr: true,
        qr_analytics_enabled: true,

        background_color: 'rgb(220,226,231)',
        logo_url: null,

        qr_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
        qr_data: null,
    }
}









export const DefaultTemplateDark: Template = {
    id: '',
    tenant_id: '',
    last_updated: '1234567890',
    published: false,
    
    // General
    name: 'New Template',
    description: 'No description',

    support_web: true,
    support_apple: true,
    support_email: true,
    support_qr: true,

    departments: [],
    supported_content: [],

    web: {
        // Media
        cover_photo: 'https://firebasestorage.googleapis.com/v0/b/social-card-business.appspot.com/o/global%2Fdemo_bg_dark.png?alt=media&token=27fc8bcc-1b31-4309-a5f9-8c2488a85125',
        logo: null,


        // Basic
        can_edit_background_color: false,
        background_color: 'rgb(0,0,0)',

        can_edit_section_text_color: false,
        section_text_color: 'rgb(255,255,255)',

        can_edit_border_style: false,
        border_color: 'rgb(30, 30, 36)',
        border_width: 5,

        // Media
        can_edit_media_style: false,
        media_cover_corner_radius: 50,
        media_headshot_corner_radius: 75,
        media_shadow_color: 'rgb(51,51,51)',

        // Quick Links
        can_edit_quick_link_style: false,
        quick_links_fill_color: 'rgb(30, 30, 36)',
        quick_links_text_color: 'rgb(93, 101, 108)',
        quick_links_shadow_color: 'rgb(51,51,51)',
        quick_links_corner_radius: 12,

        // CTA
        can_edit_cta_style: false,
        cta_fill_color: 'rgb(75, 176, 254)',
        cta_text_color: 'rgb(255,255,255)',
        cta_shadow_color: 'rgb(75, 176, 254)',
        cta_corner_radius: 12,

        // Acessory
        can_edit_acessory_style: false,
        accessory_item_fill_color: 'rgb(30, 30, 36)',
        accessory_item_text_color: 'rgb(93, 101, 108)',
        accessory_item_shadow_color: 'rgb(51,51,51)',
        accessory_item_corner_radius: 12,




        quick_link_one_content_control: true,
        quick_link_one_content_type: {name: 'Email', ref: 'email', type: {source_type: LinkTypes.email, data_type: DataTypes.email}},
        quick_link_one_content_label: null,
        quick_link_one_content_data: null,
        quick_link_one_analytics_enabled: true,
        quick_link_one_analytics_id: null,

        quick_link_two_content_control: true,
        quick_link_two_content_type: {name: 'iOS App', ref: 'ios_app', type: {source_type: LinkTypes.ios_app, data_type: DataTypes.ios_app}},
        quick_link_two_content_label: null,
        quick_link_two_content_data: null,
        quick_link_two_analytics_enabled: true,
        quick_link_two_analytics_id: null,

        quick_link_three_content_control: true,
        quick_link_three_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
        quick_link_three_content_label: null,
        quick_link_three_content_data: null,
        quick_link_three_analytics_enabled: true,
        quick_link_three_analytics_id: null,


        cta_content_control: true,
        cta_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
        cta_content_label: null,
        cta_content_data: null,
        cta_analytics_enabled: true,
        cta_analytics_id: null,


        accessory_items: {
            acessory_sections: [
                {
                    section_label: 'Default Section',
                    content_control: true,
                    postion_control: true,
                    analytics_enabled: true,
                    items: [
                        {
                            item_content_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
                            item_content_label: null,
                            item_content_data: null,
                            item_analytics_id: null,
                        },
                    ]
                },
            ]
        }
    },


    apple: {
        colors: {
            background_color: 'rgb(0,0,0)',
            foreground_color: 'rgb(245, 245, 245)',
            label_color: 'rgb(5, 175, 255)',

            can_edit_styling: false,
        },
        logos: {
            logo: '',
            logo_2x: '',
            logo_text: '',
            can_edit_logo: false,
            can_edit_logo_text: true,
        },
        thumbnails: {
            thumbnail: '',
            thumbnail_2x: '',
            can_edit_thumbnail: true,
        },

        // Fields
        frontFields: {
            primary_field: {
                key: '',
                label: '',
                value: '',
            },
            can_edit_primary_field: true,
        
            secondary_field: {
                key: '',
                label: '',
                value: '',
            },
            can_edit_secondary_field: true,
        
            auxiliary_field: {
                key: '',
                label: '',
                value: '',
            },
            can_edit_auxiliary_field: true,
        },
        qr: {
            type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
            analytics_enabled: true,
            can_edit_qr: true,
            data: '',
        },
        can_customize_front_fields: true,
        backFields: [],
        can_add_backfields: true,
        backfield_default_location: true,
    },

    qr: {
        can_edit_qr: true,
        qr_analytics_enabled: true,

        background_color: 'rgb(0,0,0)',
        logo_url: null,

        qr_type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
        qr_data: null,
    }
}
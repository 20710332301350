<nav class="topnav navbar navbar-expand fixed-top" *ngIf="showOrgSetupForm" [@fade-in]>
    <ul class="navbar-nav mr-auto ml-0 ml-md-3 my-2 my-md-0">
        <a class="a-light" routerLink="/auth/login">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="14" height="14" viewBox="0 0 24 24" stroke-width="1" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="15 6 9 12 15 18" />
            </svg>
            Back To Login
        </a>
    </ul>
</nav>

<div class="cover" [@fade-in] *ngIf="showOrgSetupForm">

    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👋
            </div>
            <p>Welcome</p>
        </div>
        <h1>Account Creation</h1>
        <p>Lets get your organization up and running on Social Card!</p>
    </div>
</div>





<div *ngIf="showOrgSetupForm" class="container-fluid form-container-fluid" [@fade-in]>
    <ul class="nav org-setup-nav justify-content-center d-lg-flex d-none">
        <li class="nav-item">
          <a class="nav-link active">Account</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Subscription</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Payment Method</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Finish</a>
        </li>
    </ul>


    <app-new-org-form
        [formContent]="orgFormContent"
        (formSubmit)="onFormSubmit($event)"
    ></app-new-org-form>

    <app-plan-selection *ngIf="showPlanSelection"
        [size]="orgFormData.organizationSize"
    ></app-plan-selection>

</div>






<div class="cover" *ngIf="showPlanSelection"  [@fade-in]>
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                😎
            </div>
            <p>Plan Selection</p>
        </div>
        <h1>Pricing for businesses of all sizes.</h1>
    </div>
</div>


<div *ngIf="showPlanSelection" class="container-fluid" [@fade-in]>
    <ul class="nav org-setup-nav justify-content-center d-lg-flex d-none">
        <li class="nav-item">
          <a class="nav-link">Account</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active">Subscription</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Payment Method</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Finish</a>
        </li>
    </ul>


    <app-plan-selection
        [size]="orgFormData.organizationSize"
    ></app-plan-selection>

    <div class="d-flex justify-content-center mt-2 mb-4">
        <a class="" href="https://www.joinsocialcard.com/pricing" target="_blank">Learn more about plans & pricing</a>
    </div>

</div>





<!-- <nav class="navbar fixed-bottom bg">
    <div class="d-flex">
        <a class="" href="https://www.joinsocialcard.com/terms" target="_blank"><small class="text-muted">Terms of service</small></a>
        <div class="ml-2 mr-2 text-muted">|</div>
        <a class="" href="https://www.joinsocialcard.com/privacy" target="_blank"><small class="text-muted">Privacy policy</small></a>
    </div>
</nav> -->




<ngx-spinner 
name="orgSetupSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>
</ngx-spinner>
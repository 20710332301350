<div class="tab-container" [@fade-in]>
    
    <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
        <h3 class="h3 mb-0">General Organization Information</h3>
        <a class="" [routerLink]="" (click)="openModal(orgInfoChangeModal)">Update</a>
    </div>
    <div class="current-item-border mt-2 mb-4"></div>

    <span class="card-label">Organization Name *</span>
    <div class="mb-4">
        <p class="mb-0">{{ tenant.name }}</p>
    </div>

    <span class="card-label mt-4">Organization Website</span>
    <div class="mb-4">
        <p class="mb-0">{{ tenant.website }}</p>
        <small class="text-muted" *ngIf="!tenant.website">No website saved</small>
    </div>

    <span class="card-label mt-4">Industry</span>
    <div class="mb-4">
        <p class="mb-0">{{ tenant.industry }}</p>
    </div>

    <span class="card-label mt-4">Organization Size</span>
    <div class="mb-4">
        <p class="mb-0">{{ tenant.size }}</p>
    </div>
</div>




<div class="tab-container" [@fade-in]>

    <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
        <h3 class="h3 mb-0">General Contact Information</h3>
        <a class="" [routerLink]="" (click)="openModal(orgContactChangeModal)">Update</a>
    </div>
    <div class="current-item-border mt-2 mb-4"></div>

    <span class="card-label">Name *</span>
    <div class="mb-4">
        <p class="mb-0">{{ tenant.contact_name }}</p>
    </div>

    <span class="card-label mt-4">Email *</span>
    <div class="mb-4">
        <p class="mb-0">{{ tenant.contact_email }}</p>
    </div>

    <span class="card-label mt-4">Phone</span>
    <div class="mb-4">
        <p class="mb-0" *ngIf="tenant.contact_phone">{{ tenant.contact_phone }}</p>
        <small class="text-muted" *ngIf="!tenant.contact_phone">No phone number saved</small>
    </div>

</div>



<div class="tab-container" [@fade-in]>
    
    <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
        <h3 class="h3 mb-0">Organization Vanity URL</h3>
        <a class="" [routerLink]="" (click)="openModal(orgVanityUrlModal)">Update</a>
    </div>
    <div class="current-item-border mt-2 mb-4"></div>

    <span class="card-label">URL</span>
    <div class="mb-4 d-flex">
        <!-- <h5 class="mb-0 text-muted">socard.me/<strong class="font-weight-bolder">{{ 'sc' }}</strong>{{'/$username'}}</h5> -->
        <div class="text-muted">socard.me/</div>
        <div class="font-weight-bolder">{{ url.value | lowercase }}</div>
        <div class="text-muted">/jane-doe</div>
    </div>

</div>


<div class="tab-container" [@fade-in]>
    
    <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
        <h3 class="h3 mb-0">Organization Logo</h3>
        <a class="" [routerLink]="" (click)="openModal(orgLogoModal)">Update</a>
    </div>
    <div class="current-item-border mt-2 mb-4"></div>

    <span class="card-label">Logo</span>
    <div class="card shadow mt-2">
        <a class="stretched-link" (click)="openModal(orgLogoModal)"></a>
        <div class="card-body card-body-sm text-center">
            <img *ngIf="logoLocalUrl" loading="lazy" [src]="(logoLocalUrl | async) || 'assets/icon_clear.svg'"  class="org-logo org-logo-center">
            <p *ngIf="!logoLocalUrl">Logo not found</p>
            <div class="text-center mt-3">
                <small>Click to change</small>
            </div>
        </div>
    </div>

</div>


















<ng-template #orgInfoChangeModal>
    <div class="modal-card">
        <ngx-spinner 
        name="orgInfoUpdateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <h2 class="mb-3">Update Information</h2>

                <form [formGroup]="generalOrgInfoForm" (ngSubmit)="updateOrgInfo()">
        
                    <div class="form-group">
                        <label for="name">Organization Name</label>
                        <input class="form-control" formControlName="name" type="text" placeholder="Ex. Social Card" id="name">
                        
                        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                            <small *ngIf="name.errors.required" class="validation-error">A name is required.</small>
                        </div>
                    </div>
    
                    <div class="form-group">
                        <label for="website">Website</label>
                        <input class="form-control" formControlName="website" type="url" placeholder="Ex. www.joinsocialcard.com" id="website">
                    </div>
    
                    <div class="form-group">
                        <label for="industry">Industry</label>
                        <input class="form-control" formControlName="industry" type="text" placeholder="Ex. SaaS" id="industry">
                        
                        <div *ngIf="industry.invalid && (industry.dirty || industry.touched)" class="form-text text-muted">
                            <small *ngIf="industry.errors.required" class="validation-error">An industry is required.</small>
                        </div>
                    </div>
    
                    <div class="form-group">
                        <label for="size">Organization Size</label>
                        <select formControlName="size" class="custom-select">
                            <option value="" disabled>Select a range</option>
                            <option
                            *ngFor="let option of sizeList"
                            [value]="option.name"
                            >{{ option.name }}</option>
                        </select>                    
                        <div *ngIf="size.invalid && (size.dirty || size.touched)" class="form-text text-muted">
                            <small *ngIf="size.errors.required" class="validation-error">An org size is required.</small>
                        </div>
                    </div>
    
                    <div class="mt-auto text-center">
                        <button type="submit" [disabled]="generalOrgInfoForm.invalid || generalOrgInfoForm.untouched || generalOrgInfoForm.pristine" class="btn btn-primary mt-4 w-100">Save Changes</button>
                        <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                    </div>
                    
                </form>

                
            </div>
        </div>
    </div>
</ng-template>




<ng-template #orgContactChangeModal>
    <div class="modal-card">
        <ngx-spinner 
        name="orgContactUpdateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <h2 class="mb-3">Contanct Update</h2>


                <form [formGroup]="orgContactForm" (ngSubmit)="updateOrgContactInfo()">
        
                    <div class="form-group">
                        <label for="contact_name">Contact Name</label>
                        <input class="form-control" formControlName="contact_name" type="name" placeholder="Ex. Jane Doe" id="contact_name">
                        
                        <div *ngIf="contact_name.invalid && (contact_name.dirty || contact_name.touched)" class="form-text text-muted">
                            <small *ngIf="contact_name.errors.required" class="validation-error">A contact name is required.</small>
                        </div>
                    </div>
    
                    <div class="form-group">
                        <label for="contact_email">Contact Email</label>
                        <input class="form-control" formControlName="contact_email" type="email" placeholder="Ex. email@example.com" id="contact_email">
                        
                        <div *ngIf="contact_email.invalid && (contact_email.dirty || contact_email.touched)" class="form-text text-muted">
                            <small *ngIf="contact_email.errors.required" class="validation-error">A contact email is required.</small>
                            <small *ngIf="contact_email.errors.pattern" class="validation-error">A valid contact email address is required.</small>
                        </div>
                    </div>
    
                    <div class="form-group">
                        <label for="contact_phone">Contact Phone</label>
                        <input class="form-control" formControlName="contact_phone" type="phone" placeholder="Ex. +1 (000)-000-0000" autocomplete="phone" id="contact_phone">
                        
                        <div *ngIf="contact_phone.invalid && (contact_phone.dirty || contact_phone.touched)" class="form-text text-muted">
                            <small *ngIf="contact_phone.errors.required" class="validation-error">A contact phone number is required.</small>
                            <small *ngIf="contact_phone.errors.pattern" class="validation-error">A valid contact phone number is required.</small>
                        </div>
                    </div>
    
                    <div class="mt-auto text-center">
                        <button type="submit" [disabled]="orgContactForm.invalid || orgContactForm.untouched || orgContactForm.pristine" class="btn btn-primary mt-4 w-100">Save Changes</button>
                        <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                    </div>
                </form>
            </div>
        </div>
    </div>

</ng-template>




<ng-template #orgVanityUrlModal>
    <div class="modal-card">
        <ngx-spinner 
        name="orgVanityUpdateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="mb-3">Vanity URL Change</h2>
                    <p class="font-weight-bolder validation-error">WARNING: Modifying this url may break links already in use by your team.</p>
                    <small>We recommemnd you inform your entire organization when these changes occur to prevent any functionality issues.</small>    
                </div>

                <form [formGroup]="orgVanityUrlForm" (ngSubmit)="modifyOrgVanityUrl()">
        
                    <div class="form-group">
                        <label for="url">Vanity URL Route</label>
                        <input class="form-control" formControlName="url" type="text" placeholder="Ex. social-card" id="url">
                        
                        <div *ngIf="url.invalid && (url.dirty || url.touched)" class="form-text text-muted">
                            <small *ngIf="url.errors.required" class="validation-error">A vanity url is required.</small>
                        </div>
                    </div>
    
                    <div class="mt-2 mb-4 d-flex">
                        <div class="text-muted">socard.me/</div>
                        <div class="font-weight-bolder">{{ url.value | lowercase }}</div>
                        <div class="text-muted">/jane-doe</div>
                    </div>
    
                    <div class="mt-auto text-center">
                        <button type="submit" [disabled]="orgVanityUrlForm.invalid || orgVanityUrlForm.untouched || orgVanityUrlForm.pristine" class="btn btn-primary mt-4 w-100">Save Changes</button>
                        <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                    </div>
                </form>
            </div>
        </div>
    </div>

</ng-template>







<ng-template #orgLogoModal>
    <div class="modal-card">
        <ngx-spinner 
        name="orgLogoUpdateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="mb-3">Logo Change</h2>
                </div>
                <label>Logo Preview</label>
                <div class="card shadow mt-2 mb-4">
                    <div class="card-body card-body-sm text-center">
                        <img *ngIf="logoLocalUrl" loading="lazy" [src]="(logoLocalUrl | async) || 'assets/icon_clear.svg'" class="org-logo org-logo-center">
                        <p *ngIf="!logoLocalUrl">Logo not found</p>
                    </div>
                </div>
                <label>Logo Upload</label>
                <app-org-logo-upload></app-org-logo-upload>

                <div class="mt-4 text-center">
                    <button class="btn btn-primary mt-4 w-100" [disabled]="!canSaveLogo" (click)="updateOrgLogo()">Save Changes</button>
                    <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                </div>
            </div>
        </div>
    </div>

</ng-template>








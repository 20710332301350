import { Component, OnInit } from '@angular/core';

import { NgxSpinnerService } from "ngx-spinner";
// ANIMATION
import { fadeInAnimation } from '@app/shared/animations/animations';
// MODELS

import { SocialCard } from '@app/core/models/socialCard.model';

@Component({
  selector: 'app-admin-template-preview',
  templateUrl: './admin-template-preview.component.html',
  styleUrls: ['./admin-template-preview.component.css'],
  animations: [
    fadeInAnimation
  ]
})
export class AdminTemplatePreviewComponent implements OnInit {


  preview: SocialCard;

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.applyTemplating()

  }

  applyTemplating() {
    // Mobile
    // this.preview.colors.background_color = this.template.colors.background_color;
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DefaultSocialCard } from '@app/core/models/socialCard.model';
import { Template } from '@app/core/models/template.model';
import { TemplatingService } from '@app/core/services/templates/templating.service';
import { fadeInAnimation } from '@app/shared/animations/animations';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-apple-wallet-front',
    templateUrl: './apple-wallet-front.component.html',
    styleUrls: ['./apple-wallet-front.component.css'],
    animations: [fadeInAnimation],
})
export class AppleWalletFrontComponent implements OnInit, OnDestroy {

    templateSub: Subscription;
    currentTemplateSettings: Template;

    isLogoTextCollapsed: boolean = false;
    isPrimaryFieldCollapsed: boolean = true;
    isSecondaryFieldCollapsed: boolean = true;
    isAuxiliaryFieldCollapsed: boolean = true;


    socialCard = DefaultSocialCard;

    logoTextForm = new FormGroup({
        logoText: new FormControl('', [Validators.required]),
    });
    logoTextFormSub: Subscription;

    get logoText() { return this.logoTextForm.get('logoText'); }


    primaryFieldForm = new FormGroup({
        primaryFieldText: new FormControl('', [Validators.required]),
    });
    primaryFieldFormSub: Subscription;

    get primaryFieldText() { return this.primaryFieldForm.get('primaryFieldText'); }


    secondaryFieldsForm = new FormGroup({
        secondaryFieldLabel: new FormControl('', [Validators.required]),
        secondaryFieldText: new FormControl('', [Validators.required]),
    });
    secondaryFieldsFormSub: Subscription;

    get secondaryFieldLabel() { return this.secondaryFieldsForm.get('secondaryFieldLabel'); }
    get secondaryFieldText() { return this.secondaryFieldsForm.get('secondaryFieldText'); }

    auxiliaryFieldsForm = new FormGroup({
        auxiliaryFieldLabel: new FormControl('', [Validators.required]),
        auxiliaryFieldText: new FormControl('', [Validators.required]),
    });
    auxiliaryFieldsFormSub: Subscription;

    get auxiliaryFieldLabel() { return this.auxiliaryFieldsForm.get('auxiliaryFieldLabel'); }
    get auxiliaryFieldText() { return this.auxiliaryFieldsForm.get('auxiliaryFieldText'); }
    
    constructor(
        private templatingService: TemplatingService,
    ) { 
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });
    }

    ngOnInit(): void {
        this.setupFormValuesFromTemplate();
        this.onChanges();
        this.updatePreview();
    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();

        this.logoTextFormSub.unsubscribe();
        this.primaryFieldFormSub.unsubscribe();
        this.secondaryFieldsFormSub.unsubscribe();
        this.auxiliaryFieldsFormSub.unsubscribe();
    }


    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);

        this.updatePreview();
    }

    updatePreview() {
        this.socialCard.logo.logo_text = this.currentTemplateSettings.apple.logos.logo_text || 'Your Name';
        this.socialCard.front_fields.primary_field.value = this.currentTemplateSettings.apple.frontFields.primary_field.value || 'Your Org Name';

        this.socialCard.front_fields.secondary_field.label = this.currentTemplateSettings.apple.frontFields.secondary_field.label.toUpperCase() || 'ROLE';
        this.socialCard.front_fields.secondary_field.value = this.currentTemplateSettings.apple.frontFields.secondary_field.value || 'Chief of Fun';

        this.socialCard.front_fields.auxiliary_field.label = this.currentTemplateSettings.apple.frontFields.auxiliary_field.label.toUpperCase() || 'EMAIL';
        this.socialCard.front_fields.auxiliary_field.value = this.currentTemplateSettings.apple.frontFields.auxiliary_field.value || 'email@example.com';

        this.socialCard.colors.background_color = this.currentTemplateSettings.apple.colors.background_color;
        this.socialCard.colors.foreground_color = this.currentTemplateSettings.apple.colors.foreground_color;
        this.socialCard.colors.label_color = this.currentTemplateSettings.apple.colors.label_color;
    }

    togglingLogoText() {
        this.isLogoTextCollapsed = !this.isLogoTextCollapsed;

        this.isPrimaryFieldCollapsed = true;
        this.isSecondaryFieldCollapsed = true;
        this.isAuxiliaryFieldCollapsed = true;
    }

    togglingPrimaryField() {
        this.isPrimaryFieldCollapsed = !this.isPrimaryFieldCollapsed;

        this.isLogoTextCollapsed = true;
        this.isSecondaryFieldCollapsed = true;
        this.isAuxiliaryFieldCollapsed = true;
    }

    togglingSecondaryField() {
        this.isSecondaryFieldCollapsed = !this.isSecondaryFieldCollapsed;

        this.isPrimaryFieldCollapsed = true;
        this.isLogoTextCollapsed = true;
        this.isAuxiliaryFieldCollapsed = true;
    }
    togglingAuxiliaryField() {
        this.isAuxiliaryFieldCollapsed = !this.isAuxiliaryFieldCollapsed;

        this.isPrimaryFieldCollapsed = true;
        this.isLogoTextCollapsed = true;
        this.isSecondaryFieldCollapsed = true;
    }



    allowLogoTextEditing(val) {
        this.currentTemplateSettings.apple.logos.can_edit_logo_text = val;
        this.updateTemplate();
    }
    allowPrimaryFieldEditing(val) {
        this.currentTemplateSettings.apple.frontFields.can_edit_primary_field = val;
        this.updateTemplate();
    }
    allowSecondaryFieldEditing(val) {
        this.currentTemplateSettings.apple.frontFields.can_edit_secondary_field = val;
        this.updateTemplate();
    }
    allowAuxiliaryFieldEditing(val) {
        this.currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field = val;
        this.updateTemplate();
    }



    onChanges(): void {
        this.logoTextFormSub = this.logoTextForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.logos.logo_text = val.logoText;
            this.updateTemplate();
        });

        this.primaryFieldFormSub = this.primaryFieldForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.frontFields.primary_field.value = val.primaryFieldText;
            this.updateTemplate();
        });

        this.secondaryFieldsFormSub = this.secondaryFieldsForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.frontFields.secondary_field.label = val.secondaryFieldLabel;
            this.currentTemplateSettings.apple.frontFields.secondary_field.value = val.secondaryFieldText;
            this.updateTemplate();
        });

        this.auxiliaryFieldsFormSub = this.auxiliaryFieldsForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.frontFields.auxiliary_field.label = val.auxiliaryFieldLabel;
            this.currentTemplateSettings.apple.frontFields.auxiliary_field.value = val.auxiliaryFieldText;
            this.updateTemplate();
        });
    }



    setupFormValuesFromTemplate() {
        this.logoTextForm.patchValue({
            logoText: this.currentTemplateSettings.apple.logos.logo_text,
        });

        this.primaryFieldForm.patchValue({
            primaryFieldText: this.currentTemplateSettings.apple.frontFields.primary_field.value,
        });

        this.secondaryFieldsForm.patchValue({
            secondaryFieldLabel: this.currentTemplateSettings.apple.frontFields.secondary_field.label,
            secondaryFieldText: this.currentTemplateSettings.apple.frontFields.secondary_field.value,
        });

        this.auxiliaryFieldsForm.patchValue({
            auxiliaryFieldLabel: this.currentTemplateSettings.apple.frontFields.auxiliary_field.label,
            auxiliaryFieldText: this.currentTemplateSettings.apple.frontFields.auxiliary_field.value,
        });
    }
}

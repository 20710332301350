import { Injectable, OnDestroy } from '@angular/core';
import { distinctUntilChanged, first, tap } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from "@angular/router";

import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';

//  Models
import { AuthUser, User } from '@core/models/user.model';

import { TenantService } from '@app/core/services/tenant/tenant.service';
import { Result } from '@app/core/models/api.model';
import { environment } from '@env/environment';


@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {

    authUser: AuthUser;

    private authUserSubject = new BehaviorSubject<AuthUser>({} as AuthUser);
    public currentAuthUser = this.authUserSubject.asObservable().pipe(distinctUntilChanged());

    private authTokenSubject = new BehaviorSubject<string>(null);
    public authToken = this.authTokenSubject.asObservable().pipe(distinctUntilChanged());

    private anonymousUidSubject = new BehaviorSubject<string>(null);
    public anonymousUid = this.anonymousUidSubject.asObservable().pipe(distinctUntilChanged());


    tokenSub: Subscription;

    constructor(
        public afAuth: AngularFireAuth,
        public router: Router,

        private tenantService: TenantService,
    ) {
        this.afAuth.authState.subscribe(user => {
            // console.log('👀 Checking Auth State', user);
            if (user) {
                this.authUser = user as unknown as AuthUser;
                this.authUserSubject.next(this.authUser);
                sessionStorage.setItem('u', this.authUser.uid);
                this.tenantService.getActiveTenant();
                this.tenantService.getActiveTenantSub();
            } else {
                this.authUserSubject.next(null);
                sessionStorage.setItem('u', null);
            }
        });

        this.tokenSub = this.afAuth.idToken.subscribe(token => {
            if (token) {
                this.authTokenSubject.next(token);
                // console.log(token);
            } else {
                //this.router.navigate(['/auth/login']);
            }
        })
    }

    ngOnDestroy() {
        this.tokenSub.unsubscribe();
    }

    getCurrentAuthUser(): string {
        if (sessionStorage.getItem('u') != null || sessionStorage.getItem('u') != undefined) {
            return sessionStorage.getItem('u');
        } else {
            this.router.navigate(['/auth/orgs'])
        }
    }

    async checkSessionAuthentication() {
        return this.afAuth.authState.pipe(
            tap(data => {
                // console.log(data)
            }),
            first()
        ).toPromise();
    }


    isAuthenticated() {
        return (sessionStorage.getItem('u')) ? true : false;
    }
    // Get User Auth Token
    getAuthToken(): string {
        return this.authTokenSubject.value;
    }

    getAnonymousUid(): string {
        return this.anonymousUidSubject.value;
    }



    // New Tenant Owner setup & login
    async anonymousLogin(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            var result = await this.afAuth.signInAnonymously().then(res => {
                this.anonymousUidSubject.next(res.user.uid);
                console.log('✅ Successfully created anonymous user account: ', res.user.uid);
                this.afAuth.idToken.subscribe(res => {
                    resolve(true);
                });

            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    // New Tenant Owner Account Link
    async anonymousToUser(email: string, password: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            var credential = firebase.auth.EmailAuthProvider.credential(email, password);
            (await this.afAuth.currentUser).linkWithCredential(credential).then(function (usercred) {
                var user = usercred.user;

                console.log("Anonymous account successfully upgraded", user);
                resolve(true);
            }).catch(function (error) {
                console.log("Error upgrading anonymous account", error);
                reject(error);
            });

        })

    }






    // Exisiting User Login
    async login(email: string, password: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.afAuth.setPersistence('local').then(async _ => {
                var result = await this.afAuth.signInWithEmailAndPassword(email, password).then(res => {
                    sessionStorage.setItem('u', res.user.uid);
                    console.log('✅ Successfully logged in user');
                    this.router.navigate(['auth/orgs']);
                    resolve(true);
                }).catch(err => {
                    console.error(err);
                    reject(err);
                })
            })

        })
    }

    // 
    async newOrgExistingAccountlogin(email: string, password: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.afAuth.setPersistence('local').then(async _ => {
                var result = await this.afAuth.signInWithEmailAndPassword(email, password).then(res => {
                    sessionStorage.setItem('u', res.user.uid);
                    console.log('✅ Successfully logged in user');
                    resolve(true);
                }).catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        })
    }












    // INVITED USER AUTH
    async createInvitedUserAccount(email: string, password: string): Promise<Result> {
        // Create auth user
        let uid: string;

        // Add user to tenant
        // Add user to users
        return new Promise<Result>(async (resolve, reject) => {
            await this.afAuth.createUserWithEmailAndPassword(email, password).then(res => {
                uid = res.user.uid;
                sessionStorage.setItem('u', uid);
                this.afAuth.idToken.subscribe(res => {
                    resolve({
                        success: true,
                        data: {
                            uid: uid,
                            email: email,
                        },
                    })
                });

            }).catch(err => {
                reject({
                    success: false,
                    data: err
                })
            })

            // const functionUrl = environment.api.pub.getInvite;
            // const body = {
            //   tenant_id: tenantId,
            //   email: invitation_id,
            // }

            // await this.http.post(`${this.baseUrl}${functionUrl}`, body).toPromise().then(res => {
            //   let result = res as Result
            //   resolve(result);
            // }).catch(err => {
            //   console.error(err);
            //   reject();
            // }); 
        })
    }

    async invitationExisitingAccountLogin(email: string, password: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            this.afAuth.setPersistence('local').then(async _ => {
                var result = await this.afAuth.signInWithEmailAndPassword(email, password).then(res => {
                    sessionStorage.setItem('u', res.user.uid);
                    console.log('✅ Successfully logged in user');
                    const uid = res.user.uid;
                    this.afAuth.idToken.subscribe(res => {
                        resolve({
                            success: true,
                            data: {
                                uid: uid,
                                email: email,
                            },
                        })
                    });
                }).catch(err => {
                    console.error(err);
                    reject(err);
                })
            })
        })
    }







    // Password Change
    async changePassword(currentPassword: string, newPassword: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const user = await this.afAuth.currentUser;

            // Reauthenticate the current user with the current password
            const credentials = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
            await user.reauthenticateWithCredential(credentials).then(() => console.log('reauth ok')).catch(err => {
                console.error(err);
                reject({
                    success: false,
                    data: err,
                });
            });

            // After reauthentication update the users password to the  new password
            user.updatePassword(newPassword).then(res => {
                resolve({
                    success: true,
                    data: null,
                });
            }).catch(err => {
                console.error(err);
                reject({
                    success: false,
                    data: err,
                });
            })
        })
    }






    // User Logout
    async logout() {
        var result = await this.afAuth.signOut().then(res => {
            console.log('🛑 Successfully signed out user!', res);
            sessionStorage.removeItem('t');
            this.router.navigate(['/auth']);

        }).catch(err => {
            console.error(err);
        })
    }




    async sendPasswordResetEmail(email: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            var result = await this.afAuth.sendPasswordResetEmail(email).then(res => {
                console.log('📩 Reset Email Sent');
                resolve(true);
            }).catch(err => {
                reject(false);
            })
        })
    }


    // Retreives client error message for returned auth error code
    handleAuthError(error: string): string {
        console.log('Handling Error: ', error);
        switch (error) {
            case 'auth/user-not-found': {
                return 'No account was found for this email. The account may have been removed.'
            }
            case 'auth/wrong-password': {
                return 'The password provided was invalid for this user.'
            }
            case 'auth/invalid-email': {
                return 'The email provided is invalid. Please provide a valid email address.'
            }
            case 'auth/internal-error': {
                return 'An internal system error occured. We apologize for the inconvenience.'
            }
            default: {
                return 'Unknown error occurred'
            }
        }
    }
}




// AUTH PROCESS
// 1. Login Page
// 2. Successful Login
// 3. based on UID check tenant associations by IDs in users ovject
// 4. If more than one tenant, show selection
  // A. get org info for selection
  // B. On selection, route to the specified org and load data
// 5. Else redirect directly to the only tenant
// 6. Load user tenant data
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { OrgLogoService } from '@core/services/org-logo/org-logo.service';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-org-logo-upload',
  templateUrl: './org-logo-upload.component.html',
  styleUrls: ['./org-logo-upload.component.css']
})
export class OrgLogoUploadComponent implements OnInit, OnDestroy {

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  
  errorMessage: string;

  progressSub: Subscription;
  progress: any = 0;


  constructor(
    private orgUploadService: OrgLogoService,
    private snackBarService: SnackBarService,
  ) { 
    this.progressSub = this.orgUploadService.logoProgress.subscribe(progress => {
      this.progress = progress;
    })
  }

  ngOnInit(): void {
    this.progress = 0;
  }
  ngOnDestroy(): void {
    this.progressSub.unsubscribe();

  }

  
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.orgUploadService.deleteUploadedLogo(); //Clears current file
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      if (item?.type.includes('svg')) {
        console.log('Error: SVG un supported');
        this.errorMessage = 'SVG files are not supported';
        this.snackBarService.error('SVG files are not supported');
        return;
      }
      if (item?.type.includes('image/')) {
        this.files.push(item);
        console.log('Files added 📂', item, files);
        this.errorMessage = null;
      }
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);


    // Sets current file in service
    console.log("FILE TYPE", this.files[0].type)
    this.orgUploadService.updateLogoObject(this.files[0]);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}

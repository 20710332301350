import { Component, Input, OnInit } from '@angular/core';

// MODELS
import { SocialCard } from '@app/core/models/socialCard.model';

@Component({
  selector: 'app-social-card',
  templateUrl: './social-card.component.html',
  styleUrls: ['./social-card.component.css']
})
export class SocialCardComponent implements OnInit {

  @Input('socialCard') socialCard: SocialCard;

  constructor() { }

  ngOnInit(): void {
  }


}

<div class="validation-error">{{ errorMessage }}</div>

<div class="card shadow" *ngIf="files.length === 0">
    <div class="card-body card-media">
        <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" class="input-dropzone" #fileDropRef id="fileDropRef" accept="image/*" (change)="fileBrowseHandler($event.target.files)">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud-upload" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                <polyline points="9 15 12 12 15 15" />
                <line x1="12" y1="12" x2="12" y2="21" />
            </svg>
            <h6>Drag and drop logo here</h6>
            <p>or</p>
            <button class="btn btn-sm btn-primary w-100" [routerLink]="">Browse for file</button>
        </div>
    </div>
</div>

<div class="text-center mt-2" *ngIf="files.length === 0">
    <small>180px x 120px recommended</small>
</div>












<div class="" *ngIf="files.length != 0">
    <div class="card shadow mt-2" *ngFor="let file of files; let i = index">
        <div class="card-body card-media-file">
            <div class="d-flex justify-content-between">
                <div class="">
                    <p class="mb-0 text-truncate file-name">{{ file?.name }}</p>
                    <small class="text-muted">{{ formatBytes(file?.size) }} | {{ file?.type }}</small>
                </div>
                <div class="">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="deleteFile(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="mt-2">
                <progressbar [max]="100" [value]="progress">
                    <span class="text-nowrap"></span>
                </progressbar>
                <small *ngIf="progress < 100" class="text-muted">Unpublished</small>
                <small *ngIf="progress === 100" class="text-muted">Published</small>
            </div>
            
        </div>
    </div>
</div>












<!-- <div class="" *ngIf="files.length != 0">
    <div class="card shadow mt-2" *ngFor="let file of files; let i = index">
        <div class="card-body card-media">
            <div class="row">
                <div class="col-2 text-center">
                    <i class="far fa-file fa-3x"></i>
                </div>
                <div class="col-9">
                    <p class="mb-0 text-truncate file-name">{{ file?.name }}</p>
                    <small class="text-muted">{{ formatBytes(file?.size) }} | {{ file?.type }}</small>
                </div>
                <div class="col-1">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="deleteFile(i)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="col-12 mt-2">
                    <progressbar [max]="100" [value]="file?.progress">
                        <span class="text-nowrap"></span>
                    </progressbar>
                </div>
            </div>
        </div>
    </div>
</div> -->


const SIZE_LIST = [
    { name: '1-10', nickname: '1-10'},
    { name: '11-50', nickname: '11-50'},
    { name: '51-200', nickname: '51-200'},
    { name: '201-500', nickname: '201-500'},
    { name: '501-1,000', nickname: '501-1,000'},
    { name: '1,001-5,000', nickname: '1,001-5,000'},
    { name: '5,001-10,000', nickname: '5,001-10,000'},
    { name: '10,001+', nickname: '10,001+'}
];

const DATA_STEP_1 = {
    organizationName: { 
        type: 'text', 
        validations: {
            required: true,
        }, 
        errors: {
            required: 'A organization name is required for setup. (Ex. Social Card)'
        }, 
        placeholder: 'Organization Name' 
    },
}

const DATA_STEP_2 = {
    organizationIndustry: { 
        type: 'text', 
        validations: {
            required: true,
        }, 
        errors: {
            required: 'An industry is required for organization setup. (Ex. Tech)'
        }, 
        placeholder: 'Industry' 
    },
    organizationSize: { 
        type: 'select',
        options: SIZE_LIST,
        validations: {
            required: true,
        }, 
        errors: {
            required: 'How many people work within your organization?'
        }, 
        placeholder: 'Organization Size' 
    },
}

const DATA_STEP_3 = {
    firstName: { 
        type: 'text', 
        validations: {
            required: true,
        }, 
        errors: {
            required: 'A first name is required for account owners.'
        }, 
        placeholder: 'First Name' 
    },
    lastName: { 
        type: 'text', 
        validations: {
            required: true,
        }, 
        errors: {
            required: 'A last name is required for account owners.'
        },
        placeholder: 'Last Name' 
    },
    email: { 
        type: 'email', 
        validations: {
            required: true,
            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
        }, 
        errors: {
            required: 'An email address is required for account owners.',
            pattern: 'Please enter a valid email.'
        }, 
        placeholder: 'Email' 
    },   
};

const STEP_ITEMS = [
    { label: 'Lets Get Started', description: "What's the name of your organization?", data: DATA_STEP_1 },
    { label: 'Your Organization', description: "Add some details about your organization.", data: DATA_STEP_2 },
    { label: 'Account Contact', description: "Give us some information about you, or the account owner (if not you).", data: DATA_STEP_3 },
    { label: 'Review & Continue', description: "Before we continue, lets review.", data: {} },
];

export { STEP_ITEMS }
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
// Custom Driectives
import { DirectivesModule } from '@app/shared/directives/directives.module';

import { NgxSpinnerModule } from "ngx-spinner";

// Modules
// NGX BOOTSRAP
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxVcardModule } from "ngx-vcard";
import { QRCodeModule } from 'angularx-qrcode';


// Components
import { SideNavComponent } from './navs/side-nav/side-nav.component';
import { TopNavComponent } from './navs/top-nav/top-nav.component';
import { SocialCardComponent } from './components/social-card/social-card.component';
import { SnackBarComponent } from './notifications/components/snack-bar/snack-bar.component';
import { SvgAssetsComponent } from '@app/core/components/svg-assets/svg-assets.component';
import { MediaUploadComponent } from './components/media-upload/media-upload.component';
import { OrgLogoUploadComponent } from './components/org-logo-upload/org-logo-upload.component';
import { ProfileNavComponent } from './navs/profile-nav/profile-nav.component';
import { DepartmentsMediaUploadComponent } from './components/departments-media-upload/departments-media-upload.component';
import { GlobalAlertComponent } from './components/global-alert/global-alert.component';
import { TemplateMediaUploadComponent } from './components/template-media-upload/template-media-upload.component';
import { TemplatePassMediaUploadComponent } from './components/template-pass-media-upload/template-pass-media-upload.component';
import { MediaCropperComponent } from './components/media-cropper/media-cropper.component';


@NgModule({
  declarations: [
    SideNavComponent, 
    TopNavComponent,
    SocialCardComponent,
    SnackBarComponent,
    SvgAssetsComponent,
    MediaUploadComponent,
    OrgLogoUploadComponent,
    ProfileNavComponent,
    DepartmentsMediaUploadComponent,
    GlobalAlertComponent,
    TemplateMediaUploadComponent,
    TemplatePassMediaUploadComponent,
    MediaCropperComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TooltipModule,
    ProgressbarModule,
    CollapseModule,
    DirectivesModule,
    NgxSpinnerModule,
    ImageCropperModule,
    NgxVcardModule,
    QRCodeModule,
  ],
  exports: [
    SideNavComponent,
    TopNavComponent,
    SocialCardComponent,
    SnackBarComponent,
    SvgAssetsComponent,
    MediaUploadComponent,
    OrgLogoUploadComponent,
    ProfileNavComponent,
    DepartmentsMediaUploadComponent,
    GlobalAlertComponent,
    TemplateMediaUploadComponent,
    TemplatePassMediaUploadComponent,
  ]
})
export class CoreModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
// Models
import { User } from '@app/core/models/user.model';
import { OrgOnboardingItem } from '@core/models/onboarding.model';
import { Tenant } from '@app/core/models/tenant.model';
// Services
import { UserService } from '@core/services/user/user.service';
import { OnboardingService } from '@core/services/onboarding/onboarding.service';
import { TenantService } from '@app/core/services/tenant/tenant.service';
// Animations
import { fadeInAnimation } from '@app/shared/animations/animations';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    animations: [
        fadeInAnimation,
    ]
})
export class DashboardComponent implements OnInit, OnDestroy {

    load_completed: boolean = false;

    userSub: Subscription;
    user: User;

    orgOnboardingSub: Subscription;
    tenant_onboarding: OrgOnboardingItem[];

    tenantSub: Subscription;
    tenant: Tenant;

    sampleDataAdmin = [
        {
            'name': 'Total Interactions',
            'period': '30',
            'start': 123456789,
            'end': 987654321,
            'values': this.getRandomValuesAdmin(),
            'previousSum': 10000
        },
        {
            'name': 'Total Active Social Cards',
            'period': '30',
            'start': 123456789,
            'end': 987654321,
            'values': this.getRandomValuesAdmin(),
            'previousSum': 1000000
        }
    ];



    sampleData = [
        {
            'name': 'Total Interactions',
            'period': '30',
            'start': 123456789,
            'end': 987654321,
            'values': this.getRandomValues(),
            'previousSum': 100
        },
        {
            'name': 'Total Mobile Interactions',
            'period': '30',
            'start': 123456789,
            'end': 987654321,
            'values': this.getRandomValues(),
            'previousSum': 100
        },
        {
            'name': 'Total Web Interactions',
            'period': '30',
            'start': 123456789,
            'end': 987654321,
            'values': this.getRandomValues(),
            'previousSum': 100
        },
        {
            'name': 'Total QR Interactions',
            'period': '30',
            'start': 123456789,
            'end': 987654321,
            'values': this.getRandomValues(),
            'previousSum': 100
        }
    ];






    constructor(
        private userService: UserService,
        private onboardingService: OnboardingService,
        private tenantService: TenantService,
        private spinner: NgxSpinnerService,
        private router: Router,
        
    ) {
        this.userSub = this.userService.currentUser.subscribe(user => {
            this.user = user;
            if (user.role === 'owner') {
                this.onboardingService.setOrgOnboardingObservable();
            }
        });
        this.orgOnboardingSub = this.onboardingService.orgOnboarding.subscribe(items => {
            this.tenant_onboarding = items;
        });
        this.tenantSub = this.tenantService.activeTenant.subscribe(tenant => {
            this.tenant = tenant;
        });
    }
    ngOnInit(): void {
        this.spinner.show('dashboardSpinner');

        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.load_completed = true;
            this.spinner.hide('dashboardSpinner');
        }, 2000);
    }

    ngOnDestroy(): void {
        this.userSub.unsubscribe();
        this.orgOnboardingSub.unsubscribe();
        this.tenantSub.unsubscribe();
    }

    ngAfterViewInit(): void {

    }



    redirectToLink() {
        console.log(new Date());
        // window.location.href = "https://www.linkedin.com/in/andrewsapashe/";

    }


    getSum(array: number[]): number {
        let sum = array.reduce((a, b) => a + b, 0);
        return sum
    }

    getRandomValuesAdmin(): number[] {
        return Array.from({ length: 30 }, () => Math.floor(Math.random() * 10000));
    }
    getRandomValues(): number[] {
        return Array.from({ length: 30 }, () => Math.floor(Math.random() * 10));
    }

    getPercentChange(array: number[], previousSum: number): number {
        const newSum = this.getSum(array);

        const change = (newSum / previousSum);

        return change
    }









}

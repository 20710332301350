import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Result } from '@app/core/models/api.model';
import { AllOrgDepartments, Department, DepartmentMember, DepartmentMembers, DepartmentUserProfiles, UserDepartments } from '@app/core/models/departments.model';
import { User } from '@app/core/models/user.model';
import { DepartmentsMediaService } from '@app/core/services/departments/departments-media/departments-media.service';
import { environment } from '@env/environment';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DepartmentsService {
    private departments: Observable<UserDepartments<DepartmentMember>>;

    private userDepartmentsSubject = new BehaviorSubject<UserDepartments<DepartmentMember>>(null);
    public userDepartments = this.userDepartmentsSubject.asObservable().pipe(distinctUntilChanged());

    departmentsSub: Subscription;


    // All departments in the org
    private allOrgDepartmentsRef: Observable<AllOrgDepartments<Department>>;

    private allOrgDepartmentsSubject = new BehaviorSubject<AllOrgDepartments<Department>>(null);
    public allOrgDepartments = this.allOrgDepartmentsSubject.asObservable().pipe(distinctUntilChanged());


    // An object with key = department id & value an object of user profiles 
    // Will contain profile info for any user seen across all departments to avoid refetching user profiles mutliple times
    private allOrgDepartmentMembersSubject = new BehaviorSubject<DepartmentUserProfiles<User>>({});
    public allOrgDepartmentsMembers = this.allOrgDepartmentMembersSubject.asObservable().pipe(distinctUntilChanged());

    allOrgDepartmentsSub: Subscription;

    private baseUrl: string = environment.api.url;

    // For View Toggling
    isTableViewVisible: boolean;
    tableViewVisibilityChange: Subject<boolean> = new Subject<boolean>();

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private departmentsMediaService: DepartmentsMediaService,
        private storage: AngularFireStorage,
    ) { 
        this.tableViewVisibilityChange.subscribe((value) => {
            this.isTableViewVisible = value
        });
    }

    toggleDepartmentView() {
        this.tableViewVisibilityChange.next(!this.isTableViewVisible);
    }



    setAllOrgDepartments() {
        const tenant_id = sessionStorage.getItem('t');

        this.allOrgDepartmentsRef = this.db.object<AllOrgDepartments<Department>>(`tenant_departments/${tenant_id}`).valueChanges();
        this.fetchAllOrgDepartments();
    }

    private fetchAllOrgDepartments() {

        this.allOrgDepartmentsSub = this.allOrgDepartmentsRef.subscribe(res => {
            this.allOrgDepartmentsSubject.next(res);
            console.log('Org Departments: ', res);

            // Retreives the user profiles for each department
            this.fetchDepartmentUserProfiles();
        });
    }


    setUserDepartments() {
        const tenant_id = sessionStorage.getItem('t');
        const uid = sessionStorage.getItem('u');

        this.departments = this.db.object<UserDepartments<DepartmentMember>>(`user_departments/${uid}/${tenant_id}`).valueChanges();
        this.fetchUserDepartments();
    }

    private fetchUserDepartments() {
        this.departmentsSub = this.departments.subscribe(res => {
            this.userDepartmentsSubject.next(res);
            console.log('User Departments: ', res);
        });
    }






    async createDepartment(department: Department): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {

            const functionUrl = environment.api.departments.createDepartment;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                department: department,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Created department', result);

                let dep_id = result.data['department_id'];
                if (result.success) {
                }
                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }


    updateBasicDepartmentSettings(depId: string, items: any): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const functionUrl = environment.api.departments.updateDepartment;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                dep_id: depId,
                items: items,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Updated department', result);

                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }




    addMemberToDepartment(depId: string, newMembers: DepartmentMembers<DepartmentMember>): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const functionUrl = environment.api.departments.addMember;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                dep_id: depId,
                new_members: newMembers,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Added member(s) to department', result);

                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }



    removeUserFromDepartment(dep_id: string, uid: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const functionUrl = environment.api.departments.removeUserFromDepartment;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                dep_id: dep_id,
                uid: uid,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Removed user from department', result);

                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }



    deleteDepartment(dep_id: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const functionUrl = environment.api.departments.deleteDepartment;

            const members = this.allOrgDepartmentsSubject.value[dep_id].members;
            const tenant_id = sessionStorage.getItem('t');

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: tenant_id,
                dep_id: dep_id,
                members: members,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('deleted department', result);

                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }



















    // 1.) Filters out all departments with no active members
    // 2.) Loops through filtered departments to start retreiving user profile info
    // 3.) Loops through each member in the current department
    // 4.) Checks to see if UID (user) information has already been fetched
    // 5.) If not, fetches the users profile from the DB
    // 6.) Adds they fetched user profile to the members object
    // 7.) Pushs the new members object up to the subscribable observer
    private async fetchDepartmentUserProfiles() {
        const tenant_id = sessionStorage.getItem('t');

        // 1.)
        const departmentsWithMembers = Object.keys(this.allOrgDepartmentsSubject.value)
            .filter(key => this.allOrgDepartmentsSubject.value[key].members) // Filters out all departments with no active members
            .reduce((obj, key) => {
                obj[key] = this.allOrgDepartmentsSubject.value[key];
                return obj;
            }, {}) as AllOrgDepartments<Department>;



        // 2.)
        const currentMembersObject = this.allOrgDepartmentMembersSubject.value;
        for (const dep of Object.values(departmentsWithMembers)) {
            // console.log('😡😡: ', dep);

            // 3.)
            for (const member of Object.keys(dep.members)) {
                // console.log('😡😡😡: ', member);

                // 4.)
                if (this.allOrgDepartmentMembersSubject.value[member] === undefined) {
                    // 5.)
                    await this.db.object<User>(`users/${member}/${tenant_id}`).valueChanges().pipe(take(1)).toPromise().then(res => {
                        // console.log('😡😡😡😡: ', res);

                        // 6.)
                        
                        currentMembersObject[member] = res;

                    }).catch(err => { console.error(err) });
                } else {
                    // console.log('😡😡😡😡😡 Failed: ', this.allOrgDepartmentMembersSubject.value[member.uid]);
                }
            }
        }
        // 7.)
        this.allOrgDepartmentMembersSubject.next(currentMembersObject);
        console.log(currentMembersObject);

    }
}

<div *ngIf="notifications.length != 0" class="">

        <div *ngFor="let notification of notifications" [ngClass]="className(notification)">
            <ng-container *ngTemplateOutlet="bottomNotificationTemplate;context:{notification:notification}"></ng-container>
        </div>

</div>
    


<ng-template #bottomNotificationTemplate let-notification="notification">
    
    <div class="snackbar-container show">
        <div id="snackbar" class="show">{{notification.message}}</div>
    </div>

</ng-template>

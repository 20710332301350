import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Tenant } from '@app/core/models/tenant.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserTenantsService {

  // Handles data on users assigned tenants
  user_tenants: Observable<{}>;
  private userTenantsSubject = new BehaviorSubject<string[]>([]);
  public currentUserTenants = this.userTenantsSubject.asObservable().pipe(distinctUntilChanged());

  // Handles details on users assigned tenants
  private userTenantsDetailsSubject = new BehaviorSubject<Tenant[]>([]);
  public currentuserTenantsDetails = this.userTenantsDetailsSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private db: AngularFireDatabase,
  ) { }

  // RETREIVES THE USERS ASSIGNED TENANT IDS
  setUserTenantsObservable(uid: string) {
    this.user_tenants = this.db.object<{}>(`user_tenants/${uid}`).valueChanges();
    this.updateUserTenantIds();
  }

  updateUserTenantIds() {
    this.user_tenants.subscribe(res => {
      console.log(res);
      if (res != null) {
        const tenant_ids: string[] = [];
        for (const [key, v] of Object.entries(res)) {
          tenant_ids.push(key);
        }
        this.userTenantsSubject.next(tenant_ids);
        console.log('USERS TENANT IDS: ', res);
      
        this.getAssignedTenantDetails();
      }
      
    });
  }











  // RETREIVES THE BASIC INFO ON EACH OF A USERS TENANTS
  async getAssignedTenantDetails() {
    var tenantDetails: Tenant[] = [];

    this.userTenantsSubject.value.forEach(async id => {
      await this.getUserTenantDetails(id).then(data => {
        if (data != undefined || data != null) {
          tenantDetails.push(data);
        }
      }).catch(err => {
        console.error(err);
      })
    });
    this.userTenantsDetailsSubject.next(tenantDetails);
  }


  async getUserTenantDetails(id: string): Promise<Tenant> {
    return new Promise<Tenant>((resolve, reject) => {
      var tenantSub: Subscription;

      // Get the tenant item from DB
      var tenantSub = this.db.object<Tenant>(`tenants/${id}`).valueChanges().pipe(take(1)).subscribe(res => {
        console.log(res);
        resolve(res);
        
      });
    });
  }


}

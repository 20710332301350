import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MediaUploadService {

  private coverPhotoSubject = new BehaviorSubject<any>([]);
  public coverPhoto = this.coverPhotoSubject.asObservable().pipe(distinctUntilChanged());

  private coverPhotoDataSubject = new BehaviorSubject<any>(null);
  public coverPhotoData = this.coverPhotoDataSubject.asObservable().pipe(distinctUntilChanged());


  private logoSubject = new BehaviorSubject<Blob[]>([]);
  public logo = this.logoSubject.asObservable().pipe(distinctUntilChanged());

  private logoDataSubject = new BehaviorSubject<any>(null);
  public logoData = this.logoDataSubject.asObservable().pipe(distinctUntilChanged());
  
  constructor(
  
  ) {}

  
  updateCoverPhotoObject(updatedObject: any) {
    this.coverPhotoSubject.next(updatedObject);

    console.log('Cover Photo 📂', this.coverPhotoSubject.value[0]);
  }

  // Sets the data string to show back to the user
  setCoverPhotoData(data: any) {
    this.coverPhotoDataSubject.next(data);
  }

  getCurrentCoverPhoto() {
    return this.coverPhotoDataSubject.value;
  }
  // Uploads the current document to cloud storage and returns the necessary references for DB
  uploadCoverPhotoToStorage() {

  }




}
<div class="card social-card mx-auto shadow" [style.background-color]="socialCard.colors.background_color">
    
    <!-- Logo & Logo Text -->
    <div class="d-flex logo-m">
       
        <img class="logo" [src]="socialCard.logo.logo" alt="logo">
       
        <div class="d-flex name-container">
            <div class="name my-auto no-wrap" [style.color]="socialCard.colors.foreground_color">
                {{ socialCard.logo.logo_text }}
            </div>
            
        </div>
    </div>

    <!-- Primary Field & Thumbnail -->
    <div class="d-flex primary-m">
        <div class="primary-field-container">
            <div class="primary-field" [style.color]="socialCard.colors.foreground_color">
                {{ socialCard.front_fields.primary_field.value }}
            </div>
            
        </div>

        <img class="thumbnail" [src]="socialCard.thumbnails.thumbnail" alt="thumbnail">
        
    </div>

    <!-- Secondary Field Label & Data -->
    <div class="secondary-m">
        <div class="">
            <div class="label text-truncate" [style.color]="socialCard.colors.label_color">
                {{ socialCard.front_fields.secondary_field.label }}
            </div>
            <div class="secondary-value text-truncate" [style.color]="socialCard.colors.foreground_color">
                {{ socialCard.front_fields.secondary_field.value }}
            </div>
        </div>    
    </div>

    <!-- Auxiliary Field Label & Data -->
    <div class="">
        <div class="">
            <div class="label text-truncate" [style.color]="socialCard.colors.label_color">
                {{ socialCard.front_fields.auxiliary_field.label }}
            </div>
            <div class="auxiliary-value text-truncate" [style.color]="socialCard.colors.foreground_color">
                {{ socialCard.front_fields.auxiliary_field.value }}
            </div>
        </div>    
    </div>

    <!-- QR CODE -->
    <div class="d-flex qr-container">
        <div class="m-auto qr">
            <div class="text-center">
                <qrcode [qrdata]="socialCard.qr.data" [width]="120" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
    </div>
</div>
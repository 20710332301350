import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, first, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '@core/services/auth/auth.service';

import { AngularFireStorage } from '@angular/fire/storage';

// MODELS
import { User, UserSettings } from '@app/core/models/user.model';
import { Result } from '@app/core/models/api.model';

// ENV
import { environment } from '@env/environment';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    // Handles data on users assigned tenants
    private user: Observable<User>;

    private userSubject = new BehaviorSubject<User>({} as User);
    public currentUser = this.userSubject.asObservable().pipe(distinctUntilChanged());

    private baseUrl: string = environment.api.url;

    private userSub: Subscription;

    constructor(
        private db: AngularFireDatabase,
        private authService: AuthService,
        private router: Router,
        private http: HttpClient,
    ) {
        // this.keepUserUpdated();
    }

    setUserForTenant(tenantId: string, uid: string) {
        this.user = this.db.object<User>(`users/${uid}/${tenantId}`).valueChanges();
        this.fetchUserForTenant();
    }

    fetchUserForTenant() {
        this.userSub = this.user.subscribe(res => {
            this.userSubject.next(res);
            console.log('USER: ', res);
        });
    }


    getCurrentUserId(): string {
        if (sessionStorage.getItem('u') != null || sessionStorage.getItem('u') != undefined) {
            return sessionStorage.getItem('u');
        } else {
            this.router.navigate(['/auth/orgs'])
        }
    }

    getCurrentUser(): User {
        return this.userSubject.value;
    }

    // Used by permissions guard to get the users role asyncronously
    async checkCurrentUserRole() {
        const tenant_id = sessionStorage.getItem('t');
        const uid = sessionStorage.getItem('u');
        return this.db.object<User>(`users/${uid}/${tenant_id}`).valueChanges().pipe(
            tap(data => {
                // console.log(data);
            }),
            first()
        ).toPromise();
    }

    // Updates a users base profile info (name, email, phone)
    async updateUser(updates: User): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const tenantId = sessionStorage.getItem('t');
            const uid = sessionStorage.getItem('u');

            // TODO: Auth email change
            const user = this.db.object<User>(`users/${uid}/${tenantId}`).update(updates).then(_ => {
                resolve(true)
            }).catch(err => {
                console.log(err, 'You dont have access!');
                reject(false);
            });
        });
    }



    // Adds the super useer to the newly generated tenant
    async addUserToTenant(email: string, plan: string, state: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            // Url of add-user function 
            const functionUrl = environment.api.users.newUser;
            var uid: string;
            if (state === 'existing') {
                uid = sessionStorage.getItem('u');
                console.log('UID EXISITING USER: ', uid);

            } else {
                uid = this.authService.getAnonymousUid();
            }


            console.log(uid);

            var availableCards: any = 0;
            if (plan == 'startup') {
                availableCards = 1
            } else {
                availableCards = 'unlimited';
            }

            const user: User = {
                email: email,
                uid: uid,
                name: '',
                phone: '',
                photo: '',
                role: 'owner',
                onboarded: false,
                settings: {
                    marketing: false,
                    reporting: true,
                }
            }



            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                uid: uid,
                tenant_id: sessionStorage.getItem('t'),
                user: user,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Add user result', result);

                resolve(result);
            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }




    async addInvitedUserToTenant(email: string, uid: string, role: string, invite_id: string, tenant_id: string) {

        return new Promise<Result>(async (resolve, reject) => {
            // Url of add-user function 
            const functionUrl = environment.api.users.newUser;

            console.log(uid);

            // 1.) sets user object for creation
            const user: User = {
                email: email,
                uid: uid,
                name: '',
                phone: '',
                photo: '',
                role: role,
                onboarded: false,
                settings: {
                    marketing: false,
                    reporting: true,
                }
            }

            // 2.) Adds the user to the tenant
            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                uid: uid,
                tenant_id: tenant_id,
                user: user,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Add user result', result);

                resolve(result);
            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }



    // Admin update of a users role
    async changeUsersRole(uid: string, role: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            // Url of add-user function 
            const functionUrl = environment.api.users.changeUserRole;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                uid: uid,
                role: role,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Updated users Role', result);

                resolve(result);
            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }


    // Removes the users access from the tenants account
    async removeUserAccess(uid: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            // Url of add-user function 
            const functionUrl = environment.api.users.removeUser;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                uid: uid,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('User Remove Successfully', result);

                resolve(result);
            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }




    // Updates a users base profile info (name, email, phone)
    async updateUserSettings(settings: UserSettings): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const tenantId = sessionStorage.getItem('t');
            const uid = sessionStorage.getItem('u');

            const functionUrl = environment.api.users.updateUserSettings;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: tenantId,
                uid: uid,
                settings: settings,
            }).toPromise().then(res => {
                let result = res as Result
                resolve(result);
            }).catch(err => {
                console.error(err);
                reject();
            });
        });
    }



















    removeUserReferences() {
        this.userSub.unsubscribe();
    }
}

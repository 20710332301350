<app-templating-left-nav [@fade-in] *ngIf="load_completed"></app-templating-left-nav>


<!-- Template General -->
<app-template-general [@fade-in] *ngIf="templateState === templateStates.General && load_completed"></app-template-general>


<!-- Template Web -->
<app-web-appearance [@fade-in] *ngIf="templateState === templateStates.WebAppearance && load_completed"></app-web-appearance>
<app-web-media [@fade-in] *ngIf="templateState === templateStates.WebMedia && load_completed"></app-web-media>
<app-web-quick-links [@fade-in] *ngIf="templateState === templateStates.WebQuickLinks && load_completed"></app-web-quick-links>
<app-web-cta [@fade-in] *ngIf="templateState === templateStates.WebCta && load_completed"></app-web-cta>
<app-web-accessory-items [@fade-in] *ngIf="templateState === templateStates.WebAccessory && load_completed"></app-web-accessory-items>


<!-- Template Apple Wallet -->
<app-apple-wallet-general [@fade-in] *ngIf="templateState === templateStates.AppleWalletGeneral && load_completed"></app-apple-wallet-general>
<app-apple-wallet-front [@fade-in] *ngIf="templateState === templateStates.AppleWalletFront && load_completed"></app-apple-wallet-front>
<app-apple-wallet-qr [@fade-in] *ngIf="templateState === templateStates.AppleWalletQr && load_completed"></app-apple-wallet-qr>
<app-apple-wallet-back [@fade-in] *ngIf="templateState === templateStates.AppleWalletBack && load_completed"></app-apple-wallet-back>

<!-- QR Downloadable -->
<app-qr [@fade-in] *ngIf="templateState === templateStates.Qr && load_completed"></app-qr>


<!-- LOADING -->
<div class="container-fluid" [@fade-in] *ngIf="!load_completed">
    <div class="position-relative">
        <ngx-spinner 
        name="templateSpinner"
        [fullScreen]="true"
        bdColor="rgba(255, 255, 255, 1)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
    </div>
</div>
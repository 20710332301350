<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isGeneralCollapsed)?'section-text-light':''">
            General
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingGeneral()" [ngClass]="(!isGeneralCollapsed)?'active':''"
        [attr.aria-expanded]="!isGeneralCollapsed" aria-controls="isGeneralCollapsed">
            <svg *ngIf="isGeneralCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isGeneralCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isGeneralCollapsed" [collapse]="isGeneralCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow QR Editing
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.qr.qr_analytics_enabled"  [(ngModel)]="currentTemplateSettings.qr.qr_analytics_enabled" (ngModelChange)="allowQrEditing($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.qr.qr_analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.qr.qr_analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change name field text.</small>
                </div>
                <div class="current-item-border"></div>
            </div>
    
            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    QR Analytics Enabled
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.qr.qr_analytics_enabled"  [(ngModel)]="currentTemplateSettings.qr.qr_analytics_enabled" (ngModelChange)="analtyicsEnabled($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.qr.qr_analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.qr.qr_analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, analytics will be enabled for this QR code.</small>
                </div>
            </div>
        </div>
    </div>










    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isStylingCollapsed)?'section-text-light':''">
            Styling
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingStyling()" [ngClass]="(!isStylingCollapsed)?'active':''"
        [attr.aria-expanded]="!isStylingCollapsed" aria-controls="isStylingCollapsed">
            <svg *ngIf="isStylingCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isStylingCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isStylingCollapsed" [collapse]="isStylingCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Background Color
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.qr.background_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="backgroundColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.qr.background_color"/> 
        
                        <div class="color-text">
                            {{ currentTemplateSettings.qr.background_color }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>








    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isMediaCollapsed)?'section-text-light':''">
            Media
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingMedia()" [ngClass]="(!isMediaCollapsed)?'active':''"
        [attr.aria-expanded]="!isMediaCollapsed" aria-controls="isMediaCollapsed">
            <svg *ngIf="isMediaCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isMediaCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isMediaCollapsed" [collapse]="isMediaCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Background Color
                </div>
            </div>
        </div>
    </div>

</div>




















<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">
            <div class="card shadow" [@fade-in]>
                <div class="card-body">
                    <div class="text-center">
                        <canvas class="mt-4 text-center" height="400" width="300" #canvas id="canvas"></canvas>
                        <qrcode hidden='true' [qrdata]="'https://www.joinsocialcard.com'" [margin]="2" [width]="100" [errorCorrectionLevel]="'M'" #qr id="qr"></qrcode>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-sm btn-primary" (click)="createQrElement()">Generate QR Page</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
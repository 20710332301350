import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Invitee, InviteeGroup } from '@app/core/models/team.model';

import { fadeInAnimation } from '@app/shared/animations/animations';

import { TeamService } from '@core/services/team/team.service';


import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StripeService } from '@app/core/services/stripe/stripe.service';

@Component({
  selector: 'app-admin-pending-invites',
  templateUrl: './admin-pending-invites.component.html',
  styleUrls: ['./admin-pending-invites.component.css'],
  animations: [fadeInAnimation],
})
export class AdminPendingInvitesComponent implements OnInit, OnDestroy {

  pendingInvites: InviteeGroup<Invitee> = {};
  countOfTotalInvites: number;

  inviteId: string = '';

  invitationsSub: Subscription;

  infoMessage: string = '';


  isCollapsed: boolean = true;

  noExisitingInvites: boolean;

  constructor(
    private teamService: TeamService,
    private stripeService: StripeService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) { 
    this.invitationsSub = this.teamService.allTeamInvites.subscribe(res => {
      this.pendingInvites = res;

      console.log('INVITES',res);
      if (res === null) {
        this.spinner.hide('invitationsSpinner');
        this.noExisitingInvites = true;
      } else {
        this.spinner.hide('invitationsSpinner');
        this.noExisitingInvites = false;
        this.countOfTotalInvites = Object.keys(this.pendingInvites).length;
      }
    });
  }

  ngOnInit(): void {
    // this.spinner.show('invitationsSpinner');
    this.teamService.getAllPendingInvites();
  }
  
  ngOnDestroy(): void {
    this.invitationsSub.unsubscribe();
  }



  // Resend an invite to a user
  // 1.) Show loading indicator
  // 2.) Ask service to request the API resend an invitation to the user
  // 3.) Handle result 
  // 4.) Handle errors
  async resendInvite() {
    // 1
    this.spinner.show('inviteItemSpinner');
    // 2 
    const email = this.pendingInvites[this.inviteId].email;
    const tenant_id = sessionStorage.getItem('t');
    const invitation_id = this.inviteId;
    this.teamService.resendEmailInvite(email, tenant_id, invitation_id).then(res => {

      // 3
      if (res.success) {
        this.spinner.hide('inviteItemSpinner');
        this.snackBarService.success('Invitation successfully resent');
        this.infoMessage = 'Invitation successfully resent.';
      }

    }).catch(err => {
      // 4
      this.spinner.hide('inviteItemSpinner');
      this.snackBarService.error('An internal error occured');
      this.infoMessage = 'An internal error occured, please try again.';
    })
  }




  // Revokes an unaccepted/pending user invite. 
  // 1.) Hide confirmation modal and show spinners
  // 2.) Send request to db to set the status for this pending invite to revoked.
  // 3.) If successfully updates, move on to billing update, else handle error
  // 4.) Request stripe api to remove a quantity of 1 from the sub to reflect the revokal
  // 5.) Hanlde any errors
  async revokeInvite() {
    // 1
    this.spinner.show('inviteItemSpinner');
    this.confirmationModalRef.hide();
    
    // 2
    const tenant_id = sessionStorage.getItem('t');
    await this.teamService.updateInviteStatus(this.inviteId, tenant_id, 'revoked').then(res => {
      console.log(res);
      // 3
      if (res.success) {
        this.snackBarService.success('Updating billing');
        // 4
        this.stripeService.updateSubForInvitationRevoke(tenant_id).then(res => {
          if (res.success) {
            console.log('Stripe updated successfully');
            this.spinner.hide('inviteItemSpinner');
            this.snackBarService.success('Invitation successfully revoked');
            this.infoMessage = 'Invitation successfully revoked.';
          }
        }).catch(err => {
          // 5
          this.spinner.hide('inviteItemSpinner');
          this.snackBarService.error('An internal error occured');
          this.infoMessage = 'An internal error occured, please try again.';
        })

      } else {
        // 5
        this.spinner.hide('inviteItemSpinner');
        this.snackBarService.error('An internal error occured');
        this.infoMessage = 'An internal error occured, please try again.';
      }
    }).catch(err => {
      // 5
      this.spinner.hide('inviteItemSpinner');
      this.snackBarService.error('An internal error occured');
      this.infoMessage = 'An internal error occured, please try again.';
    });
  }


  filterByEmail() {

  }
  filterByRole() {

  }
  filterByStatus() {

  }


  // Handles modals
  modalRef: BsModalRef;
  confirmationModalRef: BsModalRef;

  
  // Allows opening modal from inside component
  // @ViewChild('existingAccountLinkModal') modalView: TemplateRef<any>;
  openModal(template: TemplateRef<any>, index: string) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, config);
    
    this.inviteId = index;
  }

  openConfrimationModal(template: TemplateRef<any>) {
    this.spinner.show('inviteItemSpinner');
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.confirmationModalRef = this.modalService.show(template, config);
    
  }
  cancel() {
    this.spinner.hide('inviteItemSpinner');
    this.confirmationModalRef.hide();
  }
}

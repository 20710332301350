<nav class="topnav navbar navbar-expand fixed-top navbar-light bg mb-4">

    <div class="">
        <button routerLink="/admin" class="nav-btn order-1 order-lg-0" id="sidebarToggle">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
        </button>
        <div class="text-center">
            <small>Close</small>
        </div>
    </div>
    
</nav>


<div class="container-fluid">
    <h3 class="h3">Subscription Management</h3>

    <div class="row">

        <div class="col col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="subscriptionSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body" *ngIf="subscription" [@fade-in]>
                    <div class="d-flex justify-content-between">
                        <div class="card-label mt-auto">Subscription</div>
                    </div>
    
                    <div class="current-item-border mb-4 mt-2"></div>
    
                    <div class="mb-4">
                        <div class="card-label">Subscription Plan</div>
                        <div class="">{{ plan }}</div>
                    </div>
                    <div class="mb-4">
                        <div class="card-label">Subscription Term</div>
                        <div class="">{{ term }}</div>
                    </div>
                    <div class="">
                        <div class="card-label">Subscription Status</div>
                        <span class="badge badge-success badge-pill" *ngIf="subscription.status === 'active' || subscription.status === 'trialing'">{{ subscription.status | titlecase }}</span>
                        <span class="badge badge-warning badge-pill" *ngIf="subscription.cancel_at_period_end">Canceling on {{ subscription.current_period_end * 1000 | date:'mediumDate' }}</span>
                        <span class="badge badge-warning badge-pill" *ngIf="subscription.status === 'incomplete' || subscription.status === 'incomplete_expired'">{{ subscription.status | titlecase }}</span>
                        <span class="badge badge-warning badge-pill" *ngIf="subscription.status === 'past_due' || subscription.status === 'unpaid' || subscription.status === 'canceled'">{{ subscription.status | titlecase }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>











    <div class="row card-row section-margin" *ngIf="status != 'canceled'">

        <div class="col col-lg-6 col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="planChangeSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body d-flex flex-column" *ngIf="subscription" [@fade-in]>
                    <div class="d-flex justify-content-between">
                        <div class="card-label mt-auto">Change Plan</div>
                    </div>
                    <div class="current-item-border mb-4 mt-2"></div>

                    <p>Upgrade or downgrade your existing plan. Learn more about plans & pricing <a href="https://www.joinsocialcard.com/" target="_blank" class="mt-4">Here</a>.</p>
                
                    <form [formGroup]="planChangeForm" *ngIf="!enterprise">
                        <div class="form-group">
                            <label for="size">Plan</label>
                            <select formControlName="plan" class="custom-select" (change)="setNewlySelectedPlan()">
                                <option value="" disabled>No Selection</option>
                                <option disabled>--Billed Annually--</option>
                                <option [value]="planGrowthAnnual">Growth Plan (Annual)</option>
                                <option [value]="planStartupAnnual">Startup Plan (Annual)</option>
                                <option disabled>--Billed Monthly--</option>
                                <option [value]="planGrowthMonthly">Growth Plan (Monthly)</option>
                                <option [value]="planStartupMonthly">Startup Plan (Monthly)</option>
                            </select>                    
                            <div *ngIf="newPlan.invalid && (newPlan.dirty || newPlan.touched)" class="form-text text-muted">
                                <small *ngIf="newPlan.errors.required" class="validation-error">An plan is required.</small>
                            </div>
                        </div>
                    </form>
                    
                    <div class="row text-center mb-4">
                        <div class="col-6" *ngIf="!enterprise">
                            <h3 class="mb-0 text-center">{{ basePrice | currency }}</h3>
                            <small>/{{ baseTerm }}</small>
                        </div>
                        <div class="col-6" *ngIf="!enterprise">
                            <h3 class="mb-0 text-center">{{ userPrice | currency }}</h3>
                            <small>user/{{ userTerm }}</small>
                        </div>
                        <div class="col-12" *ngIf="enterprise">
                            <h3 class="mb-0 text-center">Enteprise Pricing</h3>
                            <small>Reach out to our support team for information regarding your enterprise plan.</small>
                        </div>
                        <div class="col-12 text-center">
                            <span *ngIf="enterprise" class="badge badge-pill badge-primary">Enteprise</span>

                            <span *ngIf="currentPlan || priceId === newPlan.value" class="badge badge-pill badge-secondary">Current Plan</span>
                            <span *ngIf="annualSavings" class="badge badge-pill badge-primary">Annual Savings</span>
                            <span *ngIf="upgrade" class="badge badge-pill badge-success">New Features</span>
                            <span *ngIf="downgrade" class="badge badge-pill badge-warning">Feature Downgrade</span>
                        </div>
                    </div>
                    
            
                    <div class="mt-auto">
                        <div class="current-item-border mb-4 mt-2"></div>
                        <button [disabled]="planChangeForm.invalid || planChangeForm.untouched || planChangeForm.pristine || priceId === newPlan.value" (click)="openModal(planChangeConfirmationModal)" class="btn btn-primary w-100">Change Plan</button>
                    </div>
                </div>
            </div>
        </div>
    
    
        <div class="col col-lg-6 col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="cancelationSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body d-flex flex-column" *ngIf="subscription" [@fade-in]>
                    <div class="d-flex justify-content-between">
                        <div class="card-label mt-auto">Cancel Subscription</div>
                    </div>
    
                    <div class="current-item-border mb-4 mt-2"></div>
                    <div class="mb-4" *ngIf="!cancelAtPeriodEnd">
                        <p>Subscription cancellation will take effect at the end of the current billing period. At that time, all members associated with your account will lose access to the platform, and the subsequent features of your plan.</p>
                        <small class="mb-4">NOTE: Your subscription can be resumed at any time prior to the end of the billing period. Please contact support to permanently remove your organization from the Social Card platform.</small>
                    </div>
                    <div class="mt-auto"  *ngIf="!cancelAtPeriodEnd">
                        <div class="current-item-border mb-4 mt-2"></div>
                        <button class="btn btn-error w-100" (click)="openModal(cancelConfirmationModal)">Cancel Subscription</button>
                    </div>

                    <div class="mb-4" *ngIf="cancelAtPeriodEnd">
                        <h5 class="font-weight-bolder mb-4 error">Subscription Canceled</h5>
                        <p class="">Subscription active until: <strong>{{ subscription.current_period_end * 1000 | date:'mediumDate' }}</strong></p>
                    </div>
                    <div class="mt-auto" *ngIf="cancelAtPeriodEnd">
                        <div class="current-item-border mb-4 mt-2"></div>
                        <button class="btn btn-primary w-100" (click)="resumeSubscription()">Resume Subscription</button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row card-row section-margin" *ngIf="status === 'canceled'">

        <div class="col col-12">
            <div class="card shadow">
                <ngx-spinner 
                name="canceledSubSpinner"
                [fullScreen]="false"
                bdColor="rgba(255, 255, 255, 0.9)"
                size="default"
                color="#5D656C"
                type = "ball-clip-rotate-multiple"
                >
                </ngx-spinner>
                <div class="card-body d-flex flex-column" *ngIf="subscription" [@fade-in]>
                    
                </div>
            </div>
        </div>
    </div>
</div>





<ngx-spinner 
name="subscriptionPageSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>
</ngx-spinner>


<ng-template #cancelConfirmationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">⚠️ Warning!</h2>
                </div>
                <p class="mt-4">Are you sure you want to cancel your subscription? This action will revoke access to the Social Card platform for you and your entire team at the end of the current billing period.</p>
                <div class="current-item-border mb-4 mt-2"></div>
                
                <div class="">
                    <button class="btn btn-sm btn-error w-100 mb-3" (click)="cancelSubscription()">Yes, Cancel Subscription</button>    
                    <button class="btn btn-sm btn-primary w-100" (click)="this.modalRef.hide()">No, Close</button>
                </div>

            </div>
        </div>
    </div>
</ng-template>


<ng-template #planChangeConfirmationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">⚠️ Heads Up!</h2>
                </div>
                <p class="mt-4">Are you sure you want to make these changes to your subscription? This action will immediately take effect on your Social Card invoice.</p>
                <div class="current-item-border mb-4 mt-2"></div>
                
                <div class="">
                    <button class="btn btn-sm btn-primary w-100 mb-3" (click)="changeSubscriptionPlan()">Yes, Modify Subscription</button>  
                    <div class="text-center">
                        <a (click)="this.modalRef.hide()" [routerLink]=""><small>No, Close</small></a>
                    </div>  
                </div>

            </div>
        </div>
    </div>
</ng-template>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';

@Component({
  selector: 'app-templating-left-nav',
  templateUrl: './templating-left-nav.component.html',
  styleUrls: ['./templating-left-nav.component.css']
})
export class TemplatingLeftNavComponent implements OnInit {

  parentPath: string;
  currentPath: string;

  templateType: string = 'new';

  isWebCollapsed: boolean = true;
  isAppleWalletCollapsed: boolean = true;
  isEmailSignatureCollapsed: boolean = true;


  isGeneral: boolean;

  isWebBasic: boolean;
  isWebMedia: boolean;
  isWebAppearance: boolean;
  isWebQuickLink: boolean;
  isWebCta: boolean;
  isWebAcessory: boolean;

  

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // Sets the parent and current path to match URl, Sets the nav up to correct state
    const tree: UrlTree = router.parseUrl(this.router.url)
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    if (s.length === 4) {
      this.parentPath = s[2].path;
      this.currentPath = s[3].path;
    } else {
      this.parentPath = s[1].path;
      this.currentPath = s[2].path;
    }
    
    console.log(this.parentPath, this.currentPath);
  }

  ngOnInit(): void {
    this.setNavState();

    this.route.queryParams.subscribe(params => {
      this.templateType = params['t'];
    });
  }


  setNavState() {
    if (this.currentPath === 'general') {
      this.isGeneral = true;

      if (this.templateType === 'new') {
        // TODO: show Modal
        console.log('Showing new template modal');
      }

      return;
    }


    switch(this.parentPath) {
      case 'web': {
        this.isWebCollapsed = false;
        this.setWebState();
        break;
      }
      case 'apple-wallet': {
        this.isAppleWalletCollapsed = false;
        break;
      }
      case 'email-signature': {
        this.isEmailSignatureCollapsed = false;
        break;
      }
      default: {
        this.isGeneral = true;
        break;
      }
    }
  }


  setWebState() {
    switch(this.currentPath) {
      case 'basic': {
        this.isWebBasic = true;
      }
      case 'media': {
        this.isWebBasic = true;
      }
      case 'appearance': {
        this.isWebBasic = true;
      }
      default: {

      }
    }
  }
}

<div class="tab-container" [@fade-in]>
    <h3 class="h3">Invitations</h3>
    <p>View all your organizations pending, accepted, & revoked team member invitations. Take action by clicking an invitation.</p>

    <div class="d-lg-flex d-block justify-content-between">
        <p class="mb-0 tab-p mt-auto">Showing {{ countOfTotalInvites }} sent invite(s)</p>
        <button class="btn btn-gradient btn-sm" type="button" routerLink="/admin/invite">Invite New Members</button>
    </div>

    <div class="current-item-border mb-4 mt-2"></div>



    <div class="card shadow mt-4">
        <div class="card-body table-responsive">
            <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" class="card-label">Email
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                        </svg> -->
                    </th>
                    <th scope="col" class="card-label">Role
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                        </svg> -->
                    </th>
                    <th scope="col" class="card-label">Status
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                        </svg> -->
                    </th>
                    <th scope="col" class="card-label text-right"></th>

                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invite of pendingInvites | keyvalue; index as i;" (click)="openModal(inviteeModal, invite.key)">
                        <td scope="row">{{ invite.value.email }}</td>
                        <td class="">{{ invite.value.role | titlecase }}</td>
                        <td class="">
                            <span *ngIf="invite.value.status === 'pending'" class="badge badge-primary badge-pill">Pending</span>
                            <span *ngIf="invite.value.status === 'accepted'" class="badge badge-success badge-pill">Accepted</span>
                            <span *ngIf="invite.value.status === 'revoked'" class="badge badge-error badge-pill">Revoked</span>
                        </td>
                        <td class="text-right">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dots" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="5" cy="12" r="1" />
                                <circle cx="12" cy="12" r="1" />
                                <circle cx="19" cy="12" r="1" />
                            </svg>
                        </td>
                    </tr>
                  
                </tbody>
            </table>
            <p class="text-center" *ngIf="noExisitingInvites">No Invitations Found!</p>
        </div>
    </div>
</div>



<ngx-spinner 
name="invitationsSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>
</ngx-spinner>


<ng-template #inviteeModal>
    <div class="modal-card">
        <ngx-spinner 
        name="inviteItemSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">Invitation</h2>
                </div>
            
                <div class="current-item-border mb-4 mt-2"></div>
                

                <div class="">
                    <div class="card-label">Email</div>
                    <p>{{ pendingInvites[inviteId].email }}</p>

                    <div class="card-label">Role</div>
                    <p>{{ pendingInvites[inviteId].role | titlecase }}</p>

                    <div class="card-label">Status</div>
                    <div class="">
                        <span *ngIf="pendingInvites[inviteId].status === 'pending'" class="badge badge-primary badge-pill">Pending</span>
                        <span *ngIf="pendingInvites[inviteId].status === 'accepted'" class="badge badge-success badge-pill">Accepted</span>
                        <span *ngIf="pendingInvites[inviteId].status === 'revoked'" class="badge badge-error badge-pill">Revoked</span>
                    </div>
                </div>



                
                <div class="current-item-border mb-4 mt-4"></div>

                <small>{{ infoMessage }}</small>

                <button *ngIf="!(pendingInvites[inviteId].status === 'revoked' || pendingInvites[inviteId].status === 'accepted')" class="btn btn-sm btn-orimary w-100 mb-1" (click)="resendInvite()">Resend Invitation</button>
                <button *ngIf="!(pendingInvites[inviteId].status === 'revoked' || pendingInvites[inviteId].status === 'accepted')" class="btn btn-sm btn-error w-100" (click)="openConfrimationModal(confirmationModal)">Revoke Invitation</button>
               
                <div class="text-center mt-2">
                    <a [routerLink]="" (click)="modalRef.hide()"><small>Close</small></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>










<ng-template #confirmationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">⚠️ Warning!</h2>
                </div>
                <p class="mt-4">Are you sure you want to revoke this invitation? This action will revoke the previously sent invitation, and will remove this user from your subscription.</p>
                <div class="current-item-border mb-4 mt-2"></div>
                
                <div class="d-flex justify-content-between">
                    <button class="btn btn-sm btn-primary" (click)="cancel()">No, Cancel</button>
                    <button class="btn btn-sm btn-error" (click)="revokeInvite()">Yes, Revoke Invitation</button>    
                </div>

            </div>
        </div>
    </div>
</ng-template>
<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isMediaCollapsed)?'section-text-light':''">
            Media
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingMedia()" [ngClass]="(!isMediaCollapsed)?'active':''"
        [attr.aria-expanded]="!isMediaCollapsed" aria-controls="isMediaCollapsed">
            <svg *ngIf="isMediaCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isMediaCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isMediaCollapsed" [collapse]="isMediaCollapsed" [isAnimated]="true">
        <app-template-pass-media-upload></app-template-pass-media-upload>
    </div>










    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isStylingCollapsed)?'section-text-light':''">
            Styling
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingStyling()" [ngClass]="(!isStylingCollapsed)?'active':''"
        [attr.aria-expanded]="!isStylingCollapsed" aria-controls="isStylingCollapsed">
            <svg *ngIf="isStylingCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isStylingCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isStylingCollapsed" [collapse]="isStylingCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Background Color
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.apple.colors.background_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="backgroundColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.apple.colors.background_color"/> 
        
                        <div class="color-text">
                            {{ currentTemplateSettings.apple.colors.background_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>



            <div class="control-item">
                <div class="current-item-label">
                    Text Color
                </div>
                <div class="current-item">
                    <div class="d-flex">

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.apple.colors.foreground_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="foregroundColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.apple.colors.foreground_color"/> 
        
                        <div class="color-text">
                            {{ currentTemplateSettings.apple.colors.foreground_color }}
                        </div>
                    </div>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Label Color
                </div>
                <div class="current-item">
                    <div class="d-flex">

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.apple.colors.label_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="labelColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.apple.colors.label_color"/> 
        
                        <div class="color-text">
                            {{ currentTemplateSettings.apple.colors.label_color }}
                        </div>
                    </div>
                </div>
                <div class="current-item-border"></div>
            </div>


        


            





            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.apple.colors.can_edit_styling"  [(ngModel)]="currentTemplateSettings.apple.colors.can_edit_styling" (ngModelChange)="allowUserStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.apple.colors.can_edit_styling" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.apple.colors.can_edit_styling" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change card styling.</small>
                </div>
            </div>
        </div>
    </div>
</div>


















<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">

            <div class="card shadow" [@fade-in]>
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Apple Wallet Social Card</span>
                    </div>

                    <app-social-card [socialCard]="socialCard"></app-social-card>

                    <div class="mt-4">
                        <small>NOTE: Content and appearance may slightly change based on the various device constraints.</small>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

// ANIMATIONS
import { fadeInAnimation } from '@app/shared/animations/animations';

// SERVICES
import { AuthService } from '@core/services/auth/auth.service';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    fadeInAnimation,
  ]
})
export class LoginComponent implements OnInit {

  load_completed: boolean = true;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.required,]),
    password: new FormControl('', [Validators.required]),
  });

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  passwordResetForm = new FormGroup({
    email: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.required,]),
  });

  get resetEmail() { return this.passwordResetForm.get('email'); }

  error: boolean;
  errorMessage: string;


  passwordReset: boolean;
  resetMessage: string;

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.passwordReset = false;
  }


  login() {
    this.spinner.show('loginSpinner');
    this.authService.login(this.email.value, this.password.value).then(user => {
      console.log(user);
      this.spinner.hide('loginSpinner');
    }).catch(err => {
      this.error = true;
      this.errorMessage = this.authService.handleAuthError(err.code);
      console.error(err);
      this.spinner.hide('loginSpinner');
      this.snackBarService.error(this.authService.handleAuthError(err.code));
    })

  }





  togglePasswordReset() {
    this.passwordReset = !this.passwordReset;
    // reset error messages
    this.error = false;
    this.errorMessage = '';
  }

  sendResetEmail() {
    this.spinner.show('loginSpinner');
    // Auth service email pass resert
    this.authService.sendPasswordResetEmail(this.resetEmail.value).then(res => {
      // Bottom notif to show success
      this.spinner.hide('loginSpinner');
      this.resetMessage = "Check your email, we've sent you a password reset link.";
      this.snackBarService.success('Reset email sent');
    }).catch(err => {
      // Bottom notif
      this.spinner.hide('loginSpinner');
      this.error = true;
      this.errorMessage = "Failed to send reset email. Ensure email is correct.";
      this.snackBarService.error('Error sending reset email')
    })
  }
}

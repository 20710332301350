import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { TenantService } from '@core/services/tenant/tenant.service';
import { TenantSubscription } from '@app/core/models/tenant.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionGuard implements CanActivateChild {

    sub: TenantSubscription;
    subStatus: string;

    constructor(
        private router: Router,
        private tenantService: TenantService,
    ) {
    }

    async canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {

        console.log('SubscriptionGuard.canActivate');
        const status = await this.tenantService.getStripeSubData().then(res => {
            this.sub = res;
            this.subStatus = this.sub.status;
            console.log('canActivate await value: ' + this.subStatus);

            switch (this.subStatus) {
                case 'active': {
                    return true;
                }
                case 'trialing': {
                    return true;
                }
                case 'incomplete': {
                    return true;
                }
                case 'incomplete_expired': {
                    return true;
                }


                case 'past_due': {
                    this.router.navigate(['/error']);
                    return false;
                }
                case 'unpaid': {
                    this.router.navigate(['/error']);
                    return false;
                }
                case 'canceled': {
                    this.router.navigate(['/error']);
                    return false;
                }
                default: {
                    this.router.navigate(['/error']);
                    return false;
                }
            }
        }).catch(err => {
            console.error("😡 Subscription Error: ", err);
            return false
        });


        console.log('😡 returning SUBSCRIPTION guard result now');
        return status

    }

}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import { TemplatingStateService } from '@core/services/templates/templating-state.service';
import { Template, TemplatingState } from '@core/models/template.model';

import { TemplatingService } from '@core/services/templates/templating.service';
import { SnackBarService } from '@core/notifications/services/snack-bar.service';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-templating-left-nav',
    templateUrl: './templating-left-nav.component.html',
    styleUrls: ['./templating-left-nav.component.css']
})
export class TemplatingLeftNavComponent implements OnInit {

    templateSub: Subscription;
    currentTemplateSettings: Template;

    templateState: TemplatingState;
    templateStates = TemplatingState;

    parentPath: string;
    currentPath: string;

    templateType: string = 'new';

    isWebCollapsed: boolean = true;
    isAppleWalletCollapsed: boolean = true;
    isEmailSignatureCollapsed: boolean = true;
    isQrCollapsed: boolean = true;


    constructor(
        private templatingStateService: TemplatingStateService,
        private templatingService: TemplatingService,
        private snackBarService: SnackBarService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private spinner: NgxSpinnerService,
    ) {
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });

        this.templatingStateService.templateState.subscribe(state => {
            console.log(state);
            this.templateState = state;

            // Open web collpase if true
            if (
                this.templateState === this.templateStates.WebAccessory ||
                this.templateState === this.templateStates.WebAppearance ||
                this.templateState === this.templateStates.WebBasic ||
                this.templateState === this.templateStates.WebCta ||
                this.templateState === this.templateStates.WebMedia ||
                this.templateState === this.templateStates.WebQuickLinks
            ) {
                this.isWebCollapsed = false;
                this.isAppleWalletCollapsed = true;
                this.isEmailSignatureCollapsed = true;
                this.isQrCollapsed = true;
            } else if (
                this.templateState === this.templateStates.AppleWalletGeneral ||
                this.templateState === this.templateStates.AppleWalletFront ||
                this.templateState === this.templateStates.AppleWalletQr ||
                this.templateState === this.templateStates.AppleWalletBack
            ) {
                this.isWebCollapsed = true;
                this.isAppleWalletCollapsed = false;
                this.isEmailSignatureCollapsed = true;
                this.isQrCollapsed = true;
            } else {
                this.isWebCollapsed = true;
                this.isAppleWalletCollapsed = true;
            }
        });

        // Sets the parent and current path to match URl, Sets the nav up to correct state
        const tree: UrlTree = router.parseUrl(this.router.url)
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;

        if (s.length === 4) {
            this.parentPath = s[1].path;
            this.currentPath = s[2].path;
        } else {
            this.parentPath = s[0].path;
            this.currentPath = s[1].path;
        }

        console.log(this.parentPath, this.currentPath);
    }

    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            this.templateType = params['t'];
        });
    }


    setTemplatingState(newState: TemplatingState) {
        this.templatingStateService.updateState(newState);
    }


    saveTemplate() {
        this.spinner.show('templateSaveSpinner');

        this.templatingService.saveTemplateChanges().then(res => {
            if (res.success) {
                this.spinner.hide('templateSaveSpinner');
                this.snackBarService.success('Changes successfully saved');
            } else {
                this.spinner.hide('templateSaveSpinner');
                this.snackBarService.error('Failed to save changes, try again');
            }
        }).catch(err => {
            this.spinner.hide('templateSaveSpinner');
            this.snackBarService.error('Failed to save changes, try again');
        });
    }









    modalRef: BsModalRef;


    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }

    closeModal() {
        this.modalRef.hide();
        this.templatingStateService.updateState(TemplatingState.General);
        this.templatingService.resetTemplate();

        this.router.navigate(['/admin']);
    }
}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from  "@angular/router";
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

// SERVICES
import { AngularFireStorage } from '@angular/fire/storage';
import { UserTenantsService } from '@core/services/tenant/user-tenants/user-tenants.service';
import { TenantService } from '@core/services/tenant/tenant.service';
import { AuthService } from '@core/services/auth/auth.service';
import { UserService } from '@core/services/user/user.service';

// MODELS
import { Tenant } from '@core/models/tenant.model';

// ANIMATIONS
import { fadeInAnimation } from '@app/shared/animations/animations';


@Component({
  selector: 'app-organization-selection',
  templateUrl: './organization-selection.component.html',
  styleUrls: ['./organization-selection.component.css'],
  animations: [
    fadeInAnimation
  ]
})
export class OrganizationSelectionComponent implements OnInit, OnDestroy {

  load_completed: boolean;

  tenantIds: string[];
  orgItems: Tenant[] = [];
  logoUrls: Observable<any>[] = [];

  authUserSub: Subscription;
  userTenantsSub: Subscription;
  tenantDetailsSub: Subscription;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private userTenantsService: UserTenantsService,
    private tenantService: TenantService,
    private authService: AuthService,
    private userService: UserService,
    private storage: AngularFireStorage,
  ) {}

  ngOnInit(): void {
    this.spinner.show('userTenantsSpinner');
  
    this.authUserSub = this.authService.currentAuthUser.subscribe(res => {
      this.userTenantsService.setUserTenantsObservable(res.uid);
    });
    
    this.userTenantsSub = this.userTenantsService.currentUserTenants.subscribe(res => {
      this.tenantIds = res;
    });

    this.tenantDetailsSub = this.userTenantsService.currentuserTenantsDetails.subscribe(res => {
      if (res != null) {
        this.orgItems = res;
        console.log("ORGS: ", this.orgItems);
        this.getOrgLogo();
        this.spinner.hide('userTenantsSpinner');
        this.load_completed = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.authUserSub.unsubscribe();
    this.userTenantsSub.unsubscribe();
  }



  // downloads logo(s) from storage
  async getOrgLogo() {
    this.tenantIds.forEach(element => {
      const ref = this.storage.ref(`tenants/${element}/media/logo`);
      const logoLocalUrl = ref.getDownloadURL();
      console.log('URL', logoLocalUrl);
      this.logoUrls.push(logoLocalUrl);
    });
  }




  // Route the user to the selected tenant
  routeToTenant(orgIndex: string) {
    const tenantId = this.tenantIds[orgIndex];

    const uid = this.authService.getCurrentAuthUser();

    this.tenantService.setUserTenantId(tenantId);
    this.userService.setUserForTenant(tenantId, uid);

    this.router.navigate(['/']);
  }





  logOut() {
    this.authService.logout();
  }
}

<div class="template-nav">

    <div class="d-flex master-buttons justify-content-between">
        <button class="nav-btn" (click)="openModal(templateCloseWarning)">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
        </button>

        <button class="btn btn-sm btn-primary save w-75" (click)="saveTemplate()">Save</button>
    </div>

   
    <nav class="nav flex-column">
        <a class="nav-link" (click)="setTemplatingState(templateStates.General)" [routerLink]="" [ngClass]="(templateState === templateStates.General)?'active':''">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-align-left" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="4" y1="6" x2="20" y2="6" />
                    <line x1="4" y1="12" x2="14" y2="12" />
                    <line x1="4" y1="18" x2="18" y2="18" />
                </svg>
                <div class="nav-text">General</div>
            </div>
        </a>












        <a class="nav-link" [routerLink]="" (click)="isWebCollapsed = !isWebCollapsed" [ngClass]="(!isWebCollapsed)?'active':''"
        [attr.aria-expanded]="!isWebCollapsed" aria-controls="webCollapse" *ngIf="currentTemplateSettings.support_web">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-world" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="12" r="9" />
                    <line x1="3.6" y1="9" x2="20.4" y2="9" />
                    <line x1="3.6" y1="15" x2="20.4" y2="15" />
                    <path d="M11.5 3a17 17 0 0 0 0 18" />
                    <path d="M12.5 3a17 17 0 0 1 0 18" />
                </svg>
                <div class="nav-text">Web</div>
            </div>
        </a>
        <div id="webCollapse" [collapse]="isWebCollapsed" [isAnimated]="true">
            <div class="well">
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.WebMedia)" [routerLink]="" [ngClass]="(templateState === templateStates.WebMedia)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Media</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.WebAppearance)" [routerLink]="" [ngClass]="(templateState === templateStates.WebAppearance)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Appearance</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.WebQuickLinks)" [routerLink]="" [ngClass]="(templateState === templateStates.WebQuickLinks)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Quick Links</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.WebCta)" [routerLink]="" [ngClass]="(templateState === templateStates.WebCta)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">CTA</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.WebAccessory)" [routerLink]="" [ngClass]="(templateState === templateStates.WebAccessory)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Acessory Items</div>
                    </div>
                </a>
            </div>
            
        </div>





        







        <a class="nav-link" [routerLink]="" (click)="isAppleWalletCollapsed = !isAppleWalletCollapsed" [ngClass]="(!isAppleWalletCollapsed)?'active':''"
        [attr.aria-expanded]="!isAppleWalletCollapsed" aria-controls="appleCollapse" *ngIf="currentTemplateSettings.support_apple">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-apple" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z" />
                    <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
                </svg>
                <div class="nav-text">Apple Wallet</div>
            </div>
        </a>
        <div id="appleCollapse" [collapse]="isAppleWalletCollapsed" [isAnimated]="true">
            <div class="well">
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.AppleWalletGeneral)" [routerLink]="" [ngClass]="(templateState === templateStates.AppleWalletGeneral)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Card General</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.AppleWalletFront)" [routerLink]="" [ngClass]="(templateState === templateStates.AppleWalletFront)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Card Front</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.AppleWalletQr)" [routerLink]="" [ngClass]="(templateState === templateStates.AppleWalletQr)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Card QR</div>
                    </div>
                </a>
                <a class="nav-link sub" (click)="setTemplatingState(templateStates.AppleWalletBack)" [routerLink]="" [ngClass]="(templateState === templateStates.AppleWalletBack)?'active':''">
                    <div class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 12l5 5l10 -10" />
                            <path d="M2 12l5 5m5 -5l5 -5" />
                        </svg>
                        <div class="nav-text sub-text">Card Back</div>
                    </div>
                </a>
            </div>
            
        </div>

      











        <a class="nav-link" aria-current="page" [routerLink]="" *ngIf="currentTemplateSettings.support_email">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="3" y="5" width="18" height="14" rx="2" />
                    <polyline points="3 7 12 13 21 7" />
                </svg>
                <div class="nav-text">Email Signature</div>
            </div>
        </a>

        <a class="nav-link" aria-current="page" [routerLink]="" (click)="setTemplatingState(templateStates.Qr)" *ngIf="currentTemplateSettings.support_qr" [ngClass]="(templateState === templateStates.Qr)?'active':''">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-qrcode" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="4" y="4" width="6" height="6" rx="1" />
                    <line x1="7" y1="17" x2="7" y2="17.01" />
                    <rect x="14" y="4" width="6" height="6" rx="1" />
                    <line x1="7" y1="7" x2="7" y2="7.01" />
                    <rect x="4" y="14" width="6" height="6" rx="1" />
                    <line x1="17" y1="7" x2="17" y2="7.01" />
                    <line x1="14" y1="14" x2="17" y2="14" />
                    <line x1="20" y1="14" x2="20" y2="14.01" />
                    <line x1="14" y1="14" x2="14" y2="17" />
                    <line x1="14" y1="20" x2="17" y2="20" />
                    <line x1="17" y1="17" x2="20" y2="17" />
                    <line x1="20" y1="17" x2="20" y2="20" />
                </svg>
                <div class="nav-text">QR</div>
            </div>
        </a>



        <!-- <a class="nav-link" aria-current="page" [routerLink]="">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="12" r="2" />
                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                </svg>
                <div class="nav-text">Preview Template</div>
            </div>
        </a> -->

    </nav>

</div>












<ng-template #templateCloseWarning>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <!-- <span class="card-label">Enterpise Contact Form</span> -->
                <h2 class="mb-3">Are you sure? ⚠️</h2>

                <p>Are you sure you want to close this template? Any unsaved changes will be lost.</p>
                
                <div class="d-flex justify-content-between mt-4">
                    <button class="btn btn-auxiliary" (click)="this.modalRef.hide()">No, cancel</button>
                    <button class="btn btn-primary" (click)="this.closeModal()">Yes, close</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<!-- LOADING -->

<ngx-spinner 
name="templateSaveSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.5)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>
</ngx-spinner>

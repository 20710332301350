<div>
    <div class="control-item-group">
        <div class="control-item">
            <div class="current-item-label">
                Pass Logo
            </div>
            <small class="validation-error">{{ errorMessage }}</small>
            <small class="success">{{ successMessage }}</small>
       
            <div class="card shadow" *ngIf="logoFiles.length === 0">
                <div class="card-body card-media">
                    <div class="dropzone" appDnd (fileDropped)="onLogoFileDropped($event)">
                        <input type="file" class="input-dropzone" #fileDropRefLogo id="fileDropRefLogo" accept="image/*"
                        (change)="logoFileBrowseHandler($event.target.files)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud-upload"
                            width="40" height="40" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                            <polyline points="9 15 12 12 15 15" />
                            <line x1="12" y1="12" x2="12" y2="21" />
                        </svg>
                        <h6>Drag and drop photo here</h6>
                        <p>or</p>
                        <button class="btn btn-sm btn-primary w-100">Browse for file</button>
                    </div>
                </div>
            </div>


            <div class="" *ngIf="logoFiles.length != 0">
                <div class="card shadow mt-2" *ngFor="let file of logoFiles; let i = index">
                    <div class="card-body card-media-file">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <p class="mb-0 text-truncate file-name">{{ file?.name }}</p>
                                <small class="text-muted">{{ formatBytes(file?.size) }} | {{ file?.type }}</small>
                            </div>
                            <div class="">
                                <button type="button" class="close pull-right" aria-label="Close"
                                    (click)="deleteLogoFile(i)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x"
                                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C"
                                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="mt-2">
                            <progressbar [max]="100" [value]="progress">
                                <span class="text-nowrap"></span>
                            </progressbar>
                            <small *ngIf="progress < 100" class="text-muted">Pending Upload</small>
                            <small *ngIf="progress === 100" class="text-muted">Published</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>








<ng-template #passLogoCropperModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <h2 class="mb-3">Pass Logo Cropper</h2>


                <p>Crop the uploaded image below. Pass logos will display with a 3:2 aspect ratio at approximately 90px x 60px. <a routerLink="https://joinsocialcard.com">Learn More</a></p>
                <div class="">
                    <small>Tip: Use a logo image with a transparent background for a better look.</small>
                </div>

                <div class="current-item-border mb-4 mt-2"></div>
                <div class="card-label mb-2">
                    Preview
                </div>
                <div class="text-center">
                    <img class="cropped-pass-logo-preview" [src]="croppedImage"/>
                
                    <div class="">
                        <small>90px x 60px or 3:2 ratio</small>
                    </div>
                </div>

                <div class="card-label mt-4 mb-2">
                    Cropper
                </div>
                
                <div>
                    <image-cropper 
                        [imageBase64]="tempPassLogo" 
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio" 
                        [aspectRatio]="3 / 2" 
                        [resizeToWidth]="180"
                        [resizeToHeight]="120"
                        [cropperMinWidth]="180" 
                        
                        [onlyScaleDown]="true" 
                        [roundCropper]="false" 
                        [canvasRotation]="canvasRotation"
                        [transform]="transform" 
                        [alignImage]="'center'" 
                        [style.display]="showCropper ? null : 'none'" 
                        format="png"
                        (imageCropped)="imageCropped($event)" 
                        (imageLoaded)="imageLoaded()" 
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </div>

                
                <div class="card-label mt-4">
                    Options
                </div>
                <div class="d-flex mt-2 mb-2">
                    <button class="nav-btn mr-3" (click)="rotateRight()" tooltip="Rotate right">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate-clockwise-2" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />
                            <line x1="5.63" y1="7.16" x2="5.63" y2="7.17" />
                            <line x1="4.06" y1="11" x2="4.06" y2="11.01" />
                            <line x1="4.63" y1="15.1" x2="4.63" y2="15.11" />
                            <line x1="7.16" y1="18.37" x2="7.16" y2="18.38" />
                            <line x1="11" y1="19.94" x2="11" y2="19.95" />
                        </svg>
                    </button>
                    <button class="nav-btn mr-3" (click)="rotateLeft()" tooltip="Rotate left">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate-2" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5" />
                            <line x1="18.37" y1="7.16" x2="18.37" y2="7.17" />
                            <line x1="13" y1="19.94" x2="13" y2="19.95" />
                            <line x1="16.84" y1="18.37" x2="16.84" y2="18.38" />
                            <line x1="19.37" y1="15.1" x2="19.37" y2="15.11" />
                            <line x1="19.94" y1="11" x2="19.94" y2="11.01" />
                        </svg>
                    </button>
                    <button class="nav-btn mr-3" (click)="flipHorizontal()" tooltip="Flip horizontal">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-switch-horizontal" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="16 3 20 7 16 11" />
                            <line x1="10" y1="7" x2="20" y2="7" />
                            <polyline points="8 13 4 17 8 21" />
                            <line x1="4" y1="17" x2="13" y2="17" />
                        </svg>
                    </button>
                
                    <button class="nav-btn mr-3" (click)="flipVertical()" tooltip="Flip vertical">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-switch-vertical" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="3 8 7 4 11 8" />
                            <line x1="7" y1="4" x2="7" y2="13" />
                            <polyline points="13 16 17 20 21 16" />
                            <line x1="17" y1="10" x2="17" y2="20" />
                        </svg>
                    </button>

                    <button class="nav-btn mr-3" (click)="zoomOut()" tooltip="Zoom out">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-zoom-out" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <circle cx="10" cy="10" r="7" />
                            <line x1="7" y1="10" x2="13" y2="10" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                        </svg>
                    </button>
                    <button class="nav-btn mr-3" (click)="zoomIn()" tooltip="Zoom in">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-zoom-in" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <circle cx="10" cy="10" r="7" />
                            <line x1="7" y1="10" x2="13" y2="10" />
                            <line x1="10" y1="7" x2="10" y2="13" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                        </svg>
                    </button>
                </div>
                <div class="d-flex justify-content-around mt-2">
                    <input class="form-control" [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" tooltip="Rotation"/>
                </div>
           

                <div class="mt-2">
                    <button class="btn btn-sm btn-primary mb-2 w-100" (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
                    <button class="btn btn-sm btn-auxiliary w-100" (click)="resetImage()">Reset image</button>
                </div>
                

                

                <div class="current-item-border mb-4 mt-4"></div>


                <div class="d-flex justify-content-between mt-4">
                    <button class="btn btn-auxiliary" (click)="this.modalRef.hide()">Cancel</button>
                    <button class="btn btn-primary" (click)="saveCroppedImageToService()">Continue</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { Tenant, TenantSubscription } from '@app/core/models/tenant.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, first, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    user_tenants: Observable<string[]>;
    private userTenantsSubject = new BehaviorSubject<string[]>([]);
    public currentUserTenants = this.userTenantsSubject.asObservable().pipe(distinctUntilChanged());

    tenant: Observable<Tenant>;
    private activeTenantSubject = new BehaviorSubject<Tenant>({} as Tenant);
    public activeTenant = this.activeTenantSubject.asObservable().pipe(distinctUntilChanged());

    tenant_stripe_sub: Observable<TenantSubscription>;
    private activeTenantSubscriptionSubject = new BehaviorSubject<TenantSubscription>({} as TenantSubscription);
    public activeTenantSubscription = this.activeTenantSubscriptionSubject.asObservable().pipe(distinctUntilChanged());

    userTenantsSub: Subscription;
    tenantSub: Subscription;
    tenantStripeSub: Subscription;

    constructor(
        private db: AngularFireDatabase,
        private router: Router
    ) { }


    setUserTenantId(id: string) {
        // stores tenant_id for session
        sessionStorage.setItem('t', id);
        // retreives tenant information from DB
        this.getActiveTenant();
        this.getActiveTenantSub(); // Stripe Data
    }

    getUserTenantId(): string {
        if (sessionStorage.getItem('t') != null || sessionStorage.getItem('t') != undefined) {
            return sessionStorage.getItem('t');
        } else {
            this.router.navigate(['/auth/orgs'])
        }
    }


    setUserTenantsObservable(uid: string) {
        this.user_tenants = this.db.object<[string]>(`user_tenants/${uid}`).valueChanges();
        console.log("USER TENANTS", this.user_tenants);
        this.updateUserTenants();
    }

    updateUserTenants() {
        this.userTenantsSub = this.user_tenants.subscribe(res => {
            this.userTenantsSubject.next(res);
            console.log('USER TENANTS DATA: ', res);
        });
    }



    getActiveTenant() {
        const tenant_id = sessionStorage.getItem('t');
        this.tenant = this.db.object<Tenant>(`tenants/${tenant_id}`).valueChanges();
        this.updateActiveTenant();
    }

    updateActiveTenant() {
        this.tenantSub = this.tenant.subscribe(tenant => {
            console.log('Current Tenant: ', tenant);
            this.activeTenantSubject.next(tenant);
        })
    }


    // Gets DB data on the tenants stripe sub
    getActiveTenantSub() {
        const tenant_id = sessionStorage.getItem('t');
        this.tenant_stripe_sub = this.db.object<TenantSubscription>(`tenant_subscriptions/${tenant_id}`).valueChanges();
        this.updateActiveTenantSub();
    }

    updateActiveTenantSub() {
        this.tenantStripeSub = this.tenant_stripe_sub.subscribe(sub => {
            console.log('Current Tenant Stripe Basics: ', sub);
            this.activeTenantSubscriptionSubject.next(sub);
        })
    }

    // Used by subscription guard to monitor the state of a users tenant sub
    getStripeSubData() {
        const tenant_id = sessionStorage.getItem('t');
        return this.db.object<TenantSubscription>(`tenant_subscriptions/${tenant_id}`).valueChanges().pipe(
            tap(data => {
                // console.log(data);
            }),
            first()
        ).toPromise();
    }


















    // Pushes updates to DB for changes to (org name, org size, website, industry)
    async updateTenantBasicInfo(updates: Tenant): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const tenantId = sessionStorage.getItem('t');

            // TODO: Auth email change
            const tenant = this.db.object<Tenant>(`tenants/${tenantId}`).update(updates).then(_ => {
                resolve(true)
            }).catch(err => {
                console.log(err, 'You dont have access!');
                reject(false);
            });
        });
    }

    // Pushes updates to db for org contact info
    async updateTenantContanctInfo(updates: Tenant): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const tenantId = sessionStorage.getItem('t');

            // TODO: Auth email change
            const tenant = this.db.object<Tenant>(`tenants/${tenantId}`).update(updates).then(_ => {
                resolve(true)
            }).catch(err => {
                console.log(err, 'You dont have access!');
                reject(false);
            });
        });
    }






    async updateTenantLogoUrl(url: string): Promise<Boolean> {
        return new Promise<Boolean>((resolve, reject) => {
            const tenantId = sessionStorage.getItem('t');

            // TODO: Auth email change
            const tenant = this.db.object<string>(`tenants/${tenantId}/logo`).update(url).then(_ => {
                resolve(true)
            }).catch(err => {
                console.log(err, 'You dont have access!');
                reject(false);
            });
        });
    }



    removeTenantReferences() {
        this.userTenantsSub.unsubscribe();
        this.tenantSub.unsubscribe();
    }
}

<div class="tab-container" [@fade-in]>

    <h3 class="h3">Templates</h3>

    <div class="d-flex justify-content-between">
        <p class="mb-0 tab-p">Showing {{ totalTemplates || 0 }} template(s)</p>
        <button class="btn btn-gradient btn-sm" (click)="routeToNewTemplate()">New Template</button>
    </div>
    <div class="current-item-border mb-4 mt-2"></div>
   
    <p *ngIf="tenantTemplates === null">🤔 No active templates found. <a [routerLink]="" (click)="routeToNewTemplate()">Create New Template</a></p>







    <div class="card shadow mt-4" *ngIf="tenantTemplates != null">
        <div class="card-body table-responsive">
            <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" class="card-label">Name
    
                    </th>
                    <th scope="col" class="card-label">Status
                    
                    </th>
                    <th scope="col" class="card-label text-center">Departments
    
                    </th>
                    <!-- <th scope="col" class="card-label">Web
                        
                    </th>
                    <th scope="col" class="card-label">iOS
                        
                    </th>
                    <th scope="col" class="card-label">Email
                        
                    </th> -->
                    <th scope="col" class="card-label text-center">Last Updated
                        
                    </th>

                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let template of tenantTemplates | keyvalue; index as i;" (click)="routeToTemplate(template.value, template.key)" [tooltip]="template.value.description">
                        <td scope="row">{{ template.value.name }}</td>
                        <td class="">
                            <span *ngIf="template.value.published" class="badge badge-success badge-pill">Published</span>
                            <span *ngIf="!template.value.published" class="badge badge-warning badge-pill">Not Published</span>
                        </td>
                        <td class="text-center" *ngIf="template.value.departments">{{ template.value.departments.length || 0 }}</td>
                        <td class="text-center" *ngIf="!template.value.departments">0</td>
                        <!-- <td class="">
                            <div class="" *ngIf="template.value.support_web">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M5 12l5 5l10 -10" />
                                </svg>
                            </div>
                        </td>
                        <td class="">
                            <div class="" *ngIf="template.value.support_apple">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M5 12l5 5l10 -10" />
                                </svg>
                            </div>
                        </td>
                        <td class="">
                            <div class="" *ngIf="template.value.support_email">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M5 12l5 5l10 -10" />
                                </svg>
                            </div>
                        </td> -->
                        <td scope="row" class="text-center">{{ template.value.last_updated | date:"mediumDate" }}</td>
                       
                    </tr>
                  
                </tbody>
            </table>
        </div>
    </div>




















    <!-- <div class="row card-row" *ngIf="tenantTemplates != null">
        <div class="col col-lg-6 col-12" *ngFor="let template of tenantTemplates | keyvalue; let i = index;">
            <div class="card shadow">
                <a class="stretched-link" [routerLink]="" (click)="routeToTemplate(template.value)"></a>
                <div class="card-body card-templates d-flex flex-column">
                    <h3 class="h3">{{ template.value.name }}</h3>
                    <div class="d-flex">
                        <p>Status: </p>
                        <div class="">
                            <span *ngIf="template.value.published" class="badge badge-success badge-pill">Published</span>
                            <span *ngIf="!template.value.published" class="badge badge-warning badge-pill">Not Published</span>
                        </div>
                    </div>
                    <div class="mb-2 d-flex">
                        <p>Platforms: </p>
                        <div class="">
                            <span *ngIf="template.value.support_web" class="badge badge-primary badge-pill">Web</span>
                        <span *ngIf="template.value.support_apple" class="badge badge-primary badge-pill">iOS</span>
                        <span *ngIf="template.value.support_email" class="badge badge-primary badge-pill">Email</span>
                        </div>
                    </div>
                    <p>{{ template.value.description }}</p>
                    
                    <div class="details">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="count text-center" *ngIf="template.value.departments">{{ template.value.departments.length || 0 }}</h4>
                                <h4 class="count text-center" *ngIf="!template.value.departments">0</h4>
                                <div class="section-subtitle text-center">Departments</div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-auto">
                        <div class="current-item-border"></div>
                        <div class="d-flex justify-content-between mt-4">
                            <small>Edited: {{ template.value.last_updated | date:'mediumDate' }}</small>
                            <a class="" [routerLink]="">Edit Template 
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>-->

</div>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

// Services
import { UserService } from '@core/services/user/user.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { AuthUser } from '@app/core/models/user.model';
import { TenantService } from '@app/core/services/tenant/tenant.service';

@Injectable({
    providedIn: 'root'
})
export class SessionGuardGuard implements CanActivate {

    userSub: Subscription;

    constructor(
        private router: Router,
        private userService: UserService,
        private authService: AuthService,
        private tenantSerive: TenantService,
    ) {}


    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {

        const uid = sessionStorage.getItem('u');
        const tenantId = sessionStorage.getItem('t');

        console.log('🚨 VALIDATING SESSION 🚨')
        
        // 1. If the session UID doesnt exist route away
        if (uid === null || uid === undefined) {
        console.log("😡 User not found!");
        this.router.navigate(['auth/orgs']);
        return false;
        } 

        // 2. If the session tenantId doesnt exist route away
        if (tenantId === null || tenantId === undefined) {
        console.log("😡 Tenant not found!");
        this.router.navigate(['auth/orgs']);
        return false;
        } 


        // 3. If both UID & TENANTID are found, set session (active tenant, and active tenant user) and continue to route.
        if ((uid != null || uid != undefined) && (tenantId != null || tenantId != undefined)) {
            console.log('✅ Session Found: ', uid, tenantId);
        
            const user = await this.authService.checkSessionAuthentication().then(user => {
                if (user) {
                    console.log('✅ USER FOUND:', user);
                    this.userService.setUserForTenant(tenantId, uid);
                    this.tenantSerive.getActiveTenantSub();
                
                    return true; 
                }
            
            }).catch(err => {
                console.log("😡 Session Error: ", err);
                this.router.navigate(['auth/orgs']);
                return false;
            });

            console.log('😡 returning session guard result now');
            return user
        }
    }
}

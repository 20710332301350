import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

import { NgxSpinnerService } from 'ngx-spinner';
import { fadeInAnimation } from '@app/shared/animations/animations';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';

// Stripe JS NPM package
import {loadStripe} from '@stripe/stripe-js';
// Services
import { StripeService } from '@core/services/stripe/stripe.service';
import { StripePaymentMethodsService } from '@app/core/services/stripe/stripe-payment-methods.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-card-change',
  templateUrl: './admin-card-change.component.html',
  styleUrls: ['./admin-card-change.component.css'],
  animations: [fadeInAnimation],
})
export class AdminCardChangeComponent implements OnInit, AfterViewInit {
  stripe: any;
  cardElement: any;

  card: any;
  cardInfo: any;

  error: boolean;
  errorMessage: string;

  buttonStatus: boolean = true;

  statusSub: Subscription;
  pmStatus: boolean;

  constructor(
    private stripePaymentMethodsService: StripePaymentMethodsService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
  ) { 
    this.stripePaymentMethodsService.paymentMethodStatus.subscribe(res => {
      this.pmStatus = res;
    });
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.initiateCardElement();
  } 


  //Sets up STRIPE Elements within the client component
  async initiateCardElement() {
    this.stripe = await loadStripe(environment.stripe.key);
    console.log(this.stripe)
    const elements = this.stripe.elements();
    // Set up Stripe.js and Elements to use in checkout form
    var style = {
      base: {
        color: "#464646",
        fontFamily: '"Montserrat", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#6c757d"
        }
      },
      invalid: {
        color: "#dc3545",
        iconColor: "#dc3545"
      }
    };

    this.cardElement = elements.create("card", { style: style });
    this.cardElement.mount("#card-element");

    this.cardElement.on('change', (event) => {
      this.showCardError(event);
    });
  }


  // Creates the payment method with the users entry and then calls for sub creation on success
  createPaymentMethod() {
    this.spinner.show('checkoutSpinner')
    // Calls stripe js library to generate payment method from info provided
    console.log(this.stripe, this.cardElement)
    return this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
    }).then((result) => {
      // If payment method was not successfully created, show error to client
      if (result.error) {
        this.showCardError(result.error);
        this.buttonStatus = false;
        this.spinner.hide('checkoutSpinner');
        this.snackBarService.error('An iternal error occured in Billing');
      } else {
        console.log(result.paymentMethod.id);
        let pm = result.paymentMethod.id;
        // call API to update the tenants payment method
        this.stripePaymentMethodsService.updatePaymentMethod(pm).then(res => {
          if (res.success) {
            this.spinner.hide('checkoutSpinner');
            this.snackBarService.success('Payment method successfully updated');
            this.stripePaymentMethodsService.updateStatus();
          }
        })
      }     
    }).catch(err => {
      console.error(err);
      this.showCardError(err);
      this.buttonStatus = false;
      this.spinner.hide('checkoutSpinner');
      this.snackBarService.error('An iternal error occured in Billing');
    });
  }





  // Handles errors on the client and displays their message
  showCardError(event) {
    let displayError = document.getElementById('card-errors');
    if (event.complete) {
      this.buttonStatus = false;
    }
    else if (event.error) {
      displayError.textContent = event.error.message;
      this.buttonStatus = true;
    } else {
      displayError.textContent = '';
    }
  }

}

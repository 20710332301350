import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Subscription } from 'rxjs';
// Models
import { User } from '@app/core/models/user.model';
// Services
import { UserService } from '@core/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CardCountGuard implements CanActivate {

  userSub: Subscription;
  user: User;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.userSub = this.userService.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // if (this.user.available_cards === 0 || this.user.available_cards === undefined) {
    //   console.log('User has no cards: 🛑 ', this.user.available_cards);
    //   return false
    // } else {
    //   console.log('User has cards: ✅ ', this.user.available_cards);
    //   this.router.navigate(['/']);
    //   return true
    // }
    return true
  }
  
}

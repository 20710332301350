<div>
    <div class="mb-4">
        <div class="d-flex mb-4">
            <h4 class="progress-value">{{progress}}</h4>
            <div class="progress-total">/{{ max }}</div>
        </div>

        <progressbar [max]="max" [value]="progress">
            <span class="text-nowrap"></span>
        </progressbar>
    </div>
    <h3 class="h3">{{ stepItems[activeStepIndex].label }}</h3>
    <p class="p">{{ stepItems[activeStepIndex].description }}</p>


    <ng-container *ngFor="let form of masterForm; let i = index; trackBy: trackByFn">
        <form action="" [formGroup]="masterForm[i]" *ngIf="i === activeStepIndex || masterForm.length === 1">
            <div class="">
                <div class="card shadow">
                    <div class="card-body">

                        <!-- FORM GROUP -->
                        <div class="form-group">
                            <div *ngFor="let field of formFields[i]; trackBy: trackByFn"
                                [ngSwitch]="currentFormContent[i][field].type">
                                <label class="" [for]="field">{{ currentFormContent[i][field].placeholder }}</label>

                                <!-- TEXT AREAS -->
                                <ng-container *ngSwitchCase="'textarea'">
                                    <textarea
                                        [ngClass]="{'is-invalid' : (masterForm[i].get(field).dirty || masterForm[i].get(field).touched) && masterForm[i].get(field).invalid && masterForm[i].get(field).errors}"
                                        class="form-control" [id]="field" [formControlName]="field"
                                        ></textarea>
                                </ng-container>

                                <!-- SELECTORS -->
                                <ng-container *ngSwitchCase="'select'">
                                    <select [formControlName]="field" [id]="field" class="custom-select"
                                        [ngClass]="{'is-invalid' : (masterForm[i].get(field).dirty || masterForm[i].get(field).touched) && masterForm[i].get(field).invalid && masterForm[i].get(field).errors}">

                                        <option value="" disabled>Select a range</option>
                                        <option
                                            *ngFor="let option of currentFormContent[i][field].options; trackBy: trackByFn"
                                            [value]="option.name">{{ option.name }}</option>
                                    </select>
                                </ng-container>

                                <!-- REGULAR INPUT -->
                                <ng-container *ngSwitchDefault>
                                    <input class="form-control mb-2"
                                        [ngClass]="{'is-invalid' : (masterForm[i].get(field).dirty || masterForm[i].get(field).touched) && masterForm[i].get(field).invalid && masterForm[i].get(field).errors}"
                                        [type]="currentFormContent[i][field].type" [formControlName]="field"
                                        [id]="field"/>
                                </ng-container>

                                <!-- ERROR MESSAGES -->
                                <small class="form-text validation-error mb-2"
                                    *ngIf="(masterForm[i].get(field).dirty || masterForm[i].get(field).touched) && masterForm[i].get(field).invalid && masterForm[i].get(field).errors">
                                    {{ getValidationMessage(i, field) }}
                                </small>

                            </div>
                        </div>



                        <!-- REVIEW OF INFO -->
                        <div class="" *ngIf="activeStepIndex === stepItems.length - 1">

                            <div class="">
                                <div class="">
                                    <div class="card-label">Organization Name</div>
                                    <p>{{ formData['organizationName'] }}</p>

                                    <div class="card-label">Industry</div>
                                    <p>{{ formData['organizationIndustry'] }}</p>

                                    <div class="card-label">Organization Size</div>
                                    <p>{{ formData['organizationSize'] }}</p>

                                    <div class="current-item-border mt-4 mb-4"></div>

                                    <div class="card-label">Account Owner Name</div>
                                    <p>{{ formData['firstName'] }} {{ formData['lastName'] }}</p>

                                    <div class="card-label">Account Owner Email</div>
                                    <p>{{ formData['email'] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FORM NAVIGATION BUTTONS -->
                <div class="button-group mt-4">
                    <button class="btn btn-primary w-100 mb-2" *ngIf="activeStepIndex === stepItems.length - 1"
                        type="button" (click)="onFormSubmit()">Continue To Plan Setup</button>

                    <button class="btn btn-primary w-100 mb-2" type="button"
                        *ngIf="!(activeStepIndex === stepItems.length - 1)"
                        [disabled]="activeStepIndex === stepItems.length - 1 || masterForm[i].invalid"
                        (click)="goToStep('next')">Continue</button>
                </div>
                <div class="text-center">
                    <a class="previous" [routerLink]="" *ngIf="activeStepIndex > 0"
                        (click)="goToStep('prev')">Previous</a>
                </div>
            </div>
        </form>
    </ng-container>


</div>
<div class="profile-nav-bg" (click)="toggleNav()" *ngIf="open" [ngClass]="open ? 'profile-nav-bg-open' : 'profile-nav-bg-closed'">
    <div class="profile-nav" [ngClass]="open ? 'profile-nav-open' : 'profile-nav-closed'">
        <ngx-spinner 
        name="checkoutSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.9)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        
        <div class="text-center" *ngIf="user" [@fade-in]>


            <div class="avatar-md avatar-default-md shadow text-center" *ngIf="user.photo === '' && user.role === 'user'"> 
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="48" height="48" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>           
            </div>
            <div class="avatar-md avatar-default-admin-md shadow text-center" *ngIf="user.photo === '' && user.role != 'user'"> 
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="48" height="48" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>           
            </div>
            <img *ngIf="user.photo != ''" [src]="user.photo" class="avatar-md shadow">
            


            <div class="mt-4 text-center" *ngIf="user" [@fade-in]>
                <h6 class="text-center font-weight-bolder">{{ user.name || user.email }}</h6>
                <p class="">{{ user.email }}</p>
                <div class="text-center">
                    <span class="badge badge-pill badge-primary" *ngIf="user.role === 'user'">{{ user.role | titlecase }}</span>
                    <span class="badge badge-pill badge-secondary" *ngIf="user.role === 'owner' || user.role === 'admin'">{{ user.role | titlecase }}</span>
                </div>
                <div class="text-center" *ngIf="tenant">
                    <small>{{ tenant.name || '' }}</small>
                </div>
            </div>
            
            <div class="current-item-border mt-2 mb-4"></div>

            <button class="btn btn-sm btn-primary w-100" routerLink="/profile">
                <div class="d-flex">
                    <div class="">View Profile</div>
                </div>
            </button>
            <button class="btn btn-sm btn-auxiliary w-100 mt-2" routerLink="/auth/orgs">
                <div class="d-flex">
                   
                    <div class="">Switch Accounts</div>
                </div>
            </button>
            <button class="btn btn-sm btn-auxiliary w-100 mt-2" (click)="logOut()" routerLink="/">
                <div class="d-flex">
                   
                    <div class="">Log Out</div>
                </div>
            </button>

        </div>
    </div>
</div>


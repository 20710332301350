<app-side-nav></app-side-nav>
<app-top-nav></app-top-nav>



<div class="container-fluid" [@fade-in]>
    <h2>Your Social Cards</h2>
    <!-- <p class="text-muted mb-4" *ngIf="user">{{ welcomeText }}, {{user.name || user.email}} 👋</p>

    <div style.width="100%" [style.height.px]="200" class="bg-gradient p-4 shadow-sm">
        <div class="row">
            <div class="col-lg-6 col-12">
                <h1 class="font-weight-bolder">Your Cards</h1>
                <p class="white">View all your Social Cards at a glance.</p>
            </div>
            <div class="col-lg-6 col-0 d-lg-block d-none">
                <img class="banner-img" src="assets/share.svg">
            </div>
        </div>
    </div> -->


    <div class="section-subtitle">
        <p>Showing {{ socialCards.length }} card(s)</p>
    </div>


    <p *ngIf="this.socialCards.length === 0">You have no active Social Cards. <a routerLink="/new">Create a new Social Card</a>.</p>

    <app-home-overview *ngIf="this.socialCards.length != 0"  [socialCards]="socialCards"></app-home-overview>

    


    <div class="card shadow mt-4">
        <div class="card-body">

            {{ user | json }}
        </div>
    </div>
</div>
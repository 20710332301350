<div class="bg-container">
    <div class="container-fluid" [@fade-in] *ngIf="!load_completed">
        <div class="position-relative">
            <ngx-spinner 
            name="loginSpinner"
            [fullScreen]="false"
            bdColor="rgba(255, 255, 255, 0)"
            size="default"
            color="#FFFFFF"
            type = "ball-clip-rotate-multiple"
            >
            </ngx-spinner>
        </div>
    </div>



    <nav class="topnav navbar navbar-expand fixed-top" *ngIf="load_completed" [@fade-in]="load_completed">
        <!-- <a class="navbar-brand mr-4"><img class="nav-logo" src="assets/logos/icon_white_accent.svg"></a> -->
        <!-- Navbar-->
        <ul class="navbar-nav ml-auto mr-0 mr-md-3 my-2 my-md-0">
            <!-- <button class="btn btn-sm btn-auxiliary" routerLink="/auth/org-setup">Get Started</button> -->
            <a class="a-light" routerLink="/auth/org-setup">Create Account
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 6 15 12 9 18" />
              </svg>
            </a>
        </ul>
    </nav>
    
    
    <div *ngIf="!passwordReset" class="container-fluid form-container-fluid" [@fade-in]>
        
        <h2 class="h2">Login</h2>
        <!-- <p class="p">Which account would you like to enter?</p> -->
        
    
        <div class="card dark-shadow">
            <div class="card-body">

                <div class="mb-2" *ngIf="error">
                    <small class="validation-error">
                        {{ errorMessage }}
                    </small>
                </div>
                
                <form [formGroup]="loginForm" (ngSubmit)="login()">
    
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input class="form-control" formControlName="email" type="email" placeholder="" autocomplete="username" id="email">
                        
                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-text text-muted">
                            <small *ngIf="email.errors.required" class="validation-error">A email is required.</small>
                            <small *ngIf="email.errors.pattern" class="validation-error">A valid email address is required.</small>
                        </div>
                    </div>
    
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input class="form-control" formControlName="password" type="password" placeholder="" autocomplete="current-password" id="password">
                        
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="form-text text-muted">
                            <small *ngIf="password.errors.required" class="validation-error">A password is required.</small>
                        </div>
                    </div>
    
                    <button type="submit" [disabled]="loginForm.invalid || loginForm.untouched || loginForm.pristine" class="btn btn-primary mt-4 w-100">Login</button>
                    
                </form>
                <div class="text-center mt-4">
                    <a [routerLink]="" (click)="togglePasswordReset()" class=""><small>Forgot password?</small></a>
                </div>
    
            </div>
        </div>
    </div>
    
    
    
    
    
    <div *ngIf="passwordReset" class="container-fluid form-container-fluid" [@fade-in]>
        
        <h2 class="h2">Password Reset</h2>
        <p class="p">No worries! Enter your account email and we'll send you a reset link.</p>
    
        <div class="card dark-shadow">
            <div class="card-body">

                <div class="mb-2" *ngIf="error">
                    <small class="success">{{ resetMessage }}</small>
                    <small class="validation-error">
                        {{ errorMessage }}
                    </small>
                </div>

                <form [formGroup]="passwordResetForm" (ngSubmit)="sendResetEmail()">
    
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input class="form-control" formControlName="email" type="email" placeholder="" id="email">
                        
                        <div *ngIf="resetEmail.invalid && (resetEmail.dirty || resetEmail.touched)" class="form-text text-muted">
                            <small *ngIf="resetEmail.errors.required" class="validation-error">A email is required.</small>
                            <small *ngIf="resetEmail.errors.pattern" class="validation-error">A valid email address is required.</small>
                        </div>
                    </div>
    
                    <button type="submit" [disabled]="passwordResetForm.invalid || passwordResetForm.untouched || passwordResetForm.pristine" class="btn btn-primary mt-4 w-100">Send Reset Email</button>
                    
                </form>
                <div class="text-center mt-4">
                    <a [routerLink]="" (click)="togglePasswordReset()" class=""><small>Back to login</small></a>
                </div>
            </div>
        </div>
    </div>
    
    
    
    <!-- <nav class="navbar fixed-bottom bg">
        <div class="d-flex">
            <a class="" routerLink="https://www.joinsocialcard.com/terms" target="_blank"><small>Terms of service</small></a>
            <div class="ml-2 mr-2">|</div>
            <a class="" routerLink="https://www.joinsocialcard.com/privacy" target="_blank"><small>Privacy policy</small></a>
        </div>
    </nav> -->
    
    <!-- <ngx-spinner 
    name="loginSpinner"
    [fullScreen]="true"
    bdColor="rgba(255, 255, 255, 0.9)"
    size="default"
    color="#5D656C"
    type = "ball-clip-rotate-multiple"
    >
    
    </ngx-spinner> -->
</div>
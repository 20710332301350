import { Component, OnInit, Input } from '@angular/core';

import { AllContentTypes, ContentType, DataTypes, LinkTypes } from '@app/core/models/content-type-models/content-types.model';


@Component({
  selector: 'app-svg-assets',
  templateUrl: './svg-assets.component.html',
  styleUrls: ['./svg-assets.component.css']
})
export class SvgAssetsComponent implements OnInit {

  @Input() type: string;
  @Input() size: number;
  @Input() stroke: string = '#5D656C';

  assetTypes = DataTypes;

  constructor() { }

  ngOnInit(): void {
  }

}

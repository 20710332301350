<app-top-nav></app-top-nav>
<app-side-nav></app-side-nav>

<div class="cover" [@fade-in]>
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👋
            </div>
            <p>Welcome, let's get started</p>
        </div>
        <h1>Take a few minutes to finalize your organizations account.</h1>
        
    </div>
</div>

<div class="container-fluid form-container-fluid" [@fade-in] *ngIf="!load_completed">
    <div class="position-relative">
        <ngx-spinner 
        name="onboardingOrgDetailsSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
    </div>
</div>

<div class="container-fluid form-container-fluid" *ngIf="load_completed" [@fade-in]="load_completed">
    
    <div class="">
        <h3 class="h3">Details & Contact</h3>
    </div>
    <!-- <p class="p">Which account would you like to enter?</p> -->
    <div class="mb-2">
        <small *ngIf="error" class="validation-error">
            {{ errorMessage }}
        </small>
    </div>






    <form [formGroup]="generalOrgInfoForm" (ngSubmit)="updateOrgInfo()">
        
        <div class="card shadow">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">Organization Name</label>
                    <input class="form-control" formControlName="name" type="text" placeholder="Ex. Social Card" id="name">
                    
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                        <small *ngIf="name.errors.required" class="validation-error">A name is required.</small>
                    </div>
                </div>
        
                <div class="form-group">
                    <label for="website">Website</label>
                    <input class="form-control" formControlName="website" type="url" placeholder="Ex. www.joinsocialcard.com" id="website">
                </div>
        
                <div class="form-group">
                    <label for="industry">Industry</label>
                    <input class="form-control" formControlName="industry" type="text" placeholder="Ex. SaaS" id="industry">
                    
                    <div *ngIf="industry.invalid && (industry.dirty || industry.touched)" class="form-text text-muted">
                        <small *ngIf="industry.errors.required" class="validation-error">An industry is required.</small>
                    </div>
                </div>
        
                <div class="form-group mb-4">
                    <label for="size">Organization Size</label>
                    <select formControlName="size" class="custom-select">
                        <option value="" disabled>Select a range</option>
                        <option
                        *ngFor="let option of sizeList"
                        [value]="option.name"
                        >{{ option.name }}</option>
                    </select>                    
                    <div *ngIf="size.invalid && (size.dirty || size.touched)" class="form-text text-muted">
                        <small *ngIf="size.errors.required" class="validation-error">An org size is required.</small>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="card shadow mt-4">
            <div class="card-body">
                <div class="form-group">
                    <label for="contact_name">Contact Name</label>
                    <input class="form-control" formControlName="contact_name" type="name" placeholder="Ex. Jane Doe" id="contact_name">
                    
                    <div *ngIf="contact_name.invalid && (contact_name.dirty || contact_name.touched)" class="form-text text-muted">
                        <small *ngIf="contact_name.errors.required" class="validation-error">A contact name is required.</small>
                    </div>
                </div>
        
                <div class="form-group">
                    <label for="contact_email">Contact Email</label>
                    <input class="form-control" formControlName="contact_email" type="email" placeholder="Ex. email@example.com" id="contact_email">
                    
                    <div *ngIf="contact_email.invalid && (contact_email.dirty || contact_email.touched)" class="form-text text-muted">
                        <small *ngIf="contact_email.errors.required" class="validation-error">A contact email is required.</small>
                        <small *ngIf="contact_email.errors.pattern" class="validation-error">A valid contact email address is required.</small>
                    </div>
                </div>
        
                <div class="form-group">
                    <label for="contact_phone">Contact Phone</label>
                    <input class="form-control" formControlName="contact_phone" type="phone" placeholder="Ex. +1 (000)-000-0000" id="contact_phone">
                    
                    <div *ngIf="contact_phone.invalid && (contact_phone.dirty || contact_phone.touched)" class="form-text text-muted">
                        <small *ngIf="contact_phone.errors.required" class="validation-error">A contact phone number is required.</small>
                        <small *ngIf="contact_phone.errors.pattern" class="validation-error">A valid contact phone number is required.</small>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="mt-4 text-center">
            <button type="submit" [disabled]="generalOrgInfoForm.invalid || generalOrgInfoForm.untouched || generalOrgInfoForm.pristine" class="btn btn-primary mt-4 w-100">Save Changes</button>
        </div>
    
    </form>
</div>


<div class="container-fluid form-container-fluid" *ngIf="load_completed" [@fade-in]="load_completed">
    <h3 class="h3">Logo</h3>

    <div class="card shadow mt-2">
        <a class="stretched-link" (click)="openModal(orgLogoModal)"></a>
        <div class="card-body card-body-sm text-center">
            <img *ngIf="logoLocalUrl" loading="lazy" [src]="(logoLocalUrl | async) || 'assets/icon_clear.svg'"  class="org-logo org-logo-center">
            <p *ngIf="!logoLocalUrl">Logo not found</p>
            <div class="text-center mt-3">
                <small>Click to change</small>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid form-container-fluid" [@fade-in]>
    <div class="text-center">
        <button class="btn btn-primary w-100 mb-4" routerLink="/onboarding/team">Next</button>
        <!-- <button class="btn btn-auxiliary btn-sm" routerLink="/">Finish Later</button> -->
        <a class="" routerLink="/">Finish Later</a>
    </div>
</div>



<ngx-spinner 
name="accountDetailsSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>

</ngx-spinner>








<ng-template #orgLogoModal>
    <div class="modal-card">
        <ngx-spinner 
        name="orgLogoUpdateSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="mb-3">Logo Change</h2>
                </div>
                <label>Logo Preview</label>
                <div class="card shadow mt-2 mb-4">
                    <div class="card-body card-body-sm text-center">
                        <img *ngIf="logoLocalUrl" loading="lazy" [src]="(logoLocalUrl | async) || 'assets/icon_clear.svg'" class="org-logo org-logo-center">
                        <p *ngIf="!logoLocalUrl">Logo not found</p>
                    </div>
                </div>
                <label>Logo Upload</label>
                <app-org-logo-upload></app-org-logo-upload>

                <div class="mt-4 text-center">
                    <button class="btn btn-primary mt-4 w-100" [disabled]="!canSaveLogo" (click)="updateOrgLogo()">Save Changes</button>
                    <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                </div>
            </div>
        </div>
    </div>

</ng-template>

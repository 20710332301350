import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { SideNavService } from '@core/services/side-nav/side-nav.service';
import { AuthService } from '@core/services/auth/auth.service';
import { PermissionService } from '@core/services/permission/permission.service';
import { TenantService } from '@core/services/tenant/tenant.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, OnDestroy {

  open: boolean;

  admin: boolean = false;
  growthPlan: boolean = false;

  sideNavSub: Subscription;
  permissionsSub: Subscription;
  planSub: Subscription;

  constructor(
    public sideNavService: SideNavService,
    private authService: AuthService,
    private permissionService: PermissionService,
  ) {
    this.sideNavSub = this.sideNavService.sideNavVisibilityChange.subscribe(open => {
      this.open = open;
      if (open) {} else {
        
      }
    });

    this.permissionsSub = this.permissionService.userRole.subscribe(role => {
      this.admin = this.permissionService.validatePermission('admin');
    });

    this.planSub = this.permissionService.tenantPlan.subscribe(plan => {
        this.growthPlan = this.permissionService.validateGrowthPlan();
    });
  }

  ngOnInit(): void {
    
  }
  ngOnDestroy(): void {
    this.sideNavSub.unsubscribe();
  }



  logout() {
    this.authService.logout();
  }



  checkPermission(role: string): boolean {
    return this.permissionService.validatePermission(role);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Result } from '@app/core/models/api.model';
import { StripeInvoicesObject, StripeInvoiceItem } from '@app/core/models/stripe.model';

@Injectable({
  providedIn: 'root'
})
export class StripeInvoiceService {
  private baseUrl: string = environment.api.url;

  private currentInvoiceSubject = new BehaviorSubject<StripeInvoiceItem>(null);
  public currentInvoice = this.currentInvoiceSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private http: HttpClient,
  ) { }


  getCurrentInvoice(): Promise<Result> {
    const functionUrl = environment.api.stripe.getCurrentInvoice;
    const tenant_id = sessionStorage.getItem('t');

    return new Promise<Result>(async (resolve, reject) => {

      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log(result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    });
  }



  getAllInvoices(): Promise<Result> {
    const functionUrl = environment.api.stripe.getAllInvoices;
    const tenant_id = sessionStorage.getItem('t');

    return new Promise<Result>(async (resolve, reject) => {

      await this.http.post(`${this.baseUrl}${functionUrl}`, {
        tenant_id: tenant_id,
      }).toPromise().then(res => {
        let result = res as Result
        console.log(result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    });
  }
}

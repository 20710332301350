<app-side-nav></app-side-nav>
<app-top-nav></app-top-nav>

<app-global-alert></app-global-alert>

<div class="container-fluid" [@fade-in] *ngIf="!load_completed">
    <div class="position-relative">
        <ngx-spinner 
        name="dashboardSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
    </div>
</div>




<div class="container-fluid" *ngIf="load_completed" [@fade-in]="load_completed">
    


    <div class="row card-row" *ngIf="user.role === 'owner'">
        <div class="col-12">
            <h3 class="h3">Getting Started</h3>
        </div>
        <div class="col col-12">
            <div class="card shadow" *ngIf="tenant_onboarding">
                <div class="card-body">
                    
                    <div class="d-lg-flex d-md-flex d-sm-block justify-content-between mb-4">
                        <div class="">
                            <span class="card-label">Your Quick Start Guide</span>
                        </div>
                        <a class="" href="https://www.joinsocialcard.com/learning-center" target="_blank">Explore More
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="9 6 15 12 9 18" />
                          </svg>
                        </a>
                    </div>

                    <div class="mt-3" *ngFor="let item of tenant_onboarding | keyvalue;">
                        <div class="card onboarding-card">
                            <a class="stretched-link" [routerLink]="item.value.url"></a>
                            <div class="d-flex justify-content-between">
                                <div class="d-lg-flex d-md-flex d-sm-block">
                                    <svg *ngIf="item.value.completed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-check success" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <rect x="4" y="4" width="16" height="16" rx="2" />
                                        <path d="M9 12l2 2l4 -4" />
                                    </svg>
                                    <svg *ngIf="!item.value.completed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <rect x="4" y="4" width="16" height="16" rx="2" />
                                    </svg>
                                    <p class="ml-4">{{ item.value.name }}</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <polyline points="7 7 12 12 7 17" />
                                    <polyline points="13 7 18 12 13 17" />
                                </svg>
                            </div>
                        </div>
                        
                        <div class="current-item-border"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>














    <!-- <a  href="sms:407-455-3573;">SMS test</a>
    <a href="BEGIN:VCARD VERSION:4.0 FN:John Doe N:Doe;John;;; END:VCARD" file="vcard/vcf">VCARD</a> -->

    <div class="row card-row section-margin">
        <div class="col col-lg-7 col-12">
            <div class="card shadow">
                <div class="card-body d-flex flex-column">
                    <span class="card-label">{{ tenant.name }}</span>
                    <div *ngIf="user && user.onboarded" class="h1">
                        <h1>Welcome back, {{ user.name || user.email }}! 👋</h1>
                    </div>
                    <div *ngIf="user && !user.onboarded" class="h1">
                        <h1>Welcome, {{ user.name || user.email }}! 🎉</h1>
                    </div>

                    <p *ngIf="user && user.onboarded">Checkout what’s happening across your organization, or create a Social Card of your own.</p>
                    <p *ngIf="user && !user.onboarded">Your Social Card account is ready for use. Get started by taking a tour.</p>

                    <!-- Content for bottom of card -->
                    <div class="mt-auto">
                        <div class=""><svg width="100%" height="4"><g transform="translate(2,2)"><line y1="0" y2="0" x1="0" x2="1000" stroke="#f5f5f5" stroke-width="4px" stroke-linecap="round"></line></g></svg></div>
                        <div *ngIf="user && user.onboarded" class="bottom-link text-right">
                            <a class="" routerLink="/cards/new">New Social Card
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </a>
                        </div>

                        <div *ngIf="user && !user.onboarded" class="bottom-link text-right">
                            <a class="" [routerLink]="">Take The Tour 
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </a>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>


        <div class="col col-lg-5 col-12">
            <div class="card shadow">
                <div class="card-body d-flex flex-column">
                    <span class="card-label text-center">Your Social Cards</span>

                    <div class="card-counter-lg text-center">3</div>


                    <!-- Content for bottom of card -->
                    <div class="mt-auto">
                        <button *ngIf="true" class="btn btn-primary w-100" routerLink="/cards">Your Social Cards</button>
                        <button *ngIf="false" class="btn btn-primary w-100">New Social Cards</button>
                    </div>
                </div>
            </div>
        </div>
    </div>







    <div class="row card-row section-margin">
        <div class="col-12">
            <h3 class="h3">Analytics Overview</h3>
        </div>

        <div class="col col-lg-4 col-12">
            <div class="card shadow">
                <div class="card-body card-body-sm">
                    <div class="card-label">Total Interactions</div>
                    <h5 class="font-weight-bolder mt-3">3,000</h5>
                    <small>Last 30 days</small>
                </div>
            </div>
        </div>
        <div class="col col-lg-4 col-12">
            <div class="card shadow">
                <div class="card-body card-body-sm">
                    <div class="card-label">Total Clicks</div>
                    <h5 class="font-weight-bolder mt-3">3,000</h5>
                    <small>Last 30 days</small>
                </div>
            </div>
        </div>
        <div class="col col-lg-4 col-12">
            <div class="card shadow">
                <div class="card-body card-body-sm">
                    <div class="card-label">Total CTR</div>
                    <h5 class="font-weight-bolder mt-3">{{ 0.50 | percent }}</h5>
                    <small>Last 30 days</small>
                </div>
            </div>
        </div>
    </div>


    <div class="row card-row section-margin">
        <div class="col-12">
            <h3 class="h3">Your Departments</h3>
        </div>

        <div class="col col-lg-4 col-12">
            <div class="card shadow">
                <div class="card-body card-body-sm d-flex flex-column">
                    <img class="avatar-md shadow" src="https://source.unsplash.com/random">
                    <h5 class="font-weight-bolder  text-center mt-4">Department Name</h5>
                    <div class="card-label text-center mt-2 mb-4">12 Members</div>

                    <div class="mt-auto">
                        <div class="current-item-border"></div>
                        <div class="bottom-link text-right">
                            <a class="" routerLink="/cards/new">View
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col col-lg-4 col-12">
            <div class="card shadow">
                <div class="card-body card-body-sm d-flex flex-column">
                    <img class="avatar-md shadow" src="https://source.unsplash.com/random">
                    <h5 class="font-weight-bolder  text-center mt-4">Department Name</h5>
                    <div class="card-label text-center mt-2 mb-4">12 Members</div>

                    <div class="mt-auto">
                        <div class="current-item-border"></div>
                        <div class="bottom-link text-right">
                            <a class="" routerLink="/departments">View
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col col-lg-4 col-12">
            <div class="card shadow">
                <div class="card-body card-body-sm d-flex flex-column">
                    <img class="avatar-md shadow" src="https://source.unsplash.com/random">
                    <h5 class="font-weight-bolder  text-center mt-4">Department Name</h5>
                    <div class="card-label text-center mt-2 mb-4">12 Members</div>

                    <div class="mt-auto">
                        <div class="current-item-border"></div>
                        <div class="bottom-link text-right">
                            <a class="" routerLink="/cards/new">View
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>




    <div class="row card-row section-margin">
        <div class="col-12">
            <h3 class="h3">Updates</h3>
        </div>
        
        <div class="col col-lg-5 col-12">
            <div class="card shadow news-card">
                <div class="card-body">
                    <div class="blog-container d-flex flex-column">
                        <span class="card-label">Social Card</span>

                        <h5 class="font-weight-bolder mt-4 mb-4">Learn, Get Inspired, & Stay Informed</h5>
                        <p>Visit our blog for insights straight from the team & industry experts.</p>
    
                        <div class="mt-auto">
                            <a class="btn btn-primary w-100" href="https://www.joinsocialcard.com/blog" target="_blank">Our Blog</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="col col-lg-7 col-12">
            <div class="card shadow ">
                <div class="card-body d-flex flex-column">
                    <span class="card-label">New From Social Card</span>

                    <p>Insert list of new features or fixes</p>
                    
                </div>
            </div>
        </div>
    </div>








    <!-- https://www.npmjs.com/package/ngx-trend -->
    <ngx-trend
        autoDraw="true"
        autoDrawDuration="6000"
        autoDrawEasing="ease-out"
        smooth="true"
        [data]="sampleDataAdmin[0].values"
        [gradient]="['#01F2FE', '#6cf8ff']"
        radius="10"
        strokeWidth="1.2"
        strokeLinecap="round"
    >
    </ngx-trend>

</div>
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// Models
import { GlobalAlert } from '@core/models/global-alert.model';


@Injectable({
    providedIn: 'root'
})
export class GlobalAlertService implements OnDestroy {

    isAlertVisible: boolean;

    alertCollapse: Subject<boolean> = new Subject<boolean>();

    private alertObject: Observable<GlobalAlert>;

    private alertSubject = new BehaviorSubject<GlobalAlert>(null);
    public globalAlert = this.alertSubject.asObservable().pipe(distinctUntilChanged());

    private alertSub: Subscription;

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
    ) {
        this.alertCollapse.subscribe((value) => {
            this.isAlertVisible = value;
        });
    }
    ngOnDestroy(): void {
        this.alertSub.unsubscribe();
    }


    setAlertObject() {
        this.alertObject = this.db.object<GlobalAlert>(`global_alerts`).valueChanges();
        this.fetchGlobalAlerts();
    }

    private fetchGlobalAlerts() {
        this.alertSub = this.alertObject.subscribe(res => {
            this.alertSubject.next(res);
        });
    }



    toggleAlert() {
        this.alertCollapse.next(true);
    }
}

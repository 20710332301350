<div class="row card-row">

    <div class="col-12 mb-4" *ngFor="let card of socialCards; let cardIndex = index;">

        <div class="card shadow">
            <div class="card-body">
                <span class="card-label">Organization Name</span>

                <div class="mx-auto social-card-container p-0">
                    <h3>{{ card.name }}</h3>
                    <p class="mb-0 section-subtitle">{{ card.description }}</p>
                    <span class="badge badge-primary mb-4">Template</span>
                </div>


                <app-social-card [socialCard]="card"></app-social-card>


                <div class="social-card-container mx-auto p-0">
                    <button class="btn btn-sm btn-primary w-100" (click)="routeToCard()">View or Edit</button>
                </div>
            </div>
        </div>

    </div>

</div>

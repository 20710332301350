import { AfterViewChecked, AfterViewInit, Component, ContentChildren, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeInAnimation } from '@app/shared/animations/animations';

import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { UrlFormatterService } from '@app/core/services/content-types/url/url-formatter.service';
import { VcardService } from '@app/core/services/content-types/vcard/vcard.service';
import { TemplatingService } from '@app/core/services/templates/templating.service';
import { Template } from '@core/models/template.model';
import { AllQuickLinkSectionNames, AllQuickLinkContentTypes, ContentType, LinkTypes } from '@app/core/models/content-type-models/content-types.model';
import { VCardEncoding } from 'ngx-vcard';



@Component({
    selector: 'app-qr',
    templateUrl: './qr.component.html',
    styleUrls: ['./qr.component.css'],
    animations: [fadeInAnimation],
})
export class QrComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @ViewChild('canvas', { static: false }) canvas: ElementRef;
    public context: CanvasRenderingContext2D;

    @ViewChild("qr") private parentRef: ElementRef<HTMLElement>;

    qrChild: HTMLCanvasElement;


    templateSub: Subscription;
    currentTemplateSettings: Template;

    isGeneralCollapsed: boolean = false;
    isMediaCollapsed: boolean = true;
    isStylingCollapsed: boolean = true;

    itemContentTypeSelection: boolean = false;

    sectionNames = AllQuickLinkSectionNames;
    availableContentTypes = AllQuickLinkContentTypes;

    public vCardEncoding: typeof VCardEncoding = VCardEncoding;

    qrUrl: string;
    
    constructor(
        private templatingService: TemplatingService,
        private urlFormatterService: UrlFormatterService,
        private vcardService: VcardService,
        private snackBarService: SnackBarService,
    ) { 
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });
    }
    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
    }

    ngAfterViewChecked(): void {
    }
    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }

    
    createQrElement() {
        const parentElement = this.parentRef['qrcElement']['nativeElement'];
        console.log(parentElement)
        const canvas = parentElement.children[0];
        const firstImage = parentElement.querySelector("canvas");
        console.log(this.parentRef['qrcElement']['nativeElement']);
        console.log(canvas, firstImage);
        this.qrChild = canvas;
        this.draw();
    }

    // Draws the canvas using template data
    draw() {
        
        var ctx = this.canvas.nativeElement.getContext('2d');
        
        ctx.strokeStyle = this.currentTemplateSettings.qr.background_color;
        this.roundedRect(ctx, 0, 0, 300, 400, 6); //creates the shapes 
        
        ctx.fillStyle = this.currentTemplateSettings.qr.background_color; //sets fill/background color
        
        ctx.fill();

        ctx.drawImage(this.qrChild, 100, 50, 100, 100); //Draws the QR into the view

        var img = new Image();
        img.onload = function() {
            ctx.drawImage(img, 75, 275, 150, 100);
        };
        img.src = 'https://source.unsplash.com/random';//'https://mdn.mozillademos.org/files/5395/backdrop.png';
    }





    


    


    // STYLING
    backgroundColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.qr.background_color = event;
        // then, sends update to global service
        this.createQrElement();
        this.updateTemplate();
    }


    // PAGE STATE
    togglingGeneral() {
        this.isGeneralCollapsed = !this.isGeneralCollapsed;

        this.isMediaCollapsed = true;
        this.isStylingCollapsed = true;

        this.createQrElement();
    }  
    togglingMedia() {
        this.isMediaCollapsed = !this.isMediaCollapsed;
        
        this.isGeneralCollapsed = true;
        this.isStylingCollapsed = true;

        this.createQrElement();
    }
    togglingStyling() {
        this.isStylingCollapsed = !this.isStylingCollapsed;

        this.isGeneralCollapsed = true;
        this.isMediaCollapsed = true;

        this.createQrElement();
    }


    // GENERAL STATES
    allowQrEditing(val) {
        this.currentTemplateSettings.qr.can_edit_qr = val;
        this.createQrElement();
        this.updateTemplate();
    }

    analtyicsEnabled(val) {
        this.currentTemplateSettings.qr.qr_analytics_enabled = val;
        this.createQrElement();
        this.updateTemplate();
    }


    // CONTENT 
    editItemContentType() {
        this.itemContentTypeSelection = !this.itemContentTypeSelection;
    }

    qrContentTypeChange(type: ContentType) {
        console.log(type);
        this.currentTemplateSettings.qr.qr_type = type;
        this.itemContentTypeSelection = !this.itemContentTypeSelection;
        this.createQrElement();
        this.updateTemplate();
    }




    getVcard(): string {
        console.log(this.vcardService.generateVcardString(this.currentTemplateSettings.qr.qr_data));
        return this.vcardService.generateVcardString(this.currentTemplateSettings.qr.qr_data);
    }

    // LINK TESTING
    // If link is testable then route to the items url, else show snackbar message
    async getFormattedLink() {
        const templateQrType = this.currentTemplateSettings.qr.qr_type;
        const templateQrData = this.currentTemplateSettings.qr.qr_data;

        const nonTestableLinkTypes = [LinkTypes.vcard, LinkTypes.message];
  
        if (nonTestableLinkTypes.some(type => type === templateQrType.ref)) {
            this.showNonTestableAlert(templateQrType.ref);
        } else if (templateQrType.ref === 'sms') {
            this.qrUrl = `SMSTO:${templateQrData.phone}`;
        } else if (templateQrType.ref === 'email') {
            this.qrUrl = `mailto:${templateQrData.email}`;
        } else if (templateQrType.ref === 'phone') {
            this.qrUrl = `tel:${templateQrData.phone}`;
        } else {
            if (templateQrData != null) {
                var url: string;
                await this.urlFormatterService.formatUrl(templateQrData.url, 
                    templateQrType.ref, templateQrType.type.source_type).then(res => {
                    url = res;
                });
                console.log(url);
                this.qrUrl = url;
            } else {
                this.snackBarService.warning('No URL is available. update the quick link, and try again');
            }
        }
    }


    showNonTestableAlert(type: string) {
        switch (type) {
            case LinkTypes.vcard: {
                this.snackBarService.warning('Testing the Contact type is currently unavailable');
                break;
            }
            case LinkTypes.sms: {
                this.snackBarService.warning('Testing the Text type is currently unavailable');
                break;
            }
            case LinkTypes.email: {
                this.snackBarService.warning('Testing the Email type is currently unavailable');
                break;
            }
            case LinkTypes.phone: {
                this.snackBarService.warning('Testing the Phone type is currently unavailable');
                break;
            }
        }
    }



    // A utility function to draw a rectangle with rounded corners.
    roundedRect(ctx, x, y, width, height, radius) {
        ctx.beginPath();
        ctx.moveTo(x, y + radius);
        ctx.lineTo(x, y + height - radius);
        ctx.arcTo(x, y + height, x + radius, y + height, radius);
        ctx.lineTo(x + width - radius, y + height);
        ctx.arcTo(x + width, y + height, x + width, y + height-radius, radius);
        ctx.lineTo(x + width, y + radius);
        ctx.arcTo(x + width, y, x + width - radius, y, radius);
        ctx.lineTo(x + radius, y);
        ctx.arcTo(x, y, x, y + radius, radius);
        
        ctx.stroke();
    }

}

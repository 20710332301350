import { Component, OnInit } from '@angular/core';

import { fadeInAnimation } from '@app/shared/animations/animations';
import { NgxSpinnerService } from 'ngx-spinner';

import { TemplatingStateService } from '@core/services/templates/templating-state.service';
import { Template, TemplatingState } from '@core/models/template.model';
import { Subscription } from 'rxjs';
import { TemplatingService } from '@app/core/services/templates/templating.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-templating',
    templateUrl: './templating.component.html',
    styleUrls: ['./templating.component.css'],
    animations: [fadeInAnimation],
})
export class TemplatingComponent implements OnInit {

    load_completed: boolean = false;

    templateState: TemplatingState;
    templateStates = TemplatingState;

    templateSub: Subscription;
    currentTemplateSettings: Template;

    constructor(
        private templatingStateService: TemplatingStateService,
        private templatingService: TemplatingService,
        private spinner: NgxSpinnerService,
        private router: Router,
    ) {
        this.templatingStateService.templateState.subscribe(state => {
            console.log(state);
            this.templateState = state;
        })

        // Subs to template service and monitors changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            if (template === null) {
                this.router.navigate(['/admin']);
            } else {
            }
        });
    }

    ngOnInit(): void {
        this.spinner.show('templateSpinner');

        setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.load_completed = true;
            this.spinner.hide('templateSpinner');
        }, 2000);
    }

}

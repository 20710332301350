import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { fadeInAnimation } from '@app/shared/animations/animations';

import { AllQuickLinkContentTypes, AllQuickLinkSectionNames, ContentType, DataTypes, LinkTypes } from '@app/core/models/content-type-models/content-types.model';
import { Template } from '@app/core/models/template.model';
import { TemplatingService } from '@app/core/services/templates/templating.service';

import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { UrlFormatterService } from '@app/core/services/content-types/url/url-formatter.service';
import { VcardService } from '@app/core/services/content-types/vcard/vcard.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-apple-wallet-back',
    templateUrl: './apple-wallet-back.component.html',
    styleUrls: ['./apple-wallet-back.component.css'],
    animations: [fadeInAnimation],
})
export class AppleWalletBackComponent implements OnInit, OnDestroy {

    templateSub: Subscription;
    currentTemplateSettings: Template;

    overview: boolean;
    fieldView: boolean;

    selectedFieldIndex: number;


    editFieldType: boolean;

    regexp = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i;
    urlForm = new FormGroup({
        urlLabel: new FormControl('', [Validators.required]),
        urlDataLabel: new FormControl('', [Validators.required]),
        urlData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    urlFormSub: Subscription;

    get urlLabel() { return this.urlForm.get('urlLabel'); }
    get urlDataLabel() { return this.urlForm.get('urlDataLabel'); }
    get urlData() { return this.urlForm.get('urlData'); }

    messageForm = new FormGroup({
        messageLabel: new FormControl('', [Validators.required]),
        messageData: new FormControl('', [Validators.required]),
    });
    messageFormSub: Subscription;

    get messageLabel() { return this.messageForm.get('messageLabel'); }
    get messageData() { return this.messageForm.get('messageData'); }

    emailForm = new FormGroup({
        emailLabel: new FormControl('', [Validators.required]),
        emailData: new FormControl('', [Validators.required, Validators.email]),
    });
    emailFormSub: Subscription;

    get emailLabel() { return this.emailForm.get('emailLabel'); }
    get emailData() { return this.emailForm.get('emailData'); }

    phoneForm = new FormGroup({
        phoneLabel: new FormControl('', [Validators.required]),
        phoneData: new FormControl('', [Validators.required]),
    });
    phoneFormSub: Subscription;

    get phoneLabel() { return this.phoneForm.get('phoneLabel'); }
    get phoneData() { return this.phoneForm.get('phoneData'); }





    modalRef: BsModalRef;

    constructor(
        private templatingService: TemplatingService,
        private urlFormatterService: UrlFormatterService,
        private vcardService: VcardService,
        private snackBarService: SnackBarService,
        private modalService: BsModalService,
    ) {
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });
    }

    ngOnInit(): void {
        this.overview = true;
    }

    ngOnDestroy(): void {
        this.templateSub.unsubscribe();

        this.urlFormSub.unsubscribe();
        this.messageFormSub.unsubscribe();
        this.emailFormSub.unsubscribe();
        this.phoneFormSub.unsubscribe();
    }

    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }

    onChanges(): void {
        this.urlFormSub = this.urlForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label = val.urlLabel;
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value = val.urlDataLabel;
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].attributed_value = val.urlData;
    
            this.updateTemplate();
        });

        this.messageFormSub = this.messageForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label = val.messageLabel;
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value = val.messageData;

            this.updateTemplate();
        });

        this.emailFormSub = this.emailForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label = val.emailLabel;
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value = val.emailData;

            this.updateTemplate();
        });

        this.phoneFormSub = this.phoneForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label = val.phoneLabel;
            this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value = val.phoneData;

            this.updateTemplate();
        });
    }

    setupValuesFromTemplate() {
        switch(this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type.type.data_type) {
            case ('url'): {
                this.urlForm.patchValue({
                    urlLabel: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label,
                    urlDataLabel: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value,
                    urlData: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].attributed_value,
                });
                break;
            }
            case ('message'): {
                this.messageForm.patchValue({
                    messageLabel: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label,
                    messageData: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value,
                });
                break;
            }
            case ('email'): {
                this.emailForm.patchValue({
                    emailLabel: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label,
                    emailData: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value,
                });
                break;
            }
            case ('phone'): {
                this.phoneForm.patchValue({
                    phoneLabel: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].label,
                    phoneData: this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].value,
                });
                break;
            }
        }
    }

    // OVERVIEW FUNCTIONS

    // Adds new backfield
    addNewField() {
        // Add item to array of backfields
        this.currentTemplateSettings.apple.backFields.push({
            attributed_value: '',
            key: '',
            label: 'Test Label',
            value: 'This is a test label',

            type: { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } },
            can_edit_back_field: true,
            analytics_enabled: true,
        });

        this.selectedFieldIndex = this.currentTemplateSettings.apple.backFields.length - 1;

        this.overview = false;
        this.fieldView = true;

        this.setupValuesFromTemplate();
        this.onChanges();
    }

    toggleFieldEditing(event) {
        this.currentTemplateSettings.apple.can_add_backfields = event;
        this.updateTemplate();
    }
    toggleDefaultPosition(event) {
        this.currentTemplateSettings.apple.backfield_default_location = event;
        this.updateTemplate();
    }

    selectField(index: number) {
        this.selectedFieldIndex = index;

        console.log(index);

        this.overview = false;
        this.fieldView = true;

        this.setupValuesFromTemplate();
        this.onChanges();
    }

    // FIELD FUNCTIONS

    backToOverview() {
        this.overview = true;
        this.fieldView = false;
    }

    removeField() {
        this.currentTemplateSettings.apple.backFields.splice(this.selectedFieldIndex, 1);
        this.modalRef.hide();
        this.backToOverview();
        
    }

    toggleSelectedFieldEditing(event) {
        this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].can_edit_back_field = event;
        this.updateTemplate();
    }
    toggleSelectedFieldAnalytics(event) {
        this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].analytics_enabled = event;
        this.updateTemplate();
    }



    editFieldContentType() {
        this.editFieldType = !this.editFieldType;
    }

    changeType(activeType: string) {
        switch(activeType) {
            case ('url'): {
                this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type = { name: 'Website', ref: 'url', type: { source_type: LinkTypes.url, data_type: DataTypes.url } };
                break;
            }
            case ('message'): {
                this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type = { name: 'Message', ref: 'message', type: { source_type: LinkTypes.message, data_type: DataTypes.message } };
                break;
            }
            case ('email'): {
                this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type = { name: 'Email', ref: 'email', type: { source_type: LinkTypes.email, data_type: DataTypes.email } };
                break;
            }
            case ('phone'): {
                this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type = { name: 'Phone', ref: 'phone', type: { source_type: LinkTypes.phone, data_type: DataTypes.phone } };
                break;
            }
            default: {
                this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type = { name: 'Message', ref: 'message', type: { source_type: LinkTypes.message, data_type: DataTypes.message } };
                break;
            }
        }
        console.log(this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].type);

        this.editFieldContentType();
    }



    testUrl() {
        window.open(this.currentTemplateSettings.apple.backFields[this.selectedFieldIndex].attributed_value, "_blank");
    }





    openModal(template: TemplateRef<any>) {
        var config = {
          animated: false,
          ignoreBackdropClick: true,
        };
  
        this.modalRef = this.modalService.show(template, config);
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { fadeInAnimation } from '@app/shared/animations/animations';
import { NgxSpinnerService } from 'ngx-spinner';

import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { TemplatingService } from '@core/services/templates/templating.service';
import { Template } from '@app/core/models/template.model';
import { PermissionService } from '@app/core/services/permission/permission.service';
import { OnboardingService } from '@app/core/services/onboarding/onboarding.service';

@Component({
    selector: 'app-onboarding-template',
    templateUrl: './onboarding-template.component.html',
    styleUrls: ['./onboarding-template.component.css'],
    animations: [fadeInAnimation],
})
export class OnboardingTemplateComponent implements OnInit, OnDestroy {

    load_completed: boolean = true;

    templateSub: Subscription;
    currentTemplateSettings: Template;
    allTemplatesSub: Subscription;
    allTemplatesCount: number;

    planSub: Subscription;
    growthPlan: boolean = false;


    isLight: boolean = true;

    newTemplateForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.maxLength(255)]),
    });
    newTemplateFormSub: Subscription;
    get name() { return this.newTemplateForm.get('name'); }
    get description() { return this.newTemplateForm.get('description'); }

    constructor(
        private onboardingService: OnboardingService,
        private templatingService: TemplatingService,
        private permissionService: PermissionService,
        private spinner: NgxSpinnerService,
        private snackBarService: SnackBarService,
        private router: Router
    ) { 
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });

        this.allTemplatesSub = this.templatingService.tenantTemplates.subscribe(templates => {
            if (templates != null) {
                this.allTemplatesCount = Object.keys(templates).length;
            }
        });

        this.planSub = this.permissionService.tenantPlan.subscribe(plan => {
            this.growthPlan = this.permissionService.validateGrowthPlan();
        });
    }

    ngOnInit(): void {
        this.newTemplateFormSub = this.newTemplateForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.name = val.name;
            this.currentTemplateSettings.description = val.description;

            this.updateTemplate();
        });

        this.templatingService.setTheme(true);
    }
    ngOnDestroy() {
        this.newTemplateFormSub.unsubscribe();
        this.allTemplatesSub.unsubscribe();
        this.planSub.unsubscribe();
    }

    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }


    changeTheme(event: boolean) {
        this.isLight = event
        this.templatingService.setTheme(this.isLight);
    }


    // Triggers creation of template object in database
    async createTemplate() {
        this.load_completed = false;
        this.spinner.show('newTemplateSpinner');
        this.currentTemplateSettings.last_updated = Date.now().toString();

        this.updateTemplate();

        await this.templatingService.createTemplate().then(res => {

            this.updateItemCompletion()
            // this.load_completed = true;
            // this.routeToEditor();
        }).catch(err => {
            this.snackBarService.error('Failed to created template, try again');
            this.spinner.hide('newTemplateSpinner');
            this.load_completed = false;
        });
    }

    routeToEditor() {
        this.router.navigate(['/templates/editor']);
    }


    // Updates the DB to match onboarding item
    async updateItemCompletion() {
        await this.onboardingService.setOnboardingItemAsCompleted(4).then(_ => {
            // Show success notif & stop animations
            this.load_completed = true;
            this.spinner.hide('newTemplateSpinner');
            this.snackBarService.success('Successfully created template');
            this.routeToEditor();
        }).catch(err => {
            this.spinner.hide('newTemplateSpinner');
            // handle err
            console.error(err);
            this.snackBarService.error('An internal error occured');
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Reactive Forms
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { NgxSpinnerModule } from "ngx-spinner";
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

// Routing
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { OrganizationSelectionComponent } from './pages/organization-selection/organization-selection.component';
import { OrganizationSetupComponent } from './pages/organization-setup/organization-setup.component';
import { NewOrgFormComponent } from './components/new-org-form/new-org-form.component';
import { PlanSelectionComponent } from './components/plan-selection/plan-selection.component';
import { StripeCheckoutComponent } from './components/stripe-checkout/stripe-checkout.component';


import { AccountSetupComponent } from './pages/account-setup/account-setup.component';
import { AccountLinkComponent } from './components/account-link/account-link.component';
import { AccountWelcomeComponent } from './components/account-welcome/account-welcome.component';


@NgModule({
  declarations: [
    LoginComponent, 
    OrganizationSelectionComponent, 
    OrganizationSetupComponent, 
    NewOrgFormComponent, 
    PlanSelectionComponent, 
    StripeCheckoutComponent, 
    AccountSetupComponent, 
    AccountLinkComponent, 
    AccountWelcomeComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    
    NgxSpinnerModule,
    ProgressbarModule,
    TooltipModule,
    ModalModule
  ]
})
export class AuthenticationModule { }
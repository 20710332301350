import { Component, OnInit } from '@angular/core';



// ANIMATIONS
import { fadeInAnimation } from '@app/shared/animations/animations';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.css'],
  animations: [
    fadeInAnimation,
  ]
})
export class CardViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isQuickLinkOneCollapsed)?'section-text-light':''">
            Quick Link Left
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingQuickLinkOne()" [ngClass]="(!isQuickLinkOneCollapsed)?'active':''"
        [attr.aria-expanded]="!isQuickLinkOneCollapsed" aria-controls="isQuickLinkOneCollapsed">
            <svg *ngIf="isQuickLinkOneCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isQuickLinkOneCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isQuickLinkOneCollapsed" [collapse]="isQuickLinkOneCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Content Control
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.quick_link_one_content_control"  [(ngModel)]="currentTemplateSettings.web.quick_link_one_content_control" (ngModelChange)="toggleQuickLinkContentControlOne($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.quick_link_one_content_control" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.quick_link_one_content_control" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, this quick link is NOT editable by your team.</small>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Analytics Enabled
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.quick_link_one_analytics_enabled"  [(ngModel)]="currentTemplateSettings.web.quick_link_one_analytics_enabled" (ngModelChange)="togglingQuickLinkOneAnalytics($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.quick_link_one_analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.quick_link_one_analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, interaction with this quick link will be monitored by Social Card Analytics.</small>
                </div>
            </div>
        </div>
    </div>




    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isQuickLinkTwoCollapsed)?'section-text-light':''">
            Quick Link Middle
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingQuickLinkTwo()" [ngClass]="(!isQuickLinkTwoCollapsed)?'active':''"
        [attr.aria-expanded]="!isQuickLinkTwoCollapsed" aria-controls="isQuickLinkTwoCollapsed">
            <svg *ngIf="isQuickLinkTwoCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isQuickLinkTwoCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isQuickLinkTwoCollapsed" [collapse]="isQuickLinkTwoCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Content Control
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.quick_link_two_content_control"  [(ngModel)]="currentTemplateSettings.web.quick_link_two_content_control" (ngModelChange)="toggleQuickLinkContentControlTwo($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.quick_link_two_content_control" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.quick_link_two_content_control" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, this quick link is NOT editable by your team.</small>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Analytics Enabled
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.quick_link_two_analytics_enabled"  [(ngModel)]="currentTemplateSettings.web.quick_link_two_analytics_enabled" (ngModelChange)="togglingQuickLinkTwoAnalytics($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.quick_link_two_analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.quick_link_two_analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, interaction with this quick link will be monitored by Social Card Analytics.</small>
                </div>
            </div>
        </div>
    </div>



    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isQuickLinkThreeCollapsed)?'section-text-light':''">
            Quick Link Right
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingQuickLinkThree()" [ngClass]="(!isQuickLinkThreeCollapsed)?'active':''"
        [attr.aria-expanded]="!isQuickLinkThreeCollapsed" aria-controls="isQuickLinkThreeCollapsed">
            <svg *ngIf="isQuickLinkThreeCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isQuickLinkThreeCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isQuickLinkThreeCollapsed" [collapse]="isQuickLinkThreeCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Content Control
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.quick_link_three_content_control"  [(ngModel)]="currentTemplateSettings.web.quick_link_three_content_control" (ngModelChange)="toggleQuickLinkContentControlThree($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.quick_link_three_content_control" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.quick_link_three_content_control" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, this quick link is NOT editable by your team.</small>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Analytics Enabled
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.quick_link_three_analytics_enabled"  [(ngModel)]="currentTemplateSettings.web.quick_link_three_analytics_enabled" (ngModelChange)="togglingQuickLinkThreeAnalytics($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.quick_link_three_analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.quick_link_three_analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, interaction with this quick link will be monitored by Social Card Analytics.</small>
                </div>
            </div>
        </div>
    </div>

</div>
















































<div id="preview" class="h-100">

    <div class="h-100 preview-bg" [@fade-in]>
        <div class="preview-container">

           
            <!-- QUICK LINKS -->
            <div class="card shadow" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Quick Links</span>
                    </div>
                    <div class="web-content-container">


                        <div class="d-flex web-quick-link-container justify-content-between">
                            <div class="web-quick-link" 
                            *ngIf="currentTemplateSettings.web.quick_link_one_content_type.type.data_type != 'vcard'"
                            (click)="routeToLink(0)"
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[0] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[0] = true"
                            (mouseout)="quickLinksHovered[0]= false">
  
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_one_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>
                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_one_content_label || currentTemplateSettings.web.quick_link_one_content_type.name }}</div>
                                </div>
                                
                            </div> 
                            <div class="web-quick-link" 
                            *ngIf="currentTemplateSettings.web.quick_link_one_content_type.type.data_type === 'vcard'"
                            id="downloadButton" [vcdDownloadVCard]="getVcard(0)" [encoding]="vCardEncoding.none"
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[0] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[0] = true"
                            (mouseout)="quickLinksHovered[0]= false">
  
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_one_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>
                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_one_content_label || currentTemplateSettings.web.quick_link_one_content_type.name }}</div>
                                </div>
                                
                            </div> 





                            <div class="web-quick-link"
                            *ngIf="currentTemplateSettings.web.quick_link_two_content_type.type.data_type != 'vcard'" 
                            (click)="routeToLink(1)"
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[1] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[1] = true"
                            (mouseout)="quickLinksHovered[1]= false">
                            
                            <div class="web-quick-link-child">
                                <app-svg-assets [type]="currentTemplateSettings.web.quick_link_two_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>
                                <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_two_content_label || currentTemplateSettings.web.quick_link_two_content_type.name }}</div>
                            </div>
                                
                            </div>  

                            <div class="web-quick-link" 
                            *ngIf="currentTemplateSettings.web.quick_link_two_content_type.type.data_type === 'vcard'"
                            id="downloadButton" [vcdDownloadVCard]="getVcard(1)" [encoding]="vCardEncoding.none"
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[0] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[0] = true"
                            (mouseout)="quickLinksHovered[0]= false">
  
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_one_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>
                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_one_content_label || currentTemplateSettings.web.quick_link_one_content_type.name }}</div>
                                </div>
                                
                            </div>



                            <div class="web-quick-link"
                            *ngIf="currentTemplateSettings.web.quick_link_three_content_type.type.data_type != 'vcard'"
                            (click)="routeToLink(2)"
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[2] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[2] = true"
                            (mouseout)="quickLinksHovered[2]= false">
                                
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_three_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>
                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_three_content_label || currentTemplateSettings.web.quick_link_three_content_type.name }}</div>
                                </div>
                            </div>  

                            <div class="web-quick-link" 
                            *ngIf="currentTemplateSettings.web.quick_link_three_content_type.type.data_type === 'vcard'"
                            id="downloadButton" [vcdDownloadVCard]="getVcard(2)" [encoding]="vCardEncoding.none"
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[0] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[0] = true"
                            (mouseout)="quickLinksHovered[0]= false">
  
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_one_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>
                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_one_content_label || currentTemplateSettings.web.quick_link_one_content_type.name }}</div>
                                </div>
                                
                            </div>

                        </div>
                        <div class="mt-4">
                            <small>NOTE: Click to test a quick link.</small>
                        </div>
                    </div>
                </div>
            </div>





















            <!-- Quick Link One Content Setup -->
            <div class="card shadow mt-4" *ngIf="!isQuickLinkOneCollapsed" [@fade-in]>
                <div class="card-body">
             
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content type</span>
                            <p>Select the type of content you want displayed in this quick link.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>

                        <div class="" *ngIf="quickLinkOneContentTypeSelection">
                            <div class="mb-4" *ngFor="let section of availableContentTypes; let i = index;">
                                <h4 class="mb-3">{{ sectionNames[i] }}</h4>
                                <div class="row mx-auto">
                                    <div class="content-type-container" [tooltip]="option.name" *ngFor="let option of section;" (click)="quickLinkContentTypeChange(option)">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='option.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ option.name }}</div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
                        <div class="" *ngIf="quickLinkOneContentEntry">
                            <div class="">
    
                                <div class="d-flex justify-content-center">
                                    <div class="content-type-container" (click)="changeQuickLinkOneContentType()">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='this.currentTemplateSettings.web.quick_link_one_content_type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ this.currentTemplateSettings.web.quick_link_one_content_type.name }}</div>
                                    </div>
                                </div>
                                <div class="text-center mb-4">
                                    <a [routerLink]="" (click)="changeQuickLinkOneContentType()">Change</a>
                                </div>
    
    
                                <app-content-type-input [type]="currentTemplateSettings.web.quick_link_one_content_type.ref" source="quick_link_one"></app-content-type-input>
    
                            </div>
                        </div>    
                    </div>
                </div>
            </div>





            <!-- Quick Link Two Content Setup -->
            <div class="card shadow mt-4" *ngIf="!isQuickLinkTwoCollapsed" [@fade-in]>
                <div class="card-body">
             
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content type</span>
                            <p>Select the type of content you want displayed in this quick link.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>

                        <div class="" *ngIf="quickLinkTwoContentTypeSelection">
                            <div class="mb-4" *ngFor="let section of availableContentTypes; let i = index;">
                                <h4 class="mb-3">{{ sectionNames[i] }}</h4>
                                <div class="row mx-auto">
                                    <div class="content-type-container" [tooltip]="option.name" *ngFor="let option of section;" (click)="quickLinkTwoContentTypeChange(option)">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='option.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ option.name }}</div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
                        <div class="" *ngIf="quickLinkTwoContentEntry">
                            <div class="">
    
                                <div class="d-flex justify-content-center">
                                    <div class="content-type-container" (click)="changeQuickLinkTwoContentType()">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='this.currentTemplateSettings.web.quick_link_two_content_type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ this.currentTemplateSettings.web.quick_link_two_content_type.name }}</div>
                                    </div>
                                </div>
                                <div class="text-center mb-4">
                                    <a [routerLink]="" (click)="changeQuickLinkTwoContentType()">Change</a>
                                </div>
    
    
                                <app-content-type-input [type]="currentTemplateSettings.web.quick_link_two_content_type.ref" source="quick_link_two"></app-content-type-input>
    
                            </div>
                        </div>    
                    </div>
                </div>
            </div>





            <!-- Quick Link Two Content Setup -->
            <div class="card shadow mt-4" *ngIf="!isQuickLinkThreeCollapsed" [@fade-in]>
                <div class="card-body">
             
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content type</span>
                            <p>Select the type of content you want displayed in this quick link.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>

                        <div class="" *ngIf="quickLinkThreeContentTypeSelection">
                            <div class="mb-4" *ngFor="let section of availableContentTypes; let i = index;">
                                <h4 class="mb-3">{{ sectionNames[i] }}</h4>
                                <div class="row mx-auto">
                                    <div class="content-type-container" [tooltip]="option.name" *ngFor="let option of section;" (click)="quickLinkThreeContentTypeChange(option)">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='option.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ option.name }}</div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
                        <div class="" *ngIf="quickLinkThreeContentEntry">
                            <div class="">
    
                                <div class="d-flex justify-content-center">
                                    <div class="content-type-container" (click)="changeQuickLinkThreeContentType()">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='this.currentTemplateSettings.web.quick_link_three_content_type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ this.currentTemplateSettings.web.quick_link_three_content_type.name }}</div>
                                    </div>
                                </div>
                                <div class="text-center mb-4">
                                    <a [routerLink]="" (click)="changeQuickLinkThreeContentType()">Change</a>
                                </div>
    
    
                                <app-content-type-input [type]="currentTemplateSettings.web.quick_link_three_content_type.ref" source="quick_link_three"></app-content-type-input>
    
                            </div>
                        </div>    
                    </div>
                </div>
            </div>


            <pre>{{ currentTemplateSettings | json }}</pre>

        </div>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialCard } from '@app/core/models/socialCard.model';

@Component({
  selector: 'app-home-overview',
  templateUrl: './home-overview.component.html',
  styleUrls: ['./home-overview.component.css']
})
export class HomeOverviewComponent implements OnInit {

  @Input('socialCards') socialCards: [SocialCard];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }


  routeToCard() {
    this.router.navigate(['/card']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ROUTING
import { AdminRoutingModule } from './admin-routing.module';

//Reference Modules
import { CoreModule } from '@core/core.module';


//Reactive Forms
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// COMPONENTS
import { AdminComponent } from './pages/admin/admin.component';

// NGX BOOTSRAP
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// Services
import { NgxSpinnerModule } from "ngx-spinner";
import { ColorPickerModule } from 'ngx-color-picker';

// COmponents
import { AdminTemplateComponent } from './components/admin-template/admin-template.component';
import { AdminNewTemplateComponent } from './pages/admin-new-template/admin-new-template.component';
import { AdminTemplatePreviewComponent } from './pages/admin-template-preview/admin-template-preview.component';
import { TemplatingLeftNavComponent } from './components/navs/templating-left-nav/templating-left-nav.component';
import { AdminGeneralComponent } from './components/admin-general/admin-general.component';
import { AdminTeamComponent } from './components/admin-team/admin-team.component';
import { AdminPendingInvitesComponent } from './components/admin-pending-invites/admin-pending-invites.component';
import { AdminBillingComponent } from './components/admin-billing/admin-billing.component';
import { AdminInviteComponent } from './components/admin-invite/admin-invite.component';
import { AdminCardChangeComponent } from './components/admin-card-change/admin-card-change.component';
import { AdminSubscriptionChangeComponent } from './pages/admin-subscription-change/admin-subscription-change.component';



@NgModule({
  declarations: [AdminComponent, AdminTemplateComponent, AdminNewTemplateComponent, AdminTemplatePreviewComponent, TemplatingLeftNavComponent, AdminGeneralComponent, AdminTeamComponent, AdminPendingInvitesComponent, AdminBillingComponent, AdminInviteComponent, AdminCardChangeComponent, AdminSubscriptionChangeComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    TabsModule,
    TooltipModule,
    SortableModule,
    NgxSpinnerModule,
    ColorPickerModule,
    CollapseModule,
    TypeaheadModule,
  ]
})
export class AdminModule { }

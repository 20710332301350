import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { TemplatingState } from '@core/models/template.model';

@Injectable({
  providedIn: 'root'
})
export class TemplatingStateService {


  private templateStateSubject = new BehaviorSubject<TemplatingState>(TemplatingState.General);
  public templateState = this.templateStateSubject.asObservable().pipe(distinctUntilChanged());

  constructor() { }


  updateState(state: TemplatingState) {
    this.templateStateSubject.next(state);
  }
}



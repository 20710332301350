import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-view-overview',
  templateUrl: './card-view-overview.component.html',
  styleUrls: ['./card-view-overview.component.css']
})
export class CardViewOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

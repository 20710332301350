import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContentTestingService {

    constructor() { }

    // Sends/Test an SMS/Text content type
    // Triggers the user device to send a text message to provided number
    sendSms(number: string) {
        window.location.href = `sms:${number}`;
    }

    // Sends/Test an email content type
    // Triggers the user device to send an email to the provided email address
    sendEmail(email: string) {
        window.location.href = `mailto:${email}`;
    }
    
    // Sends/Test a phone call content type
    // Triggers the user device to start a call with the provided number
    callNumber(number: string) {
        window.location.href = `tel:${number}`;
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';

import { ProfileNavService } from '@core/services/profile-nav/profile-nav.service';
import { AuthService } from '@core/services/auth/auth.service';

import { UserService } from '@app/core/services/user/user.service';
import { User } from '@app/core/models/user.model';
import { TenantService } from '@app/core/services/tenant/tenant.service';
import { Tenant } from '@app/core/models/tenant.model';
import { fadeInAnimation } from '@app/shared/animations/animations';

@Component({
  selector: 'app-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.css'],
  animations: [fadeInAnimation],
})
export class ProfileNavComponent implements OnInit, OnDestroy {

  profileNavSub: Subscription;
  open: boolean;


  userSub: Subscription;
  user: User;

  tenantSub: Subscription;
  tenant: Tenant;

  constructor(
    private profileNavService: ProfileNavService,
    private authService: AuthService,
    private userService: UserService,
    private tenantService: TenantService,
    private spinner: NgxSpinnerService,
  ) {
    this.profileNavSub = this.profileNavService.profileNavVisibilityChange.subscribe(open => {
      this.open = open;
      if (open) {} else {  
      }
    });

    this.userSub = this.userService.currentUser.subscribe(user => {
      this.user = user;
      this.spinner.hide('profileNavSpinner');
    });

    this.tenantSub = this.tenantService.activeTenant.subscribe(tenant => {
      this.tenant = tenant;
    })

  }

  ngOnInit(): void {
    this.spinner.show('profileNavSpinner');
  }
  ngOnDestroy(): void {
    this.profileNavSub.unsubscribe();
    this.userSub.unsubscribe();
    this.tenantSub.unsubscribe();

  }

  toggleNav() {
    this.profileNavService.toggleNav();
  }

  logOut() {
    this.authService.logout();
  }
}

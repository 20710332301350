<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text">
            Apple Wallet Back
        </div>
    </div>

    <div id="" *ngIf="overview">
        <div class="control-item">
            <div class="current-item">
                <p>Fields make up the back of your Apple Wallet Social Cards. This is where your team can share infromation that is not seen on the front.</p>
                <button class="btn btn-primary w-100 mt-2 mb-2" (click)="addNewField()">Add New Field</button>
            </div>
            <div class="current-item-border mt-"></div>
        </div>
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Allow Field Editing
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.can_add_backfields"  [(ngModel)]="currentTemplateSettings.apple.can_add_backfields" (ngModelChange)="toggleFieldEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.can_add_backfields" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.can_add_backfields" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to add fields to the back of their cards.</small>
            </div>
            <div class="current-item-border"></div>
        </div>

        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Default Field Position
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.backfield_default_location"  [(ngModel)]="currentTemplateSettings.apple.backfield_default_location" (ngModelChange)="toggleDefaultPosition($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.backfield_default_location" class="slider-text ml-4 mb-0">TOP</p>
                    <p *ngIf="!currentTemplateSettings.apple.backfield_default_location" class="slider-text ml-4 mb-0">BOTTOM</p>
                </div>
                
                <small>When “TOP”, the deafult fields provided in this template will be locked at the top of all other fields provided by team members.</small>
            </div>
        </div>
    </div>




    <div *ngIf="fieldView">
        <div class="control-item">
            <div class="current-item">
                <button class="btn btn-primary w-100 mb-2" (click)="backToOverview()">Back To Overview</button>
                <button class="btn btn-sm btn-error w-100 mb-2" (click)="openModal(removeFieldModal)">Remove Field</button>
            </div>
            <div class="current-item-border mt-"></div>
        </div>
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Can Edit Field
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field"  [(ngModel)]="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field" (ngModelChange)="toggleSelectedFieldEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to edit this field.</small>
            </div>
            <div class="current-item-border"></div>
        </div>

        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Field Analytics Enabled
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.backFields[selectedFieldIndex].analytics_enabled"  [(ngModel)]="currentTemplateSettings.apple.backFields[selectedFieldIndex].analytics_enabled" (ngModelChange)="toggleSelectedFieldAnalytics($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, interaction with this field will be monitored by Social Card Analytics.</small>
            </div>
        </div>
    </div>
</div>







<div id="preview" class="h-100">
    <div class="h-100 preview-bg">
        <div class="preview-container" *ngIf="overview">
            <div class="card shadow" [@fade-in] tooltip="Drag to reorder fields">
                <div class="card-body">
                    <div class="">
                        <bs-sortable
                          [(ngModel)]="currentTemplateSettings.apple.backFields"
                          [itemTemplate]="itemTemplateSection"
                          itemClass="sortable-item"
                          itemActiveClass="sortable-item-active"
                          placeholderItem="No fields have been added yet."
                          placeholderClass="placeholderStyle"
                          wrapperClass="sortable-wrapper"
                        ></bs-sortable>
                    </div>
        
                    <ng-template #itemTemplateSection let-item="item" let-index="index">
                        <div class="web-content-container" *ngIf="item.value.type.type.data_type === 'url'">
                            <div class="web-accessory-textarea shadow" (click)="selectField(index)">
                                <div class="web-accessory-textarea-header">
                                    {{ item.value.label }}
                                </div>
                                <a class="" [routerLink]="">{{ item.value.value }}</a>
                            </div>
                        </div>

                        <div class="web-content-container" *ngIf="item.value.type.type.data_type === 'message'">
                            <div class="web-accessory-textarea shadow" (click)="selectField(index)">
                                <div class="web-accessory-textarea-header">
                                    {{ item.value.label }}
                                </div>
                                <div class="web-accessory-textarea-text">{{ item.value.value }}</div>
                            </div>
                        </div>

                        <div class="web-content-container" *ngIf="item.value.type.type.data_type === 'email'">
                            <div class="web-accessory-textarea shadow" (click)="selectField(index)">
                                <div class="web-accessory-textarea-header">
                                    {{ item.value.label }}
                                </div>
                                <a class="" [href]="'mailto:' + item.value.value">{{ item.value.value }}</a>
                            </div>
                        </div>

                        <div class="web-content-container" *ngIf="item.value.type.type.data_type === 'phone'">
                            <div class="web-accessory-textarea shadow" (click)="selectField(index)">
                                <div class="web-accessory-textarea-header">
                                    {{ item.value.label }}
                                </div>
                                <a class="" [href]="'tel:' + item.value.value">{{ item.value.value }}</a>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>














        <!-- PREVIEW -->






        <div class="preview-container" *ngIf="fieldView">
            <div class="card shadow" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">
                
                        <div class="web-content-container" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'url'">
                            <div class="web-accessory-textarea shadow">
                                <div class="web-accessory-textarea-header">
                                    {{ currentTemplateSettings.apple.backFields[selectedFieldIndex].label }}
                                </div>
                                <a class="" [routerLink]="" (click)="testUrl()">{{ currentTemplateSettings.apple.backFields[selectedFieldIndex].value }}</a>
                            </div>
                        </div>

                        <div class="web-content-container" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'message'">
                            <div class="web-accessory-textarea shadow">
                                <div class="web-accessory-textarea-header">
                                    {{ currentTemplateSettings.apple.backFields[selectedFieldIndex].label }}
                                </div>
                                <div class="web-accessory-textarea-text">{{ currentTemplateSettings.apple.backFields[selectedFieldIndex].value }}</div>
                            </div>
                        </div>

                        <div class="web-content-container" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'email'">
                            <div class="web-accessory-textarea shadow">
                                <div class="web-accessory-textarea-header">
                                    {{ currentTemplateSettings.apple.backFields[selectedFieldIndex].label }}
                                </div>
                                <a class="" [href]="'mailto:' + currentTemplateSettings.apple.backFields[selectedFieldIndex].value">{{ currentTemplateSettings.apple.backFields[selectedFieldIndex].value }}</a>
                            </div>
                        </div>

                        <div class="web-content-container" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'phone'">
                            <div class="web-accessory-textarea shadow">
                                <div class="web-accessory-textarea-header">
                                    {{ currentTemplateSettings.apple.backFields[selectedFieldIndex].label }}
                                </div>
                                <a class="" [href]="'tel:' + currentTemplateSettings.apple.backFields[selectedFieldIndex].value">{{ currentTemplateSettings.apple.backFields[selectedFieldIndex].value }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>







            <div class="card shadow mt-4" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content type</span>
                            <p>Select the type of content you want displayed in this field.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>


                        <div class="">
                            <div class="mb-4">
                                <div class="row mx-auto" *ngIf="editFieldType" [@fade-in]>
                                    <div class="content-type-container" (click)="changeType('url')">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets type='url' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">URL</div>
                                    </div>

                                    <div class="content-type-container" (click)="changeType('message')">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets type='message' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">Message</div>
                                    </div>

                                    <div class="content-type-container" (click)="changeType('email')">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets type='email' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">Email</div>
                                    </div>

                                    <div class="content-type-container" (click)="changeType('phone')">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets type='phone' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">Phone</div>
                                    </div>
                                </div>

                                <div class="" *ngIf="!editFieldType" [@fade-in]>
                                    <div class="d-flex justify-content-center">
                                        <div class="content-type-container" (click)="editFieldContentType()">
                                            <div class="content-type-container-svg">
                                                <app-svg-assets [type]='currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                            </div>
                                            
                                            <div class="web-quick-link-child-text">{{ currentTemplateSettings.apple.backFields[selectedFieldIndex].type.name }}</div>
                                        </div>
                                    </div>
                                    <div class="text-center mb-4">
                                        <a [routerLink]="" (click)="editFieldContentType()">Change</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card shadow mt-4" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content Data</span>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>


                        <div class="" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'url'">
                            <div class="">
                                <h3 class="h3">Web URL</h3>
                                <p>Add the URL to the website, social media account, or other page you want to include in this field.</p>
                            </div>
                        
                            <form [formGroup]="urlForm">
                                                        
                                <div class="form-group">
                                    <label for="urlLabel" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Content Label</label>
                                    <label for="urlLabel" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field"> Content Label</label>
                                    <input class="form-control" formControlName="urlLabel" type="text" placeholder="Ex. Website" id="urlLabel">
                                </div>

                                <div class="form-group">
                                    <label for="urlDataLabel" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default URL Label</label>
                                    <label for="urlDataLabel" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field"> URL Label</label>
                                    <input class="form-control" formControlName="urlDataLabel" type="text" placeholder="Ex. Learn More" id="urlDataLabel">
                                </div>
                        
                                <div class="form-group">
                                    <label for="urlData" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Website Url</label>
                                    <label for="urlData" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Website Url</label>
                                    <input class="form-control" formControlName="urlData" type="text" placeholder="Ex. www.example.com" id="urlData">
                                    <div *ngIf="urlData.invalid && (urlData.dirty || urlData.touched)" class="form-text text-muted">
                                        <small *ngIf="urlData.errors.required" class="validation-error">A URL is required.</small>
                                        <small *ngIf="urlData.errors.pattern" class="validation-warning">Warning: this web URL may be invalid</small>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'message'">
                       
                            <div class="">
                                <h3 class="h3">Message</h3>
                                <p>The message content type will contain a provided text-based message provided by default below or provided by your team members later.</p>
                            </div>
                        
                            <form [formGroup]="messageForm">
                                                        
                                <div class="form-group">
                                    <label for="messageLabel" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Content Label</label>
                                    <label for="messageLabel" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Content Label</label>
                                    <input class="form-control" formControlName="messageLabel" type="text" placeholder="Ex. Bio" id="messageLabel">
                                </div>
                        
                                <div class="form-group">
                                    <label for="messageData" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Message Content</label>
                                    <label for="messageData" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Message Content</label>
                        
                                    <textarea class="form-control" formControlName="messageData" type="text" rows="3" placeholder="" id="messageData"></textarea>
                                    <div *ngIf="messageData.invalid && (messageData.dirty || messageData.touched)" class="form-text text-muted">
                                        <small *ngIf="messageData.errors.required" class="validation-error">A message is required.</small>
                                    </div>
                                </div>
                            </form>
                            
                        </div>


                        <!-- EMAIL -->
                        <div *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'email'" class="">
                            <div class="">
                                <h3 class="h3">Email</h3>
                                <p>The email content type will direct the viewer to their email client and begin the process of sending an email to the address specified by default below or the one provided by your team members.</p>
                            </div>

                            <form [formGroup]="emailForm">
                                                        
                                <div class="form-group">
                                    <label for="emailLabel" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Content Label</label>
                                    <label for="emailLabel" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Content Label</label>
                                    <input class="form-control" formControlName="emailLabel" type="text" placeholder="Ex. Email" id="emailLabel">
                                </div>

                                <div class="form-group">
                                    <label for="emailData" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Email Address</label>
                                    <label for="emailData" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Email Address</label>
                                    <input class="form-control" formControlName="emailData" type="text" placeholder="Ex. hello@email.com" id="emailData">
                                    <div *ngIf="emailData.invalid && (emailData.dirty || emailData.touched)" class="form-text text-muted">
                                        <small *ngIf="emailData.errors.required" class="validation-error">A email address is required.</small>
                                        <small *ngIf="emailData.errors.email" class="validation-error">A valid email address is required.</small>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <!-- PHONE -->
                        <div *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].type.type.data_type === 'phone'" class="">
                            <div class="">
                                <h3 class="h3">Phone</h3>
                                <p>The phone content type will initiate a phone call between the viewers device and that of the phone number specified by default below or the one provided by your team members.</p>
                            </div>

                            <form [formGroup]="phoneForm">
                                                        
                                <div class="form-group">
                                    <label for="phoneLabel" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Content Label</label>
                                    <label for="phoneLabel" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Content Label</label>
                                    <input class="form-control" formControlName="phoneLabel" type="text" placeholder="Ex. Call Me" id="phoneLabel">
                                </div>

                                <div class="form-group">
                                    <label for="phoneData" *ngIf="currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Default Phone Number</label>
                                    <label for="phoneData" *ngIf="!currentTemplateSettings.apple.backFields[selectedFieldIndex].can_edit_back_field">Phone Number</label>
                                    <input class="form-control" formControlName="phoneData" type="text" placeholder="Ex. 123-456-7890" id="phoneData">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
            <pre>{{ currentTemplateSettings.apple.backFields | json }}</pre>
        </div>
    </div>
</div>









<ng-template #removeFieldModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="">⚠️ Remove Field</h2>
                    <p class="">Are you sure you want to remove this field? This is permanent and cannot be undone once saved.</p>
                </div>
                
                
                <div class="mt-2 d-flex justify-content-between">
                   <button class="btn btn-auxiliary" (click)="modalRef.hide()">No, Cancel</button>
                   <button class="btn btn-primary" (click)="removeField()">Yes, Remove</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>
import { Component, OnDestroy, OnInit } from '@angular/core';

// ANIMATIONS
import { fadeInAnimation } from '@app/shared/animations/animations';

import { PermissionService } from '@app/core/services/permission/permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  animations: [
    fadeInAnimation
  ]
})
export class AdminComponent implements OnInit, OnDestroy {

  admin: boolean;
  owner: boolean;
  permissionsSub: Subscription;
  
  constructor(
    private permissionService: PermissionService,
  ) { 
    this.permissionsSub = this.permissionService.userRole.subscribe(role => {
      this.admin = this.permissionService.validatePermission('admin');
      this.owner = this.permissionService.validatePermission('owner');
    })
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.permissionsSub.unsubscribe();
  }

}

<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isLogoTextCollapsed)?'section-text-light':''">
            Name Field
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingLogoText()" [ngClass]="(!isLogoTextCollapsed)?'active':''"
        [attr.aria-expanded]="!isLogoTextCollapsed" aria-controls="isLogoTextCollapsed">
            <svg *ngIf="isLogoTextCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isLogoTextCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>
    </div>

    <div id="isLogoTextCollapsed" [collapse]="isLogoTextCollapsed" [isAnimated]="true">
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Allow Name Field Editing
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.logos.can_edit_logo_text"  [(ngModel)]="currentTemplateSettings.apple.logos.can_edit_logo_text" (ngModelChange)="allowLogoTextEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.logos.can_edit_logo_text" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.logos.can_edit_logo_text" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to change name field text.</small>
            </div>
        </div>
    </div>





    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isPrimaryFieldCollapsed)?'section-text-light':''">
            Organization Name
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingPrimaryField()" [ngClass]="(!isPrimaryFieldCollapsed)?'active':''"
        [attr.aria-expanded]="!isPrimaryFieldCollapsed" aria-controls="isPrimaryFieldCollapsed">
            <svg *ngIf="isPrimaryFieldCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isPrimaryFieldCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>
    </div>

    <div id="isPrimaryFieldCollapsed" [collapse]="isPrimaryFieldCollapsed" [isAnimated]="true">
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Allow Organization Name Editing
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.frontFields.can_edit_primary_field"  [(ngModel)]="currentTemplateSettings.apple.frontFields.can_edit_primary_field" (ngModelChange)="allowPrimaryFieldEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.frontFields.can_edit_primary_field" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_primary_field" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to change org name text.</small>
            </div>
        </div>
    </div>



    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isSecondaryFieldCollapsed)?'section-text-light':''">
            Primary Field
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingSecondaryField()" [ngClass]="(!isSecondaryFieldCollapsed)?'active':''"
        [attr.aria-expanded]="!isSecondaryFieldCollapsed" aria-controls="isSecondaryFieldCollapsed">
            <svg *ngIf="isSecondaryFieldCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isSecondaryFieldCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>
    </div>

    <div id="isSecondaryFieldCollapsed" [collapse]="isSecondaryFieldCollapsed" [isAnimated]="true">
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Allow Primary Field Editing
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.frontFields.can_edit_secondary_field"  [(ngModel)]="currentTemplateSettings.apple.frontFields.can_edit_secondary_field" (ngModelChange)="allowPrimaryFieldEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.frontFields.can_edit_secondary_field" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_secondary_field" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to change primary info text items.</small>
            </div>
        </div>
    </div>






    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isAuxiliaryFieldCollapsed)?'section-text-light':''">
            Secondary Field
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingAuxiliaryField()" [ngClass]="(!isAuxiliaryFieldCollapsed)?'active':''"
        [attr.aria-expanded]="!isAuxiliaryFieldCollapsed" aria-controls="isAuxiliaryFieldCollapsed">
            <svg *ngIf="isAuxiliaryFieldCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isAuxiliaryFieldCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>
    </div>

    <div id="isAuxiliaryFieldCollapsed" [collapse]="isAuxiliaryFieldCollapsed" [isAnimated]="true">
        <div class="control-item control-item-switch">
            <div class="current-item-label">
                Allow Secondary Field Editing
            </div>
            <div class="current-item current-item-switch">
                <div class="d-flex">
                    <label class="switch">
                        <input type="checkbox" [checked]="currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field"  [(ngModel)]="currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field" (ngModelChange)="allowAuxiliaryFieldEditing($event)">
                        <span class="slider round"></span>
                    </label>
                    <p *ngIf="currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field" class="slider-text ml-4 mb-0">ON</p>
                    <p *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field" class="slider-text ml-4 mb-0">OFF</p>
                </div>
                
                <small>When “ON”, team members will be able to change the secondary info text items.</small>
            </div>
        </div>
    </div>
</div>








<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">

            <div class="card shadow" [@fade-in]>
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Apple Wallet Social Card</span>
                    </div>

                    <app-social-card [socialCard]="socialCard"></app-social-card>

                    <div class="mt-4">
                        <small>NOTE: Content and appearance may slightly change based on the various device constraints.</small>
                    </div>
                </div>
            </div>


            <div class="card shadow mt-4" [@fade-in] *ngIf="!isLogoTextCollapsed">
                <div class="card-body">
                    <div class="template-content-container">
                        <div class="mb-4">
                            <span class="card-label mb-4">Social Card Front Fields</span>
                            <div class="current-item-border mt-2"></div>
                        </div>

                        <form [formGroup]="logoTextForm">
                                    
                            <div class="form-group">
                                <label for="logoText" *ngIf="currentTemplateSettings.apple.logos.can_edit_logo_text">Default Name Text</label>
                                <label for="logoText" *ngIf="!currentTemplateSettings.apple.logos.can_edit_logo_text">Name Text</label>
                                <input class="form-control" formControlName="logoText" type="text" placeholder="Ex. Jane Doe" id="logoText">
                            </div>
                        </form>

                    </div>

                </div>

            </div>



            <div class="card shadow mt-4" [@fade-in] *ngIf="!isPrimaryFieldCollapsed">
                <div class="card-body">
                    <div class="template-content-container">
                        <div class="mb-4">
                            <span class="card-label">Social Card Front Fields</span>
                            <div class="current-item-border mt-2"></div>
                        </div>

                        <form [formGroup]="primaryFieldForm"> 
                            <div class="form-group">
                                <label for="primaryFieldText" *ngIf="currentTemplateSettings.apple.frontFields.can_edit_primary_field">Organization Name</label>
                                <label for="primaryFieldText" *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_primary_field">Default Organization Name</label>
                                <input class="form-control" formControlName="primaryFieldText" type="text" placeholder="Ex. Social Card" id="primaryFieldText">
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            <div class="card shadow mt-4" [@fade-in] *ngIf="!isSecondaryFieldCollapsed">
                <div class="card-body">
                    <div class="template-content-container">
                        <div class="mb-4">
                            <span class="card-label">Social Card Front Fields</span>
                            <div class="current-item-border mt-2"></div>
                        </div>

                        <form [formGroup]="secondaryFieldsForm"> 
                            <div class="form-group">
                                <label for="secondaryFieldLabel" *ngIf="currentTemplateSettings.apple.frontFields.can_edit_secondary_field">Primary Field Label</label>
                                <label for="secondaryFieldLabel" *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_secondary_field">Default Primary Field Label</label>
                                <input class="form-control" formControlName="secondaryFieldLabel" type="text" placeholder="Ex. ROLE" id="secondaryFieldLabel">
                            </div>

                            <div class="form-group">
                                <label for="secondaryFieldText" *ngIf="currentTemplateSettings.apple.frontFields.can_edit_secondary_field">Primary Field Value</label>
                                <label for="secondaryFieldText" *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_secondary_field">Default Primary Field Value</label>
                                <input class="form-control" formControlName="secondaryFieldText" type="text" placeholder="Ex. Chief of Fun" id="secondaryFieldText">
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div class="card shadow mt-4" [@fade-in] *ngIf="!isAuxiliaryFieldCollapsed">
                <div class="card-body">
                    <div class="template-content-container">
                        <div class="mb-4">
                            <span class="card-label">Social Card Front Fields</span>
                            <div class="current-item-border mt-2"></div>
                        </div>

                        <form [formGroup]="auxiliaryFieldsForm"> 
                            <div class="form-group">
                                <label for="auxiliaryFieldLabel" *ngIf="currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field">Secondary Field Label</label>
                                <label for="auxiliaryFieldLabel" *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field">Default Secondary Field Label</label>
                                <input class="form-control" formControlName="auxiliaryFieldLabel" type="text" placeholder="Ex. EMAIL" id="auxiliaryFieldLabel">
                            </div>

                            <div class="form-group">
                                <label for="auxiliaryFieldText" *ngIf="currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field">Secondary Field Value</label>
                                <label for="auxiliaryFieldText" *ngIf="!currentTemplateSettings.apple.frontFields.can_edit_auxiliary_field">Default Secondary Field Value</label>
                                <input class="form-control" formControlName="auxiliaryFieldText" type="text" placeholder="Ex. email@example.com" id="auxiliaryFieldText">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
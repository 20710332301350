import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { fadeInAnimation } from '@app/shared/animations/animations';
import { NgxSpinnerService } from 'ngx-spinner';

// ModalService
import { isBs3 } from 'ngx-bootstrap/utils';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// Modals
import { Department, DepartmentMember, DepartmentMembers } from '@app/core/models/departments.model';
import { TeamMembers } from '@app/core/models/team.model';
import { User } from '@app/core/models/user.model';

// Services
import { OnboardingService } from '@core/services/onboarding/onboarding.service';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { DepartmentsMediaService } from '@app/core/services/departments/departments-media/departments-media.service';
import { DepartmentsService } from '@app/core/services/departments/departments.service';
import { TeamService } from '@app/core/services/team/team.service';


@Component({
    selector: 'app-onboarding-department',
    templateUrl: './onboarding-department.component.html',
    styleUrls: ['./onboarding-department.component.css'],
    animations: [fadeInAnimation],
})
export class OnboardingDepartmentComponent implements OnInit {
    load_completed: boolean = true;
    departmentCreated: boolean = false;

    newDepartmentForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.maxLength(255)]),
    });
    get name() { return this.newDepartmentForm.get('name'); }
    get description() { return this.newDepartmentForm.get('description'); }

    newDepartment: Department = {
        name: '',
        description: '',
        logo: '',
        members: null,
        public: false,
    };

    teamSub: Subscription;
    team: TeamMembers<User> = {};
    teamArray: User[] = [];

    // TypeAhead
    customSelected: User;
    isBs3 = isBs3();

    selectedDepHead: User;
    allSelectedDepHeads: User[] = [];
    departmentHeads: DepartmentMembers<DepartmentMember> = {};
    newDepartmentHeadForm = new FormGroup({
        role: new FormControl('', [Validators.required]),
    });
    get role() { return this.newDepartmentHeadForm.get('role'); }


    tempUrlSub: Subscription;
    tempUrl: string;

    departmentId: string;

    constructor(
        private onboardingService: OnboardingService,
        private departmentService: DepartmentsService,
        private teamService: TeamService,
        private departmentsMediaService: DepartmentsMediaService,
        private router: Router,
        private modalService: BsModalService,
        private spinner: NgxSpinnerService,
        private snackBarService: SnackBarService,
    ) {
        this.teamService.getAllTeamMembers();
        this.teamSub = this.teamService.allTeamMembers.subscribe(res => {
            // this.team = res;
            if (res != null && res != {}) {
                this.team = res;
                console.log(this.team);
                this.spinner.hide('departmentHeadSpinner');

                // Create array of team members for typeahead search
                this.teamArray = [];
                for (let user of Object.values(this.team)) {
                    this.teamArray.push(user);
                }
            }
        });

        this.tempUrlSub = this.departmentsMediaService.tempImgUrl.subscribe(url => {
            this.tempUrl = url;
        })
    }

    ngOnInit(): void {
        // this.spinner.show('newDepartmentSpinner');
        this.spinner.show('departmentHeadSpinner');
    }
    ngOnDestroy(): void {
        this.teamSub.unsubscribe();
    }



    // Generate the department via API
    async createDepartment() {
        console.log("Creating department");
        this.load_completed = false;
        this.spinner.show('newDepartmentSpinner');

        this.newDepartment.name = this.name.value;
        this.newDepartment.description = this.description.value;
        this.newDepartment.members = this.departmentHeads;


        await this.departmentService.createDepartment(this.newDepartment).then(res => {
            this.departmentId = res.data['department_id'];
            if (this.tempUrl != null) {
                this.departmentsMediaService.uploadDepartmentLogoToStorage(this.departmentId).then(res => {
                    console.log('response from upload: ', res.data);
                    this.departmentService.updateBasicDepartmentSettings(this.departmentId, { logo: res.data as string }).then(res => {

                        this.updateItemCompletion();
                    
                    }).catch(err => {
                        console.error(err);
                        this.load_completed = true;
                        this.spinner.hide('newDepartmentSpinner');
                        this.snackBarService.error('An internal error occured, refresh & try again');
                    });

                }).catch(err => {
                    console.error(err);
                    this.load_completed = true;
                    this.spinner.hide('newDepartmentSpinner');
                    this.snackBarService.error('An internal error occured, refresh & try again');
                });
            } else {
                console.log('No Department Logo');

                this.updateItemCompletion();
            }


        }).catch(err => {
            this.load_completed = true;
            this.spinner.hide('newDepartmentSpinner');
            this.snackBarService.error('An internal error occured, please try again');
        })
    }


    // Updates the DB to match onboarding item
    async updateItemCompletion() {
        await this.onboardingService.setOnboardingItemAsCompleted(3).then(_ => {
            // Show success notif & stop animations
            this.load_completed = true;
            this.spinner.hide('newDepartmentSpinner');
            this.snackBarService.success('Successfully created department');
            this.departmentCreated = true;
            this.router.navigate(['/departments/view'], { queryParams: { 'd': this.departmentId } });

        }).catch(err => {
            this.spinner.hide('newDepartmentSpinner');
            // handle err
            console.error(err);
            this.snackBarService.error('An internal error occured');
        });
    }


    // Department head selected
    onSearchSelect(event: any) {
        console.log(event);
        this.openModal(this.modalView);
        this.selectedDepHead = event.item;
    }

    // Add department head to Dep head group
    addDepartmentHead() {
        console.log(this.role.value);
        this.departmentHeads[this.selectedDepHead.uid] = {
            uid: this.selectedDepHead.uid,
            dep_role: 'head',
            org_role: this.role.value,
        };
        this.allSelectedDepHeads.push(this.selectedDepHead);

        console.log(this.departmentHeads);
        this.modalRef.hide();
    }

    removeDepartmentHead(index: number, uid: string) {
        this.allSelectedDepHeads.splice(index, 1);
        delete this.departmentHeads[uid];
    }


    resetLogoImg() {
        this.departmentsMediaService.resetTempImageData();
    }

    modalRef: BsModalRef;

    // Allows opening modal from inside component
    @ViewChild('departmentHeadModal') modalView: TemplateRef<any>;

    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.newDepartmentHeadForm.reset();

        this.modalRef = this.modalService.show(template, config);
    }

}

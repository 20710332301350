import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// Animation
import { fadeInAnimation } from '@app/shared/animations/animations'

// MODELS
import { Template, DefaultTemplate } from '@app/core/models/template.model';

import { TemplatingService } from '@core/services/templates/templating.service';
import { Subscription } from 'rxjs';
import { TemplateMediaService } from '@app/core/services/template-media/template-media.service';

@Component({
    selector: 'app-web-appearance',
    templateUrl: './web-appearance.component.html',
    styleUrls: ['./web-appearance.component.css'],
    animations: [fadeInAnimation]
})
export class WebAppearanceComponent implements OnInit, OnDestroy {



    isBasicCollapsed: boolean = false;
    isQuickLinksButtonCollapsed: boolean = true;
    isCtaButtonCollapsed: boolean = true;
    isAccessoryItemsCollapsed: boolean = true;



    // TEMP (Need to be items from a template OBject)
    templateSub: Subscription;
    currentTemplateSettings: Template;


    // Hover State booleans 
    // TODO: On live site, make these just array of booleans.
    mediaHovered: boolean[] = [false, false];
    quickLinksHovered: boolean[] = [false, false, false];
    ctaHovered: boolean[] = [false];
    accessoryHovered: boolean[] = [false, false, false];

    coverPhotoSub: Subscription;
    coverPhotoTemp: string;

    constructor(
        private formBuilder: FormBuilder,
        private templatingService: TemplatingService,
        private templateMediaService: TemplateMediaService
    ) {

        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
            console.log(this.currentTemplateSettings);
        });

        this.coverPhotoSub = this.templateMediaService.tempCoverPhotoUrl.subscribe(res => {
            this.coverPhotoTemp = res;
        });

    }

    ngOnInit(): void {

    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();
        this.coverPhotoSub.unsubscribe();
    }





    // Pushes changed data into service.
    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }


    // Toggles the state of each right nav COLLAPSE
    togglingBasic() {
        this.isBasicCollapsed = !this.isBasicCollapsed;

        this.isQuickLinksButtonCollapsed = true;
        this.isCtaButtonCollapsed = true;
        this.isAccessoryItemsCollapsed = true;
    }
    togglingQuickLinks() {
        this.isQuickLinksButtonCollapsed = !this.isQuickLinksButtonCollapsed;

        this.isBasicCollapsed = true;
        this.isCtaButtonCollapsed = true;
        this.isAccessoryItemsCollapsed = true;
    }
    togglingCta() {
        this.isCtaButtonCollapsed = !this.isCtaButtonCollapsed;

        this.isBasicCollapsed = true;
        this.isAccessoryItemsCollapsed = true;
        this.isQuickLinksButtonCollapsed = true;
    }
    togglingAccessory() {
        this.isAccessoryItemsCollapsed = !this.isAccessoryItemsCollapsed;

        this.isBasicCollapsed = true;
        this.isCtaButtonCollapsed = true;
        this.isQuickLinksButtonCollapsed = true;
    }





    allowUserBackgroundStyling(val) {
        this.currentTemplateSettings.web.can_edit_background_color = val;
        this.updateTemplate();
    }
    allowUserMediaStyling(val) {
        this.currentTemplateSettings.web.can_edit_media_style = val;
        this.updateTemplate();
    }
    allowUserBorderStyling(val) {
        this.currentTemplateSettings.web.can_edit_border_style = val;
        this.updateTemplate();
    }
    allowUserSectionTextStyling(val) {
        this.currentTemplateSettings.web.can_edit_section_text_color = val;
        this.updateTemplate();
    }
    allowUserQuickLinkStyling(val) {
        this.currentTemplateSettings.web.can_edit_quick_link_style = val;
        this.updateTemplate();
    }
    allowUserCtaStyling(val) {
        this.currentTemplateSettings.web.can_edit_cta_style = val;
        this.updateTemplate();
    }
    allowUserAccessoryItemStyling(val) {
        this.currentTemplateSettings.web.can_edit_acessory_style = val;
        this.updateTemplate();
    }



    backgroundColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.background_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    sectionTextColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.section_text_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    borderColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.border_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }



    // Helper Funcs

    quickLinksFillChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.quick_links_fill_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    ctaFillChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.cta_fill_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    accessoryFillChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.accessory_item_fill_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }


    quickLinkTextChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.quick_links_text_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    ctaTextChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.cta_text_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    accessoryTextChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.accessory_item_text_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }



    mediaShadowChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.media_shadow_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    quickLinkShadowChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.quick_links_shadow_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    ctaShadowChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.cta_shadow_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    accessoryShadowChange(event) {
        // updates template in component
        this.currentTemplateSettings.web.accessory_item_shadow_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }


    mediaCoverCornerChange(val: number) {
        // updates template in component
        this.currentTemplateSettings.web.media_cover_corner_radius = val;
        // then, sends update to global service
        this.updateTemplate();
    }
    mediaHeadshotCornerChange(val: number) {
        // updates template in component
        this.currentTemplateSettings.web.media_headshot_corner_radius = val;
        // then, sends update to global service
        this.updateTemplate();
    }
    quickLinkCornerChange(val: number) {
        // updates template in component
        this.currentTemplateSettings.web.quick_links_corner_radius = val;
        // then, sends update to global service
        this.updateTemplate();
    }
    ctaCornerChange(val: number) {
        // updates template in component
        this.currentTemplateSettings.web.cta_corner_radius = val;
        // then, sends update to global service
        this.updateTemplate();
    }
    accessoryCornerChange(val: number) {
        // updates template in component
        this.currentTemplateSettings.web.accessory_item_corner_radius = val;
        // then, sends update to global service
        this.updateTemplate();
    }


    shadowHovered(shadowColor: string) {
        // Gets selected shadow color
        let rgb = this.getRGB(shadowColor);
        let style = {
            'box-shadow': `0 22px 32px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.56), 0 9px 28px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.64)`
        }
        return style; // Returns the Social Card shadow string back
    }
    shadowNotHovered(shadowColor: string) {
        let rgb = this.getRGB(shadowColor);

        let style = {
            'box-shadow': `0 22px 25px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.22), 0 9px 23px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.5)`
        };
        return style;
    }





    getRGB(str) {
        var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        return match ? {
            red: match[1],
            green: match[2],
            blue: match[3]
        } : {};
    }
}

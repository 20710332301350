<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isGeneralCollapsed)?'section-text-light':''">
            General
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingGeneral()" [ngClass]="(!isGeneralCollapsed)?'active':''"
        [attr.aria-expanded]="!isGeneralCollapsed" aria-controls="isGeneralCollapsed">
            <svg *ngIf="isGeneralCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isGeneralCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isGeneralCollapsed" [collapse]="isGeneralCollapsed" [isAnimated]="true">

        <div>
            <div class="control-item-group"> 
                <div class="control-item">
                    <p>A name & description give your template an identity. They also make it easier for your team to use or find.</p>
                </div>
            </div>
        </div>
    </div>










    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isDepartmentsCollapsed)?'section-text-light':''">
            Departments
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingDepartments()" [ngClass]="(!isDepartmentsCollapsed)?'active':''"
        [attr.aria-expanded]="!isDepartmentsCollapsed" aria-controls="isDepartmentsCollapsed">
            <svg *ngIf="isDepartmentsCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isDepartmentsCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isDepartmentsCollapsed" [collapse]="isDepartmentsCollapsed" [isAnimated]="true">

        <div>
            <div class="control-item-group"> 
                <div class="control-item">
                    <div class="d-flex mb-3">
                        <h2 *ngIf="currentTemplateSettings.departments" class="">{{ currentTemplateSettings.departments.length }}</h2>
                        <h2 *ngIf="!currentTemplateSettings.departments" class="">0</h2>
                        <div class="ml-4">Departments assigned</div>
                    </div>
                   
                    <p>What Social Card Departments have access to this template? Select all the departments you want to make this template available to.</p>
                    <small><strong>Important:</strong> changes will only take affect for NEW Social Cards. Existing departments with cards using this template will not be effected. 
                        <a href="https://help.joinsocialcard.com/templates/assigned-departments" target="_blank">Learn more</a>
                    </small>
                </div>
            </div>
        </div>
    </div>






    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isContentCollapsed)?'section-text-light':''">
            Content Settings
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingContent()" [ngClass]="(!isContentCollapsed)?'active':''"
        [attr.aria-expanded]="!isContentCollapsed" aria-controls="isContentCollapsed">
            <svg *ngIf="isContentCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isContentCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isContentCollapsed" [collapse]="isContentCollapsed" [isAnimated]="true">

        <div>
            <div class="control-item-group"> 
                <div class="control-item">
                    <p>Control the type of information your team can share with this template. Whether strick or free, you set the requirements for the information your team shares.</p>
                </div>
            </div>
        </div>
    </div>

</div>















































<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">

     
        
            <div class="card shadow"  *ngIf="!isGeneralCollapsed" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">
                        <div class="form-group">
                            <label for="description">Publish Template</label>
                                                    
                            <div class="d-flex mt-2">
                                <label class="switch">
                                    <input type="checkbox" [checked]="currentTemplateSettings.published" (change)="currentTemplateSettings.published = !currentTemplateSettings.published">
                                    <span class="slider round"></span>
                                </label>
                                <p *ngIf="currentTemplateSettings.published" class="slider-text ml-4 mb-0">Published</p>
                                <p *ngIf="!currentTemplateSettings.published" class="slider-text ml-4 mb-0">Not Published</p>
                                
                            </div>
    
                            <div class="">
                                <small>A "Published" template is visible to everyone within the assigned departments. A "Not Published" template is onlly visible to admins, and cannot be used on Social Cards.</small>
                            </div>     
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="card shadow mt-4" *ngIf="!isGeneralCollapsed" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">

                        <h3 class="h3">General Template Information</h3>
                        <!-- <p class="p">Which account would you like to enter?</p> -->
                        <div class="mb-2">
                            <small *ngIf="error" class="validation-error">
                                {{ errorMessage }}
                            </small>
                        </div>

                        <form [formGroup]="generalTemplateInfoForm">

                            <div class="form-group">
                                <label for="name">Template Name</label>
                                <input class="form-control" formControlName="name" type="text" placeholder="" id="name">
                                
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                                    <small *ngIf="name.errors.required" class="validation-error">A template name is required.</small>
                                </div>
                            </div>
            
                            <div class="form-group">
                                <label for="desc">Template Description</label>
                                <small></small>
                                <textarea class="form-control" formControlName="desc" id="desc" rows="3"></textarea>
                        
                                <div *ngIf="desc.invalid && (desc.dirty || desc.touched)" class="form-text text-muted">
                                    <small *ngIf="desc.errors.required" class="validation-error">A template description is required.</small>
                                </div>
                            </div>
                            
                        </form>

                    </div>
                </div>
            </div>





            <div class="card shadow mt-4" *ngIf="!isGeneralCollapsed" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">

                        <h3 class="h3">Template Platforms</h3>
                        <p class="p">Which platforms will this template support?</p>
                        
                        <div class="current-item-border mt-2 mb-2"></div>
                        <div class="d-flex justify-content-between mt-2">
                            <div class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-world icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <circle cx="12" cy="12" r="9" />
                                    <line x1="3.6" y1="9" x2="20.4" y2="9" />
                                    <line x1="3.6" y1="15" x2="20.4" y2="15" />
                                    <path d="M11.5 3a17 17 0 0 0 0 18" />
                                    <path d="M12.5 3a17 17 0 0 1 0 18" />
                                </svg>
                                <div class="ml-3 mt-auto mb-auto">Web</div>
                            </div>

                            <div class="text-right">
                                <label class="switch">
                                    <input type="checkbox" [checked]="currentTemplateSettings.support_web" (change)="currentTemplateSettings.support_web = !currentTemplateSettings.support_web">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>

                        <div class="current-item-border mt-2 mb-2"></div>
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-apple icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 7c-3 0 -4 3 -4 5.5c0 3 2 7.5 4 7.5c1.088 -.046 1.679 -.5 3 -.5c1.312 0 1.5 .5 3 .5s4 -3 4 -5c-.028 -.01 -2.472 -.403 -2.5 -3c-.019 -2.17 2.416 -2.954 2.5 -3c-1.023 -1.492 -2.951 -1.963 -3.5 -2c-1.433 -.111 -2.83 1 -3.5 1c-.68 0 -1.9 -1 -3 -1z" />
                                    <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
                                </svg>
                                <div class="ml-3 mt-auto mb-auto">Apple Wallet</div>
                            </div>

                            <div class="text-right">
                                <label class="switch">
                                    <input type="checkbox" [checked]="currentTemplateSettings.support_apple" (change)="currentTemplateSettings.support_apple = !currentTemplateSettings.support_apple">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>

                        <div class="current-item-border mt-2 mb-2"></div>
                        <div class="d-flex justify-content-between mt-2">
                            <div class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <rect x="3" y="5" width="18" height="14" rx="2" />
                                    <polyline points="3 7 12 13 21 7" />
                                </svg>
                                <div class="ml-3 mt-auto mb-auto">Email Signature</div>
                            </div>

                            <div class="text-right">
                                <label class="switch">
                                    <input type="checkbox" [checked]="currentTemplateSettings.support_email" (change)="currentTemplateSettings.support_email = !currentTemplateSettings.support_email">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>


                        <div class="current-item-border mt-2 mb-2"></div>
                        <div class="d-flex justify-content-between mt-2">
                            <div class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-qrcode icon-margin" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <rect x="4" y="4" width="6" height="6" rx="1" />
                                    <line x1="7" y1="17" x2="7" y2="17.01" />
                                    <rect x="14" y="4" width="6" height="6" rx="1" />
                                    <line x1="7" y1="7" x2="7" y2="7.01" />
                                    <rect x="4" y="14" width="6" height="6" rx="1" />
                                    <line x1="17" y1="7" x2="17" y2="7.01" />
                                    <line x1="14" y1="14" x2="17" y2="14" />
                                    <line x1="20" y1="14" x2="20" y2="14.01" />
                                    <line x1="14" y1="14" x2="14" y2="17" />
                                    <line x1="14" y1="20" x2="17" y2="20" />
                                    <line x1="17" y1="17" x2="20" y2="17" />
                                    <line x1="20" y1="17" x2="20" y2="20" />
                                </svg>
                                <div class="ml-3 mt-auto mb-auto">QR Code</div>
                            </div>

                            <div class="text-right">
                                <label class="switch">
                                    <input type="checkbox" [checked]="currentTemplateSettings.support_qr" (change)="currentTemplateSettings.support_qr = !currentTemplateSettings.support_qr">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>




            <div class="card shadow mt-4"  *ngIf="!isGeneralCollapsed" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">
                        <div class="form-group">
                            <label for="description">Delete Template</label>
                                                    
                            <button class="btn btn-error mt-2" (click)="openModal(deleteTemplateModal)">Delete Template</button>
    
                            <div class="mt-2">
                                <small>Deleting a template is permanent and cannot be undone. Deleting a template will not delete any Social Cards associated with the template, but will stop these Social Cards from being edited by team members.</small>
                            </div>     
                        </div>
                    </div>
                </div>
            </div>





















            <div class="card shadow" *ngIf="!isDepartmentsCollapsed" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">

                        <h3 class="h3">Departments With Access</h3>
                        <p>All users in the selected departments will have access to use this Social Card Template.</p>

                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" class="card-label">Department</th>
                                        <th scope="col" class="card-label text-center">Members</th>
                                        <th scope="col" class="card-label text-right">Include</th>
                                    </tr>
                                </thead>
                                <tbody>



                                    <tr>
                                        <td scope="row">
                                           
                                            <div class="d-flex">
                                                <p class="font-weight-bolder mb-0">Select All</p>
                                            </div>
                                        </td>
                                        <td class="">
                                           
                                        </td>
                                        
                                        <td class="text-right">
                                            <label class="switch">
                                                <input type="checkbox" [checked]="allDepartments" (change)="selectAllDepartments()">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>


                                    <tr *ngFor="let dep of tenantDepartments | keyvalue; index as i;">
                                        <td scope="row">
                                           
                                            <div class="d-flex">
                                                <div class="table-avatar-default mr-4 shadow text-center" *ngIf="dep.value.logo === '' && dep.value.public">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="6" cy="16" r="3" />
                                                        <circle cx="16" cy="19" r="2" />
                                                        <circle cx="14.5" cy="7.5" r="4.5" />
                                                    </svg>
                                                </div>
                                                <div class="table-avatar-default-admin mr-4 shadow text-center" *ngIf="dep.value.logo === '' && !dep.value.public">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="6" cy="16" r="3" />
                                                        <circle cx="16" cy="19" r="2" />
                                                        <circle cx="14.5" cy="7.5" r="4.5" />
                                                    </svg>
                                                </div>
        
                                                
                                                <img class="table-avatar mr-4 shadow" *ngIf="dep.value.logo != ''" [src]="dep.value.logo">
                                                <p class="mb-0 mt-auto mb-auto ">{{ dep.value.name }}</p>
                                            </div>
                                        </td>
                                        <td class="">
                                            <p class="mb-0 text-center" *ngIf="dep.value.members">{{ getObjectLength(dep.value.members)}}</p>
                                            <p class="mb-0 text-center" *ngIf="!dep.value.members">0</p>
                                        </td>
                                        
                                        <td class="text-right">
                                            <label class="switch">
                                                <input type="checkbox" [checked]="isDepartmentChecked(dep.key)" (change)="toggleDepartmentForId(dep.key)">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>









            <div class="card shadow" *ngIf="!isContentCollapsed" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">

                        <h3 class="h3">Template Supported Content Types</h3>
                        <p>All selected content types will be available to team members when creating their Social Cards with this template.</p>

                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" class="card-label">Content Type</th>
                                        <th scope="col" class="card-label text-right">Include</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">
                                           
                                            <div class="d-flex">
                                                <p class="font-weight-bolder mb-0">Select All</p>
                                            </div>
                                        </td>
                                        
                                        <td class="text-right">
                                            <label class="switch">
                                                <input type="checkbox" [checked]="allowAllContent" (change)="selectAllContentTypes()">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>


                                    <tr *ngFor="let type of allContentTypes; let i = iindex;">
                                        <td scope="row">
                                            
                                            <p class="mb-0 mt-auto mb-auto ">{{ type.name }}</p>
                                        
                                        </td>
                                        
                                        <td class="text-right">
                                            <label class="switch">
                                                <input type="checkbox" [checked]="isContentTypeChecked(i)" (change)="toggleContentSettingForId(i)">
                                                <span class="slider round"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <pre>{{ currentTemplateSettings | json }}</pre>




        </div>
    </div>
</div>















<ng-template #deleteTemplateModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <!-- <span class="card-label">Enterpise Contact Form</span> -->
                <h2 class="mb-3">Are you sure? ⚠️</h2>

                <p>Are you sure you want to delete this template? This action is permanent and cannot be undone.</p>
                
                <div class="d-flex justify-content-between mt-4">
                    <button class="btn btn-auxiliary" (click)="this.modalRef.hide()">No, cancel</button>
                    <button class="btn btn-primary" (click)="deleteTemplate()">Yes, delete</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInUser = () => redirectLoggedInTo(['auth/orgs']);

// Components
import { TemplatingComponent } from './pages/templating/templating.component';
import { NewTemplateComponent } from './pages/new-template/new-template.component';
import { PageNotFoundComponent } from '@app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {path: '', canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
    {path: 'editor', component: TemplatingComponent},
  ]},
  {path: 'new', component: NewTemplateComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},

  {path: 'preview', component: NewTemplateComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplatesRoutingModule { }
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInUser = () => redirectLoggedInTo(['auth/orgs']);

// Components
import { LoginComponent } from '@app/modules/authentication/pages/login/login.component';
import { OrganizationSelectionComponent } from '@app/modules/authentication/pages/organization-selection/organization-selection.component';
import { OrganizationSetupComponent } from '@app/modules/authentication/pages/organization-setup/organization-setup.component';
import { StripeCheckoutComponent } from '@app/modules/authentication/components/stripe-checkout/stripe-checkout.component';
import { AccountSetupComponent } from '@app/modules/authentication/pages/account-setup/account-setup.component';
import { PageNotFoundComponent } from '@app/shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInUser }},
  {path: 'orgs', component: OrganizationSelectionComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'org-setup', component: OrganizationSetupComponent},
  {path: 'checkout', component: StripeCheckoutComponent},
  {path: 'account-setup', component: AccountSetupComponent},

  // {path: 'sign-up', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
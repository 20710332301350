export interface SocialCard {
    serial: string,
    tenant_id: string,
    template_id: string,
    uid: string,
    name: string,
    description: string,
    public_name: string,
    public_description: string,
    front_fields: FrontFields,
    back_fields: [BackField],
    qr: QrCode,
    logo: Logos,
    thumbnails: Thumbnails,
    colors: Colors,
    created_at: number,
    last_updated: number,
}

// FRONT FIELDS// FRONT FIELDS// FRONT FIELDS// FRONT FIELDS
// FRONT FIELDS// FRONT FIELDS// FRONT FIELDS
// FRONT FIELDS// FRONT FIELDS
// FRONT FIELDS

export interface FrontFields {
    primary_field: PrimaryField,
    secondary_field: SecondaryField,
    auxiliary_field: AuxiliaryField,
}

export interface PrimaryField {
    key: string,
    label: string,
    value: string,
}

export interface SecondaryField {
    key: string,
    label: string,
    value: string,
}

export interface AuxiliaryField {
    key: string,
    label: string,
    value: string,
}

// FRONT FIELDS
// FRONT FIELDS// FRONT FIELDS
// FRONT FIELDS// FRONT FIELDS// FRONT FIELDS
// FRONT FIELDS// FRONT FIELDS// FRONT FIELDS// FRONT FIELDS



// GENERAL// GENERAL// GENERAL// GENERAL
// GENERAL// GENERAL// GENERAL
// GENERAL// GENERAL
// GENERAL

export interface GeneralInfo {
    colors: Colors,
}

// COLORS
export interface Colors {
    background_color: string,
    foreground_color: string,
    label_color: string,
}

// GENERAL
// GENERAL// GENERAL
// GENERAL// GENERAL// GENERAL
// GENERAL// GENERAL// GENERAL// GENERAL


// QR// QR// QR// QR
// QR// QR// QR
// QR// QR
// QR
export interface QrCode {
    type: string,
    data: string,
}
// QR
// QR// QR
// QR// QR// QR
// QR// QR// QR// QR



// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS
// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS
// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS
// LOGOS & THUMBNAILS
export interface Logos {
    logo: string,
    logo_2x: string,
    logo_text: string,
}

export interface Thumbnails {
    thumbnail: string,
    thumbnail_2x: string,
}
// LOGOS & THUMBNAILS
// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS
// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS
// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS// LOGOS & THUMBNAILS



// BACK FIELD// BACK FIELD// BACK FIELD// BACK FIELD
// BACK FIELD// BACK FIELD// BACK FIELD
// BACK FIELD// BACK FIELD
// BACK FIELD
export interface BackField {
    attributed_value: string,
    key: string,
    label: string,
    value: string,
}
// BACK FIELD
// BACK FIELD// BACK FIELD
// BACK FIELD// BACK FIELD// BACK FIELD
// BACK FIELD// BACK FIELD// BACK FIELD// BACK FIELD







export const DefaultSocialCard = {
    serial: '',
    tenant_id: '',
    template_id: '=',
    uid: '',
    name: 'Social Card',
    description: 'This is my social card',
    front_fields: {
        primary_field: {
            key: 'primary',
            label: '',
            value: 'Your Org Name',
        },
        secondary_field: {
            key: 'secondary',
            label: 'ROLE',
            value: 'Chief of Fun',
        },
        auxiliary_field: {
            key: 'auxiliary',
            label: 'EMAIL',
            value: 'email@example.com',
        }
    },
    back_fields: [
        {
            key: 'secondary',
            label: 'ROLE',
            value: 'Founder & CEO',
            attributed_value: '',
        }
    ],
    qr: {
        type: 'mecard',
        data: 'MECARD:Name'
    },
    logo: {
        logo: 'assets/icon_clear.svg',
        logo_2x: 'assets/icon_clear.svg',
        logo_text: 'YOUR NAME'
    },
    thumbnails: {
        thumbnail: 'assets/user.svg',
        thumbnail_2x: 'assets/user.svg',
    },
    colors: {
        background_color: 'rgb(141, 175, 186)',
        label_color: 'rgb(255, 255, 255)',
        foreground_color: 'rgb(69, 70, 69)',
    },
};
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { TemplatingService } from '@core/services/templates/templating.service';

// Animation
import { fadeInAnimation } from '@app/shared/animations/animations'

// MODELS
import { Template, DefaultTemplate } from '@app/core/models/template.model';
import { TemplateMediaService } from '@core/services/template-media/template-media.service';


@Component({
    selector: 'app-web-media',
    templateUrl: './web-media.component.html',
    styleUrls: ['./web-media.component.css'],
    animations: [fadeInAnimation],
})
export class WebMediaComponent implements OnInit, OnDestroy {

    templateSub: Subscription;
    currentTemplateSettings: Template;
    mediaHovered: boolean[] = [false, false];

    coverPhotoSub: Subscription;
    coverPhotoTemp: string;
    logoSub: Subscription;
    logoTemp: string;

    constructor(
        private templatingService: TemplatingService,
        private templateMediaService: TemplateMediaService,
    ) {

        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });


        this.coverPhotoSub = this.templateMediaService.tempCoverPhotoUrl.subscribe(res => {
            this.coverPhotoTemp = res;
        });

        this.logoSub = this.templateMediaService.tempLogoDataUrl.subscribe(res => {
            this.logoTemp = res;
        });
    }

    ngOnInit(): void {

    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();
        this.coverPhotoSub.unsubscribe();
        this.logoSub.unsubscribe();
    }










    shadowHovered(shadowColor: string) {
        // Gets selected shadow color
        let rgb = this.getRGB(shadowColor);
        let style = {
            'box-shadow': `0 22px 32px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.56), 0 9px 28px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.64)`
        }
        return style; // Returns the Social Card shadow string back
    }
    shadowNotHovered(shadowColor: string) {
        let rgb = this.getRGB(shadowColor);

        let style = {
            'box-shadow': `0 22px 25px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.22), 0 9px 23px 0 rgba(${rgb.red},${rgb.green},${rgb.blue},.5)`
        };
        return style;
    }

    getRGB(str) {
        var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        return match ? {
            red: match[1],
            green: match[2],
            blue: match[3]
        } : {};
    }





}

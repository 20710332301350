import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(retry(1), catchError((error: HttpErrorResponse) => {
      let errorMessage = '';
      let errorStatus: Number;
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
        errorStatus = 400;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}, Message: ${error.message}`;
        errorStatus = error.status;
      }

      this.router.navigate([`/error`], { queryParams: { 'error':  error.status, 'message': errorMessage} });

      return throwError(errorMessage);
    })
  )}
}

<form id="subscription-form" *ngIf="!pmStatus">
    <label>New Card Information</label>
    <div id="card-element" class="form-control">
    <!-- Elements(STRIPE) will create input elements here -->
    </div>

    <!-- We'll put the error messages in this element -->
    <small class="card-errors validation-error" id="card-errors" role="alert"></small>


    <button class="btn btn-primary mt-4 mb-2 w-100" type="button" [disabled]="buttonStatus" (click)="createPaymentMethod()">Update Payment Method</button>

</form>

<div class="" *ngIf="pmStatus">
    <p class="mb-0">Successfully updated payment method.</p>
    <span class="badge badge-pill badge-success">Success</span>
</div>

<ngx-spinner 
    name="checkoutSpinner"
    [fullScreen]="false"
    bdColor="rgba(255, 255, 255, 0.9)"
    size="default"
    color="#5D656C"
    type = "ball-clip-rotate-multiple"
    >
</ngx-spinner>

<app-snack-bar></app-snack-bar>
<div class="row card-row">
    
    <div class="term-container">
        <div class="term-item term-item-left font-weight-bolder">
            Bill Monthly
        </div>
        <div class="term-item term-item-toggle">
            <label class="switch">
                <input type="checkbox" [checked]="isAnnual" [(ngModel)]="isAnnual" (ngModelChange)="termChange($event)">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="term-item term-item-right d-flex">
            <div class="font-weight-bolder">
                Bill Annually
            </div>
            <div class="ml-2">
                <span class="badge badge-secondary badge-pill">20% OFF</span>
            </div>
        </div>
    </div>
    

    <div class="plan-container">
        <div class="plan shadow">
            <div class="card-body-sm">
                <div class="">
                    <h3 class="m-0">Startup</h3>
                    <small class="text-muted">Just the basics</small>
                </div>
                <button class="mt-4 mb-2 btn btn-auxiliary btn-sm w-100" (click)="setSelectedPlan('startup')">Select Plan</button>
                <div class="d-flex plan-price-item" [tooltip]="startupPriceDescription">
                    <h3 class="plan-price">{{ startupPrice | currency }}</h3>
                    <div class="plan-price-sub">/mo</div>
                </div>
    
                <div class="user-price">{{ startupUserPrice | currency}}/user</div>
                <small class="text-muted">(per user per month)</small>
                <!-- <small>{{ growthPriceDescription }}</small> -->

                
            
                <div class="mt-4">
                    <ul>
                        <li>Up to <strong>50</strong> users</li>
                        <li>Social Card Enterprise platform</li>
                        <li>Corporate branding & media</li>
                        <li><strong>One</strong> Social Card template</li>
                        <li><strong>One</strong> digital profile per user</li>
                        <li>Basic Social Card analytics</li>
                    </ul> 
                </div>
                
            </div>
            
        </div>

        <div class="plan plan-selected shadow"> 
            <div class="card-body-sm">
                <div class="plan-badge text-center">
                    <span class="badge badge-pill badge-secondary">Most Popular</span>
                </div>
                <div class="">
                    <h3 class="m-0">Growth</h3>
                    <small class="text-muted">Unlock your teams full potential</small>
                </div>

                <button class="mt-4 mb-2 btn btn-primary btn-sm w-100" (click)="setSelectedPlan('growth')">Get Started</button>

                <div class="d-flex plan-price-item" [tooltip]="growthPriceDescription">
                    <h3 class="plan-price">{{ growthPrice | currency }}</h3>
                    <div class="plan-price-sub">/mo</div>
                </div>
    
                <div class="user-price">{{ growthUserPrice | currency}}/user</div>
                <small class="text-muted">(per user per month)</small>
                <!-- <small>{{ growthPriceDescription }}</small> -->

                <div class="mt-4">
                    <ul>
                        <li><strong>Everything in startup+</strong></li>
                        <li>Up to <strong>1000</strong> users</li>
                        <li><strong>Unlimited</strong> Social Card template</li>
                        <li><strong>Unlimited</strong> digital profile per user</li>
                        <li>Advanced Analytics</li>
                        <li>Social Card Departments</li>
                    </ul> 
                </div>
            </div>
        </div>

        <div class="plan enterprise-plan-card dark-shadow">
            <div class="card-body-sm">
                <div class="">
                    <h3 class="m-0">Enterprise</h3>
                    <small class="text-muted">For custom integrations</small>
                </div>
                <button class="mt-4 mb-2 btn btn-gradient btn-sm w-100" (click)="openModal(enterpriseContactModal)">Talk To Us</button>
                <div class="d-flex plan-price-item" tooltip="Pricing built for your requirements">
                    <h3 class="plan-price">Custom Pricing</h3>
                </div>

                <div class="user-price">XX</div>
                <small class="">Pricing built for you</small>
                <!-- <small>{{ growthPriceDescription }}</small> -->

                <div class="mt-4">
                    <ul>
                        <li><strong>Growth plan+</strong></li>
                        <li><strong>Unlimited</strong> users</li>
                        <li>Tailored Onboarding</li>
                        <li>Enterprise Support</li>
                    </ul> 
                </div>
            </div>
        </div>

    </div>
</div>
















<ng-template #enterpriseContactModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <!-- <span class="card-label">Enterpise Contact Form</span> -->
                <h2 class="mb-3">Hey, let's chat 👋</h2>

                <form [formGroup]="entepriseContactForm" *ngIf="openEnterpriseContact"
                    (ngSubmit)="submitEnterpriseContact()">

                    <div class="form-group">
                        <label for="name">Name</label>
                        <input class="form-control" formControlName="name" type="name" placeholder="Name" id="name">

                        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="form-text text-muted">
                            <small *ngIf="name.errors.required" class="validation-error">A name is required.</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="email">Work Email</label>
                        <input class="form-control" formControlName="email" type="email" placeholder="Work Email"
                            id="email">

                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-text text-muted">
                            <small *ngIf="email.errors.required" class="validation-error">An email is
                                required.</small>
                            <small *ngIf="email.errors.pattern" class="validation-error">A valid email address is
                                required.</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="organization">Organization Name</label>
                        <input class="form-control" formControlName="organization" type="text"
                            placeholder="Organization" id="organization">

                        <div *ngIf="organization.invalid && (organization.dirty || organization.touched)"
                            class="form-text text-muted">
                            <small *ngIf="organization.errors.required" class="validation-error">A organization name
                                is required.</small>
                        </div>
                    </div>

                    <div class="mt-auto">
                        <div class="text-center mt-4">
                            <button type="submit" [disabled]="entepriseContactForm.invalid"
                                class="btn btn-primary mb-3 w-100">Get In Touch</button>
                            <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                        </div>
                    </div>

                </form>

                <div class="" *ngIf="!openEnterpriseContact">
                    <div class="" *ngIf="!enterpriseContactFormError">
                        <p>We've received your request for more information. We'll be in touch soon!</p>
                        <p class="card-label">In the meantime</p>
                        <div class="">
                            <a class="" href="https://joinsocialcard.com">Learn more about Social Card <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <polyline points="9 6 15 12 9 18" />
                                </svg></a>
                        </div>
                        <small>Or</small>
                        <div class="">
                            <a href="https://joinsocialcard.com/blog">Checkout our blog <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-chevron-right" width="14" height="14"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="#4FACFE" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <polyline points="9 6 15 12 9 18" />
                                </svg></a>
                        </div>

                    </div>
                    <div class="" *ngIf="enterpriseContactFormError">
                        <p class="validation-error">Uh Oh, an error occured. Please try again!</p>
                    </div>

                    <div class="text-center mt-3">
                        <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Close</small></a>
                    </div>
                </div>

                <!-- Content for bottom of card -->

            </div>
        </div>
    </div>
</ng-template>
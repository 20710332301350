<h4 class="font-weight-bolder mt-4">Social Card Overview</h4>
<div class="row mt-4">

    <div class="col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center" tooltip="The estimated overrall network of your Social Card. Comprised of data from all distribution methods.">
                <div class="m-auto text-center">
                    <p class="font-weight-bolder mb-0">Overrall Card Network</p>
                    
                    <div class="card-network">
                        300
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-lg-6 col-md-6 col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center" tooltip="The number of iOS devices your Social Card is registered on.">
                <div class="m-auto text-center">
                    <p class="font-weight-bolder mb-0">Unique Web Clicks</p>
                    
                    <div class="card-network">
                        100
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center" tooltip="The number of downloads from your cards web page.">
                <div class="m-auto text-center">
                    <p class="font-weight-bolder mb-0">Web Card Network</p>
                    <small></small>
                    <div class="card-network">
                        100
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center" tooltip="The number of iOS devices your Social Card is registered on.">
                <div class="m-auto text-center">
                    <p class="font-weight-bolder mb-0">Apple Card Network</p>
                    
                    <div class="card-network">
                        100
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center" tooltip="COMING SOON: The number of Android devices your Social Card is registered on.">
                <div class="m-auto text-center">
                    <p class="font-weight-bolder mb-0">Android Card Network</p>
                    
                    <div class="card-network-disabled">
                        Coming Soon
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="col-lg-6 col-md-6 col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center">
                <div class="m-auto">
                    <p class="font-weight-bolder">Last Updated</p>
                    <div class="card-network-alt">
                        10/12/20
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
        <div class="card mb-4 shadow-sm">
            <div class="card-body text-center">
                <div class="m-auto">
                    <p class="font-weight-bolder">Created At</p>
                    <div class="card-network-alt">
                        10/12/20
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>

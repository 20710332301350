import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Social Card';

  // Scrolls to top on each comp load
  onActivate(event) {
    console.log('scrolling to top ')
    //window.scroll(0,0);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SnackBarService } from '@core/notifications/services/snack-bar.service';

import { NewTenantService } from '@core/services/tenant/new-tenant/new-tenant.service';
import { Tenant } from '@core/models/tenant.model';


// Animations
import { fadeInAnimation } from '@app/shared/animations/animations';

@Component({
    selector: 'app-plan-selection',
    templateUrl: './plan-selection.component.html',
    styleUrls: ['./plan-selection.component.css'],
    animations: [fadeInAnimation],
})
export class PlanSelectionComponent implements OnInit {

    @Input('size') size: string;
    count = 1;
    orgSize: string;
    term: string = 'annual';
    isAnnual: boolean = true;

    startupPrice: number;
    startupUserPrice: number;
    startupPriceDescription: string;
    startupEstimatedCost: number;

    growthPrice: number;
    growthUserPrice: number;
    growthPriceDescription: string;
    growthEstimatedCost: number;

    openEnterpriseContact: boolean = true;
    enterpriseContactFormError: boolean;
    contactInfo: Tenant;

    entepriseContactForm = new FormGroup({
        name: new FormControl('', [
            Validators.required,
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
        ]),
        organization: new FormControl('', [
            Validators.required,
        ]),
    });

    get name() { return this.entepriseContactForm.get('name'); }
    get email() { return this.entepriseContactForm.get('email'); }
    get organization() { return this.entepriseContactForm.get('organization'); }

    constructor(
        private newTenantService: NewTenantService,
        private router: Router,
        private modalService: BsModalService,
        private snackBarService: SnackBarService,
    ) {
        this.newTenantService.newTenantsDetails.subscribe(tenant => {
            this.orgSize = tenant.size;

        });
    }

    ngOnInit(): void {
        this.startupPrice = 7.20;
        this.startupUserPrice = 0.80;
        this.startupPriceDescription = '$7.20 + $0.80/user each month';
        this.growthPrice = 15.20;
        this.growthUserPrice = 1.60;
        this.growthPriceDescription = '$15.20 + $1.60/user each month';


        this.contactInfo = this.newTenantService.getNewTenantDetails();

        this.entepriseContactForm.patchValue({
            name: this.contactInfo.contact_name,
            email: this.contactInfo.contact_email,
            organization: this.contactInfo.name,
        });

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }


    setSelectedPlan(plan: string) {
        console.log(plan);

        this.newTenantService.setSelectedPlan(plan, this.term);

        this.router.navigate(['/auth/checkout']);

    }

    submitEnterpriseContact() {

        // TODO: Handle Request of enterprise contact. Add to CRM, Email Sales, etc.
        // TODO: Send email to client confirming we've received their request and will be in touch soon.
        this.openEnterpriseContact = !this.openEnterpriseContact;
        this.enterpriseContactFormError = false;

        this.snackBarService.success('Request sent');
    }



    termChange(isAnnual: boolean) {
        console.log(isAnnual);
        if (!isAnnual) {
            this.term = 'monthly';
            this.isAnnual = false;

            this.startupPrice = 9;
            this.startupUserPrice = 1;
            this.startupPriceDescription = '$9 + $1/user each month';
            this.growthPrice = 19;
            this.growthUserPrice = 2;
            this.growthPriceDescription = '$19 + $2/user each month';
        } else {
            this.term = 'annual';
            this.isAnnual = true;

            this.startupPrice = 7.20;
            this.startupUserPrice = 0.80;
            this.startupPriceDescription = '$7.20 + $0.80/user each month';
            this.growthPrice = 15.20;
            this.growthUserPrice = 1.60;
            this.growthPriceDescription = '$15.20 + $1.60/user each month';
        }
    }






    modalRef: BsModalRef;


    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }


}

import { Injectable } from '@angular/core';
import { DataTypes, LinkTypes } from '@app/core/models/content-type-models/content-types.model';

@Injectable({
    providedIn: 'root'
})
export class UrlFormatterService {

    constructor() { }


    async formatUrl(url: string, source: string, type: string): Promise<string> {
        console.log(url, source, type);
        switch(type) {
            case LinkTypes.url: {
                if (url.includes("https://")) {
                    return url
                } else if (url.includes("http://")) {
                    return url
                } else {
                    return 'https://' + url;
                }
                break;
            }
            case LinkTypes.social: {
                console.log('Got here: ', source)
                const res = await this.formatSocial(source, url);
                return res;
            }
            default: {
                if (url.includes("https://")) {
                    return url
                } else if (url.includes("http://")) {
                    return url
                } else {
                    return 'https://' + url;
                }
                break;
            }
        }
    }






    async formatSocial(type: string, handle: string): Promise<string> {
        switch (type) {
            case DataTypes.instagram: {
                if (handle.includes("https://")) {
                    return handle
                } else if (handle.includes("http://")) {
                    return handle
                } else if (handle.includes("@")) {
                    const cleanHandle = handle.replace("@", "");
                    return 'https://www.instagram.com/' + cleanHandle;
                } else {
                    return 'https://www.instagram.com/' + handle;
                }
                break;
            }
            case DataTypes.twitter: {
                if (handle.includes("https://")) {
                    return handle
                } else if (handle.includes("http://")) {
                    return handle
                } else {
                    return 'https://www.twitter.com/' + handle;
                }
                break;
            }
            case DataTypes.snapchat: {
                if (handle.includes("https://")) {
                    return handle
                } else if (handle.includes("http://")) {
                    return handle
                } else {
                    return 'https://www.snapchat.com/add/' + handle;
                }
                break;
            }
            default: {
                if (handle.includes("https://")) {
                    return handle
                } else if (handle.includes("http://")) {
                    return handle
                } else {
                    return 'https://' + handle;
                }
                break;
            }
        }
        
        return ''
    }

}


<app-side-nav></app-side-nav>
<app-top-nav></app-top-nav>

<div class="container-fluid" [@fade-in]>


    <h2 class="h2">Admin Settings</h2>

    <div class="mt-4 mb-4">
        <div *ngIf="admin">
            <tabset class="" type="pills" >
                <tab *ngIf="admin" heading="General" customClass="customTabs">
                    <app-admin-general></app-admin-general>
                </tab>
                <tab *ngIf="admin" heading="Templates" customClass="customTabs">
                    <app-admin-template></app-admin-template>
                </tab>
                <tab *ngIf="admin" heading="Team" customClass="customTabs">
                    <app-admin-team></app-admin-team>
                </tab>
                <tab *ngIf="admin" heading="Invitations" customClass="customTabs">
                    <app-admin-pending-invites></app-admin-pending-invites>
                </tab>
                <tab *ngIf="owner" heading="Billing" customClass="customTabs">
                    <app-admin-billing></app-admin-billing>
                </tab>
                
            </tabset>
        </div>
    </div> 
</div>

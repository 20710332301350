<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isBasicCollapsed)?'section-text-light':''">
            Basic & Media
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingBasic()" [ngClass]="(!isBasicCollapsed)?'active':''"
        [attr.aria-expanded]="!isBasicCollapsed" aria-controls="isBasicCollapsed">
            <svg *ngIf="isBasicCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isBasicCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isBasicCollapsed" [collapse]="isBasicCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Background Color
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.background_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="backgroundColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.background_color"/> 
        
                        <div class="color-text">
                            {{ currentTemplateSettings.web.background_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>



            <div class="control-item">
                <div class="current-item-label">
                    Border Color
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->
                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.border_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="borderColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.border_color"/> 

                        <div class="color-text">
                            {{ currentTemplateSettings.web.border_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Cover Corner Radius
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <form class="form-inline">
                        <div class="form-group">
                          <div class="btn-group" >
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_cover_corner_radius === 0)?'active':''" (click)="mediaCoverCornerChange(0)">0</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_cover_corner_radius === 12)?'active':''" (click)="mediaCoverCornerChange(12)">12</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_cover_corner_radius === 50)?'active':''" (click)="mediaCoverCornerChange(50)">50</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_cover_corner_radius === 100)?'active':''" (click)="mediaCoverCornerChange(100)">100</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_cover_corner_radius === 125)?'active':''" (click)="mediaCoverCornerChange(125)">125</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_cover_corner_radius === 150)?'active':''" (click)="mediaCoverCornerChange(150)">150</button>
                          </div>
                          
                          <!-- <button class="btn btn-primary" (click)="cornerRadiusFormGroup.disabled ? cornerRadiusFormGroup.enable() : cornerRadiusFormGroup.disable()">Enable/Disable</button> -->
                        </div>
                       
                    </form>
                    
                </div>
                <div class="current-item-border"></div>
            </div>

            <div class="control-item">
                <div class="current-item-label">
                    Headshot Corner Radius
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <form class="form-inline">
                        <div class="form-group">
                          <div class="btn-group" >
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_headshot_corner_radius === 0)?'active':''" (click)="mediaHeadshotCornerChange(0)">0</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_headshot_corner_radius === 12)?'active':''" (click)="mediaHeadshotCornerChange(12)">12</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_headshot_corner_radius === 25)?'active':''" (click)="mediaHeadshotCornerChange(25)">25</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_headshot_corner_radius === 75)?'active':''" (click)="mediaHeadshotCornerChange(75)">75</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_headshot_corner_radius === 100)?'active':''" (click)="mediaHeadshotCornerChange(100)">100</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.media_headshot_corner_radius === 125)?'active':''" (click)="mediaHeadshotCornerChange(125)">125</button>
                          </div>
                          
                          <!-- <button class="btn btn-primary" (click)="cornerRadiusFormGroup.disabled ? cornerRadiusFormGroup.enable() : cornerRadiusFormGroup.disable()">Enable/Disable</button> -->
                        </div>
                       
                    </form>
                    
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Media Shadow
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.media_shadow_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="mediaShadowChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.media_shadow_color"/> 
                       


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.media_shadow_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Section Text Color
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.section_text_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="sectionTextColorChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.section_text_color"/> 
        
                        <div class="color-text">
                            {{ currentTemplateSettings.web.section_text_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>




            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Background Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_background_color"  [(ngModel)]="currentTemplateSettings.web.can_edit_background_color" (ngModelChange)="allowUserBackgroundStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_background_color" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_background_color" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change background styling.</small>
                </div>
                <div class="current-item-border"></div>
            </div>
            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Media Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_media_style"  [(ngModel)]="currentTemplateSettings.web.can_edit_media_style" (ngModelChange)="allowUserMediaStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_media_style" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_media_style" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change media styling.</small>
                </div>
                <div class="current-item-border"></div>
            </div>
            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Border Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_border_style"  [(ngModel)]="currentTemplateSettings.web.can_edit_border_style" (ngModelChange)="allowUserBorderStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_border_style" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_border_style" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change border styling.</small>
                </div>
                <div class="current-item-border"></div>
            </div>
            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Section Text Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_section_text_color"  [(ngModel)]="currentTemplateSettings.web.can_edit_section_text_color" (ngModelChange)="allowUserSectionTextStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_section_text_color" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_section_text_color" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change section text styling.</small>
                </div>
            </div>
        </div>
    </div>





































    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isQuickLinksButtonCollapsed)?'section-text-light':''">
            Quick Links
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingQuickLinks()" [ngClass]="(!isQuickLinksButtonCollapsed)?'active':''"
        [attr.aria-expanded]="!isQuickLinksButtonCollapsed" aria-controls="isQuickLinksButtonCollapsed">
            <svg *ngIf="isQuickLinksButtonCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isQuickLinksButtonCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isQuickLinksButtonCollapsed" [collapse]="isQuickLinksButtonCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Fill
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.quick_links_fill_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="quickLinksFillChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.quick_links_fill_color"/> 
                        <!-- (colorPickerChange)="bg = $event" -->


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.quick_links_fill_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>

            <div class="control-item">
                <div class="current-item-label">
                    Text
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->
                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.quick_links_text_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="quickLinkTextChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.quick_links_text_color"/> 

                        <!-- <div class="color-box shadow" style="background-color: #464646;"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.quick_links_text_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>



            <div class="control-item">
                <div class="current-item-label">
                    Corner Radius
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <form class="form-inline">
                        <div class="form-group">
                          <div class="btn-group" >
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.quick_links_corner_radius === 0)?'active':''" (click)="quickLinkCornerChange(0)">0</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.quick_links_corner_radius === 4)?'active':''" (click)="quickLinkCornerChange(4)">4</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.quick_links_corner_radius === 12)?'active':''" (click)="quickLinkCornerChange(12)">12</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.quick_links_corner_radius === 25)?'active':''" (click)="quickLinkCornerChange(25)">25</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.quick_links_corner_radius === 50)?'active':''" (click)="quickLinkCornerChange(50)">50</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.quick_links_corner_radius === 75)?'active':''" (click)="quickLinkCornerChange(75)">75</button>
                          </div>
                          
                          <!-- <button class="btn btn-primary" (click)="cornerRadiusFormGroup.disabled ? cornerRadiusFormGroup.enable() : cornerRadiusFormGroup.disable()">Enable/Disable</button> -->
                        </div>
                       
                    </form>
                    
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Shadow
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.quick_links_shadow_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="quickLinkShadowChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.quick_links_shadow_color"/> 
                       


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.quick_links_shadow_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>

            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Quick Link Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_quick_link_style"  [(ngModel)]="currentTemplateSettings.web.can_edit_quick_link_style" (ngModelChange)="allowUserQuickLinkStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_quick_link_style" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_quick_link_style" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change quick link styling.</small>
                </div>
            </div>
        </div>
    </div>








































    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isCtaButtonCollapsed)?'section-text-light':''">
            CTA Button
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingCta()" [ngClass]="(!isCtaButtonCollapsed)?'active':''"
        [attr.aria-expanded]="!isCtaButtonCollapsed" aria-controls="isCtaButtonCollapsed">
            <svg *ngIf="isCtaButtonCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isCtaButtonCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isCtaButtonCollapsed" [collapse]="isCtaButtonCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Fill
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.cta_fill_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="ctaFillChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.cta_fill_color"/> 
                        <!-- (colorPickerChange)="bg = $event" -->


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.cta_fill_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>

            <div class="control-item">
                <div class="current-item-label">
                    Text
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->
                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.cta_text_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="ctaTextChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.cta_text_color"/> 

                        <!-- <div class="color-box shadow" style="background-color: #464646;"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.cta_text_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>



            <div class="control-item">
                <div class="current-item-label">
                    Corner Radius
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <form class="form-inline">
                        <div class="form-group">
                          <div class="btn-group" >
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.cta_corner_radius === 0)?'active':''" (click)="ctaCornerChange(0)">0</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.cta_corner_radius === 4)?'active':''" (click)="ctaCornerChange(4)">4</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.cta_corner_radius === 8)?'active':''" (click)="ctaCornerChange(8)">8</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.cta_corner_radius === 12)?'active':''" (click)="ctaCornerChange(12)">12</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.cta_corner_radius === 25)?'active':''" (click)="ctaCornerChange(25)">25</button>
                            <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.cta_corner_radius === 50)?'active':''" (click)="ctaCornerChange(50)">50</button>
                          </div>
                          
                          <!-- <button class="btn btn-primary" (click)="cornerRadiusFormGroup.disabled ? cornerRadiusFormGroup.enable() : cornerRadiusFormGroup.disable()">Enable/Disable</button> -->
                        </div>
                       
                    </form>
                    
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Shadow
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.cta_shadow_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="ctaShadowChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.cta_shadow_color"/> 
                       


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.cta_shadow_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow CTA Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_cta_style"  [(ngModel)]="currentTemplateSettings.web.can_edit_cta_style" (ngModelChange)="allowUserCtaStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_cta_style" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_cta_style" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change CTA styling.</small>
                </div>
            </div>
        </div>
    </div>






















    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text" [ngClass]="(isAccessoryItemsCollapsed)?'section-text-light':''">
            Accessory Items
        </div>

        <button class="nav-btn" [routerLink]="" (click)="togglingAccessory()" [ngClass]="(!isAccessoryItemsCollapsed)?'active':''"
        [attr.aria-expanded]="!isAccessoryItemsCollapsed" aria-controls="isAccessoryItemsCollapsed">
            <svg *ngIf="isAccessoryItemsCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-down" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 7 12 12 17 7" />
                <polyline points="7 13 12 18 17 13" />
            </svg>

            <svg *ngIf="!isAccessoryItemsCollapsed" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevrons-up" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="7 11 12 6 17 11" />
                <polyline points="7 17 12 12 17 17" />
            </svg>
        </button>

    </div>

    <div id="isAccessoryItemsCollapsed" [collapse]="isAccessoryItemsCollapsed" [isAnimated]="true">
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Fill
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.accessory_item_fill_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="accessoryFillChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.accessory_item_fill_color"/> 
                       


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.accessory_item_fill_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>

            <div class="control-item">
                <div class="current-item-label">
                    Text
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->
                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.accessory_item_text_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="accessoryTextChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.accessory_item_text_color"/> 

                        <!-- <div class="color-box shadow" style="background-color: #464646;"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.accessory_item_text_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>



            <div class="control-item">
                <div class="current-item-label">
                    Corner Radius
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <form class="form-inline">
                        <div class="form-group">
                            <div class="btn-group">
                                <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.accessory_item_corner_radius === 0)?'active':''" (click)="accessoryCornerChange(0)">0</button>
                                <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.accessory_item_corner_radius === 4)?'active':''" (click)="accessoryCornerChange(4)">4</button>
                                <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.accessory_item_corner_radius === 8)?'active':''" (click)="accessoryCornerChange(8)">8</button>
                                <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.accessory_item_corner_radius === 12)?'active':''" (click)="accessoryCornerChange(12)">12</button>
                                <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.accessory_item_corner_radius === 25)?'active':''" (click)="accessoryCornerChange(25)">25</button>
                                <button class="nav-btn radius-btn" [ngClass]="(currentTemplateSettings.web.accessory_item_corner_radius === 50)?'active':''" (click)="accessoryCornerChange(50)">50</button>
                            </div>
                        </div>
                    </form>
                    
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Shadow
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <!-- <div class="stretched-link" [routerLink]=""></div> -->

                        <input class="form-control color-box shadow" [style.background]="currentTemplateSettings.web.accessory_item_shadow_color"
                        [cpAlphaChannel]="'always'"
                        [cpOutputFormat]="'rgba'"
                        [cpOKButton]="false"
                        [cpOKButtonClass]="'btn btn-sm btn-primary'"
                        [cpCancelButton]="false"
                        [cpCancelButtonClass]="'btn btn-sm btn-primary'"
                        [cpAlphaChannel]="'disabled'"
                        readonly
                        (colorPickerChange)="accessoryShadowChange($event)"
                        [(colorPicker)]="currentTemplateSettings.web.accessory_item_shadow_color"/> 
                       


                        <!-- <div class="color-box shadow"></div> -->
                        <div class="color-text">
                            {{ currentTemplateSettings.web.accessory_item_shadow_color }}
                        </div>
                    </div>
                    <!-- Current Item -->
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item control-item-switch">
                <div class="current-item-label">
                    Allow Accessory Item Styling
                </div>
                <div class="current-item current-item-switch">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.can_edit_acessory_style"  [(ngModel)]="currentTemplateSettings.web.can_edit_acessory_style" (ngModelChange)="allowUserAccessoryItemStyling($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.can_edit_acessory_style" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.can_edit_acessory_style" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, team members will be able to change accessory item styling.</small>
                </div>
            </div>
        </div>
    </div>

</div>















































<div id="preview" class="h-100">

    <div class="h-100 preview-bg">
        <div class="preview-container">

            <div class="card shadow" [@fade-in] *ngIf="!isBasicCollapsed" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Background & Border</span>
                    </div>

                    <img [src]="coverPhotoTemp" *ngIf="coverPhotoTemp" class="web-media-cover" alt="..." [style.border-color]="currentTemplateSettings.web.border_color" 
                    [style.background-color]="currentTemplateSettings.web.background_color" 
                    [style.border-radius.px]="currentTemplateSettings.web.media_cover_corner_radius"
                    [ngStyle]="mediaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                    (mouseover)="mediaHovered[0] = true"
                    (mouseout)="mediaHovered[0]= false"
                    >

                    <img [src]="currentTemplateSettings.web.cover_photo" *ngIf="!coverPhotoTemp && currentTemplateSettings.web.cover_photo" class="web-media-cover" alt="..." [style.border-color]="currentTemplateSettings.web.border_color" 
                    [style.background-color]="currentTemplateSettings.web.background_color" 
                    [style.border-radius.px]="currentTemplateSettings.web.media_cover_corner_radius"
                    [ngStyle]="mediaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                    (mouseover)="mediaHovered[0] = true"
                    (mouseout)="mediaHovered[0]= false"
                    >

                    <div class="web-media-cover" *ngIf="!coverPhotoTemp && !currentTemplateSettings.web.cover_photo" [style.border-color]="currentTemplateSettings.web.border_color" 
                    [style.background-color]="currentTemplateSettings.web.background_color" 
                    [style.border-radius.px]="currentTemplateSettings.web.media_cover_corner_radius"
                    [ngStyle]="mediaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                    (mouseover)="mediaHovered[0] = true"
                    (mouseout)="mediaHovered[0]= false"
                    ></div>

                    <div class="">
                        <div class="web-media-headshot" 
                        [style.border-color]="currentTemplateSettings.web.border_color" 
                        [style.background-color]='currentTemplateSettings.web.background_color'
                        [style.border-radius.px]="currentTemplateSettings.web.media_headshot_corner_radius"
                        [ngStyle]="mediaHovered[1] ? shadowHovered(this.currentTemplateSettings.web.media_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.media_shadow_color)"
                        (mouseover)="mediaHovered[1] = true"
                        (mouseout)="mediaHovered[1]= false"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="80" height="80" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="currentTemplateSettings.web.border_color" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Basic-->
            <div class="card shadow mt-4" [@fade-in] *ngIf="!isBasicCollapsed" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="web-section-title" [style.color]="currentTemplateSettings.web.section_text_color">This is a section title</div>

                </div>
            </div>
            




















            <!-- QUICK LINKS -->
            <div class="card shadow" [@fade-in] *ngIf="!isQuickLinksButtonCollapsed" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Quick Links</span>
                    </div>
                    <div class="web-content-container">


                        <div class="d-flex web-quick-link-container justify-content-between">
                            <div class="web-quick-link" 
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[0] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[0] = true"
                            (mouseout)="quickLinksHovered[0]= false">
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_one_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>

                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_one_content_label || currentTemplateSettings.web.quick_link_one_content_type.name }}</div>
                                </div>
                                
                            </div> 

                            <div class="web-quick-link" 
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[1] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[1] = true"
                            (mouseout)="quickLinksHovered[1]= false">

                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_two_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>

                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_two_content_label || currentTemplateSettings.web.quick_link_two_content_type.name }}</div>
                                </div>
                                
                            </div>  

                            <div class="web-quick-link" 
                            [style.background-color]="currentTemplateSettings.web.quick_links_fill_color" 
                            [style.color]="currentTemplateSettings.web.quick_links_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.quick_links_corner_radius" 
                            [ngStyle]="quickLinksHovered[2] ? shadowHovered(this.currentTemplateSettings.web.quick_links_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.quick_links_shadow_color)"
                            (mouseover)="quickLinksHovered[2] = true"
                            (mouseout)="quickLinksHovered[2]= false">
                                <div class="web-quick-link-child">
                                    <app-svg-assets [type]="currentTemplateSettings.web.quick_link_three_content_type.type.data_type" [size]="60" [stroke]="currentTemplateSettings.web.quick_links_text_color"></app-svg-assets>

                                    <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.quick_link_three_content_label || currentTemplateSettings.web.quick_link_three_content_type.name }}</div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>






















            <!-- CTA -->
            <div class="card shadow" [@fade-in] *ngIf="!isCtaButtonCollapsed" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Call To Action Button</span>
                    </div>
                    <div class="web-content-container">

                        <div class="web-accessory-link" 
                        [style.background-color]="currentTemplateSettings.web.cta_fill_color" 
                        [style.color]="currentTemplateSettings.web.cta_text_color" 
                        [style.border-radius.px]="currentTemplateSettings.web.cta_corner_radius" 
                        [ngStyle]="ctaHovered[0] ? shadowHovered(this.currentTemplateSettings.web.cta_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.cta_shadow_color)"
                        (mouseover)="ctaHovered[0] = true"
                        (mouseout)="ctaHovered[0]= false">
                            <div class="d-flex">
                                <app-svg-assets [type]="currentTemplateSettings.web.cta_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.cta_text_color"></app-svg-assets>

                                <div class="web-accessory-link-text">{{ currentTemplateSettings.web.cta_content_label || currentTemplateSettings.web.cta_content_type.name }}</div>
                            </div>
                        </div>

                    </div>



                    <!-- <pre class="card card-block card-header mt-5">{{currentTemplateSettings | json}}</pre> -->
                </div>
            </div>
















            <!-- Acessory Items -->
            <div class="card shadow" [@fade-in] *ngIf="!isAccessoryItemsCollapsed" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Accessory Items</span>
                    </div>
                    <!-- <p>{{ radius }}</p>
                    <pre class="card card-block card-header">{{ cornerRadiusFormGroup.value | json }}</pre> -->
                    <div class="web-content-container">

                        <div class="web-accessory-link" 
                        [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                        [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                        [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                        [ngStyle]="accessoryHovered[0] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                        (mouseover)="accessoryHovered[0] = true"
                        (mouseout)="accessoryHovered[0]= false">
                            <div class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="this.currentTemplateSettings.web.accessory_item_text_color" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-12" />
                                </svg>

                                <div class="web-accessory-link-text">Link Text One</div>
                            </div>
                        </div>

                        <div class="web-accessory-link"
                        [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                        [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                        [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                        [ngStyle]="accessoryHovered[1] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                        (mouseover)="accessoryHovered[1] = true"
                        (mouseout)="accessoryHovered[1]= false">
                            <div class="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-3d-cube-sphere" width="40" height="40" viewBox="0 0 24 24" stroke-width="1" [style.stroke]="this.currentTemplateSettings.web.accessory_item_text_color" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M6 17.6l-2 -1.1v-2.5" />
                                    <path d="M4 10v-2.5l2 -1.1" />
                                    <path d="M10 4.1l2 -1.1l2 1.1" />
                                    <path d="M18 6.4l2 1.1v2.5" />
                                    <path d="M20 14v2.5l-2 1.12" />
                                    <path d="M14 19.9l-2 1.1l-2 -1.1" />
                                    <line x1="12" y1="12" x2="14" y2="10.9" />
                                    <line x1="18" y1="8.6" x2="20" y2="7.5" />
                                    <line x1="12" y1="12" x2="12" y2="14.5" />
                                    <line x1="12" y1="18.5" x2="12" y2="21" />
                                    <path d="M12 12l-2 -1.12" />
                                    <line x1="6" y1="8.6" x2="4" y2="7.5" />
                                  </svg>

                                <div class="web-accessory-link-text">Link Text Two</div>
                            </div>
                        </div>



                        <div class="web-accessory-textarea"
                        [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                        [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                        [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                        [ngStyle]="accessoryHovered[2] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                        (mouseover)="accessoryHovered[2] = true"
                        (mouseout)="accessoryHovered[2]= false">
                            
                            <div class="web-accessory-textarea-header">
                                Header
                            </div>
                            <div class="web-accessory-textarea-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non voluptas blanditiis minus corrupti accusantium deserunt consequuntur, facere at magnam nostrum natus? Temporibus aut neque quo eius perferendis facere ratione repudiandae.</div>
                        
                        </div>

                    </div>
                </div>
            </div>











        </div>
        
    </div>
































    
    
    <!-- <div class="">

    </div> -->
</div>
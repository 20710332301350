import { Component, OnInit } from '@angular/core';

// Form Info
import { STEP_ITEMS } from '@app/shared/forms/new-program.form';

import { fadeInAnimation } from '@app/shared/animations/animations';

// SERVICES
import { NewTenantService } from '@core/services/tenant/new-tenant/new-tenant.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-organization-setup',
    templateUrl: './organization-setup.component.html',
    styleUrls: ['./organization-setup.component.css'],
    animations: [
        fadeInAnimation
    ]
})
export class OrganizationSetupComponent implements OnInit {

    orgFormContent: any;

    orgFormData: any;

    activeStepIndex: number;

    showOrgSetupForm: boolean = true;
    showPlanSelection: boolean = false;

    viewFormSub: Subscription;
    viewPlanSub: Subscription;

    constructor(
        private newTenantService: NewTenantService,
    ) {
        this.viewFormSub = this.newTenantService.showForm.subscribe(form => {
            this.showOrgSetupForm = form;
        });
        this.viewPlanSub = this.newTenantService.showPlan.subscribe(plan => {
            this.showPlanSelection = plan;
        });

    }

    ngOnInit(): void {
        this.orgFormContent = STEP_ITEMS;
        this.orgFormData = {};

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }


    // Handles submittal of basic org setup info
    onFormSubmit(formData: any): void {
        this.orgFormData = formData;

        console.log('Org Form Details: ', this.orgFormData);

        this.newTenantService.storeNewTenantFormData(this.orgFormData);

        this.newTenantService.showPlanSelection();
        this.showOrgSetupForm = false;
        this.showPlanSelection = true;
    }


    backToOrgSetup() {
        this.showOrgSetupForm = true;
        this.showPlanSelection = false;
    }
}

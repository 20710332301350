<div class="notif-nav-bg" (click)="sideNavService.toggleNav()" *ngIf="open" [ngClass]="open ? 'notif-nav-bg-open' : 'notif-nav-bg-closed'">
  <div class="notif-nav" [ngClass]="open ? 'notif-nav-open' : 'notif-nav-closed'">



    <!-- <div class="d-flex master-buttons justify-content-between">
      <button class="nav-btn" routerLink="/admin">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
      </button>

      <button class="btn btn-sm btn-primary save w-75">Save</button>
    </div> -->

 
  <nav class="nav flex-column">
    <a class="nav-link" routerLink="/">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-apps" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="4" width="6" height="6" rx="1" />
          <rect x="4" y="14" width="6" height="6" rx="1" />
          <rect x="14" y="14" width="6" height="6" rx="1" />
          <line x1="14" y1="7" x2="20" y2="7" />
          <line x1="17" y1="4" x2="17" y2="10" />
        </svg>
        <div class="nav-text">Dashboard</div>
      </div>
    </a>




    <a class="nav-link" aria-current="page" [routerLink]="">
        <div class="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rectangle-vertical" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <rect x="5" y="3" width="14" height="18" rx="2" />
          </svg>
          <div class="nav-text">Social Cards</div>
        </div>
    </a>

    <a *ngIf="growthPlan" class="nav-link" aria-current="page" routerLink="/departments">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bubble" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <circle cx="6" cy="16" r="3" />
          <circle cx="16" cy="19" r="2" />
          <circle cx="14.5" cy="7.5" r="4.5" />
        </svg>
          <div class="nav-text">Departments</div>
      </div>
    </a>

    <a class="nav-link" aria-current="page" routerLink="/analytics">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bar" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="3" y="12" width="6" height="8" rx="1" />
          <rect x="9" y="8" width="6" height="12" rx="1" />
          <rect x="15" y="4" width="6" height="16" rx="1" />
          <line x1="4" y1="20" x2="18" y2="20" />
        </svg>
        <div class="nav-text">Analytics</div>
      </div>
    </a>


    <p *ngIf="admin" class="notif-heading section-subtitle mb-0">Admin</p>
    <a *ngIf="admin" class="nav-link" aria-current="page" [routerLink]="">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-area-line" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />
          <polyline points="4 12 7 8 11 10 16 4 20 8" />
        </svg>
        <div class="nav-text admin-nav">Admin Analytics</div>
      </div>
    </a>

    <!-- <a class="nav-link" aria-current="page" routerLink="/profile">
        <div class="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="7" r="4" />
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          </svg>
          <div class="nav-text">Profile</div>
        </div>
    </a> -->

    <a *ngIf="admin" class="nav-link" aria-current="page" routerLink="/admin">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments-alt" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="8" width="4" height="4" />
          <line x1="6" y1="4" x2="6" y2="8" />
          <line x1="6" y1="12" x2="6" y2="20" />
          <rect x="10" y="14" width="4" height="4" />
          <line x1="12" y1="4" x2="12" y2="14" />
          <line x1="12" y1="18" x2="12" y2="20" />
          <rect x="16" y="5" width="4" height="4" />
          <line x1="18" y1="4" x2="18" y2="5" />
          <line x1="18" y1="9" x2="18" y2="20" />
        </svg>
          <div class="nav-text">Admin Settings</div>
      </div>
    </a>

    

    <!-- <a class="nav-link" aria-current="page" [routerLink]="">
      <div class="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments-alt" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <rect x="4" y="8" width="4" height="4" />
          <line x1="6" y1="4" x2="6" y2="8" />
          <line x1="6" y1="12" x2="6" y2="20" />
          <rect x="10" y="14" width="4" height="4" />
          <line x1="12" y1="4" x2="12" y2="14" />
          <line x1="12" y1="18" x2="12" y2="20" />
          <rect x="16" y="5" width="4" height="4" />
          <line x1="18" y1="4" x2="18" y2="5" />
          <line x1="18" y1="9" x2="18" y2="20" />
        </svg>
          <div class="nav-text">Log Out</div>
      </div>
    </a> -->

    <!-- <button class="btn btn-sm btn-auxiliary w-100" (click)="logout()">Log Out</button> -->
    
  </nav>



    <!-- <div class="d-flex w-100 justify-content-between">
      <p class="notif-heading section-subtitle mb-0">Navigation</p>
    </div>

    <div class="">
      <a class="nav-link btn btn-sm btn-gradient m-3 mb-4" routerLink="/">
        New Social Card
      </a>
      <a class="nav-link btn btn-sm btn-primary m-3" routerLink="/">
        Home
      </a>
      <a class="nav-link btn btn-sm btn-primary m-3" routerLink="/cards">
        Social Cards
      </a>
      <a class="nav-link btn btn-sm btn-primary m-3" routerLink="/">
        Email Signatures
      </a>
      <a class="nav-link btn btn-sm btn-primary m-3 mt-4" routerLink="/help">
        Help Center
      </a>

    </div>

    <div class="" >
      <div class="d-flex w-100 justify-content-between">
        <p class="notif-heading section-subtitle mb-0">Admin</p>
      </div>
  
      <div class="">
        <a class="nav-link btn btn-sm btn-gradient m-3 mb-4">Invite Team Member</a>
        <a class="nav-link btn btn-sm btn-primary m-3" routerLink="/admin">
          Admin Settings
        </a>
        
      </div>
    </div>
    

    <div class="d-flex w-100 justify-content-between">
      <p class="notif-heading section-subtitle mb-0">Options & More</p>
    </div>

    <div class="">
      <a class="nav-link btn btn-sm btn-primary m-3" routerLink="/profile">
        Profile
      </a>
      <a class="nav-link btn btn-sm btn-auxiliary m-3" routerLink="/auth/orgs">
        Switch Organizations
      </a>
      <a class="nav-link btn btn-sm btn-auxiliary m-3" [routerLink]="" (click)="logout()">
        Log Out
      </a>
    </div> -->
    

  </div>

</div>

<nav class="topnav navbar navbar-expand fixed-top navbar-light bg">
    <!-- <a class="navbar-brand" routerLink="/"><img class="nav-logo" src="assets/logo_gradient/Logo-Gradient.svg"> | ENTERPRISE</a> -->
    <a class="navbar-brand mr-4" routerLink="/"><img class="nav-logo" src="assets/web_logo.svg"></a>
    <!-- <a class="navbar-brand" routerLink="/"><img class="nav-logo " src="assets/icon_clear.svg"></a> -->


    <button (click)="sideNavService.toggleNav()" class="nav-btn order-1 order-lg-0" id="sidebarToggle">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#464646" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" />
        </svg>
    </button>
    <!-- Navbar-->
    <ul class="navbar-nav ml-auto mr-0 mr-md-3 my-2 my-md-0">

        <!-- Notifications Dropdown -->
        <button (click)="toggleProfileNav()" class="nav-link nav-btn mr-4 mr-lg-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="7" r="4" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            </svg>
        </button>
    </ul>
    
</nav>
<app-profile-nav></app-profile-nav>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';

// Services
import { UserService } from '@app/core/services/user/user.service';
import { TenantService } from '@app/core/services/tenant/tenant.service';
import { environment } from '@env/environment';



@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    role: string;
    roleSub: Subscription;

    private userRoleSubject = new BehaviorSubject<string>(null);
    public userRole = this.userRoleSubject.asObservable().pipe(distinctUntilChanged());

    plan: string;
    planSub: Subscription;

    private tenantPlanSubject = new BehaviorSubject<string>(null);
    public tenantPlan = this.tenantPlanSubject.asObservable().pipe(distinctUntilChanged());


    constructor(
        private router: Router,
        private userService: UserService,
        private tenantService: TenantService,
    ) {
        this.roleSub = this.userService.currentUser.subscribe(user => {
            this.role = user.role;
            this.userRoleSubject.next(this.role);
        });

        this.planSub = this.tenantService.activeTenantSubscription.subscribe(sub => {
            this.plan = sub.plan;
            this.tenantPlanSubject.next(this.plan);
        })
    }

    validatePermission(requiredRole: string): boolean {
        if (requiredRole === 'owner') {

            if (this.userRoleSubject.value === 'owner') {
                return true;
            } else {
                return false;
            }

        }
        else if (requiredRole === 'admin') {

            if (this.userRoleSubject.value === 'owner' || this.userRoleSubject.value === 'admin') {
                return true;
            } else {
                return false;
            }

        }
        else if (requiredRole === 'user') {

            if (this.userRoleSubject.value === 'owner' || this.userRoleSubject.value === 'admin' || this.userRoleSubject.value === 'user') {
                return true;
            } else {
                return false;
            }

        }
        else {
            return false;
        }
    }




    validateGrowthPlan(): boolean {
        switch(this.tenantPlanSubject.value) {
            case environment.stripe.growthAnnualPriceId: {
                return true
                break;
            }
            case environment.stripe.growthMonthlyPriceId: {
                return true
                break;
            }
            case environment.stripe.startupAnnualPriceId: {
                return false
                break;
            }
            case environment.stripe.startupMonthlyPriceId: {
                return false
                break;
            }
            default: {
                return false
                break;
            }
        }
    }

}

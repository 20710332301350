import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { fadeInAnimation } from '@app/shared/animations/animations';

import { TenantService } from '@core/services/tenant/tenant.service';
import { OrgLogoService } from '@core/services/org-logo/org-logo.service';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Tenant } from '@app/core/models/tenant.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-admin-general',
    templateUrl: './admin-general.component.html',
    styleUrls: ['./admin-general.component.css'],
    animations: [fadeInAnimation],
})
export class AdminGeneralComponent implements OnInit, OnDestroy {

    tenantSub: Subscription;
    tenant: Tenant;

    generalOrgInfoForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        website: new FormControl(''),
        industry: new FormControl('', [Validators.required]),
        size: new FormControl('', [Validators.required]),
    });

    orgContactForm = new FormGroup({
        contact_name: new FormControl('', [Validators.required]),
        contact_email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]),
        contact_phone: new FormControl('', [Validators.required]),
    });

    orgVanityUrlForm = new FormGroup({
        url: new FormControl('', [Validators.required]),
    });

    get name() { return this.generalOrgInfoForm.get('name'); }
    get website() { return this.generalOrgInfoForm.get('website'); }
    get industry() { return this.generalOrgInfoForm.get('industry'); }
    get size() { return this.generalOrgInfoForm.get('size'); }

    get contact_name() { return this.orgContactForm.get('contact_name'); }
    get contact_email() { return this.orgContactForm.get('contact_email'); }
    get contact_phone() { return this.orgContactForm.get('contact_phone'); }

    get url() { return this.orgVanityUrlForm.get('url'); }

    sizeList = [
        { name: '1-10', nickname: '1-10' },
        { name: '11-50', nickname: '11-50' },
        { name: '51-200', nickname: '51-200' },
        { name: '201-500', nickname: '201-500' },
        { name: '501-1,000', nickname: '501-1,000' },
        { name: '1,001-5,000', nickname: '1,001-5,000' },
        { name: '5,001-10,000', nickname: '5,001-10,000' },
        { name: '10,001+', nickname: '10,001+' }
    ];



    logoDataSub: Subscription;
    logoLocalUrl: any;
    canSaveLogo: boolean = false;



    constructor(
        private storage: AngularFireStorage,
        private tenantService: TenantService,
        private orgLogoService: OrgLogoService,
        private modalService: BsModalService,
        private snackBarService: SnackBarService,
        private spinner: NgxSpinnerService,
    ) {
        this.tenantSub = this.tenantService.activeTenant.subscribe(tenant => {
            console.log("Tenant in comp", tenant);

            this.tenant = tenant;

            this.generalOrgInfoForm.patchValue({
                name: tenant.name,
                website: tenant.website,
                industry: tenant.industry,
                size: tenant.size,
            });

            this.orgContactForm.patchValue({
                contact_name: tenant.contact_name,
                contact_phone: tenant.contact_phone,
                contact_email: tenant.contact_email,
            });

        })


        this.logoDataSub = this.orgLogoService.logoData.subscribe(img => {
            if (img === null) {
                this.canSaveLogo = false;
            } else {
                this.canSaveLogo = true;
            }
        })
    }

    ngOnInit(): void {
        this.getOrgLogo();
    }
    ngOnDestroy(): void {
        this.tenantSub.unsubscribe();
        this.logoDataSub.unsubscribe();
    }

    // Updates the organizations basic info
    async updateOrgInfo() {
        this.spinner.show('orgInfoUpdateSpinner');
        await this.tenantService.updateTenantBasicInfo(this.generalOrgInfoForm.value).then(_ => {
            // Show success notif & stop animations
            this.modalRef.hide();
            this.spinner.hide('orgInfoUpdateSpinner');
            this.snackBarService.success('Organization info successfully updated');
        }).catch(err => {
            this.spinner.hide('orgInfoUpdateSpinner');
            // handle err
            this.snackBarService.error('Failed to complete update');
        });
    }

    async updateOrgContactInfo() {
        this.spinner.show('orgContactUpdateSpinner');

        console.log(this.orgContactForm.value);
        await this.tenantService.updateTenantContanctInfo(this.orgContactForm.value).then(_ => {
            // Show success notif & stop animations
            this.modalRef.hide();
            this.spinner.hide('orgContactUpdateSpinner');
            this.snackBarService.success('Organization contact info successfully updated');
        }).catch(err => {
            this.spinner.hide('orgContactUpdateSpinner');
            // handle err
            this.snackBarService.error('Failed to complete update');
        });
    }


    // VANITY URL
    async modifyOrgVanityUrl() {

    }


    // LOGO
    async updateOrgLogo() {
        this.spinner.show('orgLogoUpdateSpinner');
        await this.orgLogoService.uploadOrgLogoToStorage().then(res => {
            if (res) {
                this.modalRef.hide();
                this.spinner.hide('orgLogoUpdateSpinner');
                this.snackBarService.success('Logo successfully uploaded');
                this.getOrgLogo();
            }
        }).catch(err => {
            this.spinner.hide('orgLogoUpdateSpinner');
            // handle err
            this.snackBarService.error('Failed to upload logo');
        })
    }
    // downloads logo from storage
    getOrgLogo() {
        const t = sessionStorage.getItem('t');
        const ref = this.storage.ref(`tenants/${t}/media/logo`);
        this.logoLocalUrl = ref.getDownloadURL();
        console.log(this.logoLocalUrl);
    }








    modalRef: BsModalRef;


    openModal(template: TemplateRef<any>) {
        var config = {
            animated: false,
            ignoreBackdropClick: true,
        };
        this.modalRef = this.modalService.show(template, config);
    }
}


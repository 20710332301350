<nav class="topnav navbar navbar-expand fixed-top navbar-light bg mb-4">
    <a class="navbar-brand mr-4"><img class="nav-logo" src="assets/logo_full_gradient.svg"></a>
</nav>

<div class="container-fluid form-container-fluid" [@fade-in]>
    <h2 class="h2">Account Setup</h2>
    <p class="p">Verify your email, and create a password for your personal account.</p>
    <div class="mb-2">
        <small *ngIf="error" class="validation-error">
            {{ errorMessage }}
        </small>
    </div>
    <div class="card shadow">
        <div class="card-body">
            <form [formGroup]="accountLinkForm" (ngSubmit)="linkAccount()">

                <div class="form-group">
                    <label for="email">Email</label>
                    <input class="form-control" formControlName="email" type="email" placeholder="Email" id="email">
                    
                    <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-text text-muted">
                        <small *ngIf="email.errors.required" class="validation-error">A email is required.</small>
                        <small *ngIf="email.errors.pattern" class="validation-error">A valid email address is required.</small>
                    </div>
                </div>
            
                <div class="form-group">
                    <label for="password">Password</label>
                    <input class="form-control" formControlName="password" type="password" placeholder="Password" id="password">
                    
                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="form-text text-muted">
                        <small *ngIf="password.errors.required" class="validation-error">A password is required.</small>
                        <small *ngIf="password.errors.minLength" class="validation-error">A password must be atleast 6 characters.</small>
                    </div>
                </div>
            
                <button type="submit" [disabled]="(accountLinkForm.invalid || accountLinkForm.untouched || accountLinkForm.pristine)" class="btn btn-sm btn-primary mt-4 w-100">Create Account</button>
            </form>
        </div>
    </div>
</div>



<ngx-spinner 
name="accountLinkSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>

</ngx-spinner>






<ng-template #existingAccountLinkModal>
    <div class="modal-card">
        <ngx-spinner 
        name="existingAccountLinkSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <h2 class="mb-2">Login To Existing Account</h2>
                <p class="mb-4">Our system shows an account with this email is already in use. Would you like to login and link this organization to your existing Social Card account?</p>
                <div class="mb-2">
                    <small *ngIf="error" class="validation-error">
                        {{ errorMessage }}
                    </small>
                </div>
                <form [formGroup]="accountLinkForm" (ngSubmit)="loginAndLinkAccount()">

                    <div class="form-group">
                        <label for="email">Email</label>
                        <input class="form-control" formControlName="email" type="email" placeholder="Email" id="email">
                        
                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-text text-muted">
                            <small *ngIf="email.errors.required" class="validation-error">A email is required.</small>
                            <small *ngIf="email.errors.pattern" class="validation-error">A valid email address is required.</small>
                        </div>
                    </div>
                
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input class="form-control" formControlName="password" type="password" placeholder="Password" id="password">
                        
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="form-text text-muted">
                            <small *ngIf="password.errors.required" class="validation-error">A password is required.</small>
                            <small *ngIf="password.errors.minLength" class="validation-error">A password must be atleast 6 characters.</small>
                        </div>
                    </div>
    
                    <div class="mt-auto text-center">
                        <button type="submit" [disabled]="(accountLinkForm.invalid || accountLinkForm.untouched || accountLinkForm.pristine)" class="btn btn-sm btn-primary mt-4 w-100">Login & Link</button>
                        <a (click)="modalRef.hide()" class=""><small>Cancel</small></a>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</ng-template>
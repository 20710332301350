<div class="tab-container" [@fade-in]>
    <h3 class="h3">Your Social Card Team</h3>
    

    <div class="d-flex justify-content-between">
        <p class="mb-0">Showing {{ teamCount }} Member(s)</p>
        <button class="nav-btn" (click)="openSearchModal(searchModal)">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
              </svg>
        </button>
    </div>

    <div class="current-item-border mb-4 mt-2"></div>


    <div class="card shadow mt-4">
        <div class="card-body table-responsive">
            <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" class="card-label">Team Member
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                        </svg> -->
                    </th>
                    <th scope="col" class="card-label">Role
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                        </svg> -->
                    </th>
                    <th scope="col" class="card-label">Email
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)" />
                        </svg> -->
                    </th>
                    <th scope="col" class="card-label text-right"></th>

                  </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of team | keyvalue; index as i;" (click)="openModal(teamMemberModal, user.key)">
                        <td scope="row">
                           
                            <div class="d-flex">
                                <div class="table-avatar-default mr-4 shadow text-center" *ngIf="user.value.photo === '' && user.value.role === 'user'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <circle cx="12" cy="7" r="4" />
                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                    </svg>
                                </div>
                                <div class="table-avatar-default-admin mr-4 shadow text-center" *ngIf="user.value.photo === '' && user.value.role != 'user'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <circle cx="12" cy="7" r="4" />
                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                    </svg>
                                </div>
                                <img *ngIf="user.value.photo != ''" [src]="user.value.photo" class="table-avatar mr-4 shadow">
                                <p class="mb-0 mt-auto mb-auto ">{{ (user.value.name | titlecase) || user.value.email }}</p>
                            </div>
                        </td>
                        <td class="">
                            <p class="">{{ user.value.role | titlecase }}</p>
                        </td>
                        <td class="">
                            <p class="">{{ user.value.email }}</p>
                        </td>
                        <td class="text-right">
                            <p>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-dots" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <circle cx="5" cy="12" r="1" />
                                    <circle cx="12" cy="12" r="1" />
                                    <circle cx="19" cy="12" r="1" />
                                </svg>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>



<ngx-spinner 
name="teamSpinner"
[fullScreen]="true"
bdColor="rgba(255, 255, 255, 0.9)"
size="default"
color="#5D656C"
type = "ball-clip-rotate-multiple"
>
</ngx-spinner>


<ng-template #teamMemberModal>
    <div class="modal-card">
        <ngx-spinner 
        name="teamMemberSpinner"
        [fullScreen]="false"
        bdColor="rgba(255, 255, 255, 0.5)"
        size="default"
        color="#5D656C"
        type = "ball-clip-rotate-multiple"
        >
        </ngx-spinner>
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">Team Member</h2>
                </div>
            
                <div class="current-item-border mb-4 mt-2"></div>
                

                <div class="">
                    
                    <div class="mb-4">
                        <div class="table-avatar-default mr-4 shadow text-center" *ngIf="team[uid].photo === '' && team[uid].role === 'user'">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </div>
                        <div class="table-avatar-default-admin mr-4 shadow text-center" *ngIf="team[uid].photo === '' && team[uid].role != 'user'">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="7" r="4" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                        </div>
                        <img *ngIf="team[uid].photo != ''" [src]="team[uid].photo" class="table-avatar mr-4 shadow">
                    </div>

                    <div class="card-label">Name</div>
                    <p>{{ team[uid].name || 'n/a' }}</p>

                    <div class="card-label">Email</div>
                    <p>{{ team[uid].email }}</p>

                    <div class="card-label">Phone</div>
                    <p>{{ team[uid].phone || 'n/a'}}</p>

                    <div class="card-label">Role</div>
                    <p>{{ team[uid].role | titlecase }}</p>
                </div>



                
                <div class="current-item-border mb-4 mt-4"></div>

                <small>{{ infoMessage }}</small>

    
                <button *ngIf="role === 'owner' && team[uid].role != 'owner'" class="btn btn-primary btn-sm w-100" (click)="modifyingRole = !modifyingRole">Modify Role</button> 
                <button *ngIf="role === 'admin' && team[uid].role === 'user'" class="btn btn-primary btn-sm w-100" (click)="modifyingRole = !modifyingRole">Modify Role</button>

                <form [formGroup]="roleChangeForm" *ngIf="modifyingRole">
                    <div class="form-group">
                        <label>New Role</label>
                        <select class="custom-select" formControlName="role" placeholder="Role">
                            <option value="" disabled>Role</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                        <div *ngIf="newRole.invalid && (newRole.dirty || newRole.touched)" class="form-text text-muted">
                            <small *ngIf="newRole.errors.required" class="validation-error">A new role is required for modification.</small>
                        </div>
                    </div>
                    <div class="mt-2 mb-3 text-center">
                        <button type="submit" [disabled]="roleChangeForm.invalid || roleChangeForm.untouched || roleChangeForm.pristine" class="btn btn-auxiliary btn-sm mt-4 w-100" (click)="openConfrimationModal(roleChangeConfirmationModal)">Save Role Change</button>
                        <a (click)="modifyingRole = !modifyingRole" class="" [routerLink]=""><small>Cancel</small></a>
                    </div>
                    <div class="current-item-border mb-4 mt-4"></div>
                </form>
                

                <button *ngIf="role === 'admin' && (team[uid].role != 'owner' || team[uid].role != 'admin')" class="btn btn-error btn-sm w-100 mt-2" (click)="openConfrimationModal(confirmationModal)">Remove From Organization</button>
                <button *ngIf="role === 'owner' && team[uid].role != 'owner'" class="btn btn-error btn-sm w-100 mt-2" (click)="openConfrimationModal(confirmationModal)">Remove From Organization</button>
              
                <div class="text-center mt-2">
                    <a [routerLink]="" (click)="modalRef.hide()"><small>Close</small></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>










<ng-template #roleChangeConfirmationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">⚠️ Warning!</h2>
                </div>
                <p class="mt-4">Are you sure you want to modify this users role? This action will immediately take effect on this users access to your organizations account.</p>
                <div class="current-item-border mb-4 mt-2"></div>
                
                <div class="d-flex justify-content-between">
                    <button class="btn btn-sm btn-primary" (click)="cancel()">No, Cancel</button>
                    <button class="btn btn-sm btn-error" (click)="changeUserRole()">Yes, Change Role</button>    
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #confirmationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">⚠️ Warning!</h2>
                </div>
                <p class="mt-4">Are you sure you want remove to this users access to your organizations account? This action is <strong>PERMANENT</strong> and cannot be undone. Once removed, the users Social Card account will no longer be tied to your organizations account and the user will be removed from your subscription.</p>
                <div class="current-item-border mb-4 mt-2"></div>
                
                <div class="d-flex justify-content-between">
                    <button class="btn btn-sm btn-primary" (click)="cancel()">No, Cancel</button>
                    <button class="btn btn-sm btn-error" (click)="removeUser()">Yes, Remove User</button>    
                </div>
            </div>
        </div>
    </div>
</ng-template>






















<ng-template #searchModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                <div class="d-lg-flex d-md-flex d-sm-block justify-content-between">
                    <h2 class="mb-0">Team Search</h2>
                </div>
                <p class="mt-4">Enter the team members email below and we'll look them up.</p>
                <div class="current-item-border mb-4 mt-2"></div>
                <input [(ngModel)]="customSelected"
                [isAnimated]="true"
                [typeahead]="teamArray"
                placeholder="Email"
                typeaheadOptionField="email"
                [typeaheadMinLength]="3"
                [optionsListTemplate]="customListTemplate"
                (typeaheadOnSelect)="onSearchSelect($event)"
                class="form-control">

                <div class="mt-4 text-center">
                    <a (click)="searchModalRef.hide()" [routerLink]=""><small>Cancel</small></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
    <ul class="custom-list-group">
      <li class="custom-list-group-item"
          *ngFor="let user of matches"
          [class.active]="typeaheadTemplateMethods.isActive(user)"
          (click)="typeaheadTemplateMethods.selectMatch(user, $event)"
          (mouseenter)="typeaheadTemplateMethods.selectActive(user)">
          <div class="d-flex">
            <div class="table-avatar-default mr-4 shadow text-center" *ngIf="user.item.photo === '' && user.item.role === 'user'">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
            </div>
            <div class="table-avatar-default-admin mr-4 shadow text-center" *ngIf="user.item.photo === '' && user.item.role != 'user'">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#5D656C" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <circle cx="12" cy="7" r="4" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
            </div>
            <img *ngIf="user.item.photo != ''" [src]="user.item.photo" class="table-avatar mr-4 shadow">
            <div class="">
                <p class="mb-0 mt-auto mb-auto ">{{ (user.item.name | titlecase) || user.item.email }}</p>
                <small>{{ user.item.role | titlecase }} | {{ user.item.email }}</small>
            </div>

        </div>
        <!-- {{ user.item | json }} -->
      </li>
    </ul>
</ng-template>

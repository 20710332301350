import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { fadeInAnimation } from '@app/shared/animations/animations';

import { TeamService } from '@core/services/team/team.service';
import { UserService } from '@core/services/user/user.service';

import { TeamMembers, TeamUids } from '@core/models/team.model';
import { User } from '@app/core/models/user.model';

import { SnackBarService } from '@core/notifications/services/snack-bar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { isBs3 } from 'ngx-bootstrap/utils';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-admin-team',
  templateUrl: './admin-team.component.html',
  styleUrls: ['./admin-team.component.css'],
  animations: [fadeInAnimation],
})
export class AdminTeamComponent implements OnInit, OnDestroy {

  team: TeamMembers<User> = {};
  teamArray: User[] = [];
  teamSub: Subscription;
  teamCount: number = Object.keys(this.team).length || 0;
  uid: string;

  userSub: Subscription;
  user: User;
  role: string;

  infoMessage: string = '';

  // TypeAhead
  customSelected: User;
  isBs3 = isBs3();

  modifyingRole: boolean = false;
  roleChangeForm = new FormGroup({
    role: new FormControl('', [Validators.required]),
  });
  get newRole() { return this.roleChangeForm.get('role'); }
  
  constructor(
    private teamService: TeamService,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) { 
    this.teamSub = this.teamService.allTeamMembers.subscribe(res => {
      // this.team = res;
      if (res != null && res != {}) {
        this.team = res;
        this.teamCount = Object.keys(this.team).length || 0;
        this.spinner.hide('teamSpinner');
        console.log(this.team);

        // Create array of team members for typeahead search
        this.teamArray = [];
        for (let user of Object.values(this.team)) {
          this.teamArray.push(user);
        }
      }
    });

    this.userSub = this.userService.currentUser.subscribe(res => {
      this.user = res;
      this.role = res.role;
    });
  }

  ngOnInit(): void {
    this.spinner.show('teamSpinner');
    this.teamService.getAllTeamMembers();
    
  }
  ngOnDestroy(): void {
    this.teamSub.unsubscribe();
    this.userSub.unsubscribe();
  }





  onSearchSelect(event: any) {
    this.searchModalRef.hide();
    console.log(event);
    this.openModal(this.modalView, event.item.uid);
  }





  async changeUserRole() {
    this.confirmationModalRef.hide();
    this.spinner.show('teamMemberSpinner');
    
    await this.userService.changeUsersRole(this.uid, this.newRole.value).then(res => {
      if (res.success) {
        this.spinner.hide('teamMemberSpinner');
        this.snackBarService.success('Successfully updated users role');
        this.infoMessage = 'Successfully updated users role.';
      } else {
        this.snackBarService.error('An internal error occured');
        this.spinner.hide('teamMemberSpinner');
        this.infoMessage = 'An internal error occured, please try again.';
      }
    }).catch(err => {
      console.error(err);
      this.snackBarService.error('An internal error occured');
      this.spinner.hide('teamMemberSpinner');
      this.infoMessage = 'An internal error occured, please try again.';
    })
  }


  async removeUser() {
    this.confirmationModalRef.hide();
    this.modalRef.hide();
    this.snackBarService.info('Removing selected user');
    this.spinner.show('teamSpinner');

    await this.userService.removeUserAccess(this.uid).then(res => {
      if (res.success) {
        this.spinner.hide('teamSpinner');
        
        this.snackBarService.success('Successfully removed users access');
        this.infoMessage = 'Successfully removed users access.';
      } else {
        this.snackBarService.error('An internal error occured');
        this.spinner.hide('teamSpinner');
        this.infoMessage = 'An internal error occured, please try again.';
      }
    }).catch(err => {
      console.error(err);
      this.snackBarService.error('An internal error occured');
      this.spinner.hide('teamMemberSpinner');
      this.infoMessage = 'An internal error occured, please try again.';
    })
  }






  // Handles modals
  modalRef: BsModalRef;
  searchModalRef: BsModalRef;
  confirmationModalRef: BsModalRef;

  
  // Allows opening modal from inside component
  @ViewChild('teamMemberModal') modalView: TemplateRef<any>;
  openSearchModal(template: TemplateRef<any>) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.searchModalRef = this.modalService.show(template, config);
  }
  openModal(template: TemplateRef<any>, index: string) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, config);
    
    this.uid = index;
  }

  openConfrimationModal(template: TemplateRef<any>) {
    this.spinner.show('teamMemberSpinner');
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.confirmationModalRef = this.modalService.show(template, config);
    
  }
  cancel() {
    this.spinner.hide('teamMemberSpinner');
    this.confirmationModalRef.hide();
  }
}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '@core/services/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userToken = this.authService.getAuthToken() || null;
    const modifiedReq = request.clone({ 
      headers: request.headers.set('authorization', `Bearer ${userToken}`),
    });
    // console.log('TOKEN IN INTERCEPTOR: ', userToken);
    return next.handle(modifiedReq);
  }
}

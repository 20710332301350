import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContentSources, DataTypes } from '@app/core/models/content-type-models/content-types.model';
import { Template } from '@app/core/models/template.model';
import { TemplatingService } from '@app/core/services/templates/templating.service';
import { Subscription } from 'rxjs';
import { States } from '@core/constants/states.constants';
import { CountryList } from '@app/core/constants/countries.constants';

@Component({
    selector: 'app-web-accessory-content-type-input',
    templateUrl: './web-accessory-content-type-input.component.html',
    styleUrls: ['./web-accessory-content-type-input.component.css']
})
export class WebAccessoryContentTypeInputComponent implements OnInit, OnDestroy {

    @Input() type: DataTypes;
    @Input() source: string;

    // Accessory Items only
    @Input() section: number;
    @Input() item: number;

    dataTypes = DataTypes;
    states = States;
    countries = CountryList;

    templateSub: Subscription;
    currentTemplateSettings: Template;

    contentControl: string;
    labelSource: string;
    dataSource: string;

    regexp = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i;

    vcardForm = new FormGroup({
        vcardLabel: new FormControl('', [Validators.required]),
        
        vcardDataHeadshot: new FormControl('', [Validators.required]),

        vcardDataFirstName: new FormControl('', [Validators.required]),
        vcardDataLastName: new FormControl('', [Validators.required]),
        vcardDataWorkPhone: new FormControl('', [Validators.required]),
        vcardDataCellPhone: new FormControl('', [Validators.required]),
        vcardDataOrgName: new FormControl('', [Validators.required]),
        vcardDataEmail: new FormControl('', [Validators.required, Validators.email]),
        vcardDataJobTitle: new FormControl('', [Validators.required]),
        vcardDataWebsite: new FormControl('', [Validators.required]),
        
        vcardDataWorkAddressStreet: new FormControl('', [Validators.required]),
        vcardDataWorkAddressCity: new FormControl('', [Validators.required]),
        vcardDataWorkAddressState: new FormControl('', [Validators.required]),
        vcardDataWorkAddressZip: new FormControl('', [Validators.required]),
        vcardDataWorkAddressCountry: new FormControl('', [Validators.required]),
        vcardDataWorkAddressBuilding: new FormControl('', [Validators.required]),

        vcardDataAltAddressStreet: new FormControl('', [Validators.required]),
        vcardDataAltAddressCity: new FormControl('', [Validators.required]),
        vcardDataAltAddressState: new FormControl('', [Validators.required]),
        vcardDataAltAddressZip: new FormControl('', [Validators.required]),
        vcardDataAltAddressCountry: new FormControl('', [Validators.required]),
        vcardDataAltAddressBuilding: new FormControl('', [Validators.required]),

        

    });
    vcardFormSub: Subscription;

    get vcardLabel() { return this.vcardForm.get('vcardLabel'); }

    get vcardDataHeadshot() { return this.vcardForm.get('vcardDataHeadshot'); }

    get vcardDataFirstName() { return this.vcardForm.get('vcardDataFirstName'); }
    get vcardDataLastName() { return this.vcardForm.get('vcardDataLastName'); }
    get vcardDataWorkPhone() { return this.vcardForm.get('vcardDataWorkPhone'); }
    get vcardDataCellPhone() { return this.vcardForm.get('vcardDataCellPhone'); }
    get vcardDataOrgName() { return this.vcardForm.get('vcardDataOrgName'); }
    get vcardDataEmail() { return this.vcardForm.get('vcardDataEmail'); }
    get vcardDataJobTitle() { return this.vcardForm.get('vcardDataJobTitle'); }
    get vcardDataWebsite() { return this.vcardForm.get('vcardDataWebsite'); }

    get vcardDataWorkAddressStreet() { return this.vcardForm.get('vcardDataWorkAddressStreet'); }
    get vcardDataWorkAddressCity() { return this.vcardForm.get('vcardDataWorkAddressCity'); }
    get vcardDataWorkAddressState() { return this.vcardForm.get('vcardDataWorkAddressState'); }
    get vcardDataWorkAddressZip() { return this.vcardForm.get('vcardDataWorkAddressZip'); }
    get vcardDataWorkAddressCountry() { return this.vcardForm.get('vcardDataWorkAddressCountry'); }
    get vcardDataWorkAddressBuilding() { return this.vcardForm.get('vcardDataWorkAddressBuilding'); }
    

    get vcardDataAltAddressStreet() { return this.vcardForm.get('vcardDataAltAddressStreet'); }
    get vcardDataAltAddressCity() { return this.vcardForm.get('vcardDataAltAddressCity'); }
    get vcardDataAltAddressState() { return this.vcardForm.get('vcardDataAltAddressState'); }
    get vcardDataAltAddressZip() { return this.vcardForm.get('vcardDataAltAddressZip'); }
    get vcardDataAltAddressCountry() { return this.vcardForm.get('vcardDataAltAddressCountry'); }
    get vcardDataAltAddressBuilding() { return this.vcardForm.get('vcardDataAltAddressBuilding'); }





    emailForm = new FormGroup({
        emailLabel: new FormControl('', [Validators.required]),
        emailData: new FormControl('', [Validators.required, Validators.email]),
    });
    emailFormSub: Subscription;

    get emailLabel() { return this.emailForm.get('emailLabel'); }
    get emailData() { return this.emailForm.get('emailData'); }




    phoneForm = new FormGroup({
        phoneLabel: new FormControl('', [Validators.required]),
        phoneData: new FormControl('', [Validators.required]),
    });
    phoneFormSub: Subscription;

    get phoneLabel() { return this.phoneForm.get('phoneLabel'); }
    get phoneData() { return this.phoneForm.get('phoneData'); }




    textForm = new FormGroup({
        textLabel: new FormControl('', [Validators.required]),
        textData: new FormControl('', [Validators.required]),
    });
    textFormSub: Subscription;

    get textLabel() { return this.textForm.get('textLabel'); }
    get textData() { return this.textForm.get('textData'); }



    websiteForm = new FormGroup({
        websiteLabel: new FormControl('', [Validators.required]),
        websiteData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    websiteFormSub: Subscription;

    get websiteLabel() { return this.websiteForm.get('websiteLabel'); }
    get websiteData() { return this.websiteForm.get('websiteData'); }



    messageForm = new FormGroup({
        messageLabel: new FormControl('', [Validators.required]),
        messageData: new FormControl('', [Validators.required]),
    });
    messageFormSub: Subscription;

    get messageLabel() { return this.messageForm.get('messageLabel'); }
    get messageData() { return this.messageForm.get('messageData'); }



    // APPLICATIONS
    iosAppForm = new FormGroup({
        iosAppLabel: new FormControl('', [Validators.required]),
        iosAppData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    iosAppFormSub: Subscription;

    get iosAppLabel() { return this.iosAppForm.get('iosAppLabel'); }
    get iosAppData() { return this.iosAppForm.get('iosAppData'); }


    appStoreForm = new FormGroup({
        appStoreLabel: new FormControl('', [Validators.required]),
        appStoreData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    appStoreFormSub: Subscription;

    get appStoreLabel() { return this.appStoreForm.get('appStoreLabel'); }
    get appStoreData() { return this.appStoreForm.get('appStoreData'); }


    androidAppForm = new FormGroup({
        androidAppLabel: new FormControl('', [Validators.required]),
        androidAppData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    androidAppFormSub: Subscription;

    get androidAppLabel() { return this.androidAppForm.get('androidAppLabel'); }
    get androidAppData() { return this.androidAppForm.get('androidAppData'); }


    googlePlayForm = new FormGroup({
        googlePlayLabel: new FormControl('', [Validators.required]),
        googlePlayData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    googlePlayFormSub: Subscription;

    get googlePlayLabel() { return this.googlePlayForm.get('googlePlayLabel'); }
    get googlePlayData() { return this.googlePlayForm.get('googlePlayData'); }




    // Scheduling
    calendlyForm = new FormGroup({
        calendlyLabel: new FormControl('', [Validators.required]),
        calendlyData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    calendlyFormSub: Subscription;

    get calendlyLabel() { return this.calendlyForm.get('calendlyLabel'); }
    get calendlyData() { return this.calendlyForm.get('calendlyData'); }





    // Socials
    linkedinForm = new FormGroup({
        linkedinLabel: new FormControl('', [Validators.required]),
        linkedinData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    linkedinFormSub: Subscription;

    get linkedinLabel() { return this.linkedinForm.get('linkedinLabel'); }
    get linkedinData() { return this.linkedinForm.get('linkedinData'); }


    instagramForm = new FormGroup({
        instagramLabel: new FormControl('', [Validators.required]),
        instagramData: new FormControl('', [Validators.required]),
    });
    instagramFormSub: Subscription;

    get instagramLabel() { return this.instagramForm.get('instagramLabel'); }
    get instagramData() { return this.instagramForm.get('instagramData'); }

    twitterForm = new FormGroup({
        twitterLabel: new FormControl('', [Validators.required]),
        twitterData: new FormControl('', [Validators.required]),
    });
    twitterFormSub: Subscription;

    get twitterLabel() { return this.twitterForm.get('twitterLabel'); }
    get twitterData() { return this.twitterForm.get('twitterData'); }

    tikTokForm = new FormGroup({
        tikTokLabel: new FormControl('', [Validators.required]),
        tikTokData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    tikTokFormSub: Subscription;

    get tikTokLabel() { return this.tikTokForm.get('tikTokLabel'); }
    get tikTokData() { return this.tikTokForm.get('tikTokData'); }

    facebookForm = new FormGroup({
        facebookLabel: new FormControl('', [Validators.required]),
        facebookData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    facebookFormSub: Subscription;

    get facebookLabel() { return this.facebookForm.get('facebookLabel'); }
    get facebookData() { return this.facebookForm.get('facebookData'); }

    twitchForm = new FormGroup({
        twitchLabel: new FormControl('', [Validators.required]),
        twitchData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    twitchFormSub: Subscription;

    get twitchLabel() { return this.twitchForm.get('twitchLabel'); }
    get twitchData() { return this.twitchForm.get('twitchData'); }


    snapchatForm = new FormGroup({
        snapchatLabel: new FormControl('', [Validators.required]),
        snapchatData: new FormControl('', [Validators.required]),
    });
    snapchatFormSub: Subscription;

    get snapchatLabel() { return this.snapchatForm.get('snapchatLabel'); }
    get snapchatData() { return this.snapchatForm.get('snapchatData'); }


    youtubeVideoForm = new FormGroup({
        youtubeVideoLabel: new FormControl('', [Validators.required]),
        youtubeVideoData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    youtubeVideoFormSub: Subscription;

    get youtubeVideoLabel() { return this.youtubeVideoForm.get('youtubeVideoLabel'); }
    get youtubeVideoData() { return this.youtubeVideoForm.get('youtubeVideoData'); }


    youtubeChannelForm = new FormGroup({
        youtubeChannelLabel: new FormControl('', [Validators.required]),
        youtubeChannelData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    youtubeChannelFormSub: Subscription;

    get youtubeChannelLabel() { return this.youtubeChannelForm.get('youtubeChannelLabel'); }
    get youtubeChannelData() { return this.youtubeChannelForm.get('youtubeChannelData'); }


    vimeoForm = new FormGroup({
        vimeoLabel: new FormControl('', [Validators.required]),
        vimeoData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    vimeoFormSub: Subscription;

    get vimeoLabel() { return this.vimeoForm.get('vimeoLabel'); }
    get vimeoData() { return this.vimeoForm.get('vimeoData'); }

    photosForm = new FormGroup({
        photosLabel: new FormControl('', [Validators.required]),
        photosData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    photosFormSub: Subscription;

    get photosLabel() { return this.photosForm.get('photosLabel'); }
    get photosData() { return this.photosForm.get('photosData'); }




    // DESIGN
    dribbbleForm = new FormGroup({
        dribbbleLabel: new FormControl('', [Validators.required]),
        dribbbleData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    dribbbleFormSub: Subscription;

    get dribbbleLabel() { return this.dribbbleForm.get('dribbbleLabel'); }
    get dribbbleData() { return this.dribbbleForm.get('dribbbleData'); }


    behanceForm = new FormGroup({
        behanceLabel: new FormControl('', [Validators.required]),
        behanceData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    behanceFormSub: Subscription;

    get behanceLabel() { return this.behanceForm.get('behanceLabel'); }
    get behanceData() { return this.behanceForm.get('behanceData'); }


    pinterestForm = new FormGroup({
        pinterestLabel: new FormControl('', [Validators.required]),
        pinterestData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    pinterestFormSub: Subscription;

    get pinterestLabel() { return this.pinterestForm.get('pinterestLabel'); }
    get pinterestData() { return this.pinterestForm.get('pinterestData'); }



    // ENGINEERING

    gitHubForm = new FormGroup({
        gitHubLabel: new FormControl('', [Validators.required]),
        gitHubData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    gitHubFormSub: Subscription;

    get gitHubLabel() { return this.gitHubForm.get('gitHubLabel'); }
    get gitHubData() { return this.gitHubForm.get('gitHubData'); }

    gitLabForm = new FormGroup({
        gitLabLabel: new FormControl('', [Validators.required]),
        gitLabData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    gitLabFormSub: Subscription;

    get gitLabLabel() { return this.gitLabForm.get('gitLabLabel'); }
    get gitLabData() { return this.gitLabForm.get('gitLabData'); }

    bitBucketForm = new FormGroup({
        bitBucketLabel: new FormControl('', [Validators.required]),
        bitBucketData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    bitBucketFormSub: Subscription;

    get bitBucketLabel() { return this.bitBucketForm.get('bitBucketLabel'); }
    get bitBucketData() { return this.bitBucketForm.get('bitBucketData'); }


    // COMMUNICATION
    mediumForm = new FormGroup({
        mediumLabel: new FormControl('', [Validators.required]),
        mediumData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    mediumFormSub: Subscription;

    get mediumLabel() { return this.mediumForm.get('mediumLabel'); }
    get mediumData() { return this.mediumForm.get('mediumData'); }


    redditForm = new FormGroup({
        redditLabel: new FormControl('', [Validators.required]),
        redditData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    redditFormSub: Subscription;

    get redditLabel() { return this.redditForm.get('redditLabel'); }
    get redditData() { return this.redditForm.get('redditData'); }


    slackForm = new FormGroup({
        slackLabel: new FormControl('', [Validators.required]),
        slackData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    slackFormSub: Subscription;

    get slackLabel() { return this.slackForm.get('slackLabel'); }
    get slackData() { return this.slackForm.get('slackData'); }


    telegramForm = new FormGroup({
        telegramLabel: new FormControl('', [Validators.required]),
        telegramData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    telegramFormSub: Subscription;

    get telegramLabel() { return this.telegramForm.get('telegramLabel'); }
    get telegramData() { return this.telegramForm.get('telegramData'); }


    discordForm = new FormGroup({
        discordLabel: new FormControl('', [Validators.required]),
        discordData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    discordFormSub: Subscription;

    get discordLabel() { return this.discordForm.get('discordLabel'); }
    get discordData() { return this.discordForm.get('discordData'); }


    whatsAppForm = new FormGroup({
        whatsAppLabel: new FormControl('', [Validators.required]),
        whatsAppData: new FormControl('', [Validators.required, Validators.pattern(this.regexp)]),
    });
    whatsAppFormSub: Subscription;

    get whatsAppLabel() { return this.whatsAppForm.get('whatsAppLabel'); }
    get whatsAppData() { return this.whatsAppForm.get('whatsAppData'); }


    constructor(
        private templatingService: TemplatingService,
    ) {
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });
    }

    ngOnInit(): void {
        

        console.log("👋", this.source, this.section, this.item);

        this.setupFormValuesFromTemplate();
        this.onChanges();
    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();

        // FORMS
        this.vcardFormSub.unsubscribe();
        this.emailFormSub.unsubscribe();
        this.phoneFormSub.unsubscribe();
        this.textFormSub.unsubscribe();
        this.websiteFormSub.unsubscribe();
        this.messageFormSub.unsubscribe();


        // APPLICATIONS
        this.iosAppFormSub.unsubscribe();
        this.appStoreFormSub.unsubscribe();
        this.androidAppFormSub.unsubscribe();
        this.googlePlayFormSub.unsubscribe();


        this.calendlyFormSub.unsubscribe();

        // SOCIALS
        this.linkedinFormSub.unsubscribe();
        this.instagramFormSub.unsubscribe();
        this.twitterFormSub.unsubscribe();
        this.tikTokFormSub.unsubscribe();
        this.facebookFormSub.unsubscribe();
        this.twitchFormSub.unsubscribe();
        this.snapchatFormSub.unsubscribe();
        this.youtubeVideoFormSub.unsubscribe();
        this.youtubeChannelFormSub.unsubscribe();
        this.vimeoFormSub.unsubscribe();
        this.photosFormSub.unsubscribe();

        // DESIGN
        this.dribbbleFormSub.unsubscribe();
        this.behanceFormSub.unsubscribe();
        this.pinterestFormSub.unsubscribe();

        // ENGINEERING
        this.gitHubFormSub.unsubscribe();
        this.gitLabFormSub.unsubscribe();
        this.bitBucketFormSub.unsubscribe();

        // COMMUNICATIONS
        this.mediumFormSub.unsubscribe();
        this.redditFormSub.unsubscribe();
        this.slackFormSub.unsubscribe();
        this.telegramFormSub.unsubscribe();
        this.discordFormSub.unsubscribe();
        this.whatsAppFormSub.unsubscribe();
    }


    onChanges(): void {
        this.vcardFormSub = this.vcardForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.vcardLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {

                first_name: val.vcardDataFirstName,
                last_name: val.vcardDataLastName,
                email: val.vcardDataEmail,
                org: val.vcardDataOrgName, 
                org_title: val.vcardDataJobTitle,
                photo: val.vcardDataHeadshot, 
                work_phone: val.vcardDataWorkPhone, 
                cell_phone: val.vcardDataCellPhone,
                org_website: val.vcardDataWebsite,

                work_street: val.vcardDataWorkAddressStreet,
                work_city: val.vcardDataWorkAddressCity,
                work_state: val.vcardDataWorkAddressState,
                work_zip: val.vcardDataWorkAddressZip,
                work_country: val.vcardDataWorkAddressCountry,
                work_building: val.vcardDataWorkAddressBuilding,

                alt_street: val.vcardDataAltAddressStreet,
                alt_city: val.vcardDataAltAddressCity,
                alt_state: val.vcardDataAltAddressState,
                alt_zip: val.vcardDataAltAddressZip,
                alt_country: val.vcardDataAltAddressCountry,
                alt_building: val.vcardDataAltAddressBuilding,
            };

            this.updateTemplate();
        });
        
        this.emailFormSub = this.emailForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.emailLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                email:  val.emailData,
            };

            this.updateTemplate();
        });
        this.phoneFormSub = this.phoneForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.phoneLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                phone:  val.phoneData,
            };

            this.updateTemplate();
        });

        this.textFormSub = this.textForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.textLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                phone:  val.textData,
            };

            this.updateTemplate();
        });

        this.websiteFormSub = this.websiteForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.websiteLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.websiteData,
            };

            this.updateTemplate();
        });

        this.messageFormSub = this.messageForm.valueChanges.subscribe(val => {
            console.log(val)
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.messageLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                message: val.messageData,
            };

            this.updateTemplate();
        });


        // APPLICATIONS
        this.iosAppFormSub = this.iosAppForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.iosAppLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.iosAppData,
            };

            this.updateTemplate();
        });


        this.appStoreFormSub = this.appStoreForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.appStoreLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.appStoreData,
            };

            this.updateTemplate();
        });


        this.androidAppFormSub = this.androidAppForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.androidAppLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.androidAppData,
            };

            this.updateTemplate();
        });


        this.googlePlayFormSub = this.googlePlayForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.googlePlayLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.googlePlayData,
            };

            this.updateTemplate();
        });





        this.calendlyFormSub = this.calendlyForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.calendlyLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.calendlyData,
            };

            this.updateTemplate();
        });



        // SOCIALS
        this.linkedinFormSub = this.linkedinForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.linkedinLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.linkedinData,
            };

            this.updateTemplate();
        });

        this.instagramFormSub = this.instagramForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.instagramLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                handle: val.instagramData,
                url:  val.instagramData, // Call service func to generate insta URL
            };

            this.updateTemplate();
        });

        this.twitterFormSub = this.twitterForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.twitterLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                handle: val.twitterData,
                url:  val.twitterData, // Call service func to generate twitter URL
            };

            this.updateTemplate();
        });

        this.tikTokFormSub = this.tikTokForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.tikTokLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.tikTokData,
            };

            this.updateTemplate();
        });


        this.facebookFormSub = this.facebookForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.facebookLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.facebookData,
            };

            this.updateTemplate();
        });

        this.twitchFormSub = this.twitchForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.twitchLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.twitchData,
            };

            this.updateTemplate();
        });

        this.snapchatFormSub = this.snapchatForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.snapchatLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                handle: val.snapchatData,
                url:  val.snapchatData, // TODO: Func to convert handle to URL on snap
            };

            this.updateTemplate();
        });

        this.youtubeVideoFormSub = this.youtubeVideoForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.youtubeVideoLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.youtubeVideoData,
            };

            this.updateTemplate();
        });

        this.youtubeChannelFormSub = this.youtubeChannelForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.youtubeChannelLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.youtubeChannelData,
            };

            this.updateTemplate();
        });

        this.vimeoFormSub = this.vimeoForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.vimeoLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.vimeoData,
            };

            this.updateTemplate();
        });

        this.photosFormSub = this.photosForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.photosLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.photosData,
            };

            this.updateTemplate();
        });







        // DESIGN
        this.dribbbleFormSub = this.dribbbleForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.dribbbleLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.dribbbleData,
            };

            this.updateTemplate();
        });

        this.dribbbleFormSub = this.dribbbleForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.dribbbleLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.dribbbleData,
            };

            this.updateTemplate();
        });

        this.behanceFormSub = this.behanceForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.behanceLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.behanceData,
            };

            this.updateTemplate();
        });

        this.pinterestFormSub = this.pinterestForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.pinterestLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.pinterestData,
            };

            this.updateTemplate();
        });


        // ENGINEERING
        this.gitHubFormSub = this.gitHubForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.gitHubLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.gitHubData,
            };

            this.updateTemplate();
        });

        this.gitLabFormSub = this.gitLabForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.gitLabLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.gitLabData,
            };

            this.updateTemplate();
        });

        this.bitBucketFormSub = this.bitBucketForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.bitBucketLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.bitBucketData,
            };

            this.updateTemplate();
        });


        this.mediumFormSub = this.mediumForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.mediumLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.mediumData,
            };

            this.updateTemplate();
        });


        this.redditFormSub = this.redditForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.redditLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.redditData,
            };

            this.updateTemplate();
        });


        this.slackFormSub = this.slackForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.slackLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.slackData,
            };

            this.updateTemplate();
        });

        this.telegramFormSub = this.telegramForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.telegramLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.telegramData,
            };

            this.updateTemplate();
        });

        this.discordFormSub = this.discordForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.discordLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.discordData,
            };

            this.updateTemplate();
        });

        this.whatsAppFormSub = this.whatsAppForm.valueChanges.subscribe(val => {
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label = val.whatsAppLabel;
            this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data = {
                url:  val.whatsAppData,
            };

            this.updateTemplate();
        });
    }



    // Loads existing data from template into content forms
    setupFormValuesFromTemplate() {
        // FORMS
        console.log(this.type, this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data);
        switch(this.type) {
            case this.dataTypes.vcard: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.vcardForm.patchValue({
                        vcardLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
    
                        vcardDataFirstName: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.first_name,
                        vcardDataLastName: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.last_name,
                        vcardDataEmail: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.email,
                        vcardDataOrgName: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.org, 
                        vcardDataJobTitle: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.org_title,
                        vcardDataWebsite: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.org_website,

                        vcardDataWorkPhone: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_phone, 
                        vcardDataCellPhone: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.cell_phone, 
                    
                        vcardDataWorkAddressStreet: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_street, 
                        vcardDataWorkAddressCity: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_city,
                        vcardDataWorkAddressState: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_state,
                        vcardDataWorkAddressZip: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_zip,
                        vcardDataWorkAddressCountry: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_country,
                        vcardDataWorkAddressBuilding: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.work_building,
    
                        vcardDataAltAddressStreet: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.alt_street,
                        vcardDataAltAddressCity: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.alt_city,
                        vcardDataAltAddressState: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.alt_state,
                        vcardDataAltAddressZip: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.alt_zip,
                        vcardDataAltAddressCountry: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.alt_country,
                        vcardDataAltAddressBuilding: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.alt_building,
                    });
                }
                
                break;
            }
            case this.dataTypes.email: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.emailForm.patchValue({
                        emailLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        emailData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.email,
                    })
                }
                break;
            }
            case this.dataTypes.phone: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.phoneForm.patchValue({
                        phoneLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        phoneData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.phone,
                    })
                }
                break;
            }
            case this.dataTypes.sms: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.textForm.patchValue({
                        textLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        textData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.phone,
                    })
                }
                break;
            }
            case this.dataTypes.url: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.websiteForm.patchValue({
                        websiteLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        websiteData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }

            case this.dataTypes.message: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.messageForm.patchValue({
                        messageLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        messageData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.message,
                    })
                }
                break;
            }



            case this.dataTypes.ios_app: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.iosAppForm.patchValue({
                        iosAppLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        iosAppData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.app_store: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.appStoreForm.patchValue({
                        appStoreLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        appStoreData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.android_app: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.androidAppForm.patchValue({
                        androidAppLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        androidAppData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.google_play: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.googlePlayForm.patchValue({
                        googlePlayLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        googlePlayData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }



            case this.dataTypes.calendly: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.calendlyForm.patchValue({
                        calendlyLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        calendlyData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }



            case this.dataTypes.linkedin: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.linkedinForm.patchValue({
                        linkedinLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        linkedinData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.instagram: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.instagramForm.patchValue({
                        instagramLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        instagramData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.handle,
                    })
                }
                break;
            }
            case this.dataTypes.twitter: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.twitterForm.patchValue({
                        twitterLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        twitterData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.handle,
                    })
                }
                break;
            }
            case this.dataTypes.tik_tok: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.tikTokForm.patchValue({
                        tikTokLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        tikTokData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.facebook: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.facebookForm.patchValue({
                        facebookLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        facebookData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.twitch: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.twitchForm.patchValue({
                        twitchLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        twitchData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.snapchat: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.snapchatForm.patchValue({
                        snapchatLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        snapchatData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.handle,
                    })
                }
                break;
            }
            case this.dataTypes.youtube_video: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.youtubeVideoForm.patchValue({
                        youtubeVideoLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        youtubeVideoData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.youtube_channel: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.youtubeChannelForm.patchValue({
                        youtubeChannelLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        youtubeChannelData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.vimeo: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.vimeoForm.patchValue({
                        vimeoLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        vimeoData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.photo_gallery: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.photosForm.patchValue({
                        photosLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        photosData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }

            case this.dataTypes.dribbble: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.dribbbleForm.patchValue({
                        dribbbleLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        dribbbleData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.behance: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.behanceForm.patchValue({
                        behanceLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        behanceData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.pinterest: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.pinterestForm.patchValue({
                        pinterestLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        pinterestData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }



            case this.dataTypes.github: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.gitHubForm.patchValue({
                        gitHubLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        gitHubData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.gitlab: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.gitLabForm.patchValue({
                        gitLabLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        gitLabData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.bit_bucket: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.bitBucketForm.patchValue({
                        bitBucketLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        bitBucketData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }




            case this.dataTypes.medium: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.mediumForm.patchValue({
                        mediumLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        mediumData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.reddit: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.redditForm.patchValue({
                        redditLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        redditData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.slack: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.slackForm.patchValue({
                        slackLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        slackData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.telegram: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.telegramForm.patchValue({
                        telegramLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        telegramData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.discord: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.discordForm.patchValue({
                        discordLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        discordData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
            case this.dataTypes.whatsapp: {
                if (this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data != null) {
                    this.whatsAppForm.patchValue({
                        whatsAppLabel: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_label,
                        whatsAppData: this.currentTemplateSettings.web.accessory_items.acessory_sections[this.section].items[this.item].item_content_data.url,
                    })
                }
                break;
            }
        }
    }






    // Pushes changed data into service.
    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);
    }

}



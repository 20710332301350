import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Template, TenantTemplates } from '@app/core/models/template.model';

import { fadeInAnimation } from '@app/shared/animations/animations';

import { TemplatingService } from '@core/services/templates/templating.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-admin-template',
    templateUrl: './admin-template.component.html',
    styleUrls: ['./admin-template.component.css'],
    animations: [fadeInAnimation],
})
export class AdminTemplateComponent implements OnInit, OnDestroy {

    tenantTemplates: TenantTemplates<Template>;
    tenantTemplatesSub: Subscription;

    totalTemplates: number

    constructor(
        private router: Router,
        private templatingService: TemplatingService,
    ) {
        // Trigger request from DB to retreive tenants templates
        this.templatingService.setAllTenantTemplates();
        this.tenantTemplatesSub = this.templatingService.tenantTemplates.subscribe(res => {
            this.tenantTemplates = res;
            if (res != null) {
                this.countProperties();
            }
       
        })
    }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        this.tenantTemplatesSub.unsubscribe();
    }


    routeToNewTemplate() {
        this.router.navigate(["/templates/new"]);
    }
    routeToTemplate(template: Template, id: string) {
        this.templatingService.setSelectedTemplate(template, id);

        this.router.navigate(["/templates/editor"]);
    }




    // Helpers
    countProperties() {
        this.totalTemplates = Object.keys(this.tenantTemplates).length;
    }
}

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultSocialCard } from '@app/core/models/socialCard.model';
import { Template } from '@app/core/models/template.model';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { TemplateMediaService } from '@app/core/services/template-media/template-media.service';
import { TemplatingService } from '@app/core/services/templates/templating.service';
import { fadeInAnimation } from '@app/shared/animations/animations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-apple-wallet-general',
    templateUrl: './apple-wallet-general.component.html',
    styleUrls: ['./apple-wallet-general.component.css'],
    animations: [fadeInAnimation],
})
export class AppleWalletGeneralComponent implements OnInit, AfterViewInit, OnDestroy {

    templateSub: Subscription;
    currentTemplateSettings: Template;

    isMediaCollapsed: boolean = false;
    isStylingCollapsed: boolean = true;

    tempPassLogo: string;
    tempPassLogoSub: Subscription;

    socialCard = DefaultSocialCard;




    @ViewChild('canvas', { static: false }) canvas: ElementRef;
    public context: CanvasRenderingContext2D;

    constructor(
        private templatingService: TemplatingService,
        private templateMediaService: TemplateMediaService,
        private router: Router,
        private snackBarService: SnackBarService,
    ) {
        // Subs to template service and monitos changes to the loaded template
        this.templateSub = this.templatingService.newTemplateChanges.subscribe(template => {
            this.currentTemplateSettings = template;
        });


        this.tempPassLogoSub = this.templateMediaService.tempPassLogoUrl.subscribe(res => {
            this.tempPassLogo = res;

            if (this.tempPassLogo != null) {
                this.currentTemplateSettings.apple.logos.logo = this.tempPassLogo;

                this.updateTemplate();
            } else {
                this.currentTemplateSettings.apple.logos.logo = 'assets/icon_clear.svg';

                this.updateTemplate();
            }
        });

    }


    ngOnInit(): void {
        this.updatePreview();

        console.log(this.socialCard);
    }
    ngAfterViewInit(): void {
        this.context = this.canvas.nativeElement.getContext('2d');

        this.context.fillStyle = 'green';
        this.context.fillRect(0, 0, 300, 400);

    }
    ngOnDestroy(): void {
        this.templateSub.unsubscribe();
    }

    updateTemplate() {
        this.templatingService.updateTemplate(this.currentTemplateSettings);

        this.updatePreview();
    }

    updatePreview() {
        this.socialCard.colors.background_color = this.currentTemplateSettings.apple.colors.background_color;
        this.socialCard.colors.foreground_color = this.currentTemplateSettings.apple.colors.foreground_color;
        this.socialCard.colors.label_color = this.currentTemplateSettings.apple.colors.label_color;

        this.socialCard.logo.logo = this.currentTemplateSettings.apple.logos.logo;
        this.socialCard.logo.logo_2x = this.currentTemplateSettings.apple.logos.logo_2x;
    }


    togglingMedia() {
        this.isMediaCollapsed = !this.isMediaCollapsed;

        this.isStylingCollapsed = true;
    }
    togglingStyling() {
        this.isStylingCollapsed = !this.isStylingCollapsed;

        this.isMediaCollapsed = true;
    }




    backgroundColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.apple.colors.background_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    foregroundColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.apple.colors.foreground_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }
    labelColorChange(event) {
        // updates template in component
        this.currentTemplateSettings.apple.colors.label_color = event;
        // then, sends update to global service
        this.updateTemplate();
    }



    allowUserStyling(val) {
        this.currentTemplateSettings.apple.colors.can_edit_styling = val;
        this.updateTemplate();
    }
}

import { Injectable } from '@angular/core';


import { BasicPropertyParameters, VCard } from 'ngx-vcard';
import { VCardEncoding } from 'ngx-vcard';
import { VCardFormatter } from 'ngx-vcard';
@Injectable({
    providedIn: 'root'
})
export class VcardService {


    public vCardEncoding: typeof VCardEncoding = VCardEncoding;
    
    constructor() { }

    // Generates Vcard files



    // Takes input form contact content type and turns that into a vCard fromatted string and returns it
    generateVcard(vcardData: any): VCard {
        var vCard: VCard = {
            version: '3.0', // Apples uses 3.0 specification
        };

        if (vcardData != undefined) {

            // Vcard Name
            if (vcardData['first_name'] || vcardData['last_name']) {
                vCard.name = { firstNames: vcardData['first_name'], lastNames: vcardData['last_name']};
            }

            // Vcard Email
            if (vcardData['email']) {
                vCard.email = [{ value: vcardData['email'] || '', param: { type: 'work' } }];
            }

            // Vcard Telephone
            if (vcardData['cell_phone']) {
                if (vCard.telephone === undefined) {
                    vCard.telephone = [];
                }
                vCard.telephone.push({ value: vcardData['cell_phone'] || '', param: { type: 'cell' } });
            }
            if (vcardData['work_phone']) {
                if (vCard.telephone === undefined) {
                    vCard.telephone = [];
                }
                vCard.telephone.push({ value: vcardData['work_phone'] || '', param: { type: 'work' } });
            }

            // Vcard Organization
            if (vcardData['org']) {
                vCard.organization = vcardData['org'];
            }

            // Vcard Title & Role
            if (vcardData['org_title']) {
                vCard.organization = vcardData['org_title'];
            }

            // Vcard Address
            if (vcardData['work_street']) {
                vCard.address.push({
                    value: {
                        street: vcardData['work_street'],
                        locality: vcardData['work_city'],
                        region: vcardData['work_state'],
                        countryName: vcardData['work_country'],
                        postalCode: vcardData['work_zip'],
                        extendedAddress: vcardData['work_building'],
                    },
                    param: { type: 'work' }
                });
            }

            if (vcardData['alt_street']) {
                vCard.address.push({
                    value: {
                        street: vcardData['alt_street'],
                        locality: vcardData['alt_city'],
                        region: vcardData['alt_state'],
                        countryName: vcardData['alt_country'],
                        postalCode: vcardData['alt_zip'],
                        extendedAddress: vcardData['alt_building'],
                    },
                    param: { type: 'home' }
                });
            }

            // Vcard Website
            if (vcardData['org_website']) {
                vCard.url = vcardData['org_website'];
            }

        } else {
            vCard = {
                version: '3.0',
            }
        }
        

        return vCard;
    }


    generateVcardString(vcardData: any): string {
        var vCard: VCard = {
            version: '3.0', // Apples uses 3.0 specification
        };

        console.log(vcardData);

        if (vcardData != undefined) {            

            // Vcard Name
            if (vcardData['first_name'] || vcardData['last_name']) {
                vCard.name = { firstNames: vcardData['first_name'], lastNames: vcardData['last_name']};
            }

            // Vcard Email
            if (vcardData['email']) {
                vCard.email = [{ value: vcardData['email'] || '', param: { type: 'work' } }];
            }

            // Vcard Telephone
            if (vcardData['cell_phone']) {
                if (vCard.telephone === undefined) {
                    vCard.telephone = [];
                }
                vCard.telephone.push({ value: vcardData['cell_phone'] || '', param: { type: 'cell' } });
            }
            if (vcardData['work_phone']) {
                if (vCard.telephone === undefined) {
                    vCard.telephone = [];
                }
                vCard.telephone.push({ value: vcardData['work_phone'] || '', param: { type: 'work' } });
            }

            // Vcard Organization
            if (vcardData['org']) {
                vCard.organization = vcardData['org'];
            }

            // Vcard Title & Role
            if (vcardData['org_title']) {
                vCard.organization = vcardData['org_title'];
            }

            // Vcard Address
            if (vcardData['work_street']) {
                if (vCard.address === undefined) {
                    vCard.address = [];
                }
                vCard.address.push({
                    value: {
                        street: vcardData['work_street'],
                        locality: vcardData['work_city'],
                        region: vcardData['work_state'],
                        countryName: vcardData['work_country'],
                        postalCode: vcardData['work_zip'],
                        extendedAddress: vcardData['work_building'],
                    },
                    param: { type: 'work' }
                });
            }

            if (vcardData['alt_street']) {
                if (vCard.address === undefined) {
                    vCard.address = [];
                }
                vCard.address.push({
                    value: {
                        street: vcardData['alt_street'],
                        locality: vcardData['alt_city'],
                        region: vcardData['alt_state'],
                        countryName: vcardData['alt_country'],
                        postalCode: vcardData['alt_zip'],
                        extendedAddress: vcardData['alt_building'],
                    },
                    param: { type: 'home' }
                });
            }

            // Vcard Website
            if (vcardData['org_website']) {
                vCard.url = vcardData['org_website'];
            }


        } else {
            vCard = {
                version: '3.0',
            }
        }
        
        var vCardString = VCardFormatter.getVCardAsString(vCard);
        return vCardString;
    }
}


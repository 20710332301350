import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SideNavService {

    isSideNavVisible: boolean;

    sideNavVisibilityChange: Subject<boolean> = new Subject<boolean>();

    constructor() {
        this.sideNavVisibilityChange.subscribe((value) => {
            this.isSideNavVisible = value
        });
    }



    toggleNav() {
        this.sideNavVisibilityChange.next(!this.isSideNavVisible);
        // console.log('toggled SIDE NAV: ', this.isSideNavVisible);
    }
}

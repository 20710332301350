<div class="content-nav" *ngIf="isSectionView && currentSectionIndex != null && !isSectionItemView">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text">
            {{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].section_label }}
        </div>
    </div>
    <div>
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item">
                    <button class="btn btn-primary w-100 mb-2" (click)="addNewItemToSection()">Add New Item</button>
                    <button class="btn btn-sm btn-auxiliary w-100" (click)="backToSectionsView()">Back</button>
                </div>
                <div class="current-item-border mt-"></div>
            </div>
            <div class="control-item">
                <div class="current-item-label">
                    Content Control
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].content_control"  [(ngModel)]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].content_control" (ngModelChange)="toggleContentControl(currentSectionIndex, $event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].content_control" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].content_control" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, accessory items within this section are NOT editable by your team. This means they will not be able to add, remove, or edit the default accessory items.</small>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Section Position Control
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].postion_control"  [(ngModel)]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].postion_control" (ngModelChange)="toggleSectionPositionControl(currentSectionIndex, $event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].postion_control" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].postion_control" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, the position of this section is NOT editable by your team. This means they will not be able to rearrange the order of sections when using this template.</small>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Analytics Enabled
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].analytics_enabled"  [(ngModel)]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].analytics_enabled" (ngModelChange)="toggleAnalyticsEnabled(currentSectionIndex, $event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, interaction with this sections applicable accessory items will be monitored by Social Card Analytics.</small>
                </div>
                <div class="current-item-border"></div>
            </div>
        </div>
    </div>
</div>


<div class="content-nav" *ngIf="!isSectionView && !isSectionItemView">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text">
            Accessory Items
        </div>
    </div>
    <div>
        <div class="control-item-group"> 
            <div class="control-item">
                <p>Accessory items are the main content of your Social Card pages. Build out the default sections and content for your template and set controls and settings along the way.</p> 
                <button class="btn btn-primary w-100" (click)="openNewSectionModal(newAccessoryItemSectionModal)">Add New Section</button>
            </div>
        </div>
    </div>
</div>


<div class="content-nav" *ngIf="!isSectionView && isSectionItemView">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text">
            Accessory Item
        </div>
    </div>
    <div>
        <div class="control-item-group"> 

            <div class="control-item">
                <button class="btn btn-primary w-100" (click)="backToSectionView()">Back</button>
            </div>
        </div>
    </div>
</div>
















<div id="preview" class="h-100">
    <div class="h-100 preview-bg">










        <div class="preview-container" *ngIf="!isSectionView && !isSectionItemView">
            <div class="" *ngIf="!currentTemplateSettings.web.accessory_items.acessory_sections">
                No accessory items have been added to this template yet.
            </div>


            <div class="">
                <bs-sortable
                  [(ngModel)]="currentTemplateSettings.web.accessory_items.acessory_sections"
                  [itemTemplate]="itemTemplate"
                  itemClass="sortable-item"
                  itemActiveClass="sortable-item-active"
                  placeholderItem="No accessory items have been added to this template yet."
                  placeholderClass="placeholderStyle"
                  wrapperClass="sortable-wrapper"
                ></bs-sortable>
            </div>

            <ng-template #itemTemplate let-item="item" let-index="index">
                <div class="card shadow mb-4" [@fade-in] (click)="editSection(index)" [style.background-color]="currentTemplateSettings.web.background_color">
                    <button class="nav-btn remove-member-btn shadow" (click)="openModal(removeAccessoryItemSectionModal, index)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </button>
                    <div class="card-body" tooltip="Click to edit, drag to reorder sections">
                        <div class="web-content-container">
                            <div class="web-section-title" [style.text-color]="currentTemplateSettings.web.section_text_color">{{ currentTemplateSettings.web.accessory_items.acessory_sections[index].section_label }}</div>

                            <div class="" *ngFor="let accessoryItem of item.value.items; let i = index;">
                                <div class="web-accessory-link" 
                                *ngIf="accessoryItem.item_content_type.type.data_type != 'message'"
                                [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                                [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                                [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                                [ngStyle]="accessoryHovered[index][i] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                                (mouseover)="accessoryHovered[index][i] = true"
                                (mouseout)="accessoryHovered[index][i] = false">
                                    <div class="d-flex">
                                        <app-svg-assets [type]="accessoryItem.item_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.accessory_item_text_color"></app-svg-assets>
    
                                        <div class="web-accessory-link-text">{{ accessoryItem.item_content_label || accessoryItem.item_content_type.name }}</div>
                                    </div>
                                </div>
    
                                <div class="web-accessory-textarea"
                                *ngIf="accessoryItem.item_content_type.type.data_type === 'message'"
                                [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                                [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                                [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                                [ngStyle]="accessoryHovered[index][i] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                                (mouseover)="accessoryHovered[index][i] = true"
                                (mouseout)="accessoryHovered[index][i]= false">
                                    
                                    <div class="web-accessory-textarea-header">
                                        {{ accessoryItem.item_content_label || accessoryItem.item_content_type.name }}
                                    </div>
                                    <div class="web-accessory-textarea-text">{{ accessoryItem.item_content_data.message }}</div>
                                
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

















        <div class="preview-container" *ngIf="isSectionView && !isSectionItemView">
            <div class="card shadow" [@fade-in] tooltip="Drag to reorder items" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="web-content-container">
                        <div class="web-section-title" [style.text-color]="currentTemplateSettings.web.section_text_color">{{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].section_label }}</div>
                    </div>
                    <div class="">
                        <bs-sortable
                          [(ngModel)]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items"
                          [itemTemplate]="itemTemplateSection"
                          itemClass="sortable-item"
                          itemActiveClass="sortable-item-active"
                          placeholderItem="No accessory items have been added to this section yet."
                          placeholderClass="placeholderStyle"
                          wrapperClass="sortable-wrapper"
                        ></bs-sortable>
                    </div>
        
                    <ng-template #itemTemplateSection let-item="item" let-index="index">
                        <div class="web-content-container">
                            <button class="nav-btn remove-member-btn shadow" (click)="openRemoveSectionItemModal(removeAccessoryItemModal, index)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </button>
                            <div class="web-accessory-link" 
                            (click)="editSectionItem(index)"
                            *ngIf="item.value.item_content_type.type.data_type != 'message'"
                            [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                            [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                            [ngStyle]="accessoryHovered[currentSectionIndex][index] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                            (mouseover)="accessoryHovered[currentSectionIndex][index] = true"
                            (mouseout)="accessoryHovered[currentSectionIndex][index] = false">
                                <div class="d-flex">
                                    <app-svg-assets [type]="item.value.item_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.accessory_item_text_color"></app-svg-assets>

                                    <div class="web-accessory-link-text">{{ item.value.item_content_label || item.value.item_content_type.name }}</div>
                                </div>
                            </div>

                         

                            <div class="web-accessory-textarea"
                            (click)="editSectionItem(index)"
                            *ngIf="item.value.item_content_type.type.data_type === 'message'"
                            [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                            [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                            [ngStyle]="accessoryHovered[currentSectionIndex][index] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                            (mouseover)="accessoryHovered[currentSectionIndex][index] = true"
                            (mouseout)="accessoryHovered[currentSectionIndex][index] = false">
                                
                                <div class="web-accessory-textarea-header">
                                    {{ item.value.item_content_label || item.value.item_content_type.name }}
                                </div>
                                <div class="web-accessory-textarea-text">{{ item.value.item_content_data.message }}</div>
                            
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>



            <div class="card shadow mt-4" [@fade-in]>
                <div class="card-body">
                    <div class="web-content-container">
                        <form [formGroup]="sectionTitleForm">
                                
                            <div class="form-group">
                                <label for="newSectionTitle">Section Title</label>
                                <input class="form-control" formControlName="newSectionTitle" type="text" placeholder="" id="newSectionTitle">
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

















        <div class="preview-container" *ngIf="!isSectionView && isSectionItemView">
            <div class="card shadow" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Accessory Item</span>
                    </div>
                    <div class="web-content-container">

                        <div class="web-accessory-link" 
                        (click)="testAccessoryItemLink()"
                        *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type != 'message' && currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type != 'vcard'"
                        [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                        [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                        [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                        [ngStyle]="accessoryHovered[currentSectionIndex][currentItemIndex] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                        (mouseover)="accessoryHovered[currentSectionIndex][currentItemIndex] = true"
                        (mouseout)="accessoryHovered[currentSectionIndex][currentItemIndex] = false">
                            <div class="d-flex">
                                <app-svg-assets [type]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.accessory_item_text_color"></app-svg-assets>

                                <div class="web-accessory-link-text">{{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_label || currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.name }}</div>
                            </div>
                        </div>


                        <div class="web-accessory-link" 
                        id="downloadButton" [vcdDownloadVCard]="getVcard()" [encoding]="vCardEncoding.none"
                        *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type === 'vcard'"
                        [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                        [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                        [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                        [ngStyle]="accessoryHovered[currentSectionIndex][currentItemIndex] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                        (mouseover)="accessoryHovered[currentSectionIndex][currentItemIndex] = true"
                        (mouseout)="accessoryHovered[currentSectionIndex][currentItemIndex] = false">
                            <div class="d-flex">
                                <app-svg-assets [type]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.accessory_item_text_color"></app-svg-assets>

                                <div class="web-accessory-link-text">{{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_label || currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.name }}</div>
                            </div>
                        </div>

                        <div class="web-accessory-textarea"
                            *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type === 'message'"
                            [style.background-color]="currentTemplateSettings.web.accessory_item_fill_color" 
                            [style.color]="currentTemplateSettings.web.accessory_item_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.accessory_item_corner_radius" 
                            [ngStyle]="accessoryHovered[currentSectionIndex][currentItemIndex] ? shadowHovered(this.currentTemplateSettings.web.accessory_item_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.accessory_item_shadow_color)"
                            (mouseover)="accessoryHovered[currentSectionIndex][currentItemIndex] = true"
                            (mouseout)="accessoryHovered[currentSectionIndex][currentItemIndex] = false">
                                
                                <div class="web-accessory-textarea-header">
                                    {{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_label || currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.name }}
                                </div>
                                <div class="web-accessory-textarea-text"
                                *ngIf="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_data != null"
                                > {{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_data.message }}</div>
                            
                            </div>
                        <div class="mt-4">
                            <small>NOTE: Click to test the accessory item.</small>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card shadow mt-4" [@fade-in]>
                <div class="card-body">
             
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content type</span>
                            <p>Select the type of content you want displayed in this acessory item.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>

                        <div class="" *ngIf="itemContentTypeSelection">
                            <div class="mb-4" *ngFor="let section of availableContentTypes; let i = index;">
                                <h4 class="mb-3">{{ sectionNames[i] }}</h4>
                                <div class="row mx-auto">
                                    <div class="content-type-container" [tooltip]="option.name" *ngFor="let option of section;" (click)="itemContentTypeChange(option)">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='option.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ option.name }}</div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
                        <div class="" *ngIf="!itemContentTypeSelection">
                            <div class="">
    
                                <div class="d-flex justify-content-center">
                                    <div class="content-type-container" (click)="editItemContentType()">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.name }}</div>
                                    </div>
                                </div>
                                <div class="text-center mb-4">
                                    <a [routerLink]="" (click)="editItemContentType()">Change</a>
                                </div>
    

                                <app-web-accessory-content-type-input
                                    [type]="currentTemplateSettings.web.accessory_items.acessory_sections[currentSectionIndex].items[currentItemIndex].item_content_type.ref" 
                                    [section]="currentSectionIndex" 
                                    [item]="currentItemIndex" 
                                    source="accessory_item">
                                </app-web-accessory-content-type-input>
    
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>







    </div>
</div>





















































<ng-template #removeAccessoryItemSectionModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="">⚠️ Remove Section</h2>
                    <p class="">Are you sure you want to remove this accessory item section? This is permanent and cannot be undone once saved.</p>
                </div>
                
                
                <div class="mt-2 d-flex justify-content-between">
                   <button class="btn btn-auxiliary" (click)="modalRef.hide()">No, Cancel</button>
                   <button class="btn btn-primary" (click)="removeSection()">Yes, Remove</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>


<ng-template #removeAccessoryItemModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="">⚠️ Remove Section Item</h2>
                    <p class="">Are you sure you want to remove this accessory item? This is permanent and cannot be undone once saved.</p>
                </div>
                
                
                <div class="mt-2 d-flex justify-content-between">
                   <button class="btn btn-auxiliary" (click)="modalRef.hide()">No, Cancel</button>
                   <button class="btn btn-primary" (click)="removeSectionItem()">Yes, Remove</button>
                </div>
            </div>
        </div>
    </div>

</ng-template>


<ng-template #newAccessoryItemSectionModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">
                
                <div class="mb-4">
                    <h2 class="">New Section</h2>
                    <p class="">Give your new section a title.</p>
                </div>
                <form [formGroup]="newSectionForm" (ngSubmit)="addNewSection()">
                    <div class="form-group">
                        <label for="sectionTitle">Section Title</label>
                        <input class="form-control" formControlName="sectionTitle" type="text" placeholder="" id="sectionTitle">
                        <div *ngIf="sectionTitle.invalid && (sectionTitle.dirty || sectionTitle.touched)" class="form-text text-muted">
                            <small *ngIf="sectionTitle.errors.required" class="validation-error">A section title is required.</small>
                        </div>
                    </div>
                    <div class="mt-2 text-center">
                        <button class="btn btn-primary w-100 mb-2" type="submit" [disabled]="newSectionForm.invalid || newSectionForm.untouched || newSectionForm.pristine">Add Section</button>
                        <a (click)="modalRef.hide()" class="" [routerLink]=""><small>Cancel</small></a>
                    </div>
                </form>
                
                
            </div>
        </div>
    </div>

</ng-template>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// Models
import { AuthUser, User } from '@app/core/models/user.model';
// Services
import { AuthService } from '@core/services/auth/auth.service';
import { UserService } from '@core/services/user/user.service';
// Animations
import { fadeInAnimation } from '@app/shared/animations/animations';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    fadeInAnimation
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  userSub: Subscription;
  user: User;

  welcomeText: string;



  socialCards = [{
    serial: '',
    tenant_id: '',
    template_id: '=',
    uid: '',
    name: 'Social Card',
    description: 'This is my social card',
    front_fields: {
      primary_field: {
        key: 'primary',
        label: '',
        value: 'Social Card, LLC',
      },
      secondary_field: {
        key: 'secondary',
        label: 'ROLE',
        value: 'Founder & CEO',
      },
      auxiliary_field: {
        key: 'auxiliary',
        label: 'EMAIL',
        value: 'andrew@joinsocialcard.com',
      }
    },
    back_fields: [
      {
        key: 'secondary',
        label: 'ROLE',
        value: 'Founder & CEO',
        attributed_value: '',
      }
    ],
    qr: {
      type: 'mecard',
      data: 'MECARD:Name'
    },
    logo: {
      logo: 'assets/icon_clear.svg',
      logo_2x: 'assets/icon_clear.svg',
      logo_text: 'ANDREW SAPASHE'
    },
    thumbnails: {
      thumbnail: 'assets/icon_clear.svg',
      thumbnail_2x: 'assets/icon_clear.svg',
    },
    colors: {
      background_color: 'rgb(245, 245, 245)',
      label_color: 'rgb(5, 175, 255)',
      foreground_color: 'rgb(69, 70, 69)',
    },
  },
  {
    serial: '',
    tenant_id: '',
    template_id: '=',
    uid: '',
    name: 'Andrew Sapashe',
    description: 'My Business card',
    front_fields: {
      primary_field: {
        key: 'primary',
        label: '',
        value: 'Social Card, LLC',
      },
      secondary_field: {
        key: 'secondary',
        label: 'ROLE',
        value: 'Founder & CEO',
      },
      auxiliary_field: {
        key: 'auxiliary',
        label: 'EMAIL',
        value: 'andrew@joinsocialcard.com',
      }
    },
    back_fields: [
      {
        key: 'secondary',
        label: 'ROLE',
        value: 'Founder & CEO',
        attributed_value: '',
      }
    ],
    qr: {
      type: 'mecard',
      data: 'MECARD:Name'
    },
    logo: {
      logo: 'assets/icon_clear.svg',
      logo_2x: 'assets/icon_clear.svg',
      logo_text: 'ANDREW SAPASHE'
    },
    thumbnails: {
      thumbnail: 'assets/icon_clear.svg',
      thumbnail_2x: 'assets/icon_clear.svg',
    },
    colors: {
      background_color: 'rgb(69, 70, 69)',
      label_color: 'rgb(5, 175, 255)',
      foreground_color: 'rgb(245, 245, 245)',
    },
  },];



  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {
    this.userSub = this.userService.currentUser.subscribe(user => {
      console.log('USER IN HOME: ', user);
      this.user = user;
    })
  }

  ngOnInit(): void {
    
    this.setWelcomeText();
  }
  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }


  setWelcomeText() {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      this.welcomeText = 'Good morning';
    } else if (curHr < 18) {
      this.welcomeText = 'Good afternoon';
    } else {
      this.welcomeText = 'Good evening';
    }
  }
}

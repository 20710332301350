<input type="file" (change)="fileChangeEvent($event)"/>

<div class="d-flex justify-content-between mb-4">
    <button class="nav-btn" (click)="rotateLeft()" tooltip="Rotate left">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate-2" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5" />
            <line x1="18.37" y1="7.16" x2="18.37" y2="7.17" />
            <line x1="13" y1="19.94" x2="13" y2="19.95" />
            <line x1="16.84" y1="18.37" x2="16.84" y2="18.38" />
            <line x1="19.37" y1="15.1" x2="19.37" y2="15.11" />
            <line x1="19.94" y1="11" x2="19.94" y2="11.01" />
        </svg>
    </button>

    <button class="nav-btn" (click)="rotateRight()" tooltip="Rotate right">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rotate-clockwise-2" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />
            <line x1="5.63" y1="7.16" x2="5.63" y2="7.17" />
            <line x1="4.06" y1="11" x2="4.06" y2="11.01" />
            <line x1="4.63" y1="15.1" x2="4.63" y2="15.11" />
            <line x1="7.16" y1="18.37" x2="7.16" y2="18.38" />
            <line x1="11" y1="19.94" x2="11" y2="19.95" />
        </svg>
    </button>

    <button class="nav-btn" (click)="flipHorizontal()" tooltip="Flip horizontal">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-switch-horizontal" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <polyline points="16 3 20 7 16 11" />
            <line x1="10" y1="7" x2="20" y2="7" />
            <polyline points="8 13 4 17 8 21" />
            <line x1="4" y1="17" x2="13" y2="17" />
        </svg>
    </button>

    <button class="nav-btn" (click)="flipVertical()" tooltip="Flip vertical">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-switch-vertical" width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <polyline points="3 8 7 4 11 8" />
            <line x1="7" y1="4" x2="7" y2="13" />
            <polyline points="13 16 17 20 21 16" />
            <line x1="17" y1="10" x2="17" y2="20" />
        </svg>
    </button>
</div>

<button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
<button (click)="resetImage()">Reset image</button>
<br />
<br />
<input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" />
<button (click)="zoomOut()">Zoom -</button> <button (click)="zoomIn()">Zoom +</button>

<div>
    <image-cropper 
        [imageChangedEvent]="imageChangedEvent" 
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio" 
        [aspectRatio]="1 / 1" 
        [resizeToWidth]="256"
        [cropperMinWidth]="128" 
        [onlyScaleDown]="true" 
        [roundCropper]="false" 
        [canvasRotation]="canvasRotation"
        [transform]="transform" 
        [alignImage]="'left'" 
        [style.display]="showCropper ? null : 'none'" 
        format="png"
        (imageCropped)="imageCropped($event)" 
        (imageLoaded)="imageLoaded()" 
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
</div>
<img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
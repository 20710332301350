import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/database';

import { OrgOnboardingItem } from '@core/models/onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService implements OnDestroy {

  tenant_onboarding: Observable<OrgOnboardingItem[]>;
  tenantOnboardingSub: Subscription;
  private orgOnboardingSubject = new BehaviorSubject<OrgOnboardingItem[]>([]);
  public orgOnboarding = this.orgOnboardingSubject.asObservable().pipe(distinctUntilChanged());


  constructor(
    private db: AngularFireDatabase,
  ) { }

  ngOnDestroy(): void {
    this.tenantOnboardingSub.unsubscribe();
  }


  setOrgOnboardingObservable() {
    const tenant_id = sessionStorage.getItem('t');
    this.tenant_onboarding = this.db.object<OrgOnboardingItem[]>(`tenant_onboarding/${tenant_id}`).valueChanges();
    this.trackOrgOnboarding();
  }

  trackOrgOnboarding() {
    this.tenantOnboardingSub = this.tenant_onboarding.subscribe(res => {
      this.orgOnboardingSubject.next(res);
    });
  }



  // Set items as complete
  async setOnboardingItemAsCompleted(index: number):  Promise<Boolean> {
    return new Promise<Boolean>((resolve, reject) => {
      var items = this.orgOnboardingSubject.value;
      items[index].completed = true;

      const tenant_id = sessionStorage.getItem('t');

      const obarding_items = this.db.object<OrgOnboardingItem[]>(`tenant_onboarding/${tenant_id}`).set(items).then(_ => {
        resolve(true)
      }).catch(err => {
        console.log(err, 'You dont have access!');
        reject(false);
      });
    });
  }


}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Result } from '@app/core/models/api.model';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TemplateMediaService {

    private uploadProgressSubject = new BehaviorSubject<number>(0);
    public uploadProgress = this.uploadProgressSubject.asObservable().pipe(distinctUntilChanged());

    // Store the file uploaded until the publish action is called by service (user adds photo in new dep comp, added to this object, and consumed once user confrims dep creation)
    private tempCoverPhotoDataSubject = new BehaviorSubject<any>(null);
    public tempCoverPhotoData = this.tempCoverPhotoDataSubject.asObservable().pipe(distinctUntilChanged());

    private tempLogoDataSubject = new BehaviorSubject<any>(null);
    public tempLogoData = this.tempLogoDataSubject.asObservable().pipe(distinctUntilChanged());

    private tempPassLogoDataSubject = new BehaviorSubject<any>(null);
    public tempPassLogoData = this.tempPassLogoDataSubject.asObservable().pipe(distinctUntilChanged());

    // Temp file urls for component src
    private tempCoverPhotoUrlSubject = new BehaviorSubject<any>(null);
    public tempCoverPhotoUrl = this.tempCoverPhotoUrlSubject.asObservable().pipe(distinctUntilChanged());

    private tempLogoDataUrlSubject = new BehaviorSubject<any>(null);
    public tempLogoDataUrl = this.tempLogoDataUrlSubject.asObservable().pipe(distinctUntilChanged());

    private tempPassLogoUrlSubject = new BehaviorSubject<any>(null);
    public tempPassLogoUrl = this.tempPassLogoUrlSubject.asObservable().pipe(distinctUntilChanged());



    private coverPhotoSubject = new BehaviorSubject<string>('');
    public coverPhoto = this.coverPhotoSubject.asObservable().pipe(distinctUntilChanged());

    private logoSubject = new BehaviorSubject<string>('');
    public logo = this.logoSubject.asObservable().pipe(distinctUntilChanged());

    private passLogoSubject = new BehaviorSubject<string>('');
    public passLogo = this.passLogoSubject.asObservable().pipe(distinctUntilChanged());


    baseUrl: string = environment.api.url;

    constructor(
        private storage: AngularFireStorage,
        private http: HttpClient,
    ) { }


    getTemplateCoverFromStorage(templateId: string): Promise<Result> {
        const tenant_id = sessionStorage.getItem('t');

        const path = `tenants/${tenant_id}/templates/${templateId}/cover`;
        // Create a reference to the file we want to download
        const ref = this.storage.ref(path);

        return new Promise<Result>(async (resolve, reject) => {

            // Get the download URL
            ref.getDownloadURL().toPromise().then((url) => {
                // Insert url into an <img> tag to "download"
                console.log('Cover Photo 📷', url);
                this.coverPhotoSubject.next(url);
                resolve({
                    success: true,
                    data: url,
                })
            }).catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.error('📷', error);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
                reject();
            });
        })
    }




    uploadTemplateCoverToStorage(templateId: string): Promise<Result> {
        return new Promise<Result>((resolve, reject) => {

            var imgData = this.tempCoverPhotoDataSubject.value;
            var rawImgData: any;

            const tenant_id = sessionStorage.getItem('t');
            const path = `tenants/${tenant_id}/templates/${templateId}/cover`;

            // Create a reference to the file we want to download
            const ref = this.storage.ref(path);


            if (imgData === null) {
                reject();
                return;
            }

            // Ensures file uploaded is an img
            var mimeType = imgData.type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                reject();
                return;
            } else if (mimeType.match(/imagesvg\+xml|image\/svg\+xml/)) {
                console.log("Only non svg images are supported.");
                reject();
                return;
            }

            // Converts file to data of type ArrayBuffer
            var reader = new FileReader();
            reader.readAsDataURL(imgData);
            reader.onload = (_event) => {
                rawImgData = reader.result;
            }
            console.log('📷', rawImgData);

            const task = this.storage.upload(path, imgData);

            // Tracks state of upload
            task.snapshotChanges().subscribe(result => {
                console.log('📷', result.state);
                if (result.state === 'success') {

                    // get the img URL
                    this.getTemplateCoverFromStorage(templateId).then(res => {
                        if (res.success) {
                            resolve({
                                success: true,
                                data: res.data,
                            });
                        }
                    }).catch(err => {
                        console.error('Unable to retreive Dep Logo URL');
                        reject();
                    })

                } else if (result.state === 'error') {
                    reject();
                } else {
                    console.log('📷', 'still processing image');
                }
            })

            // observe percentage changes
            task.percentageChanges().subscribe(progress => {
                this.uploadProgressSubject.next(progress);
                console.log('📷', 'Uploading progress: ', progress);
                if (progress === 100) {

                }
            });

            // Handle any errors
            task.catch(err => {
                console.error('📷', err);
                switch (err.name) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        // Failed in storage rules
                        reject();
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        reject();
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        reject();
                        break;
                }
            });
        });
    }


    setTempCoverImageData(data: any) {
        this.tempCoverPhotoDataSubject.next(data);
        // Converts file to data of type ArrayBuffer
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (_event) => {
            this.tempCoverPhotoUrlSubject.next(reader.result);
            console.log('📷', this.tempCoverPhotoUrlSubject.value);
        }
    }

    resetTempCoverImageData() {
        this.tempCoverPhotoDataSubject.next(null);
        this.tempCoverPhotoUrlSubject.next(null);
    }











    // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO 
    // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO 
    // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO 
    // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO // LOGO 



    getTemplateLogoFromStorage(templateId: string): Promise<Result> {
        const tenant_id = sessionStorage.getItem('t');

        const path = `tenants/${tenant_id}/templates/${templateId}/logo`;
        // Create a reference to the file we want to download
        const ref = this.storage.ref(path);

        return new Promise<Result>(async (resolve, reject) => {

            // Get the download URL
            ref.getDownloadURL().toPromise().then((url) => {
                // Insert url into an <img> tag to "download"
                console.log('Logo Photo 📷', url);
                this.logoSubject.next(url);
                resolve({
                    success: true,
                    data: url,
                })
            }).catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.error('📷', error);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
                reject();
            });
        })
    }




    uploadTemplateLogoToStorage(templateId: string): Promise<Result> {
        return new Promise<Result>((resolve, reject) => {

            var imgData = this.tempLogoDataSubject.value;
            var rawImgData: any;

            const tenant_id = sessionStorage.getItem('t');
            const path = `tenants/${tenant_id}/templates/${templateId}/logo`;

            // Create a reference to the file we want to download
            const ref = this.storage.ref(path);


            if (imgData === null) {
                reject();
                return;
            }

            // Ensures file uploaded is an img
            var mimeType = imgData.type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                reject();
                return;
            } else if (mimeType.match(/imagesvg\+xml|image\/svg\+xml/)) {
                console.log("Only non svg images are supported.");
                reject();
                return;
            }

            // Converts file to data of type ArrayBuffer
            var reader = new FileReader();
            reader.readAsDataURL(imgData);
            reader.onload = (_event) => {
                rawImgData = reader.result;
            }
            console.log('📷', rawImgData);

            const task = this.storage.upload(path, imgData);

            // Tracks state of upload
            task.snapshotChanges().subscribe(result => {
                console.log('📷', result.state);
                if (result.state === 'success') {

                    // get the img URL
                    this.getTemplateLogoFromStorage(templateId).then(res => {
                        if (res.success) {
                            resolve({
                                success: true,
                                data: res.data,
                            });
                        }
                    }).catch(err => {
                        console.error('Unable to retreive Dep Logo URL');
                        reject();
                    })

                } else if (result.state === 'error') {
                    reject();
                } else {
                    console.log('📷', 'still processing image');
                }
            })

            // observe percentage changes
            task.percentageChanges().subscribe(progress => {
                this.uploadProgressSubject.next(progress);
                console.log('📷', 'Uploading progress: ', progress);
                if (progress === 100) {

                }
            });

            // Handle any errors
            task.catch(err => {
                console.error('📷', err);
                switch (err.name) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        // Failed in storage rules
                        reject();
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        reject();
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        reject();
                        break;
                }
            });
        });
    }


    setTempLogoImageData(data: any) {
        this.tempLogoDataSubject.next(data);
        // Converts file to data of type ArrayBuffer
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (_event) => {
            this.tempLogoDataUrlSubject.next(reader.result);
            console.log('📷', this.tempLogoDataUrlSubject.value);
        }
    }

    resetTempLogoImageData() {
        this.tempLogoDataSubject.next(null);
        this.tempLogoDataUrlSubject.next(null);
    }




















    // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS 
    // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS 
    // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS 
    // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS // WALLET PASS 



    getTemplatePassLogoFromStorage(templateId: string): Promise<Result> {
        const tenant_id = sessionStorage.getItem('t');

        const path = `tenants/${tenant_id}/templates/${templateId}/pass-logo`;
        // Create a reference to the file we want to download
        const ref = this.storage.ref(path);

        return new Promise<Result>(async (resolve, reject) => {

            // Get the download URL
            ref.getDownloadURL().toPromise().then((url) => {
                // Insert url into an <img> tag to "download"
                console.log('Pass Logo Photo 📷', url);
                this.logoSubject.next(url);
                resolve({
                    success: true,
                    data: url,
                })
            }).catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.error('📷', error);
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
                reject();
            });
        })
    }




    uploadTemplatePassLogoToStorage(templateId: string): Promise<Result> {
        return new Promise<Result>((resolve, reject) => {

            var imgData = this.tempLogoDataSubject.value;
            var rawImgData: any;

            const tenant_id = sessionStorage.getItem('t');
            const path = `tenants/${tenant_id}/templates/${templateId}/pass-logo`;

            // Create a reference to the file we want to download
            const ref = this.storage.ref(path);


            if (imgData === null) {
                reject();
                return;
            }

            // Ensures file uploaded is an img
            var mimeType = imgData.type;
            if (mimeType.match(/image\/*/) == null) {
                console.log("Only images are supported.");
                reject();
                return;
            } else if (mimeType.match(/imagesvg\+xml|image\/svg\+xml/)) {
                console.log("Only non svg images are supported.");
                reject();
                return;
            }

            // Converts file to data of type ArrayBuffer
            var reader = new FileReader();
            reader.readAsDataURL(imgData);
            reader.onload = (_event) => {
                rawImgData = reader.result;
            }
            console.log('📷', rawImgData);

            const task = this.storage.upload(path, imgData);

            // Tracks state of upload
            task.snapshotChanges().subscribe(result => {
                console.log('📷', result.state);
                if (result.state === 'success') {

                    // get the img URL
                    this.getTemplatePassLogoFromStorage(templateId).then(res => {
                        if (res.success) {
                            resolve({
                                success: true,
                                data: res.data,
                            });
                        }
                    }).catch(err => {
                        console.error('Unable to retreive Template Pass Logo URL');
                        reject();
                    })

                } else if (result.state === 'error') {
                    reject();
                } else {
                    console.log('📷', 'still processing image');
                }
            })

            // observe percentage changes
            task.percentageChanges().subscribe(progress => {
                this.uploadProgressSubject.next(progress);
                console.log('📷', 'Uploading progress: ', progress);
                if (progress === 100) {

                }
            });

            // Handle any errors
            task.catch(err => {
                console.error('📷', err);
                switch (err.name) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        // Failed in storage rules
                        reject();
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        reject();
                        break;
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        reject();
                        break;
                }
            });
        });
    }


    setTempPassLogoImageData(data: any) {
        this.tempPassLogoDataSubject.next(data);
        // Converts file to data of type ArrayBuffer
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (_event) => {
            this.tempPassLogoUrlSubject.next(reader.result);
            console.log('📷', this.tempPassLogoUrlSubject.value);
        }
    }

    resetTempPassLogoImageData() {
        this.tempPassLogoDataSubject.next(null);
        this.tempPassLogoUrlSubject.next(null);
    }
}
<div class="bg-container">
    <div class="container-fluid" [@fade-in] *ngIf="!load_completed">
        <div class="position-relative">
            <ngx-spinner 
            name="userTenantsSpinner"
            [fullScreen]="false"
            bdColor="rgba(255, 255, 255, 0)"
            size="default"
            color="#FFFFFF"
            type = "ball-clip-rotate-multiple"
            >
            </ngx-spinner>
        </div>
    </div>



    <nav class="topnav navbar navbar-expand fixed-top" *ngIf="load_completed" [@fade-in]="load_completed">
        <!-- <a class="navbar-brand mr-4"><img class="nav-logo" src="assets/logos/icon_white_accent.svg"></a> -->
        <!-- Navbar-->
        <ul class="navbar-nav ml-auto mr-0 mr-md-3 my-2 my-md-0">
            <button class="btn btn-sm btn-auxiliary" (click)="logOut()">Log Out</button>
        </ul>
    </nav>




    <div class="container-fluid" *ngIf="orgItems && load_completed" [@fade-in]="load_completed">
        
        <h2 class="h2 text-center">Select Your Organization</h2>
        <p class="p text-center">Which account would you like to enter?</p>

        <div class="row card-row justify-content-center" *ngFor="let org of orgItems; let orgIndex = index;">
            <div class="card dark-shadow">
                <a class="stretched-link shadow" [routerLink]="" (click)="routeToTenant(orgIndex)"></a>
                <div class="card-body">
                    <div class="m-auto text-center" >
                        <img class="org-logo-lg org-logo-center" loading="lazy" [src]="(logoUrls[orgIndex] | async) || 'assets/icon_clear.svg'" >
                        <ng-template #loading class="org-logo-skeleton"></ng-template>
                    </div>
                    <div class="text-center mt-4">
                        <h5>{{ org.name }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="" *ngIf="!orgItems" [@fade-in]="!orgItems">
        <h2 class="h2 text-center">Your Organizations</h2>
        <p class="p text-center">We are searching for your organizations, please be patient!</p>
    </div>


</div>

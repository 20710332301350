import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// MODELS
import { Template, DefaultTemplate, DefaultTemplateDark, TenantTemplates } from '@app/core/models/template.model';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { Result } from '@app/core/models/api.model';
import { environment } from '@env/environment';

import { TemplateMediaService } from '@core/services/template-media/template-media.service';

@Injectable({
    providedIn: 'root'
})
export class TemplatingService {

    // Holds an object of templates with keys of template id's
    private tenantTemplatesRef: Observable<TenantTemplates<Template>>;
    tenantTemplatesSub: Subscription;
    private tenantTemplatesSubject = new BehaviorSubject<TenantTemplates<Template>>(null);
    public tenantTemplates = this.tenantTemplatesSubject.asObservable().pipe(distinctUntilChanged());



    // Selected template from admin panel
    private selectedTemplateIdSubject = new BehaviorSubject<string>(null);
    public selectedTemplateId = this.selectedTemplateIdSubject.asObservable().pipe(distinctUntilChanged());
    private selectedTemplateSubject = new BehaviorSubject<Template>(null);
    public selectedTemplate = this.selectedTemplateSubject.asObservable().pipe(distinctUntilChanged());




    // The template when loaded from DB or a default/selected starter
    private newTemplateSubject = new BehaviorSubject<Template>(null);
    public newTemplate = this.newTemplateSubject.asObservable().pipe(distinctUntilChanged());

    // Starts as above subject, but stores changes user makes to changes as unsaved items.
    private newTemplateChangesSubject = new BehaviorSubject<Template>(null);
    public newTemplateChanges = this.newTemplateChangesSubject.asObservable().pipe(distinctUntilChanged());


    // The theme selected for a new template
    private newTemplateThemeSubject = new BehaviorSubject<boolean>(true);
    public newTemplateTheme = this.newTemplateThemeSubject.asObservable().pipe(distinctUntilChanged());

    private baseUrl: string = environment.api.url;

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private templateMediaService: TemplateMediaService,
    ) { }


    // Sets the reference to tenants templates
    setAllTenantTemplates() {
        const tenant_id = sessionStorage.getItem('t');

        this.tenantTemplatesRef = this.db.object<TenantTemplates<Template>>(`tenant_templates/${tenant_id}`).valueChanges();
        this.fetchAllTenantTemplates();
    }

    private fetchAllTenantTemplates() {
        this.tenantTemplatesSub = this.tenantTemplatesRef.subscribe(res => {
            this.tenantTemplatesSubject.next(res);
            console.log('Tenants Templates: ', res);
        });
    }


    async createTemplate(): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {

            const functionUrl = environment.api.templates.createTemplate;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                template: this.newTemplateChangesSubject.value,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Created template', result);

                let template_id = result.data['template_id'];
                if (result.success) {
                }
                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }


    setSelectedTemplate(template: Template, id: string) {
        this.selectedTemplateSubject.next(template);
        this.newTemplateSubject.next(template);
        this.newTemplateChangesSubject.next(template);

        this.selectedTemplateIdSubject.next(id);

        console.log(template);
        console.log(this.newTemplateSubject.value)
    }


    // Saves changes in a seperate subject as they happen
    updateTemplate(modifiedTemplate: Template) {
        this.newTemplateChangesSubject.next(modifiedTemplate);
    }

    // saves any changes to the DB and update parent subject
    async saveTemplateChanges(): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            console.log('🟣', this.newTemplateChangesSubject.value);
            console.log('🟣🟣', this.selectedTemplateIdSubject.value);

            
            await this.templateMediaService.uploadTemplateCoverToStorage(this.selectedTemplateIdSubject.value).then(res => {
                console.log(res);
                if (res.success) {
                    this.newTemplateChangesSubject.value.web.cover_photo = res.data as string;
                }
            }).catch(err => {
                console.log(err);
                // Was not able to upload a cover photo
                // Likely because one was not added by user
            });

            await this.templateMediaService.uploadTemplateLogoToStorage(this.selectedTemplateIdSubject.value).then(res => {
                console.log(res);
                if (res.success) {
                    this.newTemplateChangesSubject.value.web.logo = res.data as string;
                }
            }).catch(err => {
                console.log(err);
                // Was not able to upload a logo photo
                // Likely because one was not added by user
            });

            // upload apple wallet media
            await this.templateMediaService.uploadTemplatePassLogoToStorage(this.selectedTemplateIdSubject.value).then(res => {
                console.log(res);
                if (res.success) {
                    // this.newTemplateChangesSubject.value.apple.logos. = res.data as string;
                }
            }).catch(err => {
                console.log(err);
                // Was not able to upload a logo photo
                // Likely because one was not added by user
            });
            // Upload Email signature media
    
            // Call api to update DB
            this.newTemplateChangesSubject.value.last_updated = Date.now().toString();


            const functionUrl = environment.api.templates.updateTemplate;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                template: this.newTemplateChangesSubject.value,
                template_id: this.selectedTemplateIdSubject.value,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Updated template', result);

                if (result.success) {
                }
                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }


    resetTemplate() {
        this.newTemplateChangesSubject.next(this.newTemplateSubject.value);
    }


    // Sets the theme for a newly created template
    setTheme(isLight: boolean) {
        this.newTemplateThemeSubject.next(isLight);
        if (isLight) {
            this.newTemplateSubject.next(DefaultTemplate);
            this.newTemplateChangesSubject.next(DefaultTemplate);
        } else {
            this.newTemplateSubject.next(DefaultTemplateDark);
            this.newTemplateChangesSubject.next(DefaultTemplateDark);
        }
    }







    async deleteTemplate(): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            const functionUrl = environment.api.templates.deleteTemplate;

            await this.http.post(`${this.baseUrl}${functionUrl}`, {
                tenant_id: sessionStorage.getItem('t'),
                template_id: this.selectedTemplateIdSubject.value,
            }).toPromise().then(res => {
                let result = res as Result
                console.log('Deleted template', result);

                if (result.success) {
                }
                resolve(result);

            }).catch(err => {
                console.error(err);
                reject();
            });
        })
    }
}

import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { SnackBarNotification, SnackBarNotificationType } from "@core/models/snack-bar.model";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private subject = new Subject<SnackBarNotification>();
  private idx = 0;


  private timeout: number = 3000;

  constructor() { }

  getObservable(): Observable<SnackBarNotification> {
    return this.subject.asObservable();
  }

  info(message: string) {
    this.subject.next(new SnackBarNotification(this.idx++, SnackBarNotificationType.info, message, this.timeout));
  }

  success(message: string) {
    this.subject.next(new SnackBarNotification(this.idx++, SnackBarNotificationType.success, message, this.timeout));
    console.log('Notif Success service');
  }

  warning(message: string) {
    this.subject.next(new SnackBarNotification(this.idx++, SnackBarNotificationType.warning, message, this.timeout));
  }

  error(message: string) {
    this.subject.next(new SnackBarNotification(this.idx++, SnackBarNotificationType.error, message, this.timeout));
  }
}


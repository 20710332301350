import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInUser = () => redirectLoggedInTo(['auth/orgs']);

// Components
import { AdminComponent } from '@app/modules/admin/pages/admin/admin.component';
import { AdminNewTemplateComponent } from '@app/modules/admin/pages/admin-new-template/admin-new-template.component';
import { AdminTemplatePreviewComponent } from '@app/modules/admin/pages/admin-template-preview/admin-template-preview.component';
import { AdminSubscriptionChangeComponent } from '@app/modules/admin/pages/admin-subscription-change/admin-subscription-change.component';
import { AdminInviteComponent } from '@app/modules/admin/components/admin-invite/admin-invite.component';


const routes: Routes = [
  {path: '', component: AdminComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'subscription', component: AdminSubscriptionChangeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'template', canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, children: [
    {path: 'general', component: AdminNewTemplateComponent},
    {path: 'web', component: AdminNewTemplateComponent},
  ]},
  {path: 'preview', component: AdminTemplatePreviewComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'invite', component: AdminInviteComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
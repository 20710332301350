<app-side-nav></app-side-nav>
<app-top-nav></app-top-nav>

<div class="container-fluid mb-4" [@fade-in]>

    <p class="text-muted mb-4">View, manage, and update your Social Card.</p>
    <div style.width="100%" [style.height.px]="200" class="bg-gradient p-4 shadow-sm">
        <div class="row">
            <div class="col-lg-6 col-12">
                <h1 class="font-weight-bolder">Card Name Here</h1>
                <p class="white">This is a card description.</p>
            </div>
            <div class="col-lg-6 col-0 d-lg-block d-none">
                <img class="banner-img" src="assets/share.svg">
            </div>
        </div>
    </div>


    <div class="mt-4 mb-4">
        <div>
            <tabset class="" type="pills" >
                <tab  heading="Overview" customClass="customTabs">
                    <app-card-view-overview></app-card-view-overview>
                </tab>
                <tab  heading="Front Fields" customClass="customTabs">
                    
                </tab>
                <tab  heading="QR" customClass="customTabs">
                    
                </tab>
                <tab  heading="Back Fields" customClass="customTabs">
                    
                </tab>
                <tab  heading="Media" customClass="customTabs">
                    
                </tab>
                <tab  heading="Preview & Download" customClass="customTabs">
                    
                </tab>  
                <tab  heading="Share" customClass="customTabs">
                    
                </tab>
                
            </tabset>
        </div>
    </div> 
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo, loggedIn } from '@angular/fire/auth-guard';
import { SessionGuardGuard as SessionGuard }from '@core/guards/session-guard/session-guard.guard';
import { PermissionGuard } from '@core/guards/permission-guard/permission.guard';
import { SubscriptionGuard } from '@app/core/guards/subscription-guard/subscription.guard';

// Comps
import { ErrorComponent } from '@app/shared/components/error/error.component';
import { PageNotFoundComponent } from '@app/shared/components/page-not-found/page-not-found.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInUser = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {path: '', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), canActivate: [AngularFireAuthGuard, SessionGuard], canActivateChild: [SubscriptionGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},

  {path: 'auth', loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)}, //Guards in child routes
  {path: 'analytics', loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule), canActivate: [AngularFireAuthGuard, SessionGuard], canActivateChild: [SubscriptionGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'departments', loadChildren: () => import('./modules/departments/departments.module').then(m => m.DepartmentsModule), canActivate: [AngularFireAuthGuard, SessionGuard], canActivateChild: [SubscriptionGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AngularFireAuthGuard, SessionGuard], canActivateChild: [SubscriptionGuard, PermissionGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'templates', loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule), canActivate: [AngularFireAuthGuard, SessionGuard], canActivateChild: [SubscriptionGuard, PermissionGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},

  {path: 'profile', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule), canActivate: [AngularFireAuthGuard, SessionGuard], canActivateChild: [SubscriptionGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},


  // Invitation Acceptance 
  {path: 'invite', loadChildren: () => import('./modules/invites/invites.module').then(m => m.InvitesModule)},

  // UNAUTHENTICATED PUBLIC ROUTE
  {path: 'social.card', loadChildren: () => import('./modules/card/card.module').then(m => m.CardModule)},


  {path: 'error', component: ErrorComponent},
  {path: '404', component: PageNotFoundComponent},
  {path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import { fadeInAnimation } from '@app/shared/animations/animations';

// Stripe JS NPM package
import {loadStripe} from '@stripe/stripe-js';

// Services
import { StripeService } from '@core/services/stripe/stripe.service';
import { NewTenantService } from '@core/services/tenant/new-tenant/new-tenant.service';

import { AuthService } from '@app/core/services/auth/auth.service';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';


@Component({
  selector: 'app-stripe-checkout',
  templateUrl: './stripe-checkout.component.html',
  styleUrls: ['./stripe-checkout.component.css'],
  animations: [fadeInAnimation]
})
export class StripeCheckoutComponent implements OnInit, OnDestroy {

  stripe: any;
  cardElement: any;

  card: any;
  cardInfo: any;

  plan: string;
  paymentTerm: string;

  customerId: string;
  priceId: string;
  price: number = 0;
  userPrice: number = 0;
  users: number = 1;

  monthlyTotal: number = 0;
  subtotal: number = 0;

  planDesc: string;

  error: boolean;
  errorMessage: string;


  buttonStatus: boolean = true;

  subtotalDescription = "Your subtotal is a multiple of the monthly plan price and the number of users in your programs account."

  planSub: Subscription;
  termSub: Subscription;

  constructor(
    private stripeService: StripeService,
    private newTenantService: NewTenantService,
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { 
    this.planSub = this.newTenantService.newTenantsPlan.subscribe(plan => {
      this.plan = plan;
      if (plan === '' || plan === null) {
        this.router.navigate(['auth/org-setup']);
      }
    })
    this.termSub = this.newTenantService.newTenantPaymentTerm.subscribe(term => {
      this.paymentTerm = term;
    })
  }

  
  

  ngOnInit(): void {
    this.setPriceId();
    this.setSubtotal();

    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
  }
  ngOnDestroy(): void {
    this.planSub.unsubscribe();
    this.termSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.initiateCardElement();
  } 

  //Sets up STRIPE Elements within the client component
  async initiateCardElement() {
    this.stripe = await loadStripe(environment.stripe.key);
    console.log(this.stripe)
    const elements = this.stripe.elements();
    // Set up Stripe.js and Elements to use in checkout form
    var style = {
      base: {
        color: "#464646",
        fontFamily: '"Montserrat", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#6c757d"
        }
      },
      invalid: {
        color: "#dc3545",
        iconColor: "#dc3545"
      }
    };

    this.cardElement = elements.create("card", { style: style });
    this.cardElement.mount("#card-element");

    this.cardElement.on('change', (event) => {
      this.showCardError(event);
    });
  }



  // Start Subscription Flow by generating the stripe customer.
  async createStripeCustomer() {

    this.spinner.show('checkoutSpinner');
    //Disabled subscribe button
    this.buttonStatus = true;

    await this.authService.anonymousLogin().then(res => {

      if (res) {
        this.stripeService.createCustomer().then(result => {
      
          console.log(result);
          this.customerId = result.data['customer'];
          //Once customer is created, initiate sub setup

          this.snackBarService.success('Creating payment account');

          this.createPaymentMethod();
    
        }).catch(err => {
          this.showCardError(err);
          this.buttonStatus = false;
          this.spinner.hide('checkoutSpinner');
        })
      }

      // TODO: Show client error
      return


    }).catch(err => {
      this.showCardError(err);
      this.buttonStatus = false;
      this.spinner.hide('checkoutSpinner');
    })
   

    
  }


  
  // Creates the payment method with the users entry and then calls for sub creation on success
  createPaymentMethod() {
    // Calls stripe js library to generate payment method from info provided
    console.log(this.stripe, this.cardElement)
    return this.stripe
      .createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      })
      .then((result) => {
        // If payment method was not successfully created, show error to client
        if (result.error) {
          this.showCardError(result.error);
          this.buttonStatus = false;
          this.spinner.hide('checkoutSpinner');
        } else {
          console.log(result.paymentMethod.id);
          // call API to create the subscription using the generated method, customer, and, plan
          this.stripeService.createSubscription({
            customerId: this.customerId,
            paymentMethodId: result.paymentMethod.id,
            priceId: this.priceId,
            quantity: 1,
          }).then(data => {
            if (data.success) {
              console.log('Successfully created Subscription');

              const uid = this.authService.getAnonymousUid();

              var stripe = this.stripeService.getCurrentTenantSubscriptionObject();
              stripe.plan = this.newTenantService.getSelectedPlan();

              const data = {
                tenant: this.newTenantService.getNewTenantDetails(),
                stripe: stripe,
                owner: {}
              }
              data.owner[uid] = 0;
              
              // adds tenant details to db
              this.newTenantService.createTenant(data).then(res => {
                if (res.success) {
                  this.snackBarService.success('Successfully created organization');
                  this.router.navigate(['/auth/account-setup'], { queryParams: { 'type':  'link'} });
                }
              }).catch(err => {
                this.showCardError(err);
                this.buttonStatus = false;
                this.spinner.hide('checkoutSpinner');
              });

              // Adds sub details to

              
            }
            
          }).catch(err => {
            console.error(err);
            this.showCardError(err);
            this.buttonStatus = false;
            this.spinner.hide('checkoutSpinner');
          });
        }
    });
  }


  // Handles errors on the client and displays their message
  showCardError(event) {
    let displayError = document.getElementById('card-errors');
    if (event.complete) {
      this.buttonStatus = false;
    }
    else if (event.error) {
      displayError.textContent = event.error.message;
      this.buttonStatus = true;
    } else {
      displayError.textContent = '';
    }
  }




  // Retreives the stripe PriceId for the clients selected TERM and PLAN
  setPriceId(): string {
    switch(this.paymentTerm) {
      case 'annual': {
        switch (this.plan) {
          case 'startup': {
            this.priceId = environment.stripe.startupAnnualPriceId
            this.price = 7.20;
            this.userPrice = 0.80;
            return this.priceId;
          }
          case 'growth': {
            this.priceId = environment.stripe.growthAnnualPriceId;
            this.price = 15.20;
            this.userPrice = 1.60;
            return this.priceId;
          }
          default: {}
        }
      }
      case 'monthly': {
        switch (this.plan) {
          case 'startup': {
            this.priceId = environment.stripe.startupMonthlyPriceId;
            this.price = 9.00;
            this.userPrice = 1.00;
            return this.priceId;
          }
          case 'growth': {
            this.priceId = environment.stripe.growthMonthlyPriceId;
            this.price = 19.00;
            this.userPrice = 2.00;
            return this.priceId;
          }
          default: {}
        }
      }
      default: {}
    }
  }

  setSubtotal() {
    if (this.paymentTerm === 'annual') {
      this.monthlyTotal = this.price + this.userPrice;
      this.subtotal = (this.price * 12) + (this.userPrice * 12);
    } else {
      this.monthlyTotal = this.price + this.userPrice;
      this.subtotal = this.price + this.userPrice;
    }
  }
    
  

}

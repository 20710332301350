<app-top-nav></app-top-nav>
<app-side-nav></app-side-nav>

<div class="cover" [@fade-in]>
    <div class="cover-text">
        <div class="d-flex justify-content-center">
            <div class="mr-2">
                👋
            </div>
            <p>Welcome, let's get started</p>
        </div>
        <h1>Take a few minutes to finalize your organizations account.</h1>
    </div>
</div>



<div *ngIf="!invitesSent" class="container-fluid form-container-fluid" [@fade-in]>


    <div class="">
        <h2 class="h2">Invite your team members</h2>
        <p>Get your organization up and running faster by inviting your team and decision-makers.</p>
    </div>

    <div class="row card-row section-margin">
        <div class="col col-12 mb-4">
            <form [formGroup]="inviteForm">
                <div formArrayName="items"
                    *ngFor="let item of inviteForm.get('items')['controls']; let i = index;" [@fade-in]>
                    <div class="card shadow mb-4">
                        <button class="nav-btn remove-member-btn" (click)="removeItem(i)">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x"
                                width="16" height="16" viewBox="0 0 24 24" stroke-width="1" stroke="#000000"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                        <div class="card-body t-small">
                            <div [formGroupName]="i">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input class="form-control" formControlName="email" placeholder="Email">
                                    <div *ngIf="items.controls[i].get('email').invalid && (items.controls[i].get('email').dirty || items.controls[i].get('email').touched)"
                                        class="form-text text-muted">
                                        <small *ngIf="items.controls[i].get('email').errors.required"
                                            class="validation-error">An email is required for each
                                            invitation.</small>
                                        <small *ngIf="items.controls[i].get('email').errors.email"
                                            class="validation-error">A valid email address is required for each
                                            invitation.</small>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Role</label>
                                    <select class="custom-select" formControlName="role" placeholder="Role">
                                        <option value="" disabled>Role</option>
                                        <option *ngFor="let role of roles" [value]="role">
                                            {{ role }}
                                        </option>
                                    </select>
                                    <div *ngIf="items.controls[i].get('role').invalid && (items.controls[i].get('role').dirty || items.controls[i].get('role').touched)"
                                        class="form-text text-muted">
                                        <small *ngIf="items.controls[i].get('role').errors.required"
                                            class="validation-error">A role is required for each
                                            invitation.</small>
                                        <!-- <small *ngIf="items.controls[i].get('role').errors.email" class="validation-error">A valid email address is required for each invitation.</small> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Exposed item name: {{ inviteForm.controls.items.controls[i].controls.name.value }} -->
                </div>
            </form>
        </div>

        <button class="btn btn-auxiliary w-100 mb-4" (click)="addItem()">Add Another Member</button>
    </div>
</div>



<div *ngIf="invitesSent" class="container-fluid" [@fade-in]>
    <div class="">
        <h3 class="h3">🎉🎉 Invites Successfully Sent 🎉🎉</h3>
        <p>Your team members have received their invites via email. If an email was not received or a typo is found,
            you can always manage your team from the admin settings.</p>
    </div>
    <button class="btn btn-auxiliary btn-sm" (click)="resetInviteForms()">Invite More Members</button>
</div>




<div class="container-fluid form-container-fluid mt-4" [@fade-in]>
    <button *ngIf="!invitesSent" class="btn btn-primary w-100 mb-4" (click)="openModal(inviteConfirmationModal)"
        [disabled]="inviteForm.invalid || inviteForm.untouched || inviteForm.pristine || inviteForm.value.items.length === 0">Send
        {{ inviteForm.value.items.length }} Invite(s)</button>
    <button *ngIf="invitesSent" class="btn btn-primary w-100 mb-4"
        routerLink="/onboarding/departments">Next</button>

    <div class="text-center">
        <a class="" routerLink="/">Finish Later</a>
    </div>
</div>

    <!-- <pre class="">{{inviteForm.value.items | json}}</pre> -->

<ngx-spinner name="inviteTeamSpinner" [fullScreen]="true" bdColor="rgba(255, 255, 255, 0.9)" size="default"
    color="#5D656C" type="ball-clip-rotate-multiple">
    <small>Sending your invitations. Please be patient, this may take a few moments.</small>
</ngx-spinner>






<ng-template #inviteConfirmationModal>
    <div class="modal-card">
        <div class="card shadow">
            <div class="card-body d-flex flex-column">

                <div class="mb-4">
                    <h2 class="">Are you sure?</h2>
                </div>
                <p class="mb-4"><strong>Are you sure you want to invite these users to your Social Card
                        account?</strong></p>

                <small class="text-muted">IMPORTANT BILLING NOTICE: Changes to your subscription will automatically
                    take effect for new users based on your team's current plan and active user count. <a
                        href="https://www.joinsocialcard.com/billing" target="_blank">Learn more</a></small>

                <p class="mt-4 text-muted">Invites Queued: {{ inviteForm.value.items.length }}</p>

                <div class="mt-4 text-center">
                    <button class="btn btn-primary mt-4 w-100" (click)="sendUserInvites()">Yes, Send
                        Invites</button>
                    <a (click)="modalRef.hide()" class="" [routerLink]=""><small>No, Cancel</small></a>
                </div>
            </div>
        </div>
    </div>

</ng-template>
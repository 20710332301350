<div class="content-nav">

    <div class="d-flex justify-content-between collapse-section-header">

        <div class="section-text">
            Call To Action
        </div>
    </div>

    <div>
        <div class="control-item-group"> 
            <div class="control-item">
                <div class="current-item-label">
                    Content Control
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.cta_content_control"  [(ngModel)]="currentTemplateSettings.web.cta_content_control" (ngModelChange)="toggleCtaContentControl($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.cta_content_control" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.cta_content_control" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, the call to action button is NOT editable by your team.</small>
                </div>
                <div class="current-item-border"></div>
            </div>


            <div class="control-item">
                <div class="current-item-label">
                    Analytics Enabled
                    <!-- Current Item Label -->
                </div>
                <div class="current-item">
                    <div class="d-flex">
                        <label class="switch">
                            <input type="checkbox" [checked]="currentTemplateSettings.web.cta_analytics_enabled"  [(ngModel)]="currentTemplateSettings.web.cta_analytics_enabled" (ngModelChange)="togglingCtaOneAnalytics($event)">
                            <span class="slider round"></span>
                        </label>
                        <p *ngIf="currentTemplateSettings.web.cta_analytics_enabled" class="slider-text ml-4 mb-0">ON</p>
                        <p *ngIf="!currentTemplateSettings.web.cta_analytics_enabled" class="slider-text ml-4 mb-0">OFF</p>
                    </div>
                    
                    <small>When “ON”, interaction with Call To Action Button will be monitored by Social Card Analytics.</small>
                </div>
            </div>
        </div>
    </div>
</div>














































<div id="preview" class="h-100">

    <div class="h-100 preview-bg" [@fade-in]>
        <div class="preview-container">

        
            <div class="card shadow" [style.background-color]="currentTemplateSettings.web.background_color">
                <div class="card-body">
                    <div class="mb-4">
                        <span class="card-label mb-4">Call To Action</span>
                    </div>
                    <div class="web-content-container">

                        <div class="web-accessory-link" 
                            *ngIf="currentTemplateSettings.web.cta_content_type.type.data_type != 'vcard'"
                            (click)="routeToLink()"
                            [style.background-color]="currentTemplateSettings.web.cta_fill_color" 
                            [style.color]="currentTemplateSettings.web.cta_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.cta_corner_radius" 
                            [ngStyle]="ctaHovered ? shadowHovered(this.currentTemplateSettings.web.cta_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.cta_shadow_color)"
                            (mouseover)="ctaHovered = true"
                            (mouseout)="ctaHovered = false">
                            <div class="d-flex">
                                <app-svg-assets [type]="currentTemplateSettings.web.cta_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.cta_text_color"></app-svg-assets>

                                <div class="web-accessory-link-text">{{ currentTemplateSettings.web.cta_content_label || currentTemplateSettings.web.cta_content_type.name }}</div>
                            </div>
                        </div>

                        <div class="web-accessory-link" 
                            *ngIf="currentTemplateSettings.web.cta_content_type.type.data_type === 'vcard'"
                            id="downloadButton" [vcdDownloadVCard]="getVcard()" [encoding]="vCardEncoding.none"
                            [style.background-color]="currentTemplateSettings.web.cta_fill_color" 
                            [style.color]="currentTemplateSettings.web.cta_text_color" 
                            [style.border-radius.px]="currentTemplateSettings.web.cta_corner_radius" 
                            [ngStyle]="ctaHovered ? shadowHovered(this.currentTemplateSettings.web.cta_shadow_color) : shadowNotHovered(this.currentTemplateSettings.web.cta_shadow_color)"
                            (mouseover)="ctaHovered = true"
                            (mouseout)="ctaHovered = false">
                            <div class="d-flex">
                                <app-svg-assets [type]="currentTemplateSettings.web.cta_content_type.type.data_type" [size]="40" [stroke]="currentTemplateSettings.web.cta_text_color"></app-svg-assets>

                                <div class="web-accessory-link-text">{{ currentTemplateSettings.web.cta_content_label || currentTemplateSettings.web.cta_content_type.name }}</div>
                            </div>
                        </div>

                        <div class="mt-4">
                            <small>NOTE: Click to test the cta button.</small>
                        </div>
                    </div>
                </div>
            </div>





















            <div class="card shadow mt-4" [@fade-in]>
                <div class="card-body">
             
                    <div class="web-content-container">
                        <div class="">
                            <span class="card-label mb-4">Content type</span>
                            <p>Select the type of content you want displayed in the call to action button.</p>
                        </div>
                        
                        <div class="current-item-border mt-2 mb-4"></div>

                        <div class="" *ngIf="ctaContentTypeSelection">
                            <div class="mb-4" *ngFor="let section of availableContentTypes; let i = index;">
                                <h4 class="mb-3">{{ sectionNames[i] }}</h4>
                                <div class="row mx-auto">
                                    <div class="content-type-container" [tooltip]="option.name" *ngFor="let option of section;" (click)="ctaContentTypeChange(option)">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='option.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ option.name }}</div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
                        <div class="" *ngIf="ctaContentEntry">
                            <div class="">
    
                                <div class="d-flex justify-content-center">
                                    <div class="content-type-container" (click)="changeCtaContentType()">
                                        <div class="content-type-container-svg">
                                            <app-svg-assets [type]='this.currentTemplateSettings.web.cta_content_type.type.data_type' [size]="60" [stroke]=""></app-svg-assets>
                                        </div>
                                        
                                        <div class="web-quick-link-child-text">{{ this.currentTemplateSettings.web.cta_content_type.name }}</div>
                                    </div>
                                </div>
                                <div class="text-center mb-4">
                                    <a [routerLink]="" (click)="changeCtaContentType()">Change</a>
                                </div>
    
    
                                <app-content-type-input [type]="currentTemplateSettings.web.cta_content_type.ref" source="cta"></app-content-type-input>
    
                            </div>
                        </div>    
                    </div>
                </div>
            </div>

            <!-- <pre>{{ currentTemplateSettings | json }}</pre> -->
        </div>
    </div>
</div>


import { Injectable, OnDestroy } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';

import { Result } from '@app/core/models/api.model';
import { environment } from '@env/environment';

import { Invitee, InvitesObject, InviteeGroup, TeamUids, TeamMembers } from '@app/core/models/team.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { User } from '@app/core/models/user.model';



@Injectable({
  providedIn: 'root'
})
export class TeamService implements OnDestroy {

  private baseUrl: string = environment.api.url;

  private pendingInvite: Observable<Invitee>;

  private allInvites: Observable<InviteeGroup<Invitee>>;
  private allInvitesSub: Subscription;
  private invitesSubject = new BehaviorSubject<InviteeGroup<Invitee>>(null);
  public allTeamInvites = this.invitesSubject.asObservable().pipe(distinctUntilChanged());


  private teamUids: Observable<TeamUids>;
  private teamUidSub: Subscription;


  private team: Observable<TeamMembers<User>>;
  private allTeamMembersSub: Subscription;

  private teamSubject = new BehaviorSubject<TeamMembers<User>>(null);
  public allTeamMembers = this.teamSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private db: AngularFireDatabase,
    private http: HttpClient,
  ) { }

  ngOnDestroy(): void {
    this.allInvitesSub.unsubscribe();
    this.allTeamMembersSub.unsubscribe();
  }



  public getAllTeamMembers() {
    const tenantId = sessionStorage.getItem('t');
    // Creates observable for all of a tenants user UIDs
    this.teamUids = this.db.object<TeamUids>(`tenant_users/${tenantId}`).valueChanges();
    this.monitorTeamChanges();
  }

  private monitorTeamChanges() {
    this.teamUidSub = this.teamUids.subscribe(res => {
      // this.teamSubject.next(res);
      this.getUserInfo(res)
      console.log('Team Member UIds: ', res);
    });
  }



  // Takes an object of team member uids and loops through each retreiving the users account info.
  // 1.) 
  private async getUserInfo(users: TeamUids) {
    const tenantId = sessionStorage.getItem('t');
    let userData: TeamMembers<User> = {}; // temp stores all user data before it is pushed to observables as one large object

    for (let uid of Object.keys(users)) {
      
      const test = await this.db.list<TeamMembers<User>>(`users/${uid}/${tenantId}`).query.once("value").then(res => {
        userData[uid] = res.toJSON() as User; 
        console.log(res.toJSON() as User);
      }).catch(err => {
        console.error('Could not find user w/ error: ', err);
      });
    }

    this.teamSubject.next(userData);
  }


















  // USER INVITES

  async sendEmailInvites(invites: InvitesObject): Promise<Result> {
    console.log('Sending invites from service: ', invites);
    return new Promise<Result>(async (resolve, reject) => {

      const functionUrl = environment.api.teams.inviteUsers;

      await this.http.post(`${this.baseUrl}${functionUrl}`, invites).toPromise().then(res => {
        let result = res as Result
        console.log("Invites sent result", result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });        

    });
  }

  async resendEmailInvite(email: string, tenant_id: string, invitation_id: string): Promise<Result> {
    return new Promise<Result>(async (resolve, reject) => {

      const body ={
        email: email,
        tenant_id: tenant_id,
        invitation_id: invitation_id,
      }

      const functionUrl = environment.api.teams.resendInvite;

      await this.http.post(`${this.baseUrl}${functionUrl}`, body).toPromise().then(res => {
        let result = res as Result
        console.log("Invites resent result", result);
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject();
      });
    })
  }



  getAllPendingInvites() {
    const tenantId = sessionStorage.getItem('t');
    this.allInvites = this.db.object<InviteeGroup<Invitee>>(`tenant_pending_invitations/${tenantId}`).valueChanges();
    this.monitorInviteChanges();
  }

  monitorInviteChanges() {
    this.allInvitesSub = this.allInvites.subscribe(res => {
      this.invitesSubject.next(res);
      console.log('Team Invitations: ', res);
    });
  }





  // Calls public API func to get the new users pending invite
  async getPendingInviteData(invitation_id: string, tenant_id: string): Promise<Result> {
    return new Promise<Result>(async (resolve, reject) => {

      const functionUrl = environment.api.pub.getInvite;
      const body = {
        tenant_id: tenant_id,
        invitation_id: invitation_id,
      }

      await this.http.post(`${this.baseUrl}${functionUrl}`, body).toPromise().then(res => {
        let result = res as Result
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject(err);
      });        

    });
  }


  async updateInviteStatus(invitation_id: string, tenant_id: string, status: string): Promise<Result> {
    return new Promise<Result>(async (resolve, reject) => {
      const functionUrl = environment.api.teams.updateInvite;
      const body = {
        tenant_id: tenant_id,
        invitation_id: invitation_id,
        new_status: status,
      }

      await this.http.post(`${this.baseUrl}${functionUrl}`, body).toPromise().then(res => {
        let result = res as Result
        resolve(result);
      }).catch(err => {
        console.error(err);
        reject(err);
      }); 
    });
  }
  
  
}

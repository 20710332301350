import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

import { fadeInAnimation } from '@app/shared/animations/animations';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '@app/core/notifications/services/snack-bar.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { StripeSubscriptionObject } from '@app/core/models/stripe.model';

import { StripeService } from '@core/services/stripe/stripe.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-admin-subscription-change',
  templateUrl: './admin-subscription-change.component.html',
  styleUrls: ['./admin-subscription-change.component.css'],
  animations: [fadeInAnimation],
})
export class AdminSubscriptionChangeComponent implements OnInit {

  subscription: StripeSubscriptionObject;
  plan: string;
  priceId: string;
  term: string;

  basePrice: number;
  baseTerm: string;
  userPrice: number;
  userTerm: string;

  status: string;
  cancelAtPeriodEnd: boolean;

  // Tags
  currentPlan: boolean;
  annualSavings: boolean;
  upgrade: boolean;
  downgrade: boolean;
  enterprise: boolean;

  planGrowthAnnual: string = environment.stripe.growthAnnualPriceId;
  planGrowthMonthly: string = environment.stripe.growthMonthlyPriceId;
  planStartupAnnual: string = environment.stripe.startupAnnualPriceId;
  planStartupMonthly: string = environment.stripe.startupMonthlyPriceId;

  planChangeForm = new FormGroup({
    plan: new FormControl('', [Validators.required]),
  });
  get newPlan() { return this.planChangeForm.get('plan'); }

  constructor(
    private stripeService: StripeService,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    // this.spinner.show('subscriptionPageSpinner');
    this.loadCurrentSubscription();
  }


  loadCurrentSubscription() {
    this.spinner.show('subscriptionSpinner');
    this.stripeService.getSubscription().then(res => {
      if (res.success) {
        this.subscription = res.data as StripeSubscriptionObject;
        this.setSubPlan();
        this.status = this.subscription.status;
        this.cancelAtPeriodEnd = this.subscription.cancel_at_period_end;
        this.spinner.hide('subscriptionSpinner');
      }
    }).catch(err => {
      this.snackBarService.error('An iternal error occured in Billing');
      this.spinner.hide('subscriptionSpinner');
    });
  }








  cancelSubscription() {
    this.spinner.show('subscriptionPageSpinner');
    this.modalRef.hide();

    this.stripeService.cancelSubscription().then(res => {
      if (res.success) {
        this.subscription = res.data as StripeSubscriptionObject;
        this.setSubPlan();
        this.status = this.subscription.status;
        this.cancelAtPeriodEnd = this.subscription.cancel_at_period_end;

        this.snackBarService.success('Subscription canceled');
        this.spinner.hide('subscriptionPageSpinner');
      }
    }).catch(err => {
      this.snackBarService.error('An iternal error occured in Billing');
      this.spinner.hide('subscriptionPageSpinner');
    });
  }


  resumeSubscription() {
    this.spinner.show('subscriptionPageSpinner');
    
    this.stripeService.resumeSubscription().then(res => {
      if (res.success) {
        this.subscription = res.data as StripeSubscriptionObject;
        this.setSubPlan();
        this.status = this.subscription.status;
        this.cancelAtPeriodEnd = this.subscription.cancel_at_period_end;

        this.snackBarService.success('Subscription resumed');
        this.spinner.hide('subscriptionPageSpinner');
      }
    }).catch(err => {
      this.snackBarService.error('An iternal error occured in Billing');
      this.spinner.hide('subscriptionPageSpinner');
    });
  }


  changeSubscriptionPlan() {
    this.spinner.show('subscriptionPageSpinner');

    this.stripeService.modifySubscription(this.newPlan.value).then(res => {
      if (res.success) {
        this.subscription = res.data as StripeSubscriptionObject;
        this.setSubPlan();
        this.status = this.subscription.status;
        this.cancelAtPeriodEnd = this.subscription.cancel_at_period_end;

        this.modalRef.hide();
        this.snackBarService.success('Subscription successfully modified');
        this.spinner.hide('subscriptionPageSpinner');

      }
    }).catch(err => {
      this.snackBarService.error('An iternal error occured in Billing');
      this.spinner.hide('subscriptionPageSpinner');
    });
  }





  setSubPlan() {
    switch (this.subscription.items.data[0].plan.id) {
      case (this.planStartupAnnual): {
        this.plan = 'Startup Plan (Annual)';
        this.priceId = this.planStartupAnnual;
        this.term = 'Charged Annually';
        this.basePrice = 86.40;        
        this.userPrice = 9.60;
        this.baseTerm = 'year';
        this.userTerm = 'year';
        break;
      }
      case (this.planStartupMonthly): {
        this.plan = 'Startup Plan (Monthly)';
        this.priceId = this.planStartupMonthly;
        this.term = 'Charged Monthly';
        this.basePrice = 9.00;
        this.userPrice = 1.00;
        this.baseTerm = 'month';
        this.userTerm = 'month';
        break;
      }
      case (this.planGrowthAnnual): {
        this.plan = 'Growth Plan (Annual)';
        this.priceId = this.planGrowthAnnual;
        this.term = 'Charged Annually';
        this.basePrice = 182.40;
        this.userPrice = 19.20;
        this.baseTerm = 'year';
        this.userTerm = 'year';
        break;
      }
      case (this.planGrowthMonthly): {
        this.plan = 'Growth Plan (Monthly)';
        this.priceId = this.planGrowthMonthly;
        this.term = 'Charged Monthly';
        this.basePrice = 19.00;
        this.userPrice = 2.00;
        this.baseTerm = 'month';
        this.userTerm = 'month';
        break;
      }
      default: {
        this.plan = 'Not Found or Canceled';
        break;
      }
    }

    this.planChangeForm.patchValue({
      plan: this.priceId,
    });
  }







  setNewlySelectedPlan() {
    this.enterprise = false;

    switch (this.newPlan.value) {
      case (this.planStartupAnnual): {
        this.basePrice = 86.40;        
        this.userPrice = 9.60;
        this.baseTerm = 'year';
        this.userTerm = 'year';
        
        if (this.priceId === this.planStartupMonthly) {
          this.currentPlan = false;
          this.annualSavings = true;
          this.upgrade = false;
          this.downgrade = false;
        } 
        if (this.priceId === this.planGrowthAnnual) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = false;
          this.downgrade = true;
        } 
        if (this.priceId === this.planGrowthMonthly) {
          this.currentPlan = false;
          this.annualSavings = true;
          this.upgrade = false;
          this.downgrade = true;
        } 
        break;
      }
      case (this.planStartupMonthly): {
        this.basePrice = 9.00;
        this.userPrice = 1.00;
        this.baseTerm = 'month';
        this.userTerm = 'month';
        if (this.priceId === this.planStartupAnnual) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = false;
          this.downgrade = false;
        } 
        if (this.priceId === this.planGrowthAnnual) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = false;
          this.downgrade = true;
        } 
        if (this.priceId === this.planGrowthMonthly) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = false;
          this.downgrade = true;
        } 
        break;
      }
      case (this.planGrowthAnnual): {
        this.basePrice = 182.40;
        this.userPrice = 19.20;
        this.baseTerm = 'year';
        this.userTerm = 'year';
        if (this.priceId === this.planStartupMonthly) {
          this.currentPlan = false;
          this.annualSavings = true;
          this.upgrade = true;
          this.downgrade = false;
        } 
        if (this.priceId === this.planStartupAnnual) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = true;
          this.downgrade = false;
        } 
        if (this.priceId === this.planGrowthMonthly) {
          this.currentPlan = false;
          this.annualSavings = true;
          this.upgrade = false;
          this.downgrade = false;
        }
        break;
      }
      case (this.planGrowthMonthly): {
        this.basePrice = 19.00;
        this.userPrice = 2.00;
        this.baseTerm = 'month';
        this.userTerm = 'month';
        
        if (this.priceId === this.planStartupMonthly) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = true;
          this.downgrade = false;
        } 
        if (this.priceId === this.planStartupAnnual) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = true;
          this.downgrade = false;
        } 
        if (this.priceId === this.planGrowthAnnual) {
          this.currentPlan = false;
          this.annualSavings = false;
          this.upgrade = false;
          this.downgrade = false;
        }
        break;
      }
      default: {
        this.plan = 'Enterprise';
        this.currentPlan = false;
        this.annualSavings = false;
        this.upgrade = false;
        this.downgrade = false;
        this.enterprise = true;
        break;
      }
    }

    if (this.priceId === this.newPlan.value) { 
      this.currentPlan = true; 
      this.annualSavings = false;
      this.upgrade = false;
      this.downgrade = false;
    } else { 
      this.currentPlan = false; 
    };
    
  }




  modalRef: BsModalRef;
  
  openModal(template: TemplateRef<any>) {
    var config = {
      animated: false,
      ignoreBackdropClick: true,
    };
    this.modalRef = this.modalService.show(template, config);
  }

}
